<template>
    <div class="relative">
        <div
            v-if="parameter.id && parameter.property_id"
            style="height: 151px; width: 459px; border-radius: 20px;" 
            :style="{backgroundColor: parameter.bgColor}"
            class="border flex px-5 items-center justify-between"
        >
            <div>
                <p class="w-full truncate fs-24 fw-700 -mt-1">{{modifiedParam.title}}</p>
                <p class="w-full truncate fs-14 fw-300 hidden">Descriptive text</p>
                <div class="flex gap-x-4 items-center mt-2">
                    <div class="flex gap-x-2.5">
                        <div 
                            v-for="n in 5"
                            :key="n"
                            style="width: 15px; height: 15px; border-radius: 0.8px;"
                            :style="{ backgroundColor: getBackground(collection, activeTemplate, parameter, n, 'bg', 5) }" 
                            class="bg-secondary-four"></div>
                    </div>
                    <p class="fs-14 fw-300">{{ getParameterValueTwoDecimal(getParameterValue(collection ,parameter)) }}</p>
                </div>
                <p class="w-full truncate fs-14 fw-400 mt-2">{{ getDefinitionDescription(collection, activeTemplate, parameter) || 'Not available' }}</p>
            </div>
            <div 
                style="width: 196px"
                :style="{backgroundColor: _getParameterDefinitionColor(collection, activeTemplate, parameter)}"
                class="h-16 fs-20 fw-300 flex items-center justify-center bg-green-300 px-1 truncate text-white rounded-sm "
            >
            {{ _getParameterDefinition(collection, activeTemplate, parameter) }}
            </div>
        </div>

        <div 
            v-else
            style="height: 151px; width: 459px; border-radius: 20px;" 
            :style="{backgroundColor: parameter.bgColor}"
            class="relative border flex px-5 items-center justify-between"
        >
            <div>
                <p class="w-full truncate fs-24 fw-700 -mt-1">Impact</p>
                <p class="w-full truncate fs-14 fw-300 hidden">Descriptive text</p>
                <div class="flex gap-x-4 items-center mt-2">
                    <div class="flex" style="column-gap: 13px;">
                        <div 
                            v-for="n in 5"
                            :key="n"
                            style="width: 15px; height: 15px; border-radius: 0.8px;" 
                            class="bg-secondary-four"></div>
                    </div>
                    <p class="fs-14 fw-300">0.00</p>
                </div>
                <p class="w-full truncate fs-14 fw-400 mt-2">
                    ---
                </p>
            </div>
            <div 
                style="width: 196px" 
                class="h-16 fs-20 fw-300 flex items-center justify-center bg-green-300 px-1 truncate text-white rounded-sm "
            >
                ---
            </div>
        </div>

        <ColorIcon
            style="bottom: 12px; right: 20px;"
            :class="{'invisible': !showIcon}"
            @click.native="clickColorPicker"
            class="w-4 h-4 absolute cursor-pointer"
        />
        <input
            @input="updateActiveTemplate"
            ref="colorPickerElem" 
            type="color" 
            v-model="parameter.bgColor"
            class="absolute invisible"
            style="bottom: 13px; right: 10px;"
        />
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useMetricSummary } from '@/components/communicator/v2/templates/metricSummary/useMetricSummary.js'

const props = defineProps({
    parameter: Object,
    modifiedParam: Object,
    showIcon: Boolean,
    templateMethods: Object,
    activeTemplate: Object,
    collection: Object,
    module: Object,
})
const {
    updateActiveTemplate,
} = props.templateMethods
const colorPickerElem = ref()
const clickColorPicker = () => colorPickerElem.value.click()
const { getParameterValue, getBackground, _getParameterDefinition, _getParameterDefinitionColor, _getProperties, getDefinitionDescription} = useMetricSummary();
const getParameterValueTwoDecimal = (val) => {
    if(val) return val.toFixed(2)
    return '0.00'
}
</script>