<template>
    <div 
        :style="{
            height: '20px',
            width: '36px',
            backgroundColor: config[name]
        }" 
        class="relative rounded cursor-pointer shadow"
        @click="onClickPicker"
    >
        <input
          ref="colorInput"
          @change="$emit('onColorChange')"
          type="color"
          v-model="config[name]"
          class="invisible"
        >
    </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
    config: Object,
    name: String
})

const colorInput = ref()
function onClickPicker() {
    colorInput.value.click()
}
</script>

<style scoped>
.shadow {
    filter: drop-shadow(0px 1px 6px rgba(44, 52, 65, 0.1));
    border: 1px solid #C9C9C9;
}
</style>