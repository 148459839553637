<template>
    <div class="flex-shrink-0 rounded-lg relative">
        <slot />
        <div class="content overflow-y-auto">
            <div v-for="(data, index) in data.subtitle" :key="'subtitle'+index">
                <SubTitle 
                    :data="data" 
                    :index="index" 
                    :conf_id="data.conf_id"
                    :switchSidebarComponent="switchSidebarComponent"
                    :config_visibility="config_visibility"
                    :activeTemplate="activeTemplate"
                    :box_type="box_type"
                    :synchronize="synchronize"
                />
                <BulletPoint 
                    v-for="(description, dIndex) in data.description" 
                    :key="'description'+dIndex"
                    :index="dIndex"
                    :data="description"
                    :subtitleConfId="data.conf_id"
                    :switchSidebarComponent="switchSidebarComponent"
                    :config_visibility="config_visibility"
                    :activeTemplate="activeTemplate"
                    :box_type="box_type"
                    :synchronize="synchronize"
                />
            </div>
            <div class="mt-10">
                <img 
                    class="block w-full object-cover rounded"
                    :src="get(data, 'image')"
                    alt="" 
                />
            </div>
        </div>

        <label class="absolute cursor-pointer" style="bottom: -26px;right: -6px;">
            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 12H6V10H18V12ZM18 7H0V5H18V7ZM18 2H6V0H18V2Z" fill="currentColor"/>
            </svg>
            <input
                @input="(e)=>{
                    onChange(e);
                    uploadImage(file, activeTemplate.id);
                }"
                hidden type="file"
            />
        </label>
    </div>
</template>

<script setup>
    import axios from 'axios'
    import { get, isEmpty } from 'lodash'
    import SubTitle from '@/components/communicator/v2/templates/boxConcept/components/SubTitle.vue'
    import BulletPoint from '@/components/communicator/v2/templates/boxConcept/components/BulletPoint.vue'
    import useBoxConcept from '../useBoxConcept'
    import { useFile } from '@/composable/useFile.js'

    const { updateImage } = useBoxConcept()
    const { onChange, file } = useFile()
    
    const props = defineProps({
        activeTemplate: Object,
        config_visibility: Boolean,
        switchSidebarComponent: Function,
        box_type: String,
        synchronize: Boolean,
        data: Object,
    })


    const uploadImage = async (file, template_id) => {
        let formData = new FormData()
        formData.append('image', file)
        let imageResponse = await axios.post(`communicators/templates/${template_id}/template-image-update`, formData)
        let imageUrl = imageResponse.data.data
        updateImage(props.activeTemplate, props.box_type, imageUrl)
    }
</script>