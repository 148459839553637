<template>
    <div class="flex-shrink-0 rounded-lg relative">
        <slot />
        <div class="content overflow-y-auto">
            <MetricComparison 
                v-for="(data, index) in data.parameter"
                :key="index"
                :data="data"
                :box_type="box_type"
                :config_visibility="config_visibility"
                :activeTemplate="activeTemplate"
                :switchSidebarComponent="switchSidebarComponent"
                :synchronize="synchronize"
            />
        </div>
    </div>
</template>

<script setup>
    import MetricComparison from '@/components/communicator/v2/templates/boxConcept/components/MetricComparison.vue'
    const props = defineProps({
        activeTemplate: Object,
        config_visibility: Boolean,
        switchSidebarComponent: Function,
        box_type: String,
        data: Object,
        synchronize: Boolean,
    })
</script>