var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative rounded flex flex-col justify-center i-gap-10 overflow-y-scroll communicator__template--wrapper",class:[_vm.currentMode != 'fullscreen' ? 'scale-down' : ''],staticStyle:{"padding-left":"303px"},style:({color: _vm.activeTemplate.settings.color, overflow: 'hidden', paddingRight: _vm.activeTemplate.settings.configuration_no < 3 ? '385px' : '235px'})},[( _vm.activeTemplate.settings.configuration_no < 3)?_c('div',{staticClass:"-mt-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeTemplate.customized_data.title.value),expression:"activeTemplate.customized_data.title.value"}],staticClass:"fw-700 fs-74 w-full bg-transparent pr-5 mb-3",class:{
        'text-center': _vm.activeTemplate.settings.configuration_no == 2
      },attrs:{"disabled":_vm.currentMode == 'fullscreen',"type":"text"},domProps:{"value":(_vm.activeTemplate.customized_data.title.value)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.activeTemplate.customized_data.title, "value", $event.target.value)},function($event){return _setup.updateTemplate(_vm.activeTemplate)}]}}),_c(_setup.EditableComponent,{staticClass:"fw-500 fs-36 w-full overflow-y-auto bg-transparent",class:{
        'text-center': _vm.activeTemplate.settings.configuration_no == 2,
        'pl-10': _vm.activeTemplate.settings.configuration_no == 2,
        'pr-10': _vm.activeTemplate.settings.configuration_no == 1
      },attrs:{"spacing":0,"isDisable":_vm.currentMode == 'fullscreen'},on:{"input":function($event){return _setup.updateTemplate(_vm.activeTemplate)}},model:{value:(_vm.activeTemplate.customized_data.description.value),callback:function ($$v) {_vm.$set(_vm.activeTemplate.customized_data.description, "value", $$v)},expression:"activeTemplate.customized_data.description.value"}})],1):_c('div',{staticClass:"flex items-center gap-x-7 mb-24"},[_c('p',{staticClass:"fw-700 fs-140 mb-4",staticStyle:{"font-size":"140px","letter-spacing":".1em"}},[_vm._v("01")]),_c('div',{staticClass:"w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeTemplate.customized_data.title.value),expression:"activeTemplate.customized_data.title.value"}],staticClass:"fw-700 fs-74 w-full bg-transparent -mt-4",attrs:{"disabled":_vm.currentMode == 'fullscreen',"type":"text"},domProps:{"value":(_vm.activeTemplate.customized_data.title.value)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.activeTemplate.customized_data.title, "value", $event.target.value)},function($event){return _setup.updateTemplate(_vm.activeTemplate)}]}}),_c(_setup.EditableComponent,{staticClass:"fw-500 fs-35 w-full overflow-y-auto bg-transparent -mt-2",attrs:{"isDisable":_vm.currentMode == 'fullscreen',"spacing":0},on:{"input":function($event){return _setup.updateTemplate(_vm.activeTemplate)}},model:{value:(_vm.activeTemplate.customized_data.description.value),callback:function ($$v) {_vm.$set(_vm.activeTemplate.customized_data.description, "value", $$v)},expression:"activeTemplate.customized_data.description.value"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }