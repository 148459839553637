<template>
    <div class="relative mt-4">
        <div :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"  class="absolute -left-20 flex items-center">
            <button 
                @click="showConfiguration('TextSource', ['description'], data.conf_id)" 
                class="mr-5 cursor-pointer"
            >
                <svg width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M15.9993 7.3125L5.58579 18.2297C5.21071 18.6165 5 19.1411 5 19.6881C5 20.2351 5.21071 20.7597 5.58579 21.1465C5.96086 21.5333 6.46957 21.7506 7 21.7506C7.53043 21.7506 8.03914 21.5333 8.41421 21.1465L20.8277 8.16682C21.1991 7.78377 21.4938 7.32904 21.6948 6.82857C21.8958 6.3281 21.9993 5.7917 21.9993 5.25C21.9993 4.7083 21.8958 4.1719 21.6948 3.67143C21.4938 3.17096 21.1991 2.71623 20.8277 2.33318C20.4563 1.95014 20.0153 1.6463 19.53 1.439C19.0447 1.2317 18.5246 1.125 17.9993 1.125C17.474 1.125 16.9538 1.2317 16.4685 1.439C15.9832 1.6463 15.5423 1.95014 15.1708 2.33318L2.75736 15.3129C1.63214 16.4733 1 18.0471 1 19.6881C1 21.3292 1.63214 22.903 2.75736 24.0634C3.88258 25.2237 5.4087 25.8756 7 25.8756C8.5913 25.8756 10.1174 25.2237 11.2426 24.0634L21.4993 13.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>                    
            </button>
            <button @click="addDescription(activeTemplate, box_type, subtitleConfId)" class="cursor-pointer mr-1">+</button>
            <button @click="removeDescription(activeTemplate, box_type, subtitleConfId, data.conf_id)" class="cursor-pointer">-</button>
        </div>
        <div class="bulletPointText relative pl-6 flex items-start">
            <i class="icon absolute"></i>
            <h1 
                v-if="description(activeTemplate, data.conf_id, 'description')"
                class="fs-16 fw-500 relative"
            >
                {{ description(activeTemplate, data.conf_id, 'description') }}
            </h1>
            <h1
                v-else
                class="fs-16 fw-500 relative"
                contenteditable="true"
                placeholder="Description"
                @input="(e) => {
                    editDescription(e.target.innerText, activeTemplate, box_type, subtitleConfId, data.conf_id )
                }"
                v-once
            >
                {{ data.value }}
            </h1>
        </div>
    </div>
</template>

<script setup>
    import { useTemplate } from '@/views/screens/communicator/useTemplate.js'
    import useBoxConcept from '../useBoxConcept';
    const props = defineProps({
        data: Object, 
        index: [String, Number], 
        subtitleConfId: [String, Number],
        switchSidebarComponent: Function,
        config_visibility: Boolean,
        currentMode: String,
        activeTemplate: Object,
        synchronize: Boolean,
        box_type: String
    })    
    const { prepareRequest, getPersistance, prepareConfiguration, description } = useTemplate();
    const { addDescription, removeDescription, editDescription } = useBoxConcept()
    
    const showConfiguration = (componentName, options, confId) => {
        props.switchSidebarComponent(componentName, {
            persistance: getPersistance(props.activeTemplate, confId),
            confId: confId,
            transform: (config) => {
                let inputs = prepareConfiguration(options, config);
                return prepareRequest(props.activeTemplate, confId, config, inputs, props.synchronize);
            }
        });
    }
</script>