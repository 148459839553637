import html2canvas from 'html2canvas';

export default function useHtml2canvas() {
    const getCanvas = (element) => {
        return html2canvas(element).then(function(canvas) {
            return canvas
        });
    }

    return {
        getCanvas
    }
}