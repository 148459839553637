<template>
    <div class="relative">
        <div class="absolute w-full grid-container" :style="{top: `${topValue}px`, height: `calc(100% - ${gridHeight})`}">
            <div v-for="(year, index) in years" :key="index" class="grid-child fs-8" :class="yearTextColor">
                <div style="margin-top: -20px">{{ year }}</div>
            </div>
        </div>
        <div class="overflow-x-hidden">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'GraphLayout',
        props: {
            years: {
                type: Array
            },
            topValue: {
                type: String,
                default: () => '0'
            },
            gridHeight: {
                type: String,
                default: () => '0px'
            },
            yearTextColor: String
        }
    }
</script>

<style scoped>

.grid-container {
    display: flex;
}
.grid-child {
    min-width: 39px;
    border-right: 1px solid #fff;
    text-align: center;
    box-sizing: content-box;
}
.grid-child:nth-child(3n) {
    border-right-width: 2px;
}
.grid-child:last-child {
    border-right: none;
}
</style>