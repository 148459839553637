<template>
    <select
        class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
        :value="value"
        @change="onChange"
    >
        <option v-if="label" value="null" selected>{{ label }}</option>
        <option v-for="(item, index) in items" :key="index" :value="item[valueKey]">{{ item[textKey] }}</option>
    </select>
</template>

<script setup>
    defineProps({
        items: Array,
        value: [String, Number, Boolean],
        label: {
            type: String,
            default: () => ''
        },
        textKey: {
            type: String,
            default: () => 'title'
        },
        valueKey: {
            type: String,
            default: () => 'id'
        }
    });
    const emit = defineEmits(['input', 'change'])
    const onChange = (e) => {
        emit('input', e.target.value);
        emit('change', e.target.value);
    }
</script>