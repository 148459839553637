import { render, staticRenderFns } from "./Roadmap.vue?vue&type=template&id=48b6b3c5&scoped=true&"
import script from "./Roadmap.vue?vue&type=script&setup=true&lang=js&"
export * from "./Roadmap.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./Roadmap.vue?vue&type=style&index=0&id=48b6b3c5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48b6b3c5",
  null
  
)

export default component.exports