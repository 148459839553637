import { useTemplate } from '@/views/screens/communicator/useTemplate'

export default function useTextContent() {
    const { updateTemplate } = useTemplate();

    const createItem = (template, index) => {
        template.customized_data.items.splice(index, 0, {
            // id: (Math.random() + 1).toString(36).substring(2),
            title: "Sample text",
            listItems: [
                {
                title:
                    "Innovative, knowledgeable and pragmatic digital transformation leader. Leads through motivation and empowerment of high performing individuals.",
                },
            ],
        });
        updateTemplate(template)
    }

    const removeItem = (template, itemIndex) => {
        template.customized_data.items.splice(itemIndex, 1)
        updateTemplate(template)
    }

    const createNestedPara = (template, itemIndex, listItemIndex) => {
        template.customized_data.items[itemIndex].listItems.splice(
        listItemIndex,
          0,
          {
            title: "Innovative, knowledgeable and pragmatic digital transformation leader. Leads through motivation and empowerment of high performing individuals.",
          }
        );
        updateTemplate(template)
    }

    const removeNestedPara = (template, itemIndex, listItemIndex) => {
        template.customized_data.items[itemIndex].listItems.splice(listItemIndex, 1)
        updateTemplate(template)
    }

    return {
        createDataItem: createItem,
        removeDataItem: removeItem,
        createNestedPara,
        removeNestedPara
    }
}