<template>
  <input
    @blur="$emit('blur', localValue)"
    :style="{
      fontSize: `${fontSize}px`,
      color: color,
      letterSpacing: `${spacing}em`,
    }"
    class="bg-transparent placeholder-secondary-five"
    :class="[isBlock ? 'block' : '', classes]"
    type="text"
    v-model="localValue"
    :placeholder="placeholder"
    :disabled="isDisable"
  />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TextInput",

  computed: {
    ...mapGetters({
      presentationState: "communicator/presentationState",
    }),
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  props: {
    text: {
      type: String,
      default: "Title text",
    },
    isDisable: Boolean,
    placeholder: {
      type: String,
      default: "Enter text",
    },
    color: String,
    classes: String,
    fontSize: [String, Number],
    isBlock: {
      type: Boolean,
      default: true,
    },
    spacing: {
      default: 0.05,
    },
    value: {
      type: String,
    },
  },
};
</script>
