<template>
    <ComboChart 
        :card="localCard"
        :series="series"
        :categories="categories"
        :stroke="2"
    />
</template>

<script setup>
    import { computed } from 'vue'
    import { cloneDeep } from 'lodash'
    import ComboChart from './ComboChart.vue';
    const props = defineProps({
        card: Object,
        series: {
            type: Array
        },
        categories: {
            type: Array
        },
    })

    let localCard = computed(() => {
        let localCard = cloneDeep(props.card)
        localCard.configuration.legend = false
        return localCard
    })
</script>