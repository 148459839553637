<template>
<div class="mt-1 space-y-1">
    <div v-for="item in filteredCollections" :key="item.id">
        <div
            :class="getHeightClass(getExpansionStatesByLevel(item.id))"
            class="fs-16 fw-400 bg-white w-full px-2 py-2 rd-last-item rounded-sm border border-primary-one"
        >
            <p 
                :class="getClamp(getExpansionStatesByLevel(item.id))" 
                class="line-clamp"
            >{{ getNote(column, item.id).note}}</p>
        </div>
    </div>
</div>
</template>

<script setup>
import { set } from 'vue';
import { get } from 'lodash';
import useVerticalExpansion from '@/components/communicator/v2/templates/coordinate/useVerticalExpansion'

const props = defineProps({
    column: Object,
    widget: Object,
    module: Object,
    layout: Object,
    filteredCollections: Array,
    flatData: Array,
    isolatedModules: Array
})

const clamps = {
    'normal': 'clamp-1',
    'semiexpanded': 'clamp-3',
    'expanded': 'clamp-7',
}

const {getHeightClass, getExpansionStatesByLevel} = useVerticalExpansion()

const getNote = (column, collectionId) => {
    if(column.lastLevelNotes.constructor == Array){
        set(column, 'lastLevelNotes', {})
    }
    const note = get(column, `lastLevelNotes[${collectionId}]`)
    if(!note) {
        column.lastLevelNotes[collectionId] = {
            note: '' 
        }
    }
    return get(column, `lastLevelNotes[${collectionId}]`)
}
const getClamp = (verticalExpansionState) => clamps[verticalExpansionState]
</script>