import { render, staticRenderFns } from "./OneByFive.vue?vue&type=template&id=bb399854&scoped=true&"
import script from "./OneByFive.vue?vue&type=script&setup=true&lang=js&"
export * from "./OneByFive.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./OneByFive.vue?vue&type=style&index=0&id=bb399854&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb399854",
  null
  
)

export default component.exports