<template>
    <div :class="[currentMode != 'fullscreen' ? 'scale-down' : '']" :style="{ color: activeTemplate.settings.color, paddingRight: '175px' }"
      class="relative overflow-hidden pb-32 border border-transparent communicator__template--wrapper">
      <div style="margin-left: 120px" class="flex w-full items-center mb-3 mt-24 gap-x-2">
        <AttachmentIcon 
          @click.native="showConfiguration('TextSource', ['title'],
          activeTemplate.customized_data.heading.id)" 
          class="h-7 w-7 cursor-pointer"
          :class="{'invisible' : currentMode == 'fullscreen' || !config_visibility || !synchronize}"
        />
        <p
          v-if="getItemFromDataLayer(activeTemplate.data_layer, activeTemplate.customized_data.heading.id)"
          class="fw-900 w-full fs-64"
        >{{ getItemFromDataLayer(activeTemplate.data_layer, activeTemplate.customized_data.heading.id).title.value }}</p>
        <TextInput v-else v-model="activeTemplate.customized_data.heading.value" font-size="64" classes="fw-900 w-full" />
      </div>

      <div v-if="activeTemplate.settings.configuration_no == 1 || activeTemplate.settings.configuration_no == 3" :class="[getConfig(activeTemplate, 'grid')]" style="height: calc(100% - 200px);" class="overflow-y-scroll grid">
        <div
          
          class="h-full ml-16" :class="{'grid grid-cols-2 gap-x-28 grid-flow-column grid-rows-3': activeTemplate.settings.configuration_no == 2}"
        >
          <template v-for="(item, index) in activeTemplate.customized_data.descriptions">
            <div :key="index" class="mb-8">
              <div :style="{ color: activeTemplate.settings.color }" class="flex mb-2 items-center space-x-2">
                <div :class="{'invisible' : getIconVisibility(currentMode, config_visibility)}" class="flex items-center space-x-3">
                  <AttachmentIcon 
                    @click.native="showConfiguration('TextSource', ['title'], item.id)"
                    :class="{'invisible' : !synchronize}"
                    class="h-7 w-7 cursor-pointer" />
                  <p  @click="createItem(activeTemplate, index + 1)" class="fw-700 cursor-pointer fs-30" >+</p>
                  <p :class="{'invisible': activeTemplate.customized_data.descriptions.length < 2}" @click="removeItem(activeTemplate, item.id)" class="fw-700 cursor-pointer fs-30">-</p>
                </div>
                <TextComponent :text="index + 1 + '.'" font-size="36" classes="fw-700 pr-1" :is-block="false" />
                <p v-if="getItemFromDataLayer(activeTemplate.data_layer, item.id)" class="fs-36 fw-700"> {{ getItemFromDataLayer(activeTemplate.data_layer, item.id).title.value }} </p>
                <TextInput
                  v-else
                  @input="updateTemplate(activeTemplate)"
                  :isDisable="currentMode == 'fullscreen'"
                  v-model="item.title" 
                  font-size="36" 
                  classes="fw-700 w-full"
                />
              </div>

              <div v-for="(list, bodyIndex) in item.listItems" :key="bodyIndex" class="w-full flex mb-4 items-start space-x-4">
                <div class="flex items-center space-x-4">
                  <div class="flex items-center space-x-4" :class="{'invisible' : getIconVisibility(currentMode, config_visibility)}">
                    <AttachmentIcon 
                      @click.native="showConfiguration('TextSource', ['title'], list.id)"
                      class="h-5 w-5 invisible"
                      :class="{'invisible' : !synchronize}"
                    />
                    <p @click="createNestedBodyText(activeTemplate, index, bodyIndex + 1)" class="fw-700 cursor-pointer fs-30" >+</p>
                    <p :class="{'invisible': item.listItems.length< 2}" @click="removeBodyText(activeTemplate, index, list.id)" class="fw-700 cursor-pointer fs-30">-</p>
                    <TextComponent :text="'.'" font-size="36" classes="fw-700 -mt-5" :is-block="false" />
                  </div>
                </div>
                <EditableComponent 
                    @input="updateTemplate(activeTemplate)" 
                    isBlock 
                    v-model="list.title" 
                    :isDisable="currentMode == 'fullscreen'"
                    :font-size="getConfig(activeTemplate, 'descripTionFontSize')"
                    classes="fw-200 w-full"
                    :class="{'mt-1.5':  activeTemplate.settings.configuration_no == 3}"
                  />
              </div>
            </div>
          </template>
        </div>
        <div v-if="activeTemplate.settings.configuration_no == 3" class="flex flex-col items-end gap-y-24">
          <input @change="e => onChangeImage(e, activeTemplate)" :ref="getElement" type="file" accept="image/*" class="hidden" />
          <div v-for="n in 2" :key="n" class="relative rounded flex items-center justify-between" style="height:322px; width:573px">
            <div @click="handleFileOpen(n)" class="absolute rounded fs-48 fw=700 flex items-center justify-center inset-0 w-full h-full opacity-20 cursor-pointer" :style="{backgroundColor: activeTemplate.settings.borderColor}">+</div>
            <div @click="handleFileOpen(n)" class="absolute rounded fs-48 fw=700 flex items-center justify-center inset-0 w-full h-full cursor-pointer">+</div>
            <!-- {{activeTemplate.customized_data.images[n-1]}} -->
            <img v-if="activeTemplate.customized_data.images[n-1]" :src="activeTemplate.customized_data.images[n-1]" alt="" class="w-full h-full object-cover rounded absolute inset-0">
          </div>
        </div>
      </div>

      <div v-else class="flex h-full ml-16">
        <template v-for="(item, index) in activeTemplate.customized_data.descriptions">
            <div v-if="index < 2" :key="index" class="mb-8 overflow-y-auto gap-x-10 w-1/2" style="height: calc(100% - 200px);">
              <div :style="{ color: activeTemplate.settings.color }" class="flex mb-2 items-center space-x-2">
                <div :class="{'invisible' : getIconVisibility(currentMode, config_visibility)}" class="flex items-center space-x-2">
                  <AttachmentIcon 
                    @click.native="showConfiguration('TextSource', ['title'], item.id)"
                    :class="{'invisible' : !synchronize}"
                    class="h-7 w-7 cursor-pointer" />
                  <p :class="{'invisible': activeTemplate.customized_data.descriptions.length >= 2}" @click="createItem(activeTemplate, index + 1)" class="fw-700 cursor-pointer fs-30" >+</p>
                  <p :class="{'invisible': activeTemplate.customized_data.descriptions.length < 2}" @click="removeItem(activeTemplate, item.id)" class="fw-700 cursor-pointer fs-30">-</p>
                </div>
                <TextComponent :text="index + 1 + '.'" font-size="36" classes="fw-700 pr-1" :is-block="false" />
                <p v-if="getItemFromDataLayer(activeTemplate.data_layer, item.id)" class="fs-36 fw-700"> {{ getItemFromDataLayer(activeTemplate.data_layer, item.id).title.value }} </p>
                <TextInput
                  v-else
                  @input="updateTemplate(activeTemplate)"
                  :isDisable="currentMode == 'fullscreen'"
                  v-model="item.title" 
                  font-size="36" 
                  classes="fw-700 w-full"
                />
              </div>

              <div v-for="(list, bodyIndex) in item.listItems" :key="bodyIndex" class="w-full flex mb-4 items-start space-x-4">
                <div class="flex items-center space-x-4">
                  <div class="flex items-center space-x-4" :class="{'invisible' : getIconVisibility(currentMode, config_visibility)}">
                    <AttachmentIcon 
                      @click.native="showConfiguration('TextSource', ['title'], list.id)"
                      class="h-5 w-5 invisible"
                      :class="{'invisible' : !synchronize}"
                    />
                    <p @click="createNestedBodyText(activeTemplate, index, bodyIndex + 1)" class="fw-700 cursor-pointer fs-30" >+</p>
                    <p :class="{'invisible': item.listItems.length< 2}" @click="removeBodyText(activeTemplate, index, list.id)" class="fw-700 cursor-pointer fs-30">-</p>
                    <TextComponent :text="'.'" font-size="36" classes="fw-700 -mt-5" :is-block="false" />
                  </div>
                </div>
                <EditableComponent 
                  @input="updateTemplate(activeTemplate)" 
                  isBlock 
                  v-model="list.title" 
                  :isDisable="currentMode == 'fullscreen'"
                  :font-size="getConfig(activeTemplate, 'descripTionFontSize')"
                  classes="fw-200 w-full mt-1.5" 
                />
              </div>
            </div>
        </template>
      </div>
    </div>
</template>

<script setup>
import TemplateContainer from "../TemplateContainer.vue";
import TextComponent from "@/elements/atom/TextComponent.vue";
import TemplateStyle from "../TemplateStyle.vue";
import TemplateFooterIcons from '@/components/communicator/v2/templates/TemplateFooterIcons'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { set, computed, watch } from 'vue';
import { get } from 'lodash'
import useTitleContent from './useTitleContent'

import TextInput from "@/components/communicator/templates/TextInput.vue";
import EditableComponent from "@/elements/atom/EditableComponent.vue";

const {
  removeByKey,
  createItem,
  setInitialData,
  getSerial,
  removeItem,
  removeBodyText,
  removeBodyTextKey,
  createNestedBodyText,
  getConfig,
  openFileForTemplate,
  setCurrentFileIndex,
  onChangeImage,
  getElement,
  getItemFromDataLayer
} = useTitleContent()

const { getIconVisibility, getFileElement, openFile, getTemplateBg, onChangeFile, previewURL, updateTemplate, setTemplateBg, getDataLayerItem, prepareConfiguration, prepareRequest, getPersistance } = useTemplate()

const props = defineProps({
  activeTemplate: Object,
  currentMode: String,
  switchSidebarComponent: Function,
  config_visibility: Boolean,
  synchronize: Boolean,
})

const presentationState = 0
const emit = defineEmits(['onClickLink'])

const descriptionsLength = computed(() => {
  return props.activeTemplate.customized_data?.descriptions?.length;
})

const handleFileOpen = (index) => {
  setCurrentFileIndex(index)
  openFileForTemplate()
}

const showConfiguration = (componentName, options, confId) => {
    props.switchSidebarComponent(componentName, {
        persistance: getPersistance(props.activeTemplate, confId),
        transform: (config) => {
            let inputs = prepareConfiguration(options, config);
            return prepareRequest(props.activeTemplate, confId, config, inputs, props.synchronize);
        }
    });
}

// const manageDescription = (config) => {
//   const data = [{
//         [config.column]: config[config.storeKey],
//         value: null
//     }]
  
//   return data
// }

watch(
  () => props.activeTemplate,
  (newVal) => {
    // delete newVal.image
    // updateTemplate(newVal)
    setInitialData(props.activeTemplate)
  },
  { deep: true, immediate: true },
)

</script>

<style scoped>
.h-74 {
  height: 74px;
}

.calc-width {
  width: 100%;
}
</style>
