<template>
    <div class="_main_wrapper relative flex font-poppins" v-if="instance">
        <div class="i-left-panel px-0 shadow-one" style="height: 1080px;">
            <div class="px-10 relative">
                <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo cursor-pointer" />
                <!-- v-if="projectId"  -->
                <NavToggle class="right-10"/>
            </div>
            <div class="ws-boxes flex mt-10 px-10">
                <div class="flex workspace-scroll workspace-scroll" v-click-outside="hideContextMenu">
                    <div
                        v-for="(workspace, wsIndex) in workspaces"
                        :key="wsIndex"
                        class="ws-box bg-primary-four font-poppins fs-16 text-primary-two p-2 uppercase fw-bold cursor-pointer rounded-sm"
                        :class="{
                            'ml-5': wsIndex > 0,
                            'i-border-1 shadow-two border-primary-one': parseInt(selectedWorkspaceId) === parseInt(workspace.id),
                        }"
                        @click="toggleWorkspace(workspace.id, wsIndex)"
                        @contextmenu="rightClickHandler($event, workspace)"
                    >
                        {{ workspace.name.substring(0, 2) }}
                    </div>
                </div>
                <div class="ws-box bg-primary-one cursor-pointer rounded-sm flex items-center justify-content-center p-3"
                    :class="{ 'ml-5': workspaces.length > 0 }" @click="showWorkspaceCreateModal">
                    <img src="/images/icons/workspace/plus_icon.svg" alt="icon" />
                </div>
            </div>
            <slot name="leftSide">
                <Sidebar :selectedWorkspaceId="selectedWorkspaceId" class="mt-10" />
            </slot>
        </div>

        <div class="i-right-panel relative">
            <div class="ws-pr-dashboard-heading-box flex flex-col pr-4">
                <WorkspaceMenu  :hide-project="false" :hideFeature="!!$slots.workspace">
                    <template v-slot:workspace>
                        <slot name="workspace"></slot>
                    </template>
                </WorkspaceMenu>
            </div>
            <div>
               <slot v-bind="{ instance }"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import WorkspaceMenu from "@/components/InstMenu/WorkspaceMenu";
import Sidebar from "@/components/dashboard/sidebar/Sidebar"
import DashboardService from "@/store/modules/dashboard/dashboardService"
import { useTeam } from '@/views/screens/dashboard/useTeam.js'
import NavToggle from '@/components/navigation/components/NavToggle.vue'
import useNavigation from '@/components/navigation/useNavigation.js'

export default {
    components: {
        Sidebar,
        WorkspaceMenu,
        NavToggle
    },
    props: {
        hideWorkspaceTitle: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            contextPos: {
                top: 0,
                left: 0,
            },
            showContextMenu: false,
            contextWorkspace: null,
            type_selected: 1,
            selectedWorkspaceId: null,
            workspace: Object(null),
            instance: null,
        };
    },
    setup() {
        const { getTeams } = useTeam()
        const { projectId } = useNavigation()
        return {
            getTeams,
            projectId
        }
    },
    computed: {
        ...mapGetters({
            workspaces: "workspace/workspaces",
        })
    },
    methods: {
        ...mapActions({
            fetchWorkspace: "dashboard/fetchWorkspace",
        }),
        showWorkspaceCreateModal() {
            this.$store.dispatch("workspace/toggleWorkspaceModal", true);
        },
        toggleWorkspace(id, index) {
            const self = this;
            self.selectedWorkspaceId = id;
            self.$store.dispatch("workspace/toggleWorkspace", index);
            self.$store.dispatch("workspace/getDirectories", id);
            self.$store.dispatch("workspace/getWorkspaceData", {
                type: self.type_selected,
                workspace_id: id,
            });
            // self.route.params
        },
        rightClickHandler(e, workspace) {
            e.preventDefault();
            this.showContextMenu = true;
            this.contextPos.left = e.pageX || e.clientX;
            this.contextPos.top = e.pageY || e.clientY;
            this.contextWorkspace = workspace;
        },
        hideContextMenu() {
            this.showContextMenu = false;
            this.contextWorkspace = null;
        },
    },
    async created() {
        this.workspace = await this.fetchWorkspace(this.$route.params.workspaceId);
        await this.getTeams();
        this.instance = new DashboardService(this);
        const self = this;
        document.title = "Workspace Dashboard";

        if (self.workspaces.length <= 0) {
            self.$store.dispatch("workspace/fetchWorkspaces", 1).then((response) => {
                if (response && response.data) {
                    self.selectedWorkspaceId = self.workspaces[0].id;
                    self.$store.dispatch("workspace/toggleWorkspace", 0);
                    self.$store.dispatch("workspace/getDirectories", self.workspaces[0].id);
                    self.$store.dispatch("workspace/getWorkspaceData", { type: 1, workspace_id: self.workspaces[0].id});
                }
            });
        }
    }
};
</script>
