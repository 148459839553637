<template>
    <div>
        <div
            @click="setActiveModule(index)"
            :style="{ 'background': getBackground(module), width: '525px' }"
            class="flex justify-between items-center layout rounded px-4 mb-8"
            v-for="(module, index) in modules"
            :key="module.id"
        >
            <div class="flex cursor-pointer">
                <div class="flex">
                    <h4 class="fs-14 text-primary-three fw-700 module-title-width ellipse">{{ get(module, 'title') }}
                    </h4>
                    <img src="/images/roadmap/widget_load_white.svg" alt="icon"
                        class="pr-4 i-border-r-1 border-primary-three cursor-pointer ml-4" />
                    <!-- <h4 class="fs-14 text-primary-three fw-500 pl-4">{{ layout.title }}</h4> -->
                    <InputText v-if="layout" v-model="layout.title"
                        class="fs-14 text-primary-three fw-500 pl-4 bg-transparent placeholder-secondary-two truncate"
                        @input="updateLayout(layout)" @click.native.stop />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { get } from 'lodash'
import router from '@/routes/routes.js';
import useModule from '@/composable/useModule';
const route = router.currentRoute;

let { getBackground, getIndexCode } = useModule();

const props = defineProps({
    modules: Array,
    layout: Object,
    setActiveModule: Function
})

const getModuleBg = (module, level = 1) => {
    // return getModuleBg(module, level);
    // return '#2b80ad'
}
const updateLayout = (module, level = 1) => {
    // return getModuleBg(module, level);
    //   return '#2b80ad'
}

</script>

<style scoped>
.group-data {
  min-width: 460px;
}

.layout {
  height: 50px;
  /*min-width: 546px;*/
}

.group-shadow-bg {
  height: 50px;
}

.group-setting {
  height: 40px;
}

.widget-setting-icon {
  width: 13px;
  height: 13px;
}



.collapsed-layout {
  width: 50px;
}

.collapsed-first-layout {
  min-width: 620px;
}

.expanded-layout {
  min-width: 620px !important;
}

.module-title-width {
  width: 170px;
}
</style>
