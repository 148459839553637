<template>
    <Card
        :config="card.configuration"
        :cardId="card.id"
        :rangeVisibility="false"
        :dateVisibility="dateVisibility"
    >
        <slot></slot>
    </Card>
</template>

<script setup>
    import { watch, computed } from 'vue';
    import { get } from 'lodash';
    import Card from '@/components/dashboard/components/Card.vue';
    import { useConfiguration } from '@/views/screens/dashboard/useConfiguration.js'

    const props = defineProps({
        card: Object
    });

    const { updateChartData } = useConfiguration()
    watch(() => props.card.configuration, (value) => {
        let data = {
            configuration: value
        }
        updateChartData(data, props.card.id);
    }, {deep: true});

    const dateVisibility = computed(() => get(props.card, 'configuration.chartType', null) !== 'ProjectProgress');
</script>