import {set, ref} from 'vue'
import { prevIndex, nextIndex  } from '@/plugins/lodash'

const expansionStatesByLevel = ref({})

export default function useHorizontalExpansion() {
    const expansionStates = ['normal', 'expanded']
    const heightClass = {
        normal: 'w-12',
        // semiexpanded:'w-64',
        expanded: 'w-48'
    }

    const getExpansionStatesByLevel = (levelId) => {
        if(!expansionStatesByLevel.value[levelId]) {
            set(expansionStatesByLevel.value, `${levelId}`, 'normal')
        }
        return expansionStatesByLevel.value[levelId]
    }

    const decreaseWidth = (levelId) => {
        const currentExpansionState = getExpansionStatesByLevel(levelId)
        const expansionIndex = _getExpansionStateIndex(currentExpansionState)
        const nextExpansionIndex = prevIndex(expansionIndex, 2)
        expansionStatesByLevel.value[levelId] = expansionStates[nextExpansionIndex]
    }
    const increaseWidth = (levelId) => {
        const currentExpansionState = getExpansionStatesByLevel(levelId)
        const expansionIndex = _getExpansionStateIndex(currentExpansionState)
        const nextExpansionIndex = nextIndex(expansionIndex, 2)
        expansionStatesByLevel.value[levelId] = expansionStates[nextExpansionIndex]
    }

    const getWidthClass = (level) =>  {
        const state = getExpansionStatesByLevel(level)
        return heightClass[state]
    }

    const _getExpansionStateIndex = (name) => expansionStates.indexOf(name)

    return {
        expansionStatesByLevel,
        getExpansionStatesByLevel,
        decreaseWidth,
        increaseWidth,
        getWidthClass
    }
}