export default {
    content: {
        textarea: {
            width: '362px',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '21px',
           // marginTop: '9px',
            marginLeft: '30px',
        },
        input: {
            fontWeight: '700',
            fontSize: '24px',
            width: '100%',
        },
        icon: {
            bottom: '13px',
            right: '15px',
        }
    }
}