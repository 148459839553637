<template>
    <div class="flex px-1 items-center panzoom-exclude">
            <h4 :title="name" class="fs-10 fw-500 flex-none text-secondary-two truncate flex-shrink-0">{{ name }} :</h4>
            <input
                :class="classes"
                :value="value"
                @input="updateValue"
                :disabled="!canEdit(project.cans)"
                type="text"
                class="px-1 bg-transparent fs-10 text-primary-one fw-500"
                :title="value"
            >
            <div class="ml-auto flex-shrink-0">
                <slot v-if="canDelete(project.cans)"></slot>
            </div>
    </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit, canDelete, canRead } = usePermission()
</script>

<script>
import { debounce } from 'lodash'
import {mapGetters,mapActions} from "vuex";
export default {
    name: 'WidgetTitle',
    props: {
        name: {
            required: true
        },
        value: {
            type: [String, Number]
        },
        classes: {
            type: String,
            default: 'w-full'
        }
    },
    computed: {
        ...mapGetters({
            project: "project/project"
        })
    },
    methods: {
        updateValue: debounce(function (e) {
            this.$emit('input', e.target.value)
            this.$emit('updateWidgetTitle')
        }, 1000)
    }
}
</script>
