<template>
    <div class="grid gap-1">
        <div v-for="(option, index) in options" :key="index">
            <div class="flex gap-1 items-center">
                <button 
                    v-show="option.children && option.children.length"
                    @click="showChild=!showChild" 
                    class="mr-1" 
                >
                    <svg class="transform w-3 h-3" :class="showChild && 'rotate-90'" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M12.969 4.281 11.53 5.72 21.812 16l-10.28 10.281 1.437 1.438 11-11 .687-.719-.687-.719Z"/></svg>
                </button>
                <label class="flex gap-1 whitespace-nowrap">
                    <input type="checkbox" 
                        :value="option.id" 
                        class="!visible" 
                        @click="processData" 
                        :checked="isChecked(selectedValue, option.id)" 
                    />
                    {{ option.title }}
                </label>
            </div>

            <InputTreeSelect 
                v-show="option.children && option.children.length && showChild"
                class="pl-6"
                :options="option.children"
                :response="response"
                :selectedValue="selectedValue"
            />
        </div>
    </div>
</template>

<script setup>
    // need to refactor with b-model
    import { ref } from 'vue'
    
    const emit = defineEmits(['onChange'])
    const props = defineProps({
        options: Array,
        placeholder: String,
        response: Array,
        selectedValue: Array
    })

    const showChild = ref(false)

    const isChecked = (selectedValue, id) => {
        if(!id) return false
        return selectedValue.includes(id.toString())
    }
    const processData = (e) => {
        if(e.target.checked){
            props.response.push(e.target.value)
            props.response = [...new Set(props.response)]
        }else{
            let index = props.response.indexOf(e.target.value)
            props.response.splice(index, 1)
        }
        emit("onChange")
    }
</script>

<style scoped>
    input[type=checkbox] {
        visibility: visible;
    }
</style>