<template>
    <div class="flex justify-between">
        <div class="flex items-center">
            <div class="-ml-1 flex-shrink-0" v-for="(avatar, i) in localAvatars" :key="i">
                <img
                    :style="localDimension"
                    :title="avatar?.name"
                    class="shadow border border-white cursor-pointer rounded-full block object-cover" 
                    :src="get(avatar, [sourceKye], '/images/profile.png') || '/images/profile.png'"
                >
            </div>
            <div
                :style="localDimension"
                v-show="remaining"
                class="-ml-2 flex-shrink-0 shadow border border-white cursor-pointer rounded-full object-cover object-center bg-gray-200 grid items-center text-center text-secondary-one fs-12 fw-500"
            >
                + {{ remaining }}
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { get } from 'lodash'
    const props = defineProps({
        avatars: {
            type: Array,
            default: () => []
        },
        sourceKye: {
            type: String,
            default: () => 'file_path'
        },
        nameKey: {
            type: String,
            default: () => 'name'
        },
        dimension: {
            type: Number,
            default: () => 40
        },
        limit: {
            type: [Number, String],
            default: 4
        }
    });
    const localAvatars = computed(() => {
        return props.avatars.slice(0, props.limit);
    });
    const remaining = computed(() => {
        let diff = props.avatars.length - props.limit;
        return diff > 0 ? diff : null;
    });

    const localDimension = computed(() => ({width: `${props.dimension}px`, height: `${props.dimension}px`}))
</script>