<template>
    <div class="grid buttons">
        <button @click="handleCircleInfo" class="cursor-pointer relative z-10">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
                <path d="M2 0.5H28C28.8284 0.5 29.5 1.17157 29.5 2V18C29.5 18.8284 28.8284 19.5 28 19.5H2C1.17157 19.5 0.5 18.8284 0.5 18V2C0.5 1.17157 1.17157 0.5 2 0.5Z" fill="#F9F9F9" stroke="black"/>
                <path d="M15.0117 6.97356C14.4704 6.97356 14.0364 6.82889 13.7097 6.53955C13.3831 6.25022 13.2197 5.88622 13.2197 5.44755C13.2197 4.99955 13.3831 4.63089 13.7097 4.34155C14.0364 4.04289 14.4704 3.89355 15.0117 3.89355C15.5437 3.89355 15.9731 4.04289 16.2997 4.34155C16.6264 4.63089 16.7897 4.99955 16.7897 5.44755C16.7897 5.88622 16.6264 6.25022 16.2997 6.53955C15.9731 6.82889 15.5437 6.97356 15.0117 6.97356ZM16.5517 7.60356V15.4996H13.4437V7.60356H16.5517Z" fill="black"/>
            </svg>              
        </button>
        
        <button class="relative z-10">
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="20" viewBox="0 0 31 20" fill="none">
                <path d="M2.16364 0.540909H28.6364C29.5326 0.540909 30.2591 1.26743 30.2591 2.16364V17.3134C30.2591 18.2096 29.5326 18.9362 28.6364 18.9362H2.16364C1.26743 18.9362 0.540909 18.2096 0.540909 17.3134V2.16364C0.540909 1.26743 1.26743 0.540909 2.16364 0.540909Z" stroke="#3D3D3D" stroke-width="1.08182"/>
                <path d="M5.21777 5.9834H25.5814V8.52885H5.21777V5.9834Z" fill="#3D3D3D"/>
                <path d="M5.21777 11.0742H25.5814V13.6197H5.21777V11.0742Z" fill="#00A7FE"/>
            </svg>          
        </button>
        <div class="relative z-10" v-click-outside="() => { showColorPicker = false }">
            <button @click="showColorPicker = !showColorPicker" v-if="selectedConcept.colors" class="relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="18" viewBox="0 0 31 18" fill="none">
                    <path d="M15.7644 0H30.5445V8.85322H15.7644V0Z" fill="#19A77C"/>
                    <path d="M15.7644 17.707H30.5445V9.96047H15.7644V17.707Z" fill="#0085FF"/>
                    <path d="M14.7801 0H0V8.85322H14.7801V0Z" fill="#D6D6D6"/>
                    <path d="M14.7801 17.707H0V9.96047H14.7801V17.707Z" fill="#FE5656"/>
                </svg>
            </button>
            <div v-show="showColorPicker" class="colorPickers absolute right-full top-1 flex gap-1 z-30 bg-white p-1 shadow">
                <label class="w-4 h-3 cursor-pointer border" :style="{backgroundColor: colors[0]}">
                    <input type="color" class="absolute opacity-0" v-model="colors[0]" @change="updateStrategy(strategy)" >
                </label>
                <label class="w-4 h-3 cursor-pointer border" :style="{backgroundColor: colors[1]}">
                    <input type="color" class="absolute opacity-0" v-model="colors[1]" @change="updateStrategy(strategy)" >
                </label>
                <label class="w-4 h-3 cursor-pointer border" :style="{backgroundColor: colors[2]}">
                    <input type="color" class="absolute opacity-0" v-model="colors[2]" @change="updateStrategy(strategy)" >
                </label>
                <label class="w-4 h-3 cursor-pointer border" :style="{backgroundColor: colors[3]}">
                    <input type="color" class="absolute opacity-0" v-model="colors[3]" @change="updateStrategy(strategy)" >
                </label>
            </div>
        </div>
        <button class="relative z-10">
            <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.725586" y="13.0762" width="5.23136" height="30.5455" transform="rotate(-90 0.725586 13.0762)" fill="#3D6A83"/>
                <rect x="0.725586" y="5.23145" width="5.23136" height="30.5455" transform="rotate(-90 0.725586 5.23145)" fill="#2B80AD"/>
                <rect opacity="0.5" x="0.725586" y="20.9238" width="5.23136" height="30.5455" transform="rotate(-90 0.725586 20.9238)" fill="#6A7881"/>
                <rect opacity="0.5" x="0.725586" y="28.7715" width="5.23136" height="30.5455" transform="rotate(-90 0.725586 28.7715)" fill="#818487"/>
            </svg>                
        </button>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import useConceptCanvas from '@/components/roadmap/strategicRoadmap/roadmapConcept/useConceptCanvas.js'
    import useRoadmapConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useRoadmapConcept.js'
    import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'
    import { computed } from 'vue'
    import { isEmpty } from 'lodash'

    const { strategy, updateStrategy } = useHighLevelConcept()
    const { selectedConcept } = useRoadmapConcept()   
    const showColorPicker = ref(false)
    const { showDetail } = useConceptCanvas()

    let colors = computed(() => {
        if(!strategy.value.settings?.colors || isEmpty(strategy.value.settings.colors)){
            strategy.value.settings = {
                ...strategy.value.settings,
                colors: ['','','','','','']
            }
        }
        return strategy.value.settings.colors
    })

    const handleCircleInfo = () => {
        showDetail.value = !showDetail.value
        strategy.value = {...strategy.value}
    }
</script>

<style scoped>
    .buttons{
        right: 120px;
        top: 330px;
        align-items: flex-start;
        gap: 18px;
    }
</style>