<template>
<div class="canvas-top-gap">
  <div
    :ref="getRef"
    class=" overflow-y-auto snap-y h-screen w-screen bg-white"
  >
  <div v-for="template in templates" :key="template.id">
      <div v-if="template && template.communicator_template_id" class="flex gap-x-8">
        <component
          :current-mode="currentMode"
          :module="module"
          :activeTemplate="template"
          :is="getTemplate(template.template.component)"
        />
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { computed } from 'vue'

const  { getTemplate } = useTemplate()

const props = defineProps({
    module: Object,
    getRef: Function,
    currentMode: String
})

const templates = computed(() => {
    if(!props.module) return []
    let arr = []
    props.module.layouts.forEach(layout => arr= [...arr, ...layout.module_templates])
    return arr
})

</script>

<style>
.canvas-top-gap {
  padding-top: 160px;
  padding-left: 40px;
}
</style>
