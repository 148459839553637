<template>
    <div class="grid h-full">
        <div class="h-full flex height-380px">
            <div class="h-full w-full grid content-center">
                <span class="text-center fw-800 relative z-10" style="font-size: 187px;">
                    {{ getHindText(hintsText, 0) }}
                </span>
            </div>
            <div class="h-full w-full grid content-center">
                <span class="text-center fw-800 relative z-10" style="font-size: 187px;">
                    {{ getHindText(hintsText, 1) }}
                </span>
            </div>
        </div>
        <div class="h-full flex height-380px">
            <div class="h-full w-full grid content-center">
                <span class="text-center fw-800 relative z-10" style="font-size: 187px;">
                    {{ getHindText(hintsText, 2) }}
                </span>
            </div>
            <div class="h-full w-full grid content-center">
                <span class="text-center fw-800 relative z-10" style="font-size: 187px;">
                    {{ getHindText(hintsText, 3) }}
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'
import { computed } from 'vue'

const { strategy } = useHighLevelConcept()
const hintsText = computed(() => {
    if(strategy.value.hints_text){
        return strategy.value.hints_text
    }
    return ['Add text', 'Add text', 'Add text', 'Add text', 'Add text', 'Add text']
})

const getHindText = (hintsTextArray, index) => {
    if(hintsTextArray && hintsTextArray[index]){
        return hintsTextArray[index].charAt(0)
    }
}
</script>

<style lang="scss" scoped>

</style>