<template>
    <div class="barChartWrapper relative z-10">
        <button
            class="absolute -left-6 cursor-pointer top-12"
            :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
            @click="showConfiguration('TextSource', ['title'], 'title-id')"
        >
            <svg width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M15.9993 7.3125L5.58579 18.2297C5.21071 18.6165 5 19.1411 5 19.6881C5 20.2351 5.21071 20.7597 5.58579 21.1465C5.96086 21.5333 6.46957 21.7506 7 21.7506C7.53043 21.7506 8.03914 21.5333 8.41421 21.1465L20.8277 8.16682C21.1991 7.78377 21.4938 7.32904 21.6948 6.82857C21.8958 6.3281 21.9993 5.7917 21.9993 5.25C21.9993 4.7083 21.8958 4.1719 21.6948 3.67143C21.4938 3.17096 21.1991 2.71623 20.8277 2.33318C20.4563 1.95014 20.0153 1.6463 19.53 1.439C19.0447 1.2317 18.5246 1.125 17.9993 1.125C17.474 1.125 16.9538 1.2317 16.4685 1.439C15.9832 1.6463 15.5423 1.95014 15.1708 2.33318L2.75736 15.3129C1.63214 16.4733 1 18.0471 1 19.6881C1 21.3292 1.63214 22.903 2.75736 24.0634C3.88258 25.2237 5.4087 25.8756 7 25.8756C8.5913 25.8756 10.1174 25.2237 11.2426 24.0634L21.4993 13.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
        <h1 v-if="title(activeTemplate, 'title-id')" class="fs-64 fw-800 ml-17 mt-4 mb-4">{{ title(activeTemplate, 'title-id') }}</h1>
        <input
            v-else
            class="fs-64 fw-800 ml-17 mt-4 mb-4 bg-transparent"
            :value="getTitle(activeTemplate)"
            placeholder="Enter title"
            @input="(e) => editTitle(e.target.value, activeTemplate)"
        >

        <div class="flex">
            <div class="flex gap-4 w-20 flex-shrink-0"></div>
            <div class="flex justify-between items-center w-full">
                <!-- <div class="flex gap-10">
                    <div class="flex gap-2 items-center">
                        <span class="h-4 w-8 block bg-white"></span>
                        Current state
                    </div>
                    <div class="flex gap-2 items-center">
                        <span class="h-4 w-8 block bg-white"></span>
                        Future state
                    </div>
                </div> -->
                <!-- <div class="flex gap-3">
                    <button class="px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one">Groups</button>
                    <button class="px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one">Atomic</button>
                    <button class="px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one">3m</button>
                    <button class="px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one">6m</button>
                    <button class="px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one">9m</button>
                    <button class="px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one">YTD</button>
                    <button class="px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one">All</button>
                    <button class="px-5 py-2 rounded bg-white text-black hover:opacity-50 shadow-one">Animate</button>
                    <Dropdown
                        title="Location"
                        :list="[
                            {key: 'UK', value: 'UK'},
                            {key: 'Denmark', value: 'Denmark'},
                            {key: 'Ireland', value: 'Ireland'},
                            {key: 'Sweden', value: 'Sweden'}
                        ]"
                    />
                    <Dropdown
                        title="Function"
                        :list="[
                            {key: 'Function One', value: 'Function One'},
                            {key: 'Function Two', value: 'Function Two'},
                            {key: 'Function Three', value: 'Function Three'},
                            {key: 'Function Four', value: 'Function Four'}
                        ]"
                    />
                    <Dropdown
                        title="People"
                        :list="[
                            {key: 'People one', value: 'People one'},
                            {key: 'People Two', value: 'People Two'},
                            {key: 'People Three', value: 'People Three'},
                            {key: 'People Four', value: 'People Four'}
                        ]"
                        direction="right"
                    />
                </div> -->
            </div>
        </div>

        <div class="content-wrapper">
            <div class="graph-box">
                <LeftPanel :definitions="definitions" />
                <div class="content relative">
                    <div class="chart-wrapper business-intelligance-barchart absolute bottom-0 w-full h-full">
                        <VueApexCharts
                            v-if="chartSeries"
                            width="1680"
                            height="700"
                            type="bar"
                            :options="chartOptions"
                            :series="chartSeries"
                        />
                    </div>
                </div>

                <!-- bottom panel start -->
                <div class="bottom-panel top-full w-full">
                    <div class="number fs-14 fw-400">
                        <!-- <span>0</span>
                        <span>2</span>
                        <span>4</span>
                        <span>6</span>
                        <span>8</span>
                        <span>10</span> -->
                    </div>
                </div>
                <!-- bottom panel end -->
            </div>
        </div>
    </div>
  </template>

  <script setup>
    import { isEmpty, get } from 'lodash'
    import { onMounted, watchEffect, ref, set } from 'vue'
    import LeftPanel from './components/LeftPanel.vue';
    import useBusinessIntelligence from './useBusinessIntelligence'
    import router from '@/routes/routes.js';
    import { useTeamwork } from '@/components/shared/stickyMenu/useTeamwork.js'
    import VueApexCharts from 'vue-apexcharts'
    import { useTemplate } from '@/views/screens/communicator/useTemplate'


    const { title, subtitle, prepareConfiguration, prepareRequest, getPersistance, updateTemplate  } = useTemplate()
    const { initDefaultSettings:solidObject } = useTeamwork()
    const route = router.currentRoute;
    const { getTitle, editTitle, selectedScenarioId, getCollectionsData, getChartOptions, getChartSeries, chartSeries, chartOptions,
      intelParameters, rootParameters } = useBusinessIntelligence()

    const props = defineProps({
        activeTemplate: {
            type: Object
        },
        synchronize: {
            type: Boolean
        },
        chartData: {
            type: [Array, Object]
        },
        scenarioId: {
            type: [Number, String]
        },
        config_visibility: Boolean,
        currentMode: {
            default: 'normal'
        },
        switchSidebarComponent: Function,
    })

    const showConfiguration = (componentName, options, confId) => {
        props.switchSidebarComponent(componentName, {
            persistance: getPersistance(props.activeTemplate, confId),
            confId: confId,
            transform: (config) => {
                let inputs = prepareConfiguration(options, config);
                return prepareRequest(props.activeTemplate, confId, config, inputs, props.synchronize);
            }
        });
    }

    const collectionsData = ref([])
    const definitions = ref([])
    let isAvailable   = false

    watchEffect(async () => {
        if(!isAvailable && !isEmpty(props.chartData))
        {
            let parameters = [];
            props.chartData.parameters.forEach(item => {
              parameters.push({
                id: item.id,
                title: item.title,
                bg_color: item.bg_color ? item.bg_color : getRandomColor()
              });
            });

            isAvailable = props.chartData == true
            definitions.value = props.chartData.definitions && props.chartData.definitions[0]

            collectionsData.value = await getCollectionsData(get(props.chartData, 'scenario_settings.module_id'), props.scenarioId)

            let chartXLabels   = collectionsData.value.map(item => item.title)
            chartOptions.value = getChartOptions(chartXLabels, parameters)

            let lntelLocalParameters = props.chartData.scenario_settings.parameters
            getChartSeries(lntelLocalParameters, parameters)
            intelParameters.value = lntelLocalParameters
            rootParameters.value = parameters
        }
    })

    function getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    onMounted(() => {
        if(isEmpty(props.activeTemplate.customized_data)){
            props.activeTemplate.customized_data = {
                chartBar: {
                    title: 'Headline'
                }
            }
            updateTemplate(props.activeTemplate)
        }
    })

  </script>

  <style scoped>
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: white;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: white;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: white;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: white;
    }
    .layout-scale-down{
        transform: scale(0.654);
        transform-origin: 0 0;
    }
    .graph-box{
        display: grid;
        gap: 14px;
    }
    .graph-box .left-panel{
        grid-column: 1 / span 1;
        grid-row: 2;
    }
    .graph-box .top-panel{
        grid-column: 1 / span 3;
        grid-row: 1;
    }
    .graph-box .content{
        grid-column: 2 / span 2;
        grid-row: 2;
        width: 1680px;
        height: 655px;
        position: relative;
    }
    .graph-box .bottom-panel{
        grid-column: 2 / span 2;
        grid-row: 3;
    }
    .chart-wrapper{
        display: flex;
        width: 100%;
    }
    .chart-wrapper>div{
        width: 137px;
    }
    span.bar{
        height: 100%;
        width: 28px;
        background-color: #fff;
        display: block;
        border-radius: 4px 4px 0 0;
    }
    .bottom-panel{
        height: 20px;
    }
    .bottom-panel .number{
        display: flex;
        height: 100%;
        justify-content: space-between;
    }
  </style>

<style>
    .barChartWrapper .apexcharts-xaxis-tick,
    .barChartWrapper line#SvgjsLine2352,
    .barChartWrapper line#SvgjsLine1376,
    .barChartWrapper .apexcharts-gridline {
        stroke: var(--borderColor);
    }
    .barChartWrapper .business-intelligance-barchart text{
        fill: var(--color)
    }
    .barChartWrapper .business-intelligance-barchart .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
    .barChartWrapper .business-intelligance-barchart .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
        top: -5px !important;
        left: -11px !important;
        height: 23px;
        justify-content: flex-start;
    }
    .barChartWrapper .business-intelligance-barchart span.apexcharts-legend-text {
        color: var(--color) !important;
    }
    .apexcharts-tooltip {
        background: var(--borderColor) !important;
    }

    .apexcharts-tooltip-title{
        background: #0004 !important;
    }
</style>
