import { unref, set, watchEffect, isRef } from 'vue'
import { get } from 'lodash'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { getUnixTimeStamp } from '@/plugins/lodash'

export default function useKeysteps(activeTemplate) {

    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getIconVisibility, getTemplateBorderColor } = useTemplate();

    const onChangeConfig = (config) => {
        updateTemplate(activeTemplate.value)
    }

    const getContentImage = () => get(unref(activeTemplate), 'customized_data.styleConfig.background_image')

    const getContentImageStyle = () => {
        const opacity = get(unref(activeTemplate), 'customized_data.styleConfig.background_image_opacity')
        return {
            opacity: `${opacity}%`
        }
    }

    const getContentBgStyle = (source = null) => {
        const template = unref(activeTemplate)
        const { background_color, background_color_opacity } = source ?? template.customized_data.styleConfig
        return {
            backgroundColor: background_color,
            opacity: `${background_color_opacity}%`
        }
    }

    const getTitleStyle = () => {
        return {
            fontWeight: '900',
            fontSize: '42px',
            letterSpacing: '0.02em'
        }
    }

    const createItem = (index) => {
        let items = activeTemplate.value.customized_data.items
        if(items.length == 5) return
        items.splice(index, 0, {
            firstImage: null,
            secondImage: null,
            firstDescription: {
                id: getUnixTimeStamp(),
                value: 'Change Management boost and trend overview customized for the characteristics and current state of your organization.'
            },
            secondDescription: 'Change Management boost and trend overview customized for the characteristics and current state of your organization.'
        })
    }

    const setInitialData = () => {
        const template = unref(activeTemplate)
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
        }
        // console.log(template)
        let checkTitle = "title" in template.customized_data;
        let checkConfig = "styleConfig" in template.customized_data;
        if (!checkTitle) {
            template.customized_data.title = {
                id: getUnixTimeStamp(),
                value: 'Create interactive data driven experiences'
            };
        }
        let isItems = "items" in template.customized_data;
        if (!isItems) {
            set(template.customized_data, 'items', [])
            createItem(0) //0 in index
            updateActiveTemplate()
        }
        if (!checkConfig) {
            set(template.customized_data, 'styleConfig', {
                background_image: '',
                background_image_opacity: 100,
                background_color: '#FBF9F1',
                background_color_opacity: 100
            })
        }
    }

    const updateActiveTemplate = () => updateTemplate(activeTemplate.value)

    if (isRef(activeTemplate)) {
        watchEffect(setInitialData)
    }

    return {
        onChangeConfig,
        updateActiveTemplate,
        getContentBgStyle,
        getContentImage,
        getContentImageStyle,
        getDataLayerItem,
        prepareConfiguration,
        prepareRequest,
        getPersistance,
        getTitleStyle,
        createItem
    }
}