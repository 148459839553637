import { unref, set, watchEffect, isRef } from 'vue'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { random } from 'lodash'

export default function useOkrMetrics(activeTemplate) {

    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getTemplateBorderColor, getRootValue, getParamForAlevelAPI } = useTemplate();

    const updateActiveTemplate = () => updateTemplate(activeTemplate.value)

    const setInitialData = () => {
        const template = unref(activeTemplate)
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
            template.customized_data.title = 'Heading'
            template.customized_data.subtitle = 'OKR canvas 2023'
        }
        let isContents = "contents" in template.customized_data;
        if(!isContents){
            set(template.customized_data, 'contents', _makeContents())
            // updateActiveTemplate()
        }
        
    }

    const _makeContents = () => {
        let contents = []
        for(let i =0; i<3; i++){
            contents.push({
                objective: {
                    letter: `0${i+1}`,
                    text: 'Objective',
                    bgColor: '#2B80AD',
                },
                title: 'Objective',
                description: 'Lorem ipsum dolor sit amet  elit. Aenean bibendum. Lorem ipsu',
                bgColor: '#fff',
                parameters: [{ property_id: '', id: '', definition_id: '' }]
            })
        }
        return contents
    }

    const _makeItems = () => {
        let items = [{
            type: 'content',
            id: random(5555,9999),
            title: 'Objective',
            description: 'Lorem ipsum dolor sit amet  elit. Aenean bibendum. Lorem ipsu',
        }]
        for(let i =0; i<3; i++){
            items.push({
                id: null,
                property_id: null,
                type: 'parameter',
            })
        }
        return items
    }

    if(isRef(activeTemplate)) {
        watchEffect(setInitialData)
    }

    return {
        updateActiveTemplate,
        getDataLayerItem,
        prepareConfiguration, 
        prepareRequest, 
        getPersistance,
        getTemplateBorderColor,
        getRootValue, 
        getParamForAlevelAPI
    }
}