<template>
    <tr class="text-primary-one border-b">
        <td style="padding-left: 60px;">
            <Flag class="inline-block" style="color: #2BAD7E" />
        </td>
        <td class="whitespace-nowrap">
            <p class="fw-700 fs-12">Value Driver</p>
            <input class="fw-400 fs-12 bg-transparent text-secondary-one" v-model="item.title" @input="updateOutcome(props.item)" />
        </td>
        <td class="fw-400 fs-12 text-center">
            <div class="flex items-center justify-center rounded border border-secondary-four bg-primary-three px-2 py-1 ml-5" style="width: 96px;height: 28px;">
                <input type="number" v-model="item.equivalent_per_percent" @input="updateOutcome(props.item)" class="block bg-transparent w-full fw-600">
                <input type="text" v-model="item.equivalent_per_percent_unit" @input="updateOutcome(props.item)" class="block bg-transparent w-full">
            </div>
        </td>
        <td class="fw-600 fs-12">
            <div class="flex rounded justify-center border border-secondary-four bg-primary-three px-2 py-1 ml-4" style="width: 64px;height: 28px;">
                <input type="number" v-model="item.expected_roi" @input="updateOutcome(props.item)" class="block bg-transparent w-full text-right">
                <span>%</span>
            </div>
        </td>
        <td class="fw-400 fs-12">
            <div class="flex rounded border border-secondary-four bg-primary-three px-2 py-1" style="width: 96px;height: 28px;">
                <input type="number" v-model="item.value_per_unit" @input="updateOutcome(props.item)" class="block bg-transparent w-full fw-600">
                <select v-model="item.value_unit" @input="updateOutcome(props.item)" class="block bg-transparent w-11">
                    <option value="USD">USD</option>
                </select>
            </div>
        </td>
        
        <td class="fw-600 fs-12">
            <div class="flex rounded justify-center border border-secondary-four bg-primary-three px-2 py-1 ml-4" style="width: 64px;height: 28px;">
                <input type="number" v-model="item.exg_value_pool" @input="updateOutcome(props.item)" class="block bg-transparent w-full text-right">
                <span>%</span>
            </div>
        </td>

        <td class="fw-600 fs-12">
            <div class="flex rounded justify-center px-2 py-1">
                {{ item.value_per_weight_towards_target }} {{ item.value_unit }}
            </div>
        </td>

        <td class="fw-600 fs-12">
            <div class="flex rounded justify-center px-2 py-1">
                {{ item.future_value_pool }}%
            </div>
        </td>
        <td class="fw-600 fs-12 text-center">
            {{ item.estimated_potentiality_unit }} {{ item.value_unit }}
        </td>
        
        <td class="fw-600 fs-12 pr-2">
            <div class="flex gap-1 items-center">
                <div class="flex rounded justify-center border border-secondary-four bg-primary-three px-2 py-1" style="width: 64px;height: 28px;">
                    <input type="number" v-model="item.weight" @input="updateOutcome(props.item)" class="block bg-transparent w-full text-right">
                    <span>%</span>
                </div>
                <button @click="deleteOutcome(item.id, item.goal_id)">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 3.5h-11" stroke="#8D8D8D" stroke-width="1.2" stroke-linecap="square" stroke-linejoin="round"/><path d="M6.5 6.5v4m3-4v4m3-7V13a.5.5 0 0 1-.5.5H4a.5.5 0 0 1-.5-.5V3.5m7 0v-1a1 1 0 0 0-1-1h-3a1 1 0 0 0-1 1v1" stroke="#8D8D8D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </button>
            </div>
        </td>
    </tr>
</template>

<script setup>
import useOutcome from './useOutcome'

const { updateOutcome, deleteOutcome } = useOutcome()
let timeoutId = null
const props = defineProps({
    item: Object
})
</script>

<style scoped>
    tr{
        height: 94px;
    }
</style>