import { has, isEmpty } from 'lodash';
import { format } from 'date-fns'
import { peopleFullName } from '@/store/modules/roadmap/services';
export default class CollaboratorController {
    constructor({workspace}) {
        this.workspace = workspace;
        this.responsibles = [];
    }
    getWorkspace(projectCount, selectedProject = null) {
        let { workspace } = this.workspace;
        if(!workspace) {
            return;
        }
        let projects = selectedProject ? selectedProject.name : projectCount
        return {
            Workspace: workspace.name,
            Projects: projects,
            'Created at': format(new Date(workspace.created_at), 'MM/dd/yyyy'),
            Status: { 0: 'Inactive', 1: 'Active' }[workspace.status],
            // 'Package Type': 'Coming soon'
        };
    }
    getResponsibles(project) {
        if(!isEmpty(project)) {
            return has(project, 'responsibles') ? project.responsibles : [];
        }
        let { workspace } = this.workspace;
        return has(workspace, 'responsibles') ? workspace.responsibles : [];
    }

    getMemberName(member) {
        return peopleFullName(member) || '-';
    }

    getRole(role) {
        return {
            1: 'Administrator',
            2: 'Editor',
            3: 'Contributor',
            4: 'Viewer'
        }[role];
    }
}
