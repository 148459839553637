<template>
    <select 
        :style="getOptionStyle(activeTemplate)"
        v-model='activeTemplateName[box_type]'
        @change="(e) => switchTemplate(e.target.value)" 
        class="absolute -top-8 -right-9 bg-transparent"
    >
        <option v-for="template in templateList" :key="template.name" :value="template.name">{{ template.value }}</option>
    </select>
</template>

<script setup>
    import { get } from 'lodash'
    import { ref, onMounted } from 'vue'
    import useBoxConcept from '../useBoxConcept';

    const { templateList } = useBoxConcept()
    const props = defineProps({
        activeTemplate: {
            type: Object
        },
        switchTemplate: {
            type: Function
        },
        box_type: {
            type: String
        }
    })
    const getOptionStyle = (template) => {
        return {
            '--bgColor': template.settings.background_color,
            '--color': template.settings.color,
        }
    }
    const activeTemplateName = ref('')

    onMounted(() => {
        activeTemplateName.value = get(props.activeTemplate, 'customized_data.activeTemplate')
    })
</script>

<style scoped>
    select option{
        background-color: var(--bgColor);
        color: var(--color);
    }
</style>