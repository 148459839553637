import axios from "axios"
import { ref } from 'vue';
import { isEmpty } from 'lodash'
import router from '@/routes/routes.js';
const currentTeam = ref({});
const teams = ref([]);

export const useTeam = () => {
    const route = router.currentRoute;
    const getTeams = async () => {
        let {workspaceId, teamId } = route.params;
  
        let response = await axios.get(`/workspaces/teams/${workspaceId}`).then(({data}) => data.data);
        teams.value = response;
        if(!isEmpty(response) && !['workspaceDashboard', 'projectDashboard'].includes(route.name)) {
            let team = response.find(item => item.id == teamId);
            setCurrentTeam(team || response[0]);
        }
    }
    const setCurrentTeam = (team) => {
        currentTeam.value = team;
    }
    return {
        getTeams,
        setCurrentTeam,
        teams,
        currentTeam
    }
}