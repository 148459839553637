<template>
    <div style="margin-left: 108px;margin-right: 103px;" class="grid grid-cols-3 pt-16 gap-x-20">
        <template v-for="(item, i) in activeTemplate.customized_data.items">
            <div :key="i">
                <div class="flex">
                    <AttachmentIcon
                        :class="{'invisible': !getVisibility({currentMode, synchronize, config_visibility})}"
                        class="w-7 h-7 cursor-pointer mt-14"
                        @click.native="showConfiguration('TextSource', ['description'], item.firstDescription.id)"
                    />
                    <IndexBordered :text="i+1" class="mt-8 ml-6 mr-4"/>
                    <ImageCard
                        :cardStyle="{width: '190px', height: '106px'}"
                        :template="activeTemplate"
                        :imagePath="`customized_data.items[${i}].firstImage`"
                        @onClick="onClickUploader(`customized_data.items[${i}].firstImage`)"
                    />
                </div>
                <div
                    :class="{'invisible': !getVisibility({currentMode, config_visibility})}"
                    class="flex fs-30 fw-700 gap-x-5 -mt-2" style="margin-left: 52px"
                >
                    <span @click="createItem(i+1)" class="cursor-pointer leading-none">+</span>
                    <span class="cursor-pointer leading-none">-</span>
                </div>
                <p
                    v-if="getDataLayerItem(activeTemplate, item.firstDescription.id)"
                    class="w-full truncate key-step-textarea-3grid fw-300 fs-24 mt-3 ml-12"
                > {{ getDataLayerItem(activeTemplate, item.firstDescription.id).description.value || 'No description' }} 
                </p>
                <textarea
                    v-else
                    v-model="item.firstDescription.value"
                    class="fw-300 fs-24 bg-transparent mt-3 ml-12 key-step-textarea-3grid"
                    placeholder="No description"
                    @input="updateActiveTemplate"
                    :disabled="currentMode == 'fullscreen'"
                />
            </div>
        </template>
        <input
          hidden
          @input="onFileSelect($event, activeTemplate)"
          :ref="getFileElement"
          type="file"
          accept="image/*"
        >
    </div>
</template>

<script setup>
import useIconVisibility from "@/components/communicator/v2/useIconVisibility"
import useImageUpload from "@/components/communicator/v2/templates/useImageUpload"
import ImageCard from '@/components/communicator/v2/templates/coordinate/templates/consulting/ImageCard'
import IndexBordered from '@/components/communicator/v2/templates/coordinate/templates/consulting/IndexBordered'

const props = defineProps({
  activeTemplate: Object,
  templateMethods: Object,
  currentMode: String,
  config_visibility: Boolean,
  synchronize: Boolean,
  showConfiguration: Function
})
const {
    createItem,
    updateActiveTemplate,
    getDataLayerItem
}=props.templateMethods

const { 
    getFileElement,
    onFileSelect,
    onClickUploader
} = useImageUpload()

const { getVisibility } = useIconVisibility()
</script>

<style scoped>
.key-step-textarea-3grid{
    font-weight: 300;
    font-size: 32px;
    line-height: 150%;
    letter-spacing: 0.02em;
    width: 475px;
    height: 230px;
}
</style>