<template>
  <div 
    :class="[currentMode != 'fullscreen' ? 'scale-down' : '']" 
    :style="{paddingRight: getConfig(activeTemplate, 'rightPadding')}"
    class="rounded pl-28 relative border border-transparent overflow-y-hidden communicator__template--wrapper"
  >
      <div :style="{ color: activeTemplate.settings.color }" class="flex items-center gap-5 i-mt-103">
        <TextInput v-model="activeTemplate.customized_data.heading" font-size="64" classes="fw-700 w-full pl-12" />
      </div>
      <hr :style="getTemplateBorderColor(activeTemplate, '#fff')"  class="border-t mt-5 ml-12 mb-8 calc_width" />
      <div style="height: calc(100% - 300px)" class="pb-20 overflow-y-scroll">
        <div :class="[getGridClass(activeTemplate)]" class="grid relative">
          <div v-for="(people, index) in getDataLayer(activeTemplate)" :key="index">
            <div :class="{'invisible' : getIconVisibility(currentMode, config_visibility)}" :style="{ color: activeTemplate.settings.color }"
              class="flex space-x-3 absolute items-center -top-3">
              <AttachmentIcon :class="{'invisible' : !synchronize}"  @click.native="handleComponentSwitch('PeopleSelector', index)" class="w-7 h-7 cursor-pointer"/>
              <p v-if="plusIconVisibility(activeTemplate.settings.configuration_no, activeTemplate.data_layer.length)" @click="createItem(activeTemplate, index + 1)" class="fw-800 fs-38 cursor-pointer">+</p>
              <p @click="removeItem(activeTemplate, index)" class="fw-800 fs-38 cursor-pointer">-</p>
            </div>
            <!-- {{ people }} -->
            <div 
              :style="{ 
                  color: activeTemplate.settings.color,
                  marginTop: getConfig(activeTemplate, 'peopleTopMargin')
                }" 
              class="flex items-center mt-10 pl-12"
              :class="[getConfig(activeTemplate, 'descripTionGap')]"
            >
              <RoundImage 
                :class="[people.user_id ? 'cursor-not-allowed' : 'cursor-pointer']" 
                @onClickImage="onClickImage(people)" 
                :path="people.image || '/images/avatar.jpg'"
                :height="getConfig(activeTemplate, 'imageDimension')" 
                :width="getConfig(activeTemplate, 'imageDimension')" />
              <div class="-ml-1 w-full" :class="[getConfig(activeTemplate, 'descriptionMarginBottom')]">
                <TextInput v-model="people.name" @input="handleUpdate" :isDisable="Boolean(people.user_id) || currentMode == 'fullscreen'" :font-size="getConfig(activeTemplate, 'nameFontSize')" classes="fw-700 i-w-270 w-full" placeholder="Add text"/>
                <TextInput v-model="people.occupation"  @input="handleUpdate" :isDisable="Boolean(people.user_id) || currentMode == 'fullscreen'" :font-size="getConfig(activeTemplate, 'descriptionFontSize')" classes="fw-500 i-w-270 w-full" placeholder="Add text"/>
                <textarea
                  :style="{
                      fontSize: `${getConfig(activeTemplate, 'descriptionFontSize')}px`,
                      height: getConfig(activeTemplate, 'textAreaHeight'),
                      maxWidth: '355px'
                    }"
                  v-model="people.body"
                  placeholder="Insert your desired text here"
                  @input="updateTemplate(activeTemplate)"
                  class="fw-400 w-full bg-transparent mt-2"
                ></textarea>
                <!-- <EditableComponent :row="2" v-model="people.biography" font-size="14" classes="fw-500 i-w-270" /> -->
              </div>
            </div>

            <div v-if="isBiography(activeTemplate)" :style="{ color: activeTemplate.settings.color }" class="mt-14 pl-12">
              <div class="fs-36 fw-700 mb-2">About Me</div>
              <EditableComponent 
                font-size="24"
                classes="fw-400 w-full"
                placeholder="Add text"
                :row="1"
                @input="handleUpdate"
                :isDisable="Boolean(people.user_id) || currentMode == 'fullscreen'"
                v-model="people.biography"
              />
              
            </div>
          </div>
          <input @change="e => onChangeProfileImage(e, activeTemplate)" :ref="getProfileFileElement" type="file" accept="image/*" class="hidden" />
        </div>
      </div>
  </div>
</template>

<script setup>
import TemplateContainer from "../TemplateContainer.vue";
import RoundImage from "@/elements/atom/RoundImage.vue";
import TextComponent from "@/elements/atom/TextComponent.vue";
import TemplateFooterIcons from '@/components/communicator/v2/templates/TemplateFooterIcons'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { set, computed, watch } from 'vue';
import { get } from 'lodash'
import useProfile from './useProfile'
import {debounce} from 'lodash'

import TextInput from "@/components/communicator/templates/TextInput.vue";
import EditableComponent from "@/elements/atom/EditableComponent.vue";

const {
  getDataLayer,
  createItem,
  setInitialData,
  isBiography,
  getGridClass,
  getConfig,
  onChangeProfileImage,
  getProfileFileElement,
  openFileForProfileImage,
  setCurrentDataLayer,
  updateConfiguration,
  plusIconVisibility,
  removeItem } = useProfile()

const { updateTemplate, getIconVisibility, getTemplateBorderColor } = useTemplate()

const props = defineProps({
  activeTemplate: Object,
  switchSidebarComponent: Function,
  currentMode: String,
  config_visibility: Boolean,
  synchronize: Boolean,
})

const presentationState = 0
const emit = defineEmits(['onClickLink'])

const handleLinkClick = (type) => {
  set(props.activeTemplate, 'configuration_key', type);
  emit('onClickLink')
}

const descriptionsLength = computed(() => {
  return props.activeTemplate.customized_data?.descriptions?.length;
})

const handleComponentSwitch = (name, index) => {
  set(props.activeTemplate.settings, 'currentDataIndex', index)
  props.switchSidebarComponent(name)
}

const onClickImage = (people) => {
  if(people.user_id != null) return
  setCurrentDataLayer(people)
  openFileForProfileImage()
}

const handleUpdate = debounce(() => {
  updateConfiguration(props.activeTemplate)
}, 700)

watch(
  () => props.activeTemplate,
  (newVal) => {
    // delete newVal.image
    // updateTemplate(newVal)
    setInitialData(props.activeTemplate)
  },
  { deep: true, immediate: true },
)

</script>
