import { render, staticRenderFns } from "./ContentBox.vue?vue&type=template&id=14fa6d62&scoped=true&"
import script from "./ContentBox.vue?vue&type=script&setup=true&lang=js&"
export * from "./ContentBox.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ContentBox.vue?vue&type=style&index=0&id=14fa6d62&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14fa6d62",
  null
  
)

export default component.exports