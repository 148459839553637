    import BaseLayout from '@/views/layouts/BaseLayout';
    import Sidebar from '@/elements/leftSidebar/Sidebar.vue';
    import Navbar from '@/elements/leftSidebar/Navbar.vue';
    import Toolbar from '@/components/communicator/v2/header/Toolbar';
    import ModuleLayout from '@/components/communicator/v2/wrapper/ModuleLayout';
    import TemplateLeftSidebar from "@/components/communicator/v2/sidebar/TemplateSidebar";
    import TemplateRightSidebar from "@/components/communicator/v2/sidebar/TemplateRightSidebar";
    import FullScreen from "@/components/communicator/v2/presentation/FullScreen";
    import Presentation from "@/components/communicator/v2/presentation/Presentation";
    import ModuleComponent from "@/components/communicator/v2/Modules";
    import TemplateContainer from '@/components/communicator/v2/wrapper/TemplateContainer'

    /* Bellow components are will be used in sidebar */
    import Properties from '@/components/communicator/v2/sidebar/contents/Properties';
    import TextSource from '@/components/communicator/v2/sidebar/configuration/TextSource';
    import DataSource from '@/components/communicator/v2/sidebar/configuration/DataSource';
    import OkrContentDataSource from '@/components/communicator/v2/sidebar/configuration/OkrContentDataSource';
    import OkrDataSource from '@/components/communicator/v2/sidebar/configuration/OkrDataSource';
    import OkrManagementDataSource from '@/components/communicator/v2/sidebar/configuration/okrManagement/DataSource';
    import ModuleList from '@/components/communicator/v2/sidebar/contents/ModuleList';
    import PeopleSelector from '@/components/communicator/v2/sidebar/configuration/PeopleSelector';
    import HighlevelFilter from '@/components/communicator/v2/sidebar/configuration/HighlevelFilter.vue';
    export const sidebarComponents = {
        ModuleList,
        Properties,
        TextSource,
        DataSource,
        PeopleSelector,
        OkrDataSource,
        OkrContentDataSource,
        OkrManagementDataSource,
        HighlevelFilter
    }
    export {
        BaseLayout,
        Sidebar,
        Navbar,
        Toolbar,
        ModuleLayout,
        TemplateLeftSidebar,
        FullScreen,
        Presentation,
        TemplateContainer,
        TemplateRightSidebar,
        ModuleComponent
    }