import { set, ref } from 'vue'
import {get, uniqBy} from 'lodash'
import axios from 'axios'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
let selectedScenarioId = ref(null)

export default function useBusinessIntelligence()
{
    const { updateTemplate  } = useTemplate()
    const levelCollections = ref([])
    const responseLevelCollections = ref([])
    const activeChartItem = ref(null)
    const chartReferenceSeries = ref([])
    const chartSeries = ref([])
    const intelParameters = ref([])
    const rootParameters = ref([])
    const chartOptions = ref({})
    const moduleData = ref({})
    const parentTreeStructureChartData = ref([])

    const setInitialData = (template) =>
    {
        if (template.settings.color === true || !template.settings.color) {
            set(template.settings, 'color', '#1C1C1D')
        }
        if (!('background_image_opacity' in template.settings)) {
            set(template.settings, 'background_image_opacity', 100);
        }
        if (!('background_color_opacity' in template.settings)) {
            set(template.settings, 'background_color_opacity', 100);
        }
        if (!('background_color' in template.settings)) {
            set(template.settings, 'background_color', '#fff');
        }
    }

    const getChartList = async (projectId) => {
        let { data } = await axios.get(`brainstorms/intels/${projectId}`)
        return data.data;
    }

    const getChartData = async (chartId) => {
        let { data } = await axios.get(`brainstorms/intels/show/${chartId}`)
        return data && data.data;
    }

    const getCollectionsData = async (moduleId, scenarioId) => {
        let {data: collectionRes} = await axios.get(`communicators/system/${scenarioId}/a-level-data?module_id=${moduleId}&with_parameter=1&response_type=nested`)
        responseLevelCollections.value = collectionRes.data
        _getModuleData(collectionRes.data, moduleId)
        levelCollections.value = getFlattenCollections(collectionRes.data)
        chartReferenceSeries.value = collectionRes.data
        return collectionRes.data
    }

    const _getModuleData = (collections, moduleId) => {
        let parameters = [];
        if(intelParameters.value.length > 0) {
            intelParameters.value.forEach(parameter => {
               let parameterSampleArray = [];
               collections.forEach(item => {
                   let paramCollection = item.properties.find(paramItem => paramItem.property_collection_id == parameter);
                   let sampleValue = 0;
                   if(paramCollection && paramCollection.sample_value) {
                       sampleValue = paramCollection.sample_value;
                   }
                   parameterSampleArray.push(sampleValue);
               });

               let sum = parameterSampleArray.reduce((accumulator, current) => accumulator + current);
               parameters.push({
                   id: parameter,
                   sample_value: sum / parameterSampleArray.length
               });
            });
        }

        moduleData.value = {
            id: moduleId,
            title: collections && collections.length > 0 ? collections[0].module_title : 'N/A',
            properties: parameters
        }
    }

    const getFlattenCollections = (items) => {
        let flatten = (arr) => {
            let data = [];
            if(!arr) {
                return
            }
            arr.forEach(recursiveFlatten);
            function recursiveFlatten(item) {
                data.push(item);
                if (item.child && item.child.length > 0) {
                    item.child.forEach((child) => {
                        recursiveFlatten(child);
                    });
                }
            }
            return data;
        }
        return uniqBy(flatten(items), 'id')
    }

    const _setActiveChartItem = (index) => {
        if(index > 0) {
            let item = chartReferenceSeries.value[index - 1];
            if(activeChartItem.value && item.id == activeChartItem.value.id) {
                let parentIndex = parentTreeStructureChartData.value.findIndex(data => data.id == item.parent_id)
                if(parentIndex >= 0) {
                    let child = levelCollections.value.filter(data => data.parent_id == parentTreeStructureChartData.value[parentIndex].parent_id)
                    parentTreeStructureChartData.value.splice(parentIndex, 1)

                    let selfIndex = parentTreeStructureChartData.value.findIndex(data => data.id == item.id)
                    if(selfIndex >= 0) {
                        parentTreeStructureChartData.value.splice(selfIndex, 1)
                    }

                    parentTreeStructureChartData.value = [...parentTreeStructureChartData.value, ...child]
                    chartReferenceSeries.value = parentTreeStructureChartData.value
                }
            } else {
                if(item.child.length > 0) {
                    activeChartItem.value = item;

                    parentTreeStructureChartData.value = []
                    _getParentFromChild(activeChartItem.value)

                    chartReferenceSeries.value = []
                    let series = parentTreeStructureChartData.value
                    series.push(item)
                    chartReferenceSeries.value = [...series, ...item.child]
                }
            }
            let chartXLabels = chartReferenceSeries.value.map(data => data.title)
            getChartOptions(chartXLabels, rootParameters.value)
            getChartSeries(intelParameters.value, rootParameters.value)
        }
    }

    const getChartOptions = (labels, parameters) => {
        if(!labels) return {}
        let parameterBg = parameters.map(param => param.bg_color)
        let moduleTitle = moduleData.value && moduleData.value.title ? moduleData.value.title : 'N/A'
        let options = {
            chart: {
                events: {
                    click: function(event, chartContext, config) {
                        _setActiveChartItem(config.dataPointIndex)
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: [moduleTitle, ...labels],
                color: '#fff'
            },
            yaxis: {
                show: false,
                min: 0,
                max: 12,
            },
            stroke: {
                show: true,
                width: 5,
                colors: ['transparent']
            },
            fill: {
                colors: parameterBg
            },
            legend: {
                show: true
            },
            colors: parameterBg
        }

        chartOptions.value = options
        return options
    }

    const getChartSeries = (lntelParameters, parameters = []) => {
        let chartLocalSeries = []
        lntelParameters.forEach((param, index) =>
        {
            let parameter = parameters.find(item => item.id == param);
            chartLocalSeries.push({
                name: parameter && parameter.title ? parameter.title : 'N/A',
                data: []
            })

            let moduleValue = moduleData.value && moduleData.value.properties && moduleData.value.properties.length > 0 ? moduleData.value.properties[index].sample_value.toFixed(2) : 0
            chartLocalSeries[index].data.push(moduleValue)

            chartReferenceSeries.value.forEach((item, collectionIndex) => {
                let itemParam = item.properties.find(prValue => prValue.property_collection_id == param);
                let value = 0;
                if(itemParam) {
                    value = itemParam.sample_value.toFixed(2);
                }
                chartLocalSeries[index].data.push(value)
            })
        })
        chartSeries.value = chartLocalSeries
    }

    const _getParentFromChild = (item) => {
        if(item.parent_id) {
            let parent = levelCollections.value.find(data => data.id == item.parent_id)
            if(parent) {
                parentTreeStructureChartData.value.unshift(parent);
                _getParentFromChild(parent)
            }
        }
    }

    const handleCollectionData = (e) => {
        selectedScenarioId.value = e.target.value
    }

    const getTitle = (activeTemplate) => {
        return get(activeTemplate, 'customized_data.chartBar.title')
    }
    let timeoutId = null
    const editTitle = (title, activeTemplate) => {
        set(activeTemplate.customized_data.chartBar, 'title', title)
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            updateTemplate(activeTemplate)
        }, 1000)
    }


    return {
        setInitialData,
        getChartList,
        getChartData,
        getCollectionsData,
        getChartOptions,
        getChartSeries,
        handleCollectionData,
        selectedScenarioId,
        getTitle,
        editTitle,
        chartSeries,
        chartOptions,
        intelParameters,
        rootParameters
    }
}
