import { ref } from 'vue'

export default function useScroll() {
    const element = ref()

    const getRef = el => {
         element.value = el
    }

    const resetScroll = () => {
        let el = element.value
        if(el) {
            el.scrollTo(0,0)
        }
    }

    return {
        getRef,
        resetScroll
    }
}