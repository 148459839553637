export const initialData = {
    componentName:'',
    notes: {},
    lastLevelNotes: {},
    id: null, //parameter_id
    property_id: null,
    showNoteComment: false,
    showLastLevelNote: false,
    title: 'Default',
    definition_id: null,
    associated_module_id: null,
    definition_style: [
        { range: '0-2.5', bg: '#F1F1F1', definition: 'Yes' },
        { range: '2.5-5', bg: '#C9C9C9', definition: 'Unsure' },
        { range: '5-7.5', bg: '#FFFFFF', definition: 'No' },
        { range: '7.5-10', bg: '#FFFFFF', definition: 'NA' },
    ]
}