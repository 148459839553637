<template>
    <div 
        class="wrapper three_box_concept communicator__template--wrapper relative"
        :class="[currentMode != 'fullscreen' ? 'scale-down' : '']"
        :style="{
            color: activeTemplate.settings.color, 
            '--color': activeTemplate.settings.color,
            '--borderColor': activeTemplate.settings.borderColor,
        }"
    >
        <input 
            class="fs-64 fw-800 title mb-2 bg-transparent w-full block"
            :value="getTemplateTitle(activeTemplate)"
            @input="(e) => editTemplateTitle(e.target.value, activeTemplate)"
        >
        <input 
            class="fs-24 fw-600 subtitle mb-4 bg-transparent w-full block" 
            :value="getTemplateSubTitle(activeTemplate)"
            @input="(e) => editTemplateSubTitle(e.target.value, activeTemplate)"
        />
        <div class="box-concept-wrapper flex">
            <div 
                class="flex-shrink-0 rounded-lg card relative"
                :style="getBoxOneBgCss"
            >
                <Template 
                        :config_visibility="config_visibility"
                        :currentMode="currentMode"
                        :activeTemplate="activeTemplate"
                        :activeChildTemplate="activeChildTemplate('box_five')"
                        box_type="box_five"
                        :switchSidebarComponent="switchSidebarComponent"
                        class="relative z-10"
                        :synchronize="synchronize"
                    />
                <BackgroundAndBorderController
                    class="relative -bottom-11 -left-12"
                    :isBackgroundRemoveAble="false"
                    :getConfig="(config) => configureBackground(config, activeTemplate, 'box_one')"
                    :config="getBoxOneConfig"
                    :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
                />
            </div>
            
            <div 
                class="flex-shrink-0 rounded-lg card"
                :style="getBoxTwoBgCss"
            >
                <Template 
                    :config_visibility="config_visibility"
                    :currentMode="currentMode"
                    :activeTemplate="activeTemplate"
                    :activeChildTemplate="activeChildTemplate('box_two')"
                    box_type="box_two"
                    :switchSidebarComponent="switchSidebarComponent"
                    class="relative z-10"
                    :synchronize="synchronize"
                />
                <BackgroundAndBorderController
                    class="relative -bottom-11 -left-12"
                    :isBackgroundRemoveAble="false"
                    :getConfig="(config) => configureBackground(config, activeTemplate, 'box_two')"
                    :config="getBoxTwoConfig"
                    :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
                />
            </div>

            <div 
                class="flex-shrink-0 rounded-lg card"
                :style="getBoxThreeBgCss"
            >
                <Template 
                    :config_visibility="config_visibility"
                    :currentMode="currentMode"
                    :activeTemplate="activeTemplate"
                    :activeChildTemplate="activeChildTemplate('box_three')"
                    box_type="box_three"
                    :switchSidebarComponent="switchSidebarComponent"
                    class="relative z-10"
                    :synchronize="synchronize"
                />
                <BackgroundAndBorderController
                    class="relative -bottom-11 -left-12"
                    :isBackgroundRemoveAble="false"
                    :getConfig="(config) => configureBackground(config, activeTemplate, 'box_three')"
                    :config="getBoxThreeConfig"
                    :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { get } from 'lodash'
    import { onMounted, computed, watchEffect, watch, ref, set } from 'vue';
    import useBoxConcept from './useBoxConcept';
    import Template from './template/index.vue'
    import { useTemplate } from '@/views/screens/communicator/useTemplate'
    import BackgroundAndBorderController from '@/components/communicator/v2/BackgroundAndBorderController/index.vue'
    import useTemplateConfiguration from './useTemplateConfiguration'

    const { templateList, initialCustomizedData, setInitialData, getTemplateTitle,editTemplateTitle,  getTemplateSubTitle,  editTemplateSubTitle } = useBoxConcept()
    const { updateTemplate } = useTemplate()

    const { configureBackground, getBackgroundConfig, getBackgroundCSS } = useTemplateConfiguration()
    const props = defineProps({
        activeTemplate: Object,
        config_visibility: Boolean,
        currentMode: String,
        switchSidebarComponent: Function,
        synchronize: Boolean,
    })

    const activeChildTemplate = (box_type) => 
    {
        let innerTemplateLists = get(props.activeTemplate, 'customized_data.activeTemplate')
        if(innerTemplateLists){
            let template = templateList.find(item => item.name == innerTemplateLists[box_type])
            return template
        }
        return {}
    }

    const getBoxOneBgCss = computed(()=>{
        return getBackgroundCSS(props.activeTemplate, 'box_one')
    })
    const getBoxTwoBgCss = computed(()=>{
        return getBackgroundCSS(props.activeTemplate, 'box_two')
    })
    const getBoxThreeBgCss = computed(()=>{
        return getBackgroundCSS(props.activeTemplate, 'box_three')
    })


    const getBoxOneConfig = computed(()=>{
        return getBackgroundConfig(props.activeTemplate, 'box_one')
    })
    const getBoxTwoConfig = computed(()=>{
        return getBackgroundConfig(props.activeTemplate, 'box_two')
    })
    const getBoxThreeConfig = computed(()=>{
        return getBackgroundConfig(props.activeTemplate, 'box_three')
    })




    onMounted(() => {
        if(!Object.keys(props.activeTemplate.customized_data).length){
            set(props.activeTemplate, 'customized_data', initialCustomizedData)
            updateTemplate(props.activeTemplate)
        }
    })

    watch(
        () => props.activeTemplate,
        (newVal) => {
            setInitialData(props.activeTemplate)
        },
        { deep: true, immediate: true },
    )
</script>

<style scoped>
    @import '../../../../../assets/css/communicator/box-concept/index.css'
</style>