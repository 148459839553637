import { get } from 'lodash'
import { ref, set } from 'vue'
import { useSidebar } from '@/views/screens/communicator/useSidebar'
import { useFile } from '@/composable/useFile'
import store from '@/store';


export default function useProfile() {

    const { saveTemplateConfiguration } = useSidebar();
    const { onChange, previewURL, file, appendFile, formData, clear } = useFile()

    const fileElement = ref(null)

    const currentDataLayer = ref({})

    const getProfileFileElement = elm => fileElement.value= elm

    const initialData = {
        name: "Name",
        occupation: "Designation",
        biography: "Biography",
        image: "",
        user_id: "",
        description: "",
    }
    const configuration = {
        1: {
            peopleShowLength: 1,
            grid: 'grid-cols-1 gap-5',
            showBio: true,
            imageDimension: 290,
            maxItem: 1,
            peopleTopMargin: '40px',
            nameFontSize: 36,
            descriptionFontSize: 28,
            descripTionGap:'gap-x-12',
            rightPadding: '112px',
            descriptionMarginBottom: 'mb-1'

        },
        2:{
            peopleShowLength: 2,
            grid: 'grid-cols-2 gap-x-7',
            showBio: true,
            imageDimension: 290,
            maxItem: 2,
            peopleTopMargin: '40px',
            nameFontSize: 36,
            descriptionFontSize: 28,
            descripTionGap:'gap-x-12',
            rightPadding: '112px',
            descriptionMarginBottom: 'mb-1'
        },
        3:{
            peopleShowLength: 9,
            grid: 'grid-cols-3 gap-2',
            showBio: false,
            imageDimension: 197,
            maxItem: 9,
            peopleTopMargin: '16px',
            nameFontSize: 24,
            descriptionFontSize: 18,
            descripTionGap:'gap-x-8',
            rightPadding: '225px',
            descriptionMarginBottom: 'mb-2'
        }
    }

    const plusIconVisibility = (configNo, itemNumber) => {
        return itemNumber < configuration[configNo].maxItem
    }

    const onChangeProfileImage = async (event, template) => {
        onChange(event)
        currentDataLayer.value.image = previewURL
        appendFile('image')
        let {data: {data, status}} =  await store.dispatch('communicator/updatePeopleImage',{id:template.id, data: formData});
        if(status) {
            currentDataLayer.value.image = data
        }
        updateConfiguration(template)
    }

    const getDataLayer = (template) => {
        const configurationNo = get(template, 'settings.configuration_no', null)
        if(!configurationNo) return []
        return template.data_layer.slice(0, configuration[configurationNo].peopleShowLength)
    }

    const isBiography = (template) => {
        const configurationNo = get(template, 'settings.configuration_no', null)
        if(configurationNo) {
            return configuration[configurationNo].showBio
        }
    }

    const getGridClass = (template) => {
        const configurationNo = get(template, 'settings.configuration_no', null)
        if(configurationNo) {
            return configuration[configurationNo].grid
        }
    }

    const getConfig = (template, key) => {
        const configurationNo = get(template, 'settings.configuration_no', null)
        if(configurationNo) {
            return configuration[configurationNo][key]
        }
    }

    const removeItem = (template, index) => {
        template.data_layer.splice(index, 1);
        updateConfiguration(template)
    }

    const createItem = (template, index, data=initialData) => {
        template.data_layer.splice(index, 0, JSON.parse(JSON.stringify(data)));
        updateConfiguration(template)
    }

    const setInitialData = (template) => {
        if (template?.data_layer.constructor == Array && template.data_layer.length == 0) {
            createItem(template, 0, initialData)
        }

        let checkData = "heading" in template.customized_data;
        if (!checkData) {
            template.customized_data.heading = "Introduction";
        }

        if (template.settings.color === true || !template.settings.color) {
            set(template.settings, 'color', '#1C1C1D')
        }
        if (!('background_image_opacity' in template.settings)) {
            set(template.settings, 'background_image_opacity', 100);
        }
        if (!('background_color_opacity' in template.settings)) {
            set(template.settings, 'background_color_opacity', 100);
        }
        if (!('background_color' in template.settings)) {
            set(template.settings, 'background_color', '#fff');
        }
        if (!('configuration_no' in template.settings)) {
            set(template.settings, 'configuration_no', 1);
        }
    }

    const updateConfiguration = async (template) => {
        const data = {
            synchronize: true,
            configuration: template.data_layer
        }
        let { configuration } =  await saveTemplateConfiguration(template.id, data);
        template.data_layer = configuration;
    }

    const openFile = () => {
        fileElement.value.value = null
        fileElement.value.click()
    }

    const setCurrentDataLayer = (data) => currentDataLayer.value = data

    return {
        getDataLayer,
        createItem,
        setInitialData,
        removeItem,
        isBiography,
        getGridClass,
        getConfig,
        getProfileFileElement,
        onChangeProfileImage,
        openFileForProfileImage: openFile,
        setCurrentDataLayer,
        updateConfiguration,
        plusIconVisibility
    }
}
