<template>
    <div class="border bg-white custom_shadow rounded-lg h-full">
        <div class="bg-primary-three border-b border-secondary-three flex justify-between items-center py-4 px-6 rounded-t-lg">
            <router-link 
                :to="{
                    name: 'chartConfiguration',
                    params: {
                        workspaceId,
                        teamId,
                        cardId,
                        dashboardId,
                    }
                }"
                class="fw-700 fs-14 text-primary-one whitespace-nowrap"
            >
                {{ title }}
            </router-link>
            <div class="flex">
                <!-- <h2 v-if="dateVisibility" class="fw-500 fs-12 text-color-616161 px-5 border-r border-secondary-five flex items-center">
                    01.07.2022-01.12.2022
                </h2>
                <select v-if="rangeVisibility" class="fw-500 fs-12 text-color-616161 pl-5 pr-2 mr-3 bg-transparent border-0">
                    <option value="Last 6 month">Last 6 month</option>
                    <option value="Last 3 month">Last 3 month</option>
                    <option value="Last 1 month">Last 1 month</option>
                </select> -->
                <button 
                    class="pl-5 relative"
                    v-click-outside="() => showDropdown = false"
                >
                    <svg @click="showDropdown = !showDropdown" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" fill="#8D8D8D"/>
                        <path d="M6 13.5C6.82843 13.5 7.5 12.8284 7.5 12C7.5 11.1716 6.82843 10.5 6 10.5C5.17157 10.5 4.5 11.1716 4.5 12C4.5 12.8284 5.17157 13.5 6 13.5Z" fill="#8D8D8D"/>
                        <path d="M18 13.5C18.8284 13.5 19.5 12.8284 19.5 12C19.5 11.1716 18.8284 10.5 18 10.5C17.1716 10.5 16.5 11.1716 16.5 12C16.5 12.8284 17.1716 13.5 18 13.5Z" fill="#8D8D8D"/>
                    </svg>
                    <slot name="action">
                        <CardDropdown :cardId="cardId" :config="config" v-if="showDropdown" @closeDropdown="(value) => showDropdown = value"/>
                    </slot>
                </button>
            </div>
        </div>
        <div class="px-6 pb-8 pt-7 min-h-150px">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue'
    import routes from '@/routes/routes.js'
    import CardDropdown from './CardDropdown.vue'
    
    const showDropdown = ref(false)
    const { teamId, workspaceId } = routes.currentRoute.params
    const { dashboardId } = routes.currentRoute.query

    const props = defineProps({
        config: Object,
        cardId: [String, Number],
        dateVisibility: {
            type: Boolean,
            default: () => true
        },
        rangeVisibility: {
            type: Boolean,
            default: () => true
        },
        navigateUrl: Function
    });

    const title = computed(() => props.config?.title || 'Chart Name');
</script>

<style scoped>
    .custom_shadow{
        box-shadow: 0px 2px 8px rgba(44, 52, 65, 0.1);
    }
    .min-h-150px{
        min-height: 150px;
    }
</style>