<template>
    <div class="flex items-center space-x-4">
        <div class="flex items-center gap-x-2">
            <XCircle @click.native="deleteBgImage(activeTemplate, {})" class="w-3 h-3 text-primary-one cursor-pointer" />
            <div
                @click="$emit('onClickCoverImage')"
                class="w-9 h-5 border border-secondary-five rounded-sm cursor-pointer bg-center bg-cover bg-no-repeat"
                :style="{
                    backgroundImage: `url(${get(activeTemplate, 'image.file_path', '')})`
                }"
            >
            </div>
            <div class="flex fs-16 text-primary-one items-center">
                <span>Image</span>
                <input @input="updateTemplate(activeTemplate)" v-model="activeTemplate.settings.background_image_opacity" class="w-7 bg-transparent text-right" type="number" max="100" min="0">
                <span>%</span>
            </div>
        </div>
        <div class="flex items-center gap-x-2">
            <div @click="clickBorderColorPicker" :style="{backgroundColor: getTemplateBorderColor(activeTemplate, '#bababa').borderColor}" class="w-9 h-5 rounded-sm cursor-pointer"></div>
            <span class="text-primary-one">Secondary Color </span>

            <input @input="updateTemplate(activeTemplate)" ref="borderColorPicker" type="color" v-model="activeTemplate.settings.borderColor" class="absolute top-0 bottom-0 invisible" />
        </div>
        <div class="flex items-center gap-x-2">
            <div @click="clickColorPicker" :style="{backgroundColor: activeTemplate.settings.background_color}" class="w-9 h-5 rounded-sm cursor-pointer"></div>
            <div class="flex fs-16 text-primary-one items-center">
                <span>Background</span>
                <input @input="updateTemplate(activeTemplate)" v-model="activeTemplate.settings.background_color_opacity" class="w-7 bg-transparent text-right" type="number" max="100" min="0">
                <span>%</span>
            </div>
            <input @input="updateTemplate(activeTemplate)" ref="colorPickerElem" type="color" v-model="activeTemplate.settings.background_color" class="absolute top-0 bottom-0 invisible" />
        </div>
    </div>
</template>

<script setup>
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { ref } from 'vue'
import { get } from 'lodash'

const { deleteBgImage, updateTemplate, getTemplateBorderColor } = useTemplate()

const props = defineProps({
    activeTemplate: Object,
    setTemplateBg: Function
})

const colorPickerElem = ref()
const borderColorPicker = ref()

const clickColorPicker = () => {
    colorPickerElem.value.click()
}
const clickBorderColorPicker = () => {
    borderColorPicker.value.click()
}
</script>
