import axios from "axios";
export default class DefinitionController {
    constructor({ workspace }) {
        
        this.workspace = workspace.workspace;
        this.items = workspace.workspace.definition_group.items;
        this.definitionTitle = workspace.workspace.definition_group.title;
        this.definitionId = workspace.workspace.definition_group.id;
        this.workspaceId = workspace.workspace.id;
        this.selectedDefinition = {name:this.definitionTitle, items:this.items};
    }
    async updateTitle(editedTitle){
        axios({
            method: 'post',
            url: `workspaces/dashboard/definitions/${this.definitionId}/update`,
            headers: {}, 
            data: {
              title: editedTitle
            }
        });
    }

    getName(){
        return this.definitionTitle;
    }
    getItems() {
        return this.items;
    }

    async getAll() {
        let res = await axios.get(`workspaces/dashboard/definitions`).then(({ data }) => data.data);
        return res;
    }

    async updateDefinition(id){
        let res = await axios.post(`workspaces/dashboard/definitions/${this.workspaceId}/workspace-link`, {definition_group_id: id});

        if(res.data.status){
            let data = await axios.get(`workspaces/dashboard/initial/${this.workspaceId}`).then(({data}) => data.data);
            if(data){
                let definitionData = data.workspace.definition_group;
                this.selectedDefinition = {
                    name: definitionData.title, 
                    items: definitionData.items
                };
            }
        }
    }

    async changeDefinitionData(object){
        let res = await axios.post(`workspaces/dashboard/definitions/${object.id}/update-item`, object);
    }
}