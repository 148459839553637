import Coordinate from '@/components/communicator/v2/templates/coordinate/Index'
import ConsultingFrontpage from '@/components/communicator/v2/templates/coordinate/templates/consulting/ConsultingFrontpage'
import ConsultingKeyinsights from '@/components/communicator/v2/templates/coordinate/templates/consulting/keyinsights/Index'
import ConsultingKeysteps from '@/components/communicator/v2/templates/coordinate/templates/consulting/keysteps/Index'
import ConsultingOverview from '@/components/communicator/v2/templates/coordinate/templates/consulting/overview/Index'
import ConsultingIntroduction from '@/components/communicator/v2/templates/coordinate/templates/consulting/introduction/Index'

export default {
    'consulting-frontpage': ConsultingFrontpage,
    'coordinate': Coordinate,
    'consulting-keyinsights': ConsultingKeyinsights,
    'consulting-keysteps': ConsultingKeysteps,
    'consulting-overview': ConsultingOverview,
    'consulting-introduction': ConsultingIntroduction,
}