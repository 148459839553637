<template>
    <div>
        <div class="absolute z-20 top-0 w-full top-input flex justify-between">
            <input
                type="text" 
                class="bg-transparent mt-6 ml-6 fw-800 fs-16" 
                placeholder="Add text"
                v-model="hintsText[0]"
                @blur="updateStrategy(strategy)"
            >
            <input
                type="text" 
                class="bg-transparent mt-6 mr-6 fw-800 fs-16 text-right" 
                placeholder="Add text"
                v-model="hintsText[1]"
                @blur="updateStrategy(strategy)"
            >
        </div>
        <div class="absolute z-20 bottom-0 w-full top-input flex justify-between">
            <input
                type="text" 
                class="bg-transparent mb-6 ml-6 fw-800 fs-16" 
                placeholder="Add text"
                v-model="hintsText[2]"
                @blur="updateStrategy(strategy)"
            >
            <input
                type="text" 
                class="bg-transparent mb-6 mr-6 fw-800 fs-16 text-right" 
                placeholder="Add text"
                v-model="hintsText[3]"
                @blur="updateStrategy(strategy)"
            >
        </div>
    </div>
</template>

<script setup>
import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'
import { computed } from 'vue'
import { isEmpty } from 'lodash'

const { strategy, updateStrategy } = useHighLevelConcept()
const hintsText    = computed(() => {
    if(!strategy.value.hints_text || isEmpty(strategy.value.hints_text)){
        strategy.value.hints_text = ['Add text', 'Add text', 'Add text', 'Add text', 'Add text', 'Add text']
    }
    return strategy.value.hints_text
})
</script>

<style scoped>
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: var(--color);
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: var(--color);
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: var(--color);
    }
    :-moz-placeholder { /* Firefox 18- */
        color: var(--color);
    }
</style>