import { ref, watchEffect } from 'vue'
import axios from 'axios'
import BasicInformation from '@/components/dashboard/goal/basic_information/BasicInformation.vue'
import ActivityLog from '@/components/dashboard/goal/ActivityLog.vue'
import Outcome from '@/components/dashboard/goal/outcome/Outcome.vue'
import Progress from '@/components/dashboard/goal/Progress.vue'
import { setLoader } from './useLoader'
import {debounce} from 'lodash'
import { dateDefer } from '@/plugins/lodash.js'
import { format as dateFormat } from 'date-fns'
import UseProgressCalculation from '@/components/dashboard/goal/components/UseProgressCalculation.js'


const { transformGoal } = UseProgressCalculation()
const hasUnSaveItem = ref(false)
const primaryComponents = {
    BasicInformation,
    ActivityLog,
    Progress,
    Outcome
}

const priorities = {
    1:{
        name: 'low',
        value: 1,
        color: '#AD2B81'
    },
    2:{
        name: 'low',
        value: 1,
        color: '#ABA62B'
    },
    3:{
        name: 'low',
        value: 1,
        color: '#2BAD7E'
    }
}

const activityTypeSet = {
    1: 'System',
    2: 'Metric',
    3: 'Confidence'
}
let page = 1
const expandedIds = ref([])
const infoIds = ref([])
const showActiveOnly = ref(false)

const showConfiguration = ref(false);
const activity = ref({});
const goals = ref([])

export const useGoal = () => {
    const activePrimaryComponent = ref('BasicInformation')
    const activeBasicInfoComponent = ref('BasicInfoProgress')
    
    const loadGoals = async (workspaceId) => {
        setLoader(true)
        const {data} = await _getGoalByPage(workspaceId, page)
        if(data.data) {
            goals.value.push(data.data)
            page++
            loadGoals(workspaceId)
        } else {
            page =1
        }
        setLoader(false)
        transformGoal(goals.value, showActiveOnly.value)
    }

    const handleExpandedId = (item) => {
        let idIm = `${item.id}-${item.i_am}`
        if(expandedIds.value.includes(idIm)) {
            expandedIds.value.splice(expandedIds.value.indexOf(idIm), 1)
        } else {
            expandedIds.value.push(idIm)
        }
        return expandedIds.value.includes(idIm)
    }

    const handleConfiguration = (item) => {
        showConfiguration.value = !showConfiguration.value;
        activity.value = item;
        hasUnSaveItem.value = true
        transformGoal(goals.value, showActiveOnly.value)
    }
    
    const handleInfoId = (item) => {
        let idIm = `${item.id}-${item.i_am}`
        if(infoIds.value.includes(idIm)) {
            infoIds.value.splice(infoIds.value.indexOf(idIm), 1)
        } else {
            infoIds.value.push(idIm)
        }

        return infoIds.value.includes(idIm)
    }

    const handleGoalCreate = async (workspaceId, goalId) => {
        setLoader(true)
        const {data} = await _createGoal({
            workspace_id: workspaceId,
            title: 'Goal Title',
            description: ''
        })
        setLoader(false)
        if(data && data.status && goalId){//goalId is the id of the clicked goal
             goals.value.splice(goals.value.findIndex(({id}) => id == goalId)+1, 0, data.data)
             transformGoal(goals.value, showActiveOnly.value)
             return
        }
        if(data && data.status) goals.value.push(data.data)
    }

    const handleGoalDelete = async (goalId) => {
        setLoader(true)
        const {data} = await _deleteGoal(goalId)
        setLoader(false)
        if(data && data.status) {
            goals.value.splice(goals.value.findIndex(({id}) => id == goalId), 1)
        }
    }

    const _getGoalByPage = async (workspaceId, page) => await axios.get(`/workspaces/goals/${workspaceId}?page=${page}`)
    const _getGoalById = async (goalId) => await axios.get(`/workspaces/goals/${goalId}/view`)
    const _createGoal = async (data) => await axios.post('/workspaces/goals/create', data)
    const _deleteGoal = async (goalId) => await axios.delete(`/workspaces/goals/${goalId}`)
    const updateGoal = async (payload,  successCb = null) => {
        await axios.post(`/workspaces/goals/${payload.workspaceId}/update`, payload.data)
        if(successCb) successCb()
    }

    const updateGoalObjectForSpecificId = async (goalId) => {
        const { data } = await _getGoalById(goalId)
        if(data.data) {
            const oldGoalIndex = goals.value.findIndex(({id}) => id == data.data.id)
            goals.value.splice(oldGoalIndex, 1, data.data)
            transformGoal(goals.value, showActiveOnly.value)
        }
    }
    
    const updateActivity = debounce(async ({item, goalId}) => {  
        setLoader(true)
        let res = await axios.post(_getApiUrl(item), item)
        if(res.data?.status){
            await updateGoalObjectForSpecificId(goalId)
            transformGoal(goals.value, showActiveOnly.value)
            setLoader(false)
        }
    },100)

    const _getApiUrl = (item) => {
       let url = `workspaces/goals/${item.id}/update`
       switch(item.i_am){
           case 'objective': url = `workspaces/goals/objectives/${item.id}/update`
           break
           case 'key-result': url = `workspaces/goals/objectives/key-results/${item.id}/update`
           break
           case 'key-activity': url = `workspaces/goals/objectives/key-results/key-activities/${item.id}/update`
           break
           case 'activity': url = `workspaces/goals/objectives/key-results/key-activities/activities/${item.id}/update`
           break
       }
       return url
    }

    const updateConfiguration = async (item, workspaceId) => {
        setLoader(true)
        return await axios.post(`workspaces/goals/objectives/key-results/key-activities/activities/${item.id}/update`, item).then(({data}) => {
            loadGoals(workspaceId);
            setLoader(false);
            return data;
       });
    }

    const getProgress = (item) => {
        let totalDays = dateDefer(item.start_date, item.end_date)
        let totalDaysForCurrentDate = dateDefer(item.start_date, dateFormat(new Date(), 'yyyy-MM-dd'))

        let progress = (100 * totalDaysForCurrentDate) / totalDays
        return progress
    }

    return {
        activePrimaryComponent,
        activeBasicInfoComponent,
        primaryComponents,
        showConfiguration,
        activity,
        handleConfiguration,
        updateConfiguration,
        goals,
        handleGoalCreate,
        updateGoal,
        handleGoalDelete,
        loadGoals,
        activityTypeSet,
        updateActivity,
        getProgress,
        handleExpandedId,
        expandedIds,
        infoIds,
        handleInfoId,
        priorities,
        showActiveOnly,
        updateGoalObjectForSpecificId,
        hasUnSaveItem
    }
}