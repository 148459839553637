import { set } from 'vue'
import { get, update } from 'lodash'
import axios from 'axios'
import { useTemplate } from '@/views/screens/communicator/useTemplate'

import dashboard_close from '@/components/dashboard/icons/lg/dashboard_close.vue'
import dashboard_check from '@/components/dashboard/icons/lg/dashboard_check.vue'
import dashboard_pause from '@/components/dashboard/icons/lg/dashboard_pause.vue'
import dashboard_progress from '@/components/dashboard/icons/lg/dashboard_progress.vue'

const iconTemplates = {
    dashboard_close,
    dashboard_check,
    dashboard_pause,
    dashboard_progress,
}

export default function useRoadMap() 
{
    const { updateTemplate } = useTemplate()
    const setInitialData = (template) => 
    {
        if (template.settings.color === true || !template.settings.color) {
            set(template.settings, 'color', '#1C1C1D')
        }
        if (!('background_image_opacity' in template.settings)) {
            set(template.settings, 'background_image_opacity', 100);
        }
        if (!('background_color_opacity' in template.settings)) {
            set(template.settings, 'background_color_opacity', 100);
        }
        if (!('background_color' in template.settings)) {
            set(template.settings, 'background_color', '#fff');
        }
    }

    const handleElement = (activeTemplate, index, actionType, limit=8) => {
        if(actionType == 'remove'){
            if(index==0) return;

            // delete data_layer data
            activeTemplate.data_layer.forEach((element, lear_index) => {
                let customized_data_id = activeTemplate.customized_data.element[index].id
                if(element.conf_id ==  customized_data_id){
                    activeTemplate.data_layer.splice(lear_index, 1)
                }
            })

            activeTemplate.customized_data.element.splice(index, 1)
            updateTemplate(activeTemplate)
            return
        }
        
        if(activeTemplate.customized_data.element.length<limit){
            let data = {
                id: 'conf_id-'+Date.now(),
                title: 'Headline',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum. Lorem ipsum dolor sit amet, consectetur adipiscing. ',
                range: 0,
                progressColor: '#AD2B81',
                progressIconName: 'dashboard_close'
            }
            activeTemplate.customized_data.element.push(data)
            updateTemplate(activeTemplate)
        }
    }
    const getSampleValue = (activeTemplate, conf_id) => {
        let selectedDataLayer = activeTemplate.data_layer.find(elem => elem.conf_id == conf_id)
        if(!selectedDataLayer?.parameter) return null;
        return selectedDataLayer?.parameter?.sample_value
    }
    const getSampleTitle = (activeTemplate, conf_id) => {
        let selectedDataLayer = activeTemplate.data_layer.find(elem => elem.conf_id == conf_id)
        if(!selectedDataLayer?.parameter) return null;
        return selectedDataLayer?.parameter?.parameter_title
    }
    const getElement = (activeTemplate, index) => {
        if(!get(activeTemplate, `customized_data.element[${index}]`)) return {}
        return activeTemplate?.customized_data?.element[index]
    }

    const getProgressSentiment = async (projectId) => {
        if(!projectId) return {};
        const {data} = await axios.get(`projects/${projectId}?solid_object=true`).then(({data})=>data)
        if(data.definitions){
            return data.definitions.find(item => item.type === 2) //type = 2 means Progress Sentiment
        }
        return {}
    }
    const handleProgress = (activeTemplate, index, progressSentiment) => 
    {
        if(!activeTemplate?.customized_data?.element[index]) return {}
        let element = activeTemplate?.customized_data?.element[index]


        if((typeof element.range == 'number') && element.range < 100){
            set(element,'range', element.range+10)
        }else{
            set(element,'range', 0)
        }
        
        let icon  = ''
        let color = ''
        if(progressSentiment?.range){
            progressSentiment.range.forEach((item, index) => 
            {
                const [min, max] = [...item.replaceAll(/%/ig, '').split('-')]
                if(min <= element.range && max >= element.range){
                    icon = progressSentiment.icon[index]
                    icon = icon.replace('-', '_')
                    color = progressSentiment.color[index]
                }
            })

            set(element,'progressColor', color)
            set(element,'progressIconName', icon)
            return { color: color, icon: iconTemplates[icon] }
        }
        updateTemplate(activeTemplate)
    }

    const editTemplateTitle = (text, template) => {
        clearTimeout(timeoutId)
        set(template.customized_data.titleOne, 'title', text)
        setTimeout(() => {
            updateTemplate(template)
        }, 1000)
    }
    const editTemplateSubtitle = (text, template) => {
        clearTimeout(timeoutId)
        set(template.customized_data.titleTwo, 'subtitle', text)
        setTimeout(() => {
            updateTemplate(template)
        }, 1000)
    }
    const editTemplateDescription = (text, template) => {
        clearTimeout(timeoutId)
        set(template.customized_data.titleTwo, 'description', text)
        setTimeout(() => {
            updateTemplate(template)
        }, 1000)
    }

    let timeoutId = null;
    const editTitle = (modifiedText, data, activeTemplate) => {
        data.title = modifiedText
        if(timeoutId) clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            updateTemplate(activeTemplate)
        }, 500)
    }
    const editDescription = (modifiedText, data, activeTemplate) => {
        data.description = modifiedText
        if(timeoutId) clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            updateTemplate(activeTemplate)
        }, 500)
    }

    return {
        setInitialData,
        handleElement,
        getSampleValue,
        getSampleTitle,
        getElement,
        handleProgress,
        getProgressSentiment,
        iconTemplates,
        editTitle,
        editDescription,
        editTemplateTitle,
        editTemplateSubtitle,
        editTemplateDescription,
        // defaultTitleAndDescription
    }
}