import { get } from 'lodash'
import { set, ref } from 'vue'
import { useFile } from '@/composable/useFile'
import store from '@/store';
import { useTemplate } from '@/views/screens/communicator/useTemplate'


export default function useTableConcept() {
    const { onChange, previewURL, file, appendFile, formData, clear } = useFile()
    const { updateTemplate, saveScreenshot } = useTemplate()

    const fileElement = ref(null)
    const fileIndex = ref(null)
    
    const getElement = elm => fileElement.value= elm
    //have to refactor
    const configuration = {
        1: {
            agendaShowLength: 1,
            grid: 'grid-cols-1 gap-5',
            descripTionFontSize: 28
        },
        2:{
            agendaShowLength: 2,
            grid: 'grid-cols-1',
            descripTionFontSize: 20
        },
        3:{
            agendaShowLength: 9,
            grid: 'grid-cols-2 gap-2',
            descripTionFontSize: 20
        }
    }

    const openFileForTemplate = () => fileElement.value.click()
    const setCurrentFileIndex = (data) => {
        fileIndex.value = data
    }
    

    const onChangeImage = async (event, template) => {
        onChange(event)
        // currentDataLayer.value.image = previewURL
        appendFile('image')
        let {data: {data, status}} =  await store.dispatch('communicator/updateTemplateImage',{id:template.id, data: formData});
        if(status) {
            template.customized_data.images.splice(fileIndex.value -1, 1, data)
        }
        updateTemplate(template)
    }

    const getItemFromDataLayer = (dataLayer, id) => {
        return dataLayer.find(({conf_id}) => conf_id == id)
    }

    const getConfig = (template, key) => {
        const configurationNo = get(template, 'settings.configuration_no', null)
        if(configurationNo) {
            return configuration[configurationNo][key]
        }
    }

    const removeByKey = (template, e, index) => {
        if (e.keyCode === 46 && template.customized_data.itemsLength > 1) {
            removeItem(template, index);
        }
        updateTemplate(template)
    }

    const removeItem = (template, itemId) => {
        template.customized_data.items.splice(template.customized_data.items.findIndex(({id}) => id == itemId), 1);
        let dataLayerIndex = template.data_layer.findIndex(({conf_id}) => conf_id == itemId)
        if(dataLayerIndex != -1) {
            template.data_layer.splice(dataLayerIndex, 1);
        }
        updateTemplate(template)
    }

    const removeBodyText = (template, index, bodyIndex) => {
        template.customized_data.items[index].listItems.splice(bodyIndex, 1)
        updateTemplate(template)
    }
    
    const createItem = (template, index) => {
        if(template.customized_data.items.length >= 4) return
        template.customized_data.items.splice(index, 0, {
            id: (Math.random() + 1).toString(36).substring(2),
            title: "Sample text",
            description: 'Description',
            status: 'Yes',
            note: 'Your note',
            backgroundColor: '#edededb8',
        });
        updateTemplate(template)
    }

    const setInitialData = (template) => {
        if (template.customized_data.constructor == Array) {
            template.customized_data = {};
            set(template.customized_data, "items", []);
            updateTemplate(template)
        }
        if (!("heading" in template.customized_data)) {
            template.customized_data.heading = {
                id: (Math.random() + 1).toString(36).substring(2),
                value: 'Table Concept'
            };
            updateTemplate(template)
        }
        if (!("subtitle" in template.customized_data)) {
            template.customized_data.subtitle = 'Validate goals'
            updateTemplate(template)
        }
        if (!("first_th" in template.customized_data)) {
            template.customized_data.first_th = 'Identified Goals '
            updateTemplate(template)
        }
        if (!("second_th" in template.customized_data)) {
            template.customized_data.second_th = 'Agreed Yes/No/Tbd '
            updateTemplate(template)

        }
        if (!("third_th" in template.customized_data)) {
            template.customized_data.third_th = 'Amendments/Notes '
            updateTemplate(template)
        }
        if (!("thBackgroundColor" in template.customized_data)) {
            set(template.customized_data, 'thBackgroundColor', '#edededb8')
            updateTemplate(template)
        }

        if (template.customized_data.items.length == 0) {
            createItem(template, 0);
        }

    }

    const getSerial = (index) => {
        return index < 10 ? "0" + (index + 1) : index + 1;
    }

    const getStyle = (item, key, colorCode) => {
        return {[key]: get(item, key, colorCode)}
    }

    return {
        removeByKey,
        createItem,
        setInitialData,
        getSerial,
        removeItem,
        removeBodyText,
        getConfig,
        getElement,
        openFileForTemplate,
        onChangeImage,
        setCurrentFileIndex,
        getItemFromDataLayer,
        getStyle
    }
}