import { unref, set, watchEffect, isRef } from 'vue'
import { get } from 'lodash'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { getUnixTimeStamp } from '@/plugins/lodash'

export default function useOverview(activeTemplate) {

    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getIconVisibility, getTemplateBorderColor } = useTemplate();

    const onChangeConfig = (config) => {
        updateTemplate(activeTemplate.value)
    }

    const getContentImage = () => get(unref(activeTemplate), 'customized_data.styleConfig.background_image')

    const getContentImageStyle = () => {
        const opacity = get(unref(activeTemplate), 'customized_data.styleConfig.background_image_opacity')
        return {
            opacity: `${opacity}%`
        }
    }

    const getContentBgStyle = (source=null) => {
        const template = unref(activeTemplate)
        const {background_color, background_color_opacity} = source ?? template.customized_data.styleConfig
        return {
            backgroundColor: background_color,
            opacity: `${background_color_opacity}%`
        }
    }

    const getTitleStyle = () => {
        return {
            fontWeight: '900',
            fontSize: '32px',
            height: '95px',
            lineHeight: '140%',
            letterSpacing: '0.02em'
        }
    }

    const setInitialData = () => {
        const template = unref(activeTemplate)
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
        }
        // console.log(template)
        let checkTitle = "title" in template.customized_data;
        let subTitle = "subTitle" in template.customized_data;
        let checkDescription = "description" in template.customized_data;
        let checkConfig = "styleConfig" in template.customized_data;
        let mainImage = "mainImage" in template.customized_data;
        if (!checkTitle) {
            template.customized_data.title = {
                id: getUnixTimeStamp(),
                value: 'Create interactive data driven experiences'
            };
        }
        if (!subTitle) {
            template.customized_data.subTitle = {
                id: getUnixTimeStamp(),
                value: 'based on your distinct characteristics and options!'
            };
        }
        if (!checkDescription) {
            template.customized_data.description = {
                id: getUnixTimeStamp(),
                value: 'WE SEE A NEED TO DEMYSTIFY CHAN MANY ACTORS GO ABOUT BOTH STRATEGISING AND IMPLEMENTING BIM PROCESSES AND DIGITAL TECHNOLOGIES INTO THEIR ORGANISATIONS'
            };
        }
        if(!mainImage) set(template.customized_data, 'mainImage', null)
        if(!checkConfig) {
            set(template.customized_data, 'styleConfig', {
                background_image: '',
                background_image_opacity: 100,
                background_color: '#FBF9F1',
                background_color_opacity: 100
            })
        }
        
    }
    
    if(isRef(activeTemplate)) {
        watchEffect(setInitialData)
    }

    return {
        onChangeConfig,
        updateTemplate,
        getContentBgStyle,
        getContentImage,
        getContentImageStyle,
        getDataLayerItem,
        prepareConfiguration, 
        prepareRequest, 
        getPersistance,
        getTitleStyle
    }
}