import LayoutSixImg from '@/components/dashboard/components/image/LayoutSixImg.vue'

let layout  = {
    name: 'layout6',
    image: LayoutSixImg,
    rows: [
        { id: 'row_13', class: 'grid gap-5 grid-cols-3 grid-rows-2' },
        { id: 'row_14', class: 'grid gap-5 grid-cols-2' },
        { id: 'row_15', class: 'grid gap-5' },
    ],
    cards: [
        {
            rows_reference: 'row_13',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: 'i-row-span-2 col-span-2',
                height: 546,
                componentName: 'LargeChart'
            }
        },
        {
            rows_reference: 'row_13',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_13',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },

        {
            rows_reference: 'row_14',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        },
        {
            rows_reference: 'row_14',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        },

        {
            rows_reference: 'row_15',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        }
    ],
    isSelected: false
}


export default layout