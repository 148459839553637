import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { get } from 'lodash'

export default function useSectionCover() {
    const { getDataLayerItem, updateTemplate, prepareConfiguration, prepareRequest, getPersistance } = useTemplate()
    //have to refactor
    const getData = (template, key) => {
        return template.data_layer[key]
    }

    const removeTitleItem = (items, index, template) => {
        items.splice(index, 1);
        updateTemplate(template)
    }

    const createTitleItem = (items, index, template) => {
        items.splice(index, 0, {
            name: 'Name',
            title: 'Title'
        });
        updateTemplate(template)
    }

    return {
        getData,
        removeTitleItem,
        createTitleItem,
        updateTemplate,
        getDataLayerItem,
        prepareConfiguration, prepareRequest, getPersistance
    }
}