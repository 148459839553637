import router from '@/routes/routes.js'
import { ref, computed, unref, isRef, watchEffect } from 'vue'
import { get } from 'lodash'
import { add as addDate, format as formatDate } from 'date-fns'

import { useProjectData } from '@/components/dashboard/sidebar/useProjectData.js'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import useData from './useData'
import axios from 'axios'

export const _flatTree = (localLevel) => (result, object) => {
    let child = object.children || [];
    let item = Object.keys(object).reduce(function (o, k) {
        if (k !== 'children') {
            o[k] = object[k];
        }
        return o;
    }, {});
    localLevel = localLevel || 0;
    item.localLevel = localLevel;
    return result.concat(item, child.reduce(_flatTree(localLevel + 1), []));
};

export default function useHighlevelPlan(activeTemplate) {
    const template = unref(activeTemplate)
    let firsTime = true
    const systemData = ref([])
    const scenarios = ref([])
    const route = router.currentRoute;
    const series = ref([])

    const chartOptions = {
        chart: {
            type: 'rangeBar',
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                distributed: true,
                rangeBarOverlap: false,
                dataLabels: {
                    hideOverflowingLabels: true
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                var label = opts.w.globals.labels[opts.dataPointIndex]
                
                return label
            },
            style: {
                colors: ['#f3f4f5', '#fff']
            }
        },
        xaxis: {
            type: 'datetime',
            show: false,
        },
        yaxis: {
            show: true,
            labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 280,
                style: {
                    colors: [],
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                }
            },
        },
        grid: {
            borderColor: 'blue',
            strokeDashArray: 5,
            xaxis: {
                lines: {
                    show: true
                }
            }
        }
       
    }

    const updateActiveTemplate = () => updateTemplate(template)

    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getTemplateBorderColor } = useTemplate();
    const { setInitialData } = useData()
    const { getScenariosByProject, getModulesByProject, getPropertiesByModule } = useProjectData()

    const projectId = computed(() => route.params.id)

    const getChartData = (items) => {
        if(!items || !items.length) return
        let filteredData = []
        if(template.settings.filterTreeData && template.settings.filterTreeData.length < 1) {
            filteredData = items.slice(0, 7)
        } else {
            let flatData = items.reduce(_flatTree(), []);
            filteredData = flatData.filter(({id}) => template.settings.filterTreeData.includes(id.toString()))
        }

        const data = _makeChartData(filteredData)
        return [{ data: data }]
    }

    const setSystemData = async () => {
        const { moduleId, scenarioId } = template.settings
        if (moduleId && scenarioId) {
            const data = await fetchSystemData(scenarioId, {
                parameter: 0,
                synchronized: 1,
                phases: true,
                module_id: moduleId
            })
            systemData.value = data
            // if (data.children) {
            //     let flattenCollections = data.children.reduce(_flatTree(), []);
                
            //     const chartData = _makeChartData(flattenCollections)
            //     series.value.splice(0, 1, { data: chartData })
            // }
        }
        updateActiveTemplate()
    }

    const _makeChartData = (flattenCollections) => {
        const { item_start_date } = systemData.value.dates
        let data = flattenCollections.map(({ index_id, title, index_code, start_date, end_date, background_color }) => ({
            x: `${index_id}.${index_code}.${title}`,
            y: [
                new Date(start_date || item_start_date).getTime(),
                new Date(_getEndDate(end_date, start_date, item_start_date)).getTime()
            ],
            fillColor: background_color
        }))

        return data
    }

    const _getEndDate = (end_date, start_date, item_start_date) => {
        if(end_date) return end_date
        const endDate = addDate(new Date(start_date || item_start_date), {days: 30})
        return formatDate(endDate, 'yyyy-MM-dd')
    }

    const getModule = (modules) => {
        const { moduleId } = template.settings
        if (moduleId) {
            return modules.find(({ id }) => id == moduleId)
        }
    }

    const getColor = (key) => get(template, `customized_data.colorConfig[${key}]`, '#fff')

    const fetchSystemData = async (scenarioId, params) => {
        return axios.get(`/roadmaps/strategies/system-data/${scenarioId}`, {
            params: { ...params }
        }).then(({ data }) => data.data);
    }

    const handleModuleChange = () => {
        setSystemData()
        updateActiveTemplate()
    }

    if (isRef(activeTemplate)) {
        watchEffect(async () => {
            setInitialData(template, updateTemplate)
            if (firsTime) {
                setSystemData()
                scenarios.value = await getScenariosByProject(projectId.value)
            }
            firsTime = false
        })
    }

    return {
        series,
        chartOptions,
        updateTemplate,
        prepareConfiguration,
        prepareRequest,
        getPersistance,
        getDataLayerItem,
        getTemplateBorderColor,
        getModule,
        systemData,
        setSystemData,
        scenarios,
        getColor,
        updateActiveTemplate,
        handleModuleChange,
        getChartData
    }
}