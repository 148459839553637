<template>
    <div
        :class="{'scale-down' : currentMode != 'fullscreen'}"
        class="matric_detail_wrapper communicator__template--wrapper relative"
        :style="{
          color: activeTemplate.settings.color,
          '--borderColor': activeTemplate.settings.borderColor,
        }"
    >
        <Header
          :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
          :textColor="getTemplateTextColor(activeTemplate, '#fff')"
          :modules="modules"
          :activeTemplate="activeTemplate"
          @selectScenario="value => scenarioId = value"
        />
        
        <div class="flex items-center relative gap-12 mt-7 mb-3">
            <AttachmentIcon 
              :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
              @click.native="showConfiguration(['title'], 'main_title')" 
              class="title_icon w-6 h-6 cursor-pointer flex-shrink-0" 
            />
            <h2 v-if="title(activeTemplate, 'main_title')" class="title fw-900 w-full text-6xl left-gap m-0">
              {{ title(activeTemplate, 'main_title') }}
            </h2>
            <input 
              v-else 
              class="title fw-900 w-full text-6xl left-gap m-0 bg-transparent"
              contenteditable="true" 
              placeholder="Title goes here"
              @input="(e) => editTitle(e.target.value, activeTemplate)"
              :value="getTitle(activeTemplate)"
            />
        </div>

      <div class="relative z-10 ml-12 mb-6 fs-34 flex items-center gap-3">
        <PlusSquareIcon
          v-if="config_visibility"
          id="plusSquare"
          class="w-5 h-5 mt-1 cursor-pointer flex-shrink-0 flex-grow-0 absolute -left-8"
          @click.native="switchSidebarComponent('DataSource', null)"
        />
      </div>
      <div class="relative z-10 flex gap-2 content-wrapper">
        <div class="content-area relative overflow-hidden">
          
          <div
            class="grid gap-8 grid-column-metric grid-row-metric overflow-x-auto"
            ref="header"
            @scroll="(e) => ($refs.body.scrollLeft = e.target.scrollLeft)"
          >
            <SidebarTitleText
              :title="get(collection, 'title', '') || 'Title'"
              :description="get(collection, 'description', '') || 'Description'"
              icon-classes="w-5"
              class="sticky left-0 SidebarTitleText"
              :isFullWidth="true"
            />

            <!-- Placeholder start -->
            <ContentBox
              v-if="_getProperties(activeTemplate.parameters, module).length == 0"
              IsSemibold
              title="Parameter 1"
              subtitle="Non or limited Capability"
              description="Disruptive capabilities and business models is extending value creation and the text here can continue if necessary "
              :colorCode="activeTemplate.settings.borderColor"
              :config_visibility="config_visibility"
            >
              <div
                v-for="(i) in 10"
                :key="i"
                class="radius-2 bg-secondary-three w-5 h-5"
                :style="{ backgroundColor: activeTemplate.settings.borderColor }"
              ></div>
            </ContentBox>
            <!-- Placeholder end -->
            
            <!-- class="border-b border-secondary-four" -->
            <ContentBox
                v-for="(property, i) in _getProperties(activeTemplate.parameters, module)"
                :key="i"
                IsSemibold
                :title="property.title || '_'"
                :subtitle="_getParameterDefinition(collection, activeTemplate, property)"
                :description="getDefinitionDescription(collection, activeTemplate, property)"
                :colorCode="_getParameterDefinitionColor(collection, activeTemplate, property)"
                :config_visibility="config_visibility"
            >
              <div
                v-for="(i, index) in 10"
                :key="i"
                class="radius-2 bg-secondary-three w-5 h-5"
                :style="{ backgroundColor: getBackground(collection, activeTemplate, property, index) || activeTemplate.settings.borderColor }"
              ></div>
            </ContentBox>
          </div>


          <div
            class="overflow-auto border-t grid pt-6"
            style="max-height: 525px;display: grid;gap: 52px;padding-top: 30px;border-color: var(--borderColor)"
            ref="body"
            @scroll="(e) => ($refs.header.scrollLeft = e.target.scrollLeft)"
          >
            <div
              class="grid gap-8 grid-column-metric"
              v-for="collection in collections"
              :key="collection.id"
            >
              <SidebarTitleText
                :title="collection.title"
                class="items-center sticky left-0"
                :isFullWidth="true"
                :titleStyle="{fontSize: '24px', fontWeight: 500}"
              />
              <ContentBox
                v-for="(property, i) in _getProperties(activeTemplate.parameters, module)"
                :key="i"
                :subtitle="_getParameterDefinition(collection, activeTemplate, property)"
                class="bottomParameter"
              >
                <div
                  v-for="index in 10"
                  :key="index"
                  class="radius-2 bg-secondary-three w-5 h-4 -mt-1"
                  :style="{ backgroundColor: getBackground(collection, activeTemplate, property, index) || activeTemplate.settings.borderColor }"
                ></div>
              </ContentBox>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
    import TemplateContainer from "../TemplateContainer";
    import TextComponent from "@/elements/atom/TextComponent";
    import SidebarTitleText from "@/components/communicator/v2/templates/SidebarTitleText.vue";
    import ContentBox from "./ContentBox.vue";
    import TextInput from "@/components/communicator/templates/TextInput.vue";
    import Header from '@/components/communicator/v2/templates/Header.vue';
    import { ref, onMounted, watchEffect, computed } from 'vue';
    import { find, get, isEmpty } from 'lodash';
    import store from '@/store'

    import { useHorizontalMetrics } from '@/components/communicator/v2/templates/metricDetails/useMetricDetails.js'
    import { useTemplate } from '@/views/screens/communicator/useTemplate.js';
    import { useCollection } from '@/composable/useCollection.js';
    import useModule from '@/composable/useModule.js'
    import useTemplateTitle from '../useTemplateTitle'

    const { getBackground, _getParameterDefinition, _getParameterDefinitionColor, _getProperties, getDefinitionDescription, getBackgroundByCollection } = useHorizontalMetrics();
    const { title, getLevelId, getRootType, loadCollection, prepareConfiguration, prepareRequest, getPersistance, getTemplateTextColor, updateTemplate } = useTemplate();
    const { getCollectionById } = useCollection();
    const { prepareModuleProperties, getModuleIdFromDataLayer } = useModule();
    const { initTitle, editTitle, getTitle } = useTemplateTitle();
    
    const collections = ref([]);
    const scenarioId = ref(null);

    const collection = ref({
        title: '',
        description: ''
    });
    const module = ref({
        title: '',
        description: ''
    });

    const props = defineProps({
        modules: Array,
        activeTemplate: Object,
        switchSidebarComponent: Function,
        currentMode: String,
        config_visibility: Boolean,
        synchronize: Boolean
    });


    const showConfiguration = (options, confId) => {
        props.switchSidebarComponent('TextSource', {
            persistance: getPersistance(props.activeTemplate, confId),
            confId: confId,
            transform: (config) => {
                let inputs = prepareConfiguration(options, config);
                return prepareRequest(props.activeTemplate, confId, config, inputs, props.synchronize);
            }
        })
    }

    watchEffect(async () => {
        let response = await loadCollection(props.activeTemplate, scenarioId.value);
        collection.value = getCollectionById(response, getLevelId(props.activeTemplate)) || {};
        collections.value = collection.value?.child || [];

        if(getRootType(props.activeTemplate) === 'module') {
            collections.value = response;
            let module = find(props.modules, ['id', parseInt(getLevelId(props.activeTemplate))]);
            if(!isEmpty(module)) {
                collection.value = prepareModuleProperties(module, collections.value) || {};
            }
        }
        module.value = find(props.modules, ['id', getModuleIdFromDataLayer(props.activeTemplate)]);
    });

    onMounted(() => {
      initTitle(props.activeTemplate, 'Headline')
    })
</script>

<style scoped>
.grid-column-metric {
  grid-template-columns: 465px 373px 373px 373px 373px 373px;
  grid-auto-flow: column;
  grid-auto-columns: 147px;
}

.grid-row-metric {
  grid-template-rows: 256px;
}

.content-area::before {
  content: "";
  position: absolute;
  width: 1px;
  background: var(--borderColor);
  height: 100%;
  top: -50px;
  left: 482px;
}

.content-area::after {
  content: "";
  position: absolute;
  width: 1px;
  background: var(--borderColor);
  height: 100%;
  bottom: 0px;
  left: 482px;
}
.matric_detail_wrapper{
  padding: 43px 88px;
}
.title{
  margin-top: 16px;
  margin-left: 27px;
}
.title_icon{
  margin-left: -26px;
  margin-bottom: -15px;
}
.content-wrapper{
  margin-left: 70px;
  margin-top: -8px;
}
#plusSquare{
  top: 30px;
  left: -41px;
}
.bottomParameter{
    margin-top: -10px !important;
}
.bottomParameter .title{
  font-size: 20px;
  font-weight: 600px;
}
</style>