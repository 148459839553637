<template>
    <div
        :style="{
            backgroundImage: `url(${getImage()})`,
            ...cardStyle
        }"
        :class="{'border border-dashed': !getImage()}"
        class="border-secondary-five rounded relative bg-cover bg-no-repeat bg-center"
    >
        <MenuAltSolid
            @click.native="$emit('onClick')"
            class="w-5 h-5 transform rotate-180 absolute right-3 bottom-1 cursor-pointer" 
        />
    </div>
</template>

<script setup>
import { get } from 'lodash'

const props = defineProps({
    template: Object,
    cardStyle: Object,
    imagePath: {
        type: String,
        default: 'customized_data.mainImage'
    }
})

const getImage = () => {
    const template = props.template
    const logo =  get(template, props.imagePath, '/images/instrat_logo.svg')
    return logo ? logo : null
}
</script>