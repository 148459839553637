import useHtml2canvas from './useHtml2canvas'
import useBlob from './useBlob'

export default function useScreenshot() {
    const { getCanvas } = useHtml2canvas()
    const { blobCreationFromURL } = useBlob()

    const getDataURL = async(element) => {
        if(!element) return
        let canvas = await getCanvas(element)
        return canvas.toDataURL()
    }
    
    const getBlobImageFile = async (elm=null) => {
        let element = elm || document.querySelector("#capture")
        if(!element) return
        const dataUrl =  await getDataURL(element)
        return blobCreationFromURL(dataUrl)
        // let canvas = await getCanvas(element)
        // return canvas.toBlob()
    }

    return {
        getDataURL,
        getBlobImageFile,
    } 
}