var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border bg-white custom_shadow rounded-lg h-full"},[_c('div',{staticClass:"bg-primary-three border-b border-secondary-three flex justify-between items-center py-4 px-6 rounded-t-lg"},[_c('router-link',{staticClass:"fw-700 fs-14 text-primary-one whitespace-nowrap",attrs:{"to":{
                name: 'chartConfiguration',
                params: {
                    workspaceId: _setup.workspaceId,
                    teamId: _setup.teamId,
                    cardId: _vm.cardId,
                    dashboardId: _setup.dashboardId,
                }
            }}},[_vm._v(" "+_vm._s(_setup.title)+" ")]),_c('div',{staticClass:"flex"},[_c('button',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _setup.showDropdown = false),expression:"() => showDropdown = false"}],staticClass:"pl-5 relative"},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){_setup.showDropdown = !_setup.showDropdown}}},[_c('path',{attrs:{"d":"M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z","fill":"#8D8D8D"}}),_c('path',{attrs:{"d":"M6 13.5C6.82843 13.5 7.5 12.8284 7.5 12C7.5 11.1716 6.82843 10.5 6 10.5C5.17157 10.5 4.5 11.1716 4.5 12C4.5 12.8284 5.17157 13.5 6 13.5Z","fill":"#8D8D8D"}}),_c('path',{attrs:{"d":"M18 13.5C18.8284 13.5 19.5 12.8284 19.5 12C19.5 11.1716 18.8284 10.5 18 10.5C17.1716 10.5 16.5 11.1716 16.5 12C16.5 12.8284 17.1716 13.5 18 13.5Z","fill":"#8D8D8D"}})]),_vm._t("action",function(){return [(_setup.showDropdown)?_c(_setup.CardDropdown,{attrs:{"cardId":_vm.cardId,"config":_vm.config},on:{"closeDropdown":(value) => _setup.showDropdown = value}}):_vm._e()]})],2)])],1),_c('div',{staticClass:"px-6 pb-8 pt-7 min-h-150px"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }