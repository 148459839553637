import { sidebarComponents as components } from './useImporter';
import { ref, computed } from 'vue';
import axios from 'axios';

export const useSidebar = () => {
    const currentSidebar = ref('ModuleList');
    const sidebarVisibility = ref(true);
    const propsData = ref({});
    const activeSidebarComponent = computed(() => components[currentSidebar.value]);
    const switchSidebarComponent = (value, data) => {
        if(currentSidebar.value == 'ModuleList' && value == 'ModuleList') {
            sidebarVisibility.value = false;
        }
        currentSidebar.value = value;
        propsData.value = data;
    }
    const getScenariosByProject = async (projectId) => {
       return axios.get(`communicators/system/${projectId}/scenarios`).then(({ data }) => data.data);
    }
    const getLevelDataByScenarioAndModule = async (scenarioId, moduleId, params) => {
       return axios.get(`communicators/system/${scenarioId}/a-level-data`, {
            params: {
                module_id: moduleId,
                response_type: 'nested',
                // with_nested_module: true,
                ...params
            }
       }).then(({ data }) => data.data);
    }

    const saveTemplateConfiguration = async (templateId, payload) => {
        return axios.post(`communicators/templates/${templateId}`, payload).then(({data}) => data.data);
    }

    return {
        currentSidebar,
        sidebarVisibility,
        activeSidebarComponent,
        propsData,
        // Methods
        getScenariosByProject,
        switchSidebarComponent,
        saveTemplateConfiguration,
        getLevelDataByScenarioAndModule
    }
}
