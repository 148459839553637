<template>
    <div>
        <select
            class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
            placeholder="Property"
            v-model="configuration.property_id"
        >
            <option value="null" disabled selected>Select Property</option>
            <option v-for="(property, index) in getProperties" :key="index" :value="property.id">{{ property.title }}</option>
        </select>

        <select
            class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
            placeholder="Parameter"
            v-model="configuration.parameter_id"
        >
            <option value="null" disabled selected>Select Parameter</option>
            <option
                v-for="(parameter, parameterIndex) in getParameters"
                :key="parameterIndex"
                :value="parameter.id"
            >
                {{ parameter.title }}
          </option>
        </select>
    </div>
</template>

<script setup>
    import { computed, ref } from 'vue';
    const props = defineProps({
        module: Object,
        configuration: Object
    });
    const form = ref({
        property_id: null,
        parameter_id: null
    });
    const getProperties = computed(() => {
        if(props.module.system_properties && props.module.system_properties.length > 0) {
            return props.module.system_properties;
        }
        return [];
    });

    const getParameters = computed(() => {
      if(props.configuration.property_id) {
        let data = props.module.system_properties.find(item => parseInt(item.id) === parseInt(props.configuration.property_id));
        return data?.property_collections || [];
      }
      return [];
    });
</script>