<template>
  <div class="flex flex-col" :class="{'ml-5': layoutIndex !== 0}">
    <div v-if="isExpanded || (!isExpanded && layoutIndex === 0)"
      class="flex justify-between items-center layout rounded px-4 mb-8" :class="{'expanded-layout': isExpanded,
      'collapsed-layout': !isExpanded && layoutIndex !== 0,
      'collapsed-first-layout': !isExpanded && layoutIndex === 0}"
      :style="{'background': getModuleBg(module)}">
      <div class="flex cursor-pointer" :class="{'mr-5': isExpanded}" @click="isExpanded = !isExpanded">
        <div class="flex" v-if="isExpanded || (!isExpanded && layoutIndex === 0)">
          <h4 class="fs-14 text-primary-three fw-700 module-title-width ellipse">{{ get(module, 'title') }} </h4>
          <img src="/images/roadmap/widget_load_white.svg" alt="icon"
            class="pr-4 i-border-r-1 border-primary-three cursor-pointer ml-4" />
          <!-- <h4 class="fs-14 text-primary-three fw-500 pl-4">{{ layout.title }}</h4> -->
          <InputText v-if="layout" v-model="layout.title"
            class="fs-14 text-primary-three fw-500 pl-4 bg-transparent placeholder-secondary-two truncate"
            @input="updateLayout(layout)" @click.native.stop />
        </div>
      </div>
      
    </div>
    <div class="flex gap-5 relative i-mb-28" v-show="isExpanded">
      <slot> </slot>
    </div>
    <slot v-if="isExpanded" name="footer" :isExpanded="isExpanded"/>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import InputText from '@/elements/inputs/InputText';
import {debounce, get} from 'lodash'
// import { getModuleBg } from '@/store/modules/global/services';


const props = defineProps({
  module: {
    type: Object
  },
  layout: Object,
  layoutIndex: Number,
  moduleIndex: Number,
})

const isExpanded = ref(props.moduleIndex == 0)

const getModuleBg = (module, level = 1) => {
  // return getModuleBg(module, level);
  return '#2b80ad'
}

const updateLayout = debounce(function (layout) {
  return
  //store.dispatch("communicator/updateLayout", layout);
}, 2000)


</script>

<style scoped>
.group-data {
  min-width: 460px;
}

.layout {
  height: 50px;
  /*min-width: 546px;*/
}

.group-shadow-bg {
  height: 50px;
}

.group-setting {
  height: 40px;
}

.widget-setting-icon {
  width: 13px;
  height: 13px;
}



.collapsed-layout {
  width: 50px;
}

.collapsed-first-layout {
  min-width: 620px;
}

.expanded-layout {
  min-width: 620px !important;
}

.module-title-width {
  width: 170px;
}
</style>
