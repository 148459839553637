import { render, staticRenderFns } from "./FourZonesWithText.vue?vue&type=template&id=26abf6f4&scoped=true&"
import script from "./FourZonesWithText.vue?vue&type=script&setup=true&lang=js&"
export * from "./FourZonesWithText.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./FourZonesWithText.vue?vue&type=style&index=0&id=26abf6f4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26abf6f4",
  null
  
)

export default component.exports