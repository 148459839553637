export default function useIconVisibility() {
    const getVisibility = (...arg) => {
        if(arg.length < 1) return false
        const [{currentMode, ...other}] = arg
        if(currentMode == 'fullscreen') {
          return false
        }
        return _checkOther(other)
      }
      
      const _checkOther = (other) => {
         return Object.keys(other).every(key => {
           if(other[key] === false) return false
           return true
         })
      }

    return {
        getVisibility
    } 
}