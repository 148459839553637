<template>
    <div
        :class="{'scale-down' : currentMode != 'fullscreen'}"
        class="wrapper communicator__template--wrapper relative pt-12 px-14"
        :style="[ getTemplateTextColor(activeTemplate, '#fff')]"
    >
        <Header
            :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
            :modules="modules"
            :textColor="getTemplateTextColor(activeTemplate, '#fff')"
            :activeTemplate="activeTemplate"
            @selectScenario="value => scenarioId = value"
        />
        <div class="flex items-center relative z-10">
            <AttachmentIcon
                @click.native="showConfiguration('TextSource', ['title'], 'main_title')" 
                class="attachmentIcon w-5 h-5 cursor-pointer flex-shrink-0"
                :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
            />
            <h2 
                v-if="title(activeTemplate, 'main_title')"
                class="fw-800 w-full i-mt-34 i-mb-28 text-4xl left-gap" 
                :style="{ fontSize: '64px', marginTop: '55px'}"
            >
                {{ title(activeTemplate, 'main_title') }}
            </h2>
            <input 
                v-else
                class="fw-900 w-full i-mt-34 i-mb-28 text-4xl left-gap bg-transparent" 
                :style="{
                    fontSize: '60px',
                    marginTop: '55px',
                    fontSize: '60px',
                    marginTop: '30px',
                    marginBottom: '4px'
                }"
                contenteditable="true" 
                placeholder="Title goes here"
                @input="(e) => editTitle(e.target.value, activeTemplate)"
                :value="getTitle(activeTemplate)"
            />
        </div>
        <div class="flex relative z-10 mt-12">
            <PlusSquareIcon
                class="w-5 h-5 cursor-pointer mt-8"
                @click.native="showConfiguration('DataSource')"
                :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
            />
            <div
                class="flex w-full overflow-auto ml-12 mt-6 mb-0"
                style="
                    margin-left: 40px;
                    width: 1577px;
                    height: 382px;
                    display: flex;
                    gap: 36px;
                "
            >
            
            <!-- this box used as Placeholder start -->
            <ContentBox
                v-if="_getProperties(activeTemplate.parameters, module).length == 0"
                title="Parameter 1"
                short-description="Transformational Capability"
                description="Disruptive capabilities and business models is extending value creation."
                description-color="inherit"
            >
                <div
                    style="height: 16px"
                    class="w-full radius-2"
                    v-for="(n) in 10"
                    :key="n"
                    :style="{ backgroundColor: activeTemplate.settings.borderColor || '#ccc'}"
                ></div>
            </ContentBox>
            <!-- this box used as Placeholder end -->

            <ContentBox
                v-for="(property, i) in _getProperties(activeTemplate.parameters, module)"
                :key="i"
                :title="property.title"
                :short-description="_getParameterDefinition(collection, activeTemplate, property)"
                :description="getDefinitionDescription(collection, activeTemplate, property)"
                :description-color="_getParameterDefinitionColor(collection, activeTemplate, property)"
            >
                <div
                    style="height: 16px"
                    class="w-full radius-2"
                    v-for="(n, index) in 10"
                    :key="n"
                    :style="{ backgroundColor: getBackground(collection, activeTemplate, property, index) || activeTemplate.settings.borderColor}"
                ></div>
            </ContentBox>
        </div>
      </div>
      <div class="flex mt-14 relative z-10">
        <AttachmentIcon
            @click.native="showConfiguration('DataSource')" 
            class="attachmentIcon w-5 h-5 mt-0 cursor-pointer flex-shrink-0"
            :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
        />
        <div class="left-gap">
            <h2 class="text-5xl fw-800 mb-3">
                {{ get(collection, 'title') || 'Title' }}
            </h2>
            <p class="fs-18 leading-7 font-normal description">
                {{ get(collection, 'description') || 'Description' }}
            </p>
        </div>
      </div>
    </div>
</template>
<script setup>
    import ContentBox from "./ContentBox.vue";
    import Header from '@/components/communicator/v2/templates/Header.vue';
    import TextInput from "@/components/communicator/templates/TextInput.vue";
    import { useMetricSummary } from '@/components/communicator/v2/templates/metricSummary/useMetricSummary.js'
    import { useTemplate } from '@/views/screens/communicator/useTemplate.js';
    import { useCollection } from '@/composable/useCollection.js';
    import useModule from '@/composable/useModule.js';
    import { ref, watchEffect, onMounted, computed } from 'vue';
    import { find, get } from 'lodash'
    import store from '@/store'
    import useTemplateTitle from '../useTemplateTitle'

    const collections = ref([]);
    const collection = ref({});
    const module = ref({});
    const scenarioId = ref(null);
    const { initTitle, editTitle, getTitle } = useTemplateTitle();

    const { getBackground, _getParameterDefinition, _getParameterDefinitionColor, _getProperties, getDefinitionDescription} = useMetricSummary();
    const { title, getLevelId, getRootType, prepareConfiguration, prepareRequest, getPersistance, loadCollection, getTemplateTextColor } = useTemplate();
    const { getCollectionById } = useCollection();
    const { prepareModuleProperties, getModuleIdFromDataLayer } = useModule();
    const props =  defineProps({
        modules: Array,
        activeTemplate: Object,
        switchSidebarComponent: Function,
        currentMode: String,
        config_visibility: Boolean,
        synchronize: Boolean
    });
    
    const showConfiguration = (componentName, options, confId) => {
        props.switchSidebarComponent(componentName, {
            persistance: getPersistance(props.activeTemplate, confId),
            confId: confId,
            transform: (config) => {
                let inputs = prepareConfiguration(options, config);
                return prepareRequest(props.activeTemplate, confId, config, inputs, props.synchronize);
            }
        });
    }
    
    watchEffect(async () => {
        collections.value = await loadCollection(props.activeTemplate, scenarioId.value);
        collection.value = getCollectionById(collections.value, getLevelId(props.activeTemplate));
        if(getRootType(props.activeTemplate) === 'module') {
            let module = find(props.modules, ['id', parseInt(getLevelId(props.activeTemplate))]);
            if(module){
                collection.value = prepareModuleProperties(module, collections.value);
            }
        }
        module.value = find(props.modules, ['id', getModuleIdFromDataLayer(props.activeTemplate)]) || {};
    });

    const getTemplateTitle = computed(() => {
        getTitle(props.activeTemplate)
    })

    onMounted(() => {
        initTitle(props.activeTemplate, 'Headline')
    })

</script>

<style scoped>
    .wrapper{
        padding-top: 41px;
        padding-left: 95px;
        padding-right: 95px;
    }
    .left-gap {
        margin-left: 44px;
    }

  .description{
    font-size: 40px;
    margin-top: 0px;
    line-height: 1.2;
    height: 195px;
    overflow-y: auto;
  }
  .attachmentIcon{
    transform: translate(-30px, 15px) scale(1.3);
  }
</style>