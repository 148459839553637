<template>
    <div
        class="h-full relative"
        :style="{
            color: activeTemplate.settings.color,
            '--color': activeTemplate.settings.color,
            '--bg-color': activeTemplate.settings.background_color,
            '--borderColor': activeTemplate.settings.borderColor
        }">
        <div
            class="wrapper flex-grow-0 relative"
            :class="[currentMode != 'fullscreen' ? 'layout-scale-down' : '']"
        >
            <div
                class="relative z-10 w-full flex gap-5 top-bar -mt-1"
                :class="currentMode == 'fullscreen' && 'invisible'"
            >
                <div :class="!config_visibility&&'invisible'">
                    <span class="fw-800 fs-14">Definition Set: </span>
                    <select
                        @change="getDefinition"
                        class="bg-transparent outline-none fw-600 fs-14"
                        v-model="activeDefinitionId"
                    >
                        <option :style="{background:activeTemplate.settings.background_color, color: activeTemplate.settings.color}" selected value="0">-Select-</option>
                        <option :style="{background:activeTemplate.settings.background_color, color: activeTemplate.settings.color}" v-for="set in definitionSets" :key="set.id" :value="set.id">{{ set.title }}</option>
                    </select>
                </div>
            </div>
            <Title :activeTemplate="activeTemplate" />
            <div class="definitions relative z-10 overflow-x-auto">
                <div
                    v-for="(item,index) in definitionSet?.items"
                    v-if="index<=2"
                    :key="'uuid_'+index">
                    <h2 class="fs-30 fw-600 mt-8 mb-6">{{ item.title }}</h2>
                    <div 
                        class="flex flex-col-reverse border-b"
                        style="border-color: var(--borderColor)"
                    >
                        <div v-for="(range_item, index) in item?.range_with_definition" :key="'ruuid'+index"
                            class="fs-22 fw-600 border-t pt-4 pb-4"
                            style="border-color: var(--borderColor)">

                            <div class="flex items-center gap-4 item">
                                <span v-if="range_item.range" class="whitespace-nowrap">{{ range_item.range }}:</span>
                                <span
                                    v-if="range_item.range && range_item.range.indexOf('Level')<0"
                                    class="max-h-full overflow-y-auto"
                                >
                                    {{ range_item.definition }}
                                </span>
                                <span v-else class="max-h-full overflow-y-auto fw-600">
                                    {{ range_item.definition }}
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import store from '@/store'
    import { onMounted, ref, watch } from 'vue'
    import routes from '@/routes/routes.js'
    import { useTemplate } from '@/views/screens/communicator/useTemplate'
    import useDefinition from "./useDefinition"
    import Title from "./components/Title.vue"

    const { setInitialData } = useDefinition()

    const props = defineProps({
        activeTemplate: Object,
        config_visibility: Boolean,
        currentMode: {
            default: 'normal'
        }
    })
    const { updateTemplate } = useTemplate()
    const projectId = ref(routes.currentRoute.params?.id)
    const definitionSets = ref([])
    const definitionSet  = ref()
    const activeDefinitionId = ref()

    const getDefinition = (e, id=null) => {
        const definitionId = (id) ? id : e.target.value
        if(!definitionId) return;
        activeDefinitionId.value = definitionId

        store.dispatch("definition/getDefinitionSet", { definition_id: definitionId })
        .then(res=>{
            // set selected definition as active
            if(props.activeTemplate.data_layer.length){
                props.activeTemplate.data_layer[0]['activeDefinitionIdForToggleSet'] = definitionId
            }else{
                props.activeTemplate.data_layer[0] = {'activeDefinitionIdForToggleSet': definitionId}
            }
            updateTemplate(props.activeTemplate)
            definitionSet.value = res.data.data
        })
    }

    onMounted(() => {
        store.dispatch("definition/getDefinitionSets", { project_id: projectId.value })
        .then(res=>{
            definitionSets.value = res.data.data
        })

        // load active definition set
        if(props.activeTemplate.data_layer.length){
            getDefinition(null, props.activeTemplate.data_layer[0]['activeDefinitionIdForToggleSet'])
        }
    })

    watch(
        () => props.activeTemplate,
        (newVal) => {
            // delete newVal.image
            // updateTemplate(newVal)
            setInitialData(props.activeTemplate)
        },
        { deep: true, immediate: true },
    )
</script>

<style scoped>
    .layout-scale-down{
        transform: scale(0.654);
        transform-origin: 0 0;
    }
    .wrapper{
        padding: 48px 157px;
        width: 1920px;
        height: 1080px;
    }
    .top-bar>div{
        width: 550px;
        flex-shrink: 0;
    }

    .set-name{
        font-size: 62px;
    }
    .definitions{
        display: flex;
        gap: 98px;
    }
    .definitions>div{
        width: 480px;
        flex-shrink: 0;
        height: 745px;
        overflow-y: auto;
    }
    .item{
        display: flex;
        align-items: center;
        height: 102px;
    }
    select option{
        background-color: var(--bg-color);
        color: var(--color);
    }
</style>
