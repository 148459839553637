import LayoutFiveImg from '@/components/dashboard/components/image/LayoutFiveImg.vue'

let layout = {
    name: 'layout5',
    image: LayoutFiveImg,
    rows: [
        {id: 'row_10', class: 'grid gap-5 grid-cols-2' },
        { id: 'row_11', class: 'grid gap-5 grid-cols-3 i-grid-rows-2' },
        { id: 'row_12', class: 'grid gap-5' },
    ],
    cards: [
        {
            rows_reference: 'row_10',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        },
        {
            rows_reference: 'row_10',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        },

        {
            rows_reference: 'row_11',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: 'i-row-span-2 col-span-2',
                height: 546,
                componentName: 'LargeChart'
            }
        },
        {
            rows_reference: 'row_11',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_11',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },

        {
            rows_reference: 'row_12',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        }
    ],
    isSelected: false
}


export default layout