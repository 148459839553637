import { ref } from 'vue'

import layoutOne from './layoutOne'
import layoutTwo from './layoutTwo'
import layoutThree from './layoutThree'
import layoutFour from './layoutFour'
import layoutFive from './layoutFive'
import layoutSix from './layoutSix'
import layoutSeven from './layoutSeven'
import layoutEight from './layoutEight'
import layoutNine from './layoutNine'
import medium from './default/medium'

const layoutDefault = {
    type: 'Default',
    layout: [
        medium
    ]
}

const layoutSet = ref([
    layoutOne,
    layoutTwo,
    layoutThree,
    layoutFour,
    layoutFive,
    layoutSix,
    layoutSeven,
    layoutEight,
    layoutNine,
    layoutDefault
])
export { layoutSet }