<template>
    <div class="space-y-1 mt-1">
        <div v-for="item in filteredCollections" :key="item.id">
            <div :class="getHeightClass(getExpansionStatesByLevel(item.id))" class="flex gap-x-1">
                <ParameterText
                    class="w-full"
                    :showNoteComment="column.showNoteComment"
                    parameterBg="#fff"
                    :parameterText="getParameterValue(item, column)"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import useBusiness from '@/components/roadmap/canvas/widgets/Business/useBusiness'
import { getParameterValue} from '@/store/modules/roadmap/services'
import Description from './Description';
import ParameterText from './ParameterText';
import useVerticalExpansion from '@/components/communicator/v2/templates/coordinate/useVerticalExpansion'

const props = defineProps({
    column: Object,
    widget: Object,
    module: Object,
    layout: Object,
    filteredCollections: Array,
    flatData: Array,
    isolatedModules: Array,
    horizontalExpansionState: String
})

const { getNote } =useBusiness()
const {getHeightClass, getExpansionStatesByLevel} = useVerticalExpansion()
</script>