import { unref, isRef, watchEffect } from 'vue'
import { get } from 'lodash'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import useData from './useData'

export default function useImplementation(activeTemplate) {
    const template = unref(activeTemplate)
    const { setInitialData } = useData()
    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getTemplateBorderColor } = useTemplate();

    const updateActiveTemplate = () => updateTemplate(template)

    const getColor = (key) => get(template, `customized_data.colorConfig[${key}]`, '#fff')

    if (isRef(activeTemplate)) {
        watchEffect(async () => {
            setInitialData(template, updateTemplate)
        })
    }

    return {
        updateTemplate,
        prepareConfiguration,
        prepareRequest,
        getPersistance,
        getDataLayerItem,
        getTemplateBorderColor,
        updateActiveTemplate,
        getColor
    }
}