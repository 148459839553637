<template>
    <div class="flex border-4 borderColor rounded layoutOneByFiveWrapper">
        
        <template v-for="(cat, index) in gridLayout.layout_grid_category">
            <div v-if="selectedCategories.includes(cat.category)" :key="cat.id" > 
                <LayoutComponent 
                    class="layoutWrapper relative i-p-15 h-full border borderColor flex-1"
                    :style="{'--backgroundImage': `url(${backgroundImages[index]?.bg})`, '--opacityForImg': backgroundImages[index]?.opacity}"
                    :category="cat" 
                />
            </div>
        </template>
    </div>
</template>

<script setup>
    import LayoutComponent from './components/LayoutComponent.vue';
    import imageOne from '@/assets/images/visualize/1x5-1.webp'
    import imageTwo from '@/assets/images/visualize/1x5-2.webp'
    import imageThree from '@/assets/images/visualize/1x5-3.webp'
    
    const props = defineProps(['gridLayout'])
    const selectedCategories = ['key-partner', 'key-activity', 'value-proportion', 'customer-relationship', 'customer-segment'];

    const backgroundImages = [
        {
            bg: imageOne,
            opacity: 1/100*5
        },
        {}, // this empty object to match with index of for loop
        {}, // this empty object to match with index of for loop
        {
            bg: imageTwo,
            opacity: 1/100*10
        },
        {}, // this empty object to match with index of for loop
        {}, // this empty object to match with index of for loop
        {
            bg: imageThree,
            opacity: 1/100*10
        },
    ]
</script>

<style scoped>
    .layoutOneByFiveWrapper{
        height: 723px;
        margin-left: 67px;
        width: 1600px;
    }
    .i-left-panel+div>.i-right-panel .layoutOneByFiveWrapper{
        min-width: unset;
    }
    .layoutOneByFiveWrapper>div{
        flex: 1;
    }
    .list{
        list-style: disc;
        display: grid;
        gap: 15px;
        margin-left: 20px;
        font-size: 16px;
        font-weight: 300px;
        color: #1C1C1D;
    }
    .layoutWrapper::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: var(--backgroundImage);
        opacity: var(--opacityForImg);
        pointer-events: none;
    }
    .borderColor{
        border-color: var(--borderColor);
    }
</style>