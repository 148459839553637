<template>
    <div class="i-mt-30 font-poppins communicator-conf overflow-y-auto">
        <input type="text" @keyup="updateTemplate" v-model="template.title" class="i-border-1 i-h-30 border-secondary-five px-2 text-primary-one w-full rounded-sm fs-14 fw-500" />
        <input type="text" :value="getTemplateName(template)" disabled class="i-border-1 i-h-30 border-secondary-five bg-primary-three px-2 text-primary-one w-full rounded-sm fs-14 fw-500 mt-5" />
  
        <ContentConfiguration
            :template="template"
            :modules="modules"
            :setActiveModule="setActiveModule"
            @updateTemplate="updateTemplate"
            :index="itemIndex"
        />

        <div v-if="module" class="i-mt-25" >
          <template>
            <div 
              v-for="(parameter, index) in getParameters"
              :key="index"
            >
              <ParameterSet
                :template="template"
                :parameter="parameter"
                :parameterType="parameterType"
                :index="index"
                :module="module"
                showType
                @newParameter="newParameter"
                @deleteParameter="deleteParameter"
                @updateTemplate="updateTemplate"
              />
                <StyleLogic
                  :template="template"
                  :styles="parameter.definition_style"
                  @updateTemplate="updateTemplate"
              />
            </div>
          </template>
        </div>
    </div>
  </template>
  
  <script>
  import ContentConfiguration from "./OkrConfiguration.vue";
  import ParameterSet from "../../components/ParameterSet";
  import StyleLogic from "../../components/StyleLogic";
  import {get} from "lodash";
  export default {
    name: "OkrDataSource",
    components: {
        StyleLogic,
        ParameterSet,
        ContentConfiguration
    },
    props: {
        template: Object,
        modules: Array,
        itemIndex: Number
    },
    data() {
        return {
            module: null,
            parameterType: ['Dynamic', 'Status', 'Percentage', 'Value']
        }
    },
    computed: {
      getParameters() {
        let parameters = get(this.template, `customized_data.contents[${this.itemIndex}].parameters`)
        if(parameters.length > 0) return parameters
        // if(this.template && this.template.parameters && this.template.parameters.length > 0) {
        //   return this.template.parameters;
        // }
        return [
          { property_id: null, id: null, definition_id: null }
        ];
      }
    },
    methods: {
      newParameter() {
        this.template.customized_data.contents[this.itemIndex].parameters.push(
          { 
            property_id: null,
            id: null,
            definition_id: null,
            type: null,
            definition_style:[
                { range: '0-2', bg: '#AD2B81', definition: 'Very Low' },
                { range: '2-4', bg: '#ABA62B', definition: 'Low' },
                { range: '4-6', bg: '#8D8D8D', definition: 'Medium' },
                { range: '6-8', bg: '#2BAD7E', definition: 'High' },
                { range: '8-10', bg: '#2B80AD', definition: 'Extreme' }
            ]
        });
      },
      deleteParameter(index, template) {
        this.template.customized_data.contents[this.itemIndex].parameters.splice(index, 1);
        this.updateTemplate(template);
        if(this.template.customized_data.contents[this.itemIndex].parameters <= 0) {
          this.template.customized_data.contents[this.itemIndex].parameters = [{ property_id: '', id: '', definition_id: '' }];
        }
      },
      getTemplateName(layoutTemplate) {
        let template = layoutTemplate.template;
        return template.title;
      },
      updateTemplate() {
       this.$store.dispatch("communicator/modifyTemplate", this.template);
      },
      setActiveModule(module) {
        this.module = module;
      }
    }
  }
  </script>
  
  <style scoped>
  .param-dropdown:focus {
    border: 1px solid #C9C9C9;
  }
  .communicator-conf {
    height: 900px;
  }
  </style>