<template>
    <div class="relative font-poppins mt-12">   
      <div
            class="relative rounded-sm dashed-border no-border"
            style="margin-top: -43px"
        >
            <GraphLayout :years="years" topValue="43" gridHeight="46px" yearTextColor="text-white">
                <div class="p-2" style="margin-top: 31px">
                    <div
                        class="flex flex-col progress"
                        v-for="(progress) in collections" :key="progress.id"
                        :style="{height: progress.expandedHeight ? `${progress.expandedHeight}px` : `30px`}"
                    >
                        <ProgressBar
                            style="margin-top: 10px"
                            :progress-width="getProgressAmount(progress)"
                            :width="getProgressWidth(progress)"
                            :margin-left="getProgressMarginLeft(progress)"
                            :background="getProgressBackground(progress)"
                        />
                    </div>
                </div>
            </GraphLayout>
        </div>
    </div>
  </template>
  
<script>
  import { mapGetters, mapActions } from "vuex";
  import ProgressBar from './ProgressBar.vue';
  import GraphLayout from './GraphLayout.vue';
  import { getBackground, progress } from '@/store/modules/roadmap/services';
  import { startDateOfYear, endDateOfYear, differenceInCalendarYears, isDate} from '@/plugins/lodash.js';
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  export default {
    name: "Timeline",
    components: {
      ProgressBar,
      GraphLayout
  },
    props: ['module', 'collections', 'widget'],
    async created() {
      this.systemData = this.module;
      this.systemData.child = this.systemData.collections;
    },
    mounted() {
        this.year = differenceInCalendarYears(this.startDate, this.endDate);
    },
    data() {
      return {
        currentPeopleIndex: 0,
        isExpanded: true,
        parentHeight: 0,
        settings: false,
        systemData: [],
        peoples: [],
        year: 1,
        quatre: ['Q1', 'Q2', 'Q3', 'Q4'],
        pixelWidth: 1.3
      };
    },
    computed: {
      ...mapGetters({
        activeRoadmapData: "roadmap/programmatic",
        modules: "programmatic/projectModules",
        colorCodes: "global/colorCodes",
        parameterId: 'roadmap/parameterId'
      }),
      years() {
          let data = [];
          for(let x = 0; x < this.year; x++) {
              months.forEach(month => data.push(month))
          }
          return data;
      },
      quatres() {
          let data = [];
          for(let x = 0; x < this.year; x++) {
            this.quatre.forEach(item => data.push(item))
          }
          return data;
      },
      firstYear() {
        let d = new Date(this.startDate);
        if(!isDate(d)) return;
        return d.getFullYear()
      },
      startDate() {
        return startDateOfYear(this.collections.map(i => i.start_date))
      },
      endDate() {
        return endDateOfYear(this.collections.map(i => i.end_date))
      },
      getModuleSettings() {
        let module = this.modules.find(item => parseInt(item.id) === parseInt(this.module.id));
        if (module) {
          return JSON.parse(module.collection_settings);
        }
        return null;
      },
    },
    methods: {
      ...mapActions({
          getPeoples: "people/getPeoples"
      }),
      getProgressWidth(item) {
          if(item.end_date && item.start_date) {
              let days = this.$lodash.dateDefer(item.end_date, item.start_date);
              let startFrom = this.$lodash.dateDefer(item.start_date, this.startDate);
              return Math.round(days * this.pixelWidth);
          }
      },
      getProgressMarginLeft(item) {
          if(item.end_date && item.start_date) {
              let startFrom = this.$lodash.dateDefer(item.start_date, this.startDate);
             return Math.ceil(startFrom * this.pixelWidth);
          }
      },
      getProgressBackground(item) {
          let parameterId = this.widget.parameters[0].id;
          if(!parameterId) {
              return '';
          }
          let parameter = item.properties?.find(i => i.property_collection_id == parameterId);
          if(!parameter) {
              return '';
          }
          return parameter ? getBackground(this.widget, parameter.sample_value): ''
      },
      getProgressAmount(item) {
          let parameterId = this.widget.parameters[0].id;
          if(!parameterId) {
              return '0';
          }
          let parameter = item.properties?.find(i => i.property_collection_id == parameterId);
          if(!parameter) {
              return '0';
          }
          return progress(parameter)
      }
    }
  };
  </script>
  
  <style>
  .grid-child {
      min-width: 40px;
      text-align: center;
  }
  .progress {
      position: relative;
      min-height: 20px;
  }
  .progress::after {
      content: "";
      position: absolute;
      bottom: -5px;
      width: 100%;
      height: 1px;
      background-color: #fff;
  }
  :root {
      --people-element-with: 63px;
  }
  .people-element-with {
    min-width: var(--people-element-with);
  }
  .quatre-width {
    min-width: 117px;
  }
  .btn-area {
    width: 100px;
    height: 20px;
    border-radius: 4px;
  }
  .logic-parameter-outline {
    float: left;
    width: 80px;
    margin-left: 4px;
    height: 20px;
    border-radius: 2px;
  }
  .logic-box-container {
    float: left;
    width: 80px;
    height: 20px;
  }
  .box-container-operator {
    width: 96px;
  }
  .default-style {
    /*min-width: 250px;*/
    width: 440px;
  }
  .group-shadow-bg {
    height: 50px;
  }
  .group-setting {
    height: 40px;
  }
  .widget-setting-icon {
    width: 13px;
    height: 13px;
  }
  .value-text-pos {
    top: -18px;
    left: 4px;
  }
  .dashed-border {
    border: 1px dashed #C9C9C9;
  }
  .no-border {
    border: none;
  }
  .fs-6 {
    font-size: 6px;
  }
  .none-margin {
    margin: 0;
  }
  </style>
  