<template>
    <div class="mb-1">
        <div @click="toggleTeam" class="px-10 cursor-pointer flex justify-between items-center py-4">
            <button class="text-primary-one fw-700 fs-16">
                {{ team.title }}
            </button>
            <svg @click.stop="createDashboard" class="ml-auto mr-4" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#8D8D8D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
                <path d="M6.875 10H13.125" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 6.875V13.125" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.75 7.5L10.5 13.75L4.25 7.5" stroke="#8D8D8D" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
        </div>
        <div v-if="showImplementationTeam && dashBoardData">
            <template>
                <div 
                    v-for="(dashboard, index) in team.dashboards" :key="dashboard.id"
                    class="px-10 bg-white font-poppins fs-16 fw-500 text-color-616161 border-b border-secondary-four uppercase py-5"
                    :class="dashboard.id == dashBoardData.id ? 'text-primary-four bg-white' : 'bg-primary-two'"
                >
                    <div class="flex justify-between items-center cursor-pointer">
                        <p @click="selectDashboard(dashboard)">
                            {{ dashboard.title }}
                        </p>
                        <button 
                            @click="handleDashboardDelete(dashboard.id, team.dashboards, index, (dashboard.id == dashBoardData.id))" 
                            title="Remove Item" 
                            class="w-5 h-5 text-white fw-600 fs-13 rounded-full transform rotate-45"
                        >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-auto mr-4">
                                <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#8D8D8D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"></path>
                                <path d="M6.875 10H13.125" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path d="M10 6.875V13.125" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </button>
                    </div>
                </div>

                <div v-if="team.dashboards.length" class="block mt-4 px-10">
                    <div class="flex justify-between">
                        <div class="flex items-center gap-2">
                            <template v-for="(invitation, index) in invitations.filter(item => item.email)" >
                                <img 
                                    v-if="index <= 3"
                                    :key="index" 
                                    class="w-7 h-7 shadow border cursor-pointer rounded-full block object-cover object-center" 
                                    :src="getAvatar(invitation)" 
                                    :alt="getFullName(invitation)"
                                    :title="getFullName(invitation)"
                                >
                            </template>
                            
                            <span v-if="invitations.length > 3" class="w-7 h-7 shadow border cursor-pointer rounded-full object-cover object-center bg-gray-200 grid items-center text-center text-secondary-one fs-12 fw-500">
                                +{{ invitations.length - 4 }}
                            </span>
                        </div>
                        <button @click="showSharePopup = true" class="px-3 py-1 rounded bg-primary-four text-white">Share</button>
                    </div>
                </div>
            </template>
        </div>
        <Modal v-model="showSharePopup">
            <Share @close="(showSharePopup = false)" :teamId="team.id" :callback="rerenderShare" />
        </Modal>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { useTeam } from '@/views/screens/dashboard/useTeam.js'
    import { useTeamDashboard } from '@/views/screens/dashboard/useTeamDashboard.js'
    import router from '@/routes/routes.js';
    import Modal from '@/elements/atom/Modal'
    import Share from '@/components/dashboard/Share.vue'
    import { useShare } from '@/views/screens/dashboard/useShare.js'
    
    const route = router.currentRoute;
    const {workspaceId, teamId } = route.params;
    const { loadInvitations, invitations, getAvatar, getFullName } = useShare()
    const showImplementationTeam = ref(false);
    const showSharePopup = ref(false);
    const props = defineProps({
        team: Object
    });

    const emit = defineEmits(['showDashboardCreateModal']);

    const { setCurrentTeam, currentTeam } = useTeam();
    const { setDashboard, dashBoardData, deleteDashboard } = useTeamDashboard();

    const toggleTeam = () => {
        showImplementationTeam.value = !showImplementationTeam.value
    }
    const selectDashboard = (dashboard) => {
        setCurrentTeam(props.team);
        setDashboard(dashboard.id, props.team.id);
    }
    const createDashboard = () => {
        emit('showDashboardCreateModal');
        setCurrentTeam(props.team);
    }
    const handleDashboardDelete = async (dashboardId, dashboards, index, isCurrentDashboard) => 
    {
        if(confirm(`Are you sure to delete this dashboard? ${dashboardId}`))
        {
            deleteDashboard(dashboardId)
            dashboards.splice(index, 1)
            if(isCurrentDashboard){
                dashBoardData.value = {
                    id: null
                }
                router.push({name: 'teamDashboard', params: {workspaceId, teamId}})
            }
        }
    }

    const rerenderShare = () => {
        loadInvitations(props.team.id)
    }
    onMounted(() => {
        showImplementationTeam.value = props.team.id == currentTeam.value.id
        loadInvitations(props.team.id)
    })
</script>