<template>
  <div class="relative">
    <Accordion 
        :title="`Property Set ${index + 1}`"
        active
        bg="transparent"
        classes="shadow-none"
        titleClasses="text-primary-one fs-14"
        iconClasses="w-6 h-6"
        buttonClasses="i-right-n-2 i-top-0"
        accordionClasses=""
        align="left"
    >
        <div>
            <select
                class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                placeholder="Property"
                v-model="parameter.property_id"
            >
                <option value="null" disabled selected>Select Property</option>
                <option v-for="(property, index) in getProperties(module)" :key="index" :value="property.id">{{ property.title }}</option>
            </select>

            <select
                class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                placeholder="Parameter"
                v-model="parameter.id"
                @change="$emit('updateTemplate', template)"
            >
                <option value="null" disabled selected>Select Parameter</option>
                <option
                    v-for="(moduleParameter, mPIndex) in getParameters"
                    :key="mPIndex"
                    :value="moduleParameter.id">
                        {{ moduleParameter.title }}
                </option>
            </select>

            <select
                class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                placeholder="Definition"
                v-model="parameter.definition_id"
                @change="$emit('updateTemplate', template)"
            >
                <option value="null" disabled selected>Select Definition</option>
                <option
                    v-for="(definition, dIndex) in definitionSets"
                    :key="dIndex"
                    :value="definition.id"
                >
                    {{ definition.title }}
                </option>
            </select>
            <select
                v-if="showType"
                class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                placeholder="Type"
                v-model="parameter.type"
                @change="$emit('updateTemplate', template)"
            >
                <option value="null" disabled selected>Select Type</option>
                <option
                    v-for="(type, typeIndex) in parameterType"
                    :key="typeIndex"
                    :value="type"
                >
                    {{ type }}
                </option>
            </select>
        </div>

        <div class="absolute icons-pos">
            <div class="flex items-center">
                <img src="/images/icons/brainstormV2/plus_circle_outline.svg" alt="icon"
                    class="settings-icon cursor-pointer"
                    @click="$emit('newParameter')"
                />
                <img src="/images/icons/brainstormV2/close.svg" alt="icon"
                    class="settings-icon ml-2 cursor-pointer"
                    @click="$emit('deleteParameter',index, template)"
                />
            </div>
        </div>
    </Accordion>
  </div>
</template>

<script setup>
    import Accordion from "@/elements/atom/Accordion.vue";
    import { computed, toRefs } from 'vue';
    import store from '@/store'
    const props = defineProps({
        template: Object,
        parameter: Object,
        index: Number,
        module: Object,
        showType: Boolean,
        parameterType: {
            type: Array,
            default:() => ([])
        }
    });

    const { module, parameter } = toRefs(props);

    const getProperties = (module) => {
        if(module && module.system_properties && module.system_properties.length > 0) {
            return module.system_properties;
        }
        return [];
    };

    const getParameters = computed(() => {
        if(props.module && props.parameter.property_id) {
            let data = props.module.system_properties.find(item => parseInt(item.id) === parseInt(props.parameter.property_id));
            return data?.property_collections || [];
        }
        return [];
    });

    const definitionSets = computed(() => store.getters['definition/definitionSets'])

</script>

<style scoped>
.icons-pos {
  top: 5px;
  right: 30px;
}
.settings-icon {
  width: 14px;
  height: 14px;
}
</style>
