<template>
    <div class="flex gap-x-8 justify-between mt-6 pr-8 overflow-y-auto" :style="{paddingLeft: width, height: '369px'}">
        <div style="width: 360px" class="fs-14 fw-400">
            <textarea
                class="text-secondary-six bg-transparent w-full h-32"
                v-model="parent.description"
                placeholder="Add description"
               @blur="handleUpdate(parent)"
            >
            </textarea>
           
            <div class="flex items-center mt-7 gap-x-8">
                <div class="flex fs-14 items-center">
                    <p>Active Status</p>
                    <SwitchComponent
                        :value="parent.status == 1 ? true : false"
                        @onClick="handleStatus"
                        style="transform: scale(.6)" 
                        class="transform  opacity-50"
                    />
                </div>
                <DateWithIcon :date="parent.end_date"/>
            </div>
            <AvatarGroup :avatars="getResponsibleImages()"/>
            <div class="flex mt-7 items-center gap-x-3">
                <component :is="badgeIcon[parent.progress_achieve_icon]" class="flex-shrink-0" />
                <p class="fs-14 fw-500">{{ parent.progress_goal_headline }}</p>
            </div>
        </div>
        <div v-show="activeComponent== 'BasicInfoProgress'" class="-mt-4">
            <Progress 
                :series="seriesProgress"
                :actualProgress="actualProgress"
                :target="target"
                :goalId="goalId"
                :y-max="getMaxYvalue(seriesProgress)"
            />
        </div>
        <div v-show="activeComponent== 'Confidence'" class="-mt-4">
            <Progress 
                :series="seriesConfidence"
                :actualProgress="actualProgress"
                :target="target"
                :goalId="goalId"
                :yFormatter="yFormatter"
            />
        </div>
        <div v-show="activeComponent== 'Outcomes'" class="-mt-4">
            <Progress 
                :series="seriesOutcome"
                :actualProgress="actualProgress"
                :target="target"
                :goalId="goalId"
                :yFormatter="yFormatter"
            />
        </div>
        <Comment :item="parent" />
    </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import SwitchComponent from '@/elements/atom/SwitchComponent.vue'
import Comment from '../comments/Comment.vue'
import Progress from './Progress.vue'
import DateWithIcon from '@/elements/atom/DateWithIcon.vue'
import AvatarGroup from '@/elements/atom/AvatarGroup.vue'
// import Confidence from './Confidence.vue'
import { format } from 'date-fns'
import TrophyIcon from '@/components/dashboard/goal/icons/badge/Trophy.vue'
import CrownIcon from '@/components/dashboard/goal/icons/badge/Crown.vue'
import MedalIcon from '@/components/dashboard/goal/icons/badge/Medal.vue'
import ThumbsUpIcon from '@/components/dashboard/goal/icons/badge/ThumbsUp.vue'
import axios from 'axios'
import { last, maxBy } from 'lodash'
import moment from 'moment'

const props = defineProps({
    activeComponent: String,
    handleCreate: Function,
    handleDelete: Function,
    handleUpdate: Function,
    parent: Object,
    goalId: Number,
    width: {
        type: String,
        default: '98px'
    }
})
const seriesProgress = ref([])
const seriesConfidence = ref([])
const seriesOutcome = ref([])
const target = ref(0)
const actualProgress = ref('')
const badgeIcon = {
    Trophy: TrophyIcon,
    Crown: CrownIcon,
    Medal: MedalIcon,
    ThumbsUp: ThumbsUpIcon
}

const handleStatus = (val) => {
    props.parent.status = val == false ? 0 : 1
    props.handleUpdate(props.parent)
}

const yFormatter = (val) => {
    if(!val) return 0
    if(Math.abs(val) > 1000) return val/1000 + 'K'
    return val
}

//have to refactor this
const fetchHistory = async () => {
    try{
        const {data} = await axios.get(`/workspaces/goals/${props.parent.id}/progress-history?i_am=${props.parent.i_am}&goal_id=${props.goalId}`)
        makeChartData(data)
    }catch(e) {
        console.log(e)
    }
}

//have to refactor this
const fetchConfidence = async () => {
    try{
        const {data} = await axios.get(`/workspaces/goals/${props.parent.id}/progress-history?i_am=${props.parent.i_am}&goal_id=${props.goalId}&activity_type=3`)
        makeConfidenceData(data)
    }catch(e) {
        console.log(e)
    }
}

//have to refactor this
const makeChartData = data => {
    let own = data.data[0]
    let children
    if(!own) return {}
    let dueDate = own.end_date
    actualProgress.value = own.actual_progress
    target.value = own.weight_target > 100 ? 100 : own.weight_target
    children = own.children
    let _seriesProgress = []
    let _seriesOfProgressLine = []
    let _seriesOutcome = []
    if(children.length){
        children.forEach(child => {
            let progressLineData = {
                type: 'line',
                name: 'Target',
                data: convertHistoryToData(child.history, 'day_wise_target')
            }
            
            let itemProgress = {data: []}
            let itemOutcome  = {data: []}
            itemProgress.type = 'area'
            itemProgress.name = child.title
            itemOutcome.name  = child.title
            itemProgress.data = convertHistoryToData(child.history, 'weight_progress_towards_target')
            itemOutcome.data  = convertHistoryToData(child.history, 'outcome_total')
            getDataForMissingDate(itemProgress, dueDate)
            getDataForMissingDate(progressLineData, dueDate)
            getDataForProgressLineData(progressLineData, child)
            getDataForMissingDate(itemOutcome, dueDate)
            _seriesProgress.push(itemProgress)
            _seriesProgress.push(progressLineData)
            _seriesOutcome.push(itemOutcome)
        })
    }
    seriesProgress.value = _seriesProgress
    seriesOutcome.value = _seriesOutcome
}

const getDataForProgressLineData = (progressLineData, item) => {
    const totalDays = moment(item.end_date).diff(moment(item.start_date), 'days')
    const startDate = moment(item.start_date)
    progressLineData.data.map(progressData => {
        const currentDate = moment(progressData.x)
        const dayPassed = currentDate.diff(startDate, 'days')
        if(totalDays > 0) {
            progressData.y = (item.weight_target * dayPassed) / totalDays
        }
    })
}

//have to refactor this
const makeConfidenceData = data => {
    let own = data.data[0]
    let children
    if(!own) return {}
    let dueDate = own.end_date
    
    children = own.children
    let _seriesConfidence = []
    if(children.length){
        children.forEach(child => {
            let itemConfidence = {data: []}
            itemConfidence.name = child.title
            itemConfidence.data = convertHistoryToData(child.history, 'weight_progress_towards_target')
           getDataForMissingDate(itemConfidence, dueDate)
            _seriesConfidence.push(itemConfidence)
        })
    }
    seriesConfidence.value = _seriesConfidence
}
const convertHistoryToData = (histories, key) => histories.map(history => {
    return {
        x: history.inserted_at, y: history[key]
    }
})

const getDataForMissingDate = (item, dueDate) => {
    // let data = []
    const startDate = new Date(new Date(last(item.data).x));
    const endDate = new Date(dueDate);
    if(startDate < endDate) {
        
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
            item.data.push({
                x: format(date, 'yyyy-MM-dd'),
                y: 0
            })
        }
    }
    // return data
}
const getResponsibleImages = () => props.parent?.responsibles.map(item => ({
    name: `${item.first_name} ${item.last_name}`,
    file_path: item.image?.file_path
}))
watchEffect(() => {
    if(props.activeComponent == 'BasicInfoProgress') fetchHistory()
    if(props.activeComponent == 'Confidence') fetchConfidence()
})

const getMaxYvalue = (series) => {
    let max
    const maxes = series.map(series => {
        return maxBy(series.data, i=>i.y)
    })
    if(maxes) {
        let _max = maxBy(maxes, i=>i.y)
        max = _max ? _max.y : 0
    }
    return max < 100 ? 100 : max
}
</script>