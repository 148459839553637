import LayoutTwoImg from '@/components/dashboard/components/image/LayoutTwoImg.vue'

let layout = {
    name: 'layout2',
    image: LayoutTwoImg,
    rows: [
        {id: 'row_3', class: 'grid gap-5 grid-cols-3 grid-rows-2' },
        { id: 'row_4', class: 'grid gap-5 grid-cols-3' }
    ],
    cards: [
        {
            rows_reference: 'row_3',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_3',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_3',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: 'col-end-4 col-span-2 i-row-span-2 row-end-3',
                height: 546,
                componentName: 'LargeChart'
            }
        },

        {
            rows_reference: 'row_4',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_4',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_4',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        }
    ],
    isSelected: false
}

export default layout