import { useTemplate } from '@/views/screens/communicator/useTemplate'
import {set} from 'vue'

export default function useCoordinate() {
    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getIconVisibility } = useTemplate();

    const setInitialData = (template) => {
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
        }
        if (!("heading" in template.customized_data)) {
            template.customized_data.heading = {
                id: (Math.random() + 1).toString(36).substring(2),
                value: 'Headline'
            };
            updateTemplate(template)
        }
        if (!("configuration" in template.customized_data)) {
            set(template.customized_data, 'configuration', {
                moduleId: null,
                roadmapId: null,
                layoutId: null,
                widgetId: null
            })
            updateTemplate(template)
        }
    }

    return {
        setInitialData,
        updateTemplate,
        prepareConfiguration,
        prepareRequest,
        getPersistance,
        getDataLayerItem,
        getIconVisibility
    }
}