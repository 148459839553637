<template>
  <div class="flex justify-between items-center px-2 h-full">
        <div class="fs-14 font-medium text-white truncate w-5/6">
            {{ fullName(people) }}
        </div>
        <div class="flex-shrink-0 border-2 border-white rounded-full shadow-two overflow-hidden">
            <img
                class="w-4 h-4"
                :src="getUserAvatar(people)"
                :title="fullName(people)"
            >
        </div>
    </div>
</template>

<script setup>
    import usePeople from '@/store/modules/people/usePeople.js';
    defineProps({
        people: Object
    });
    let { fullName, getUserAvatar } = usePeople();
</script>