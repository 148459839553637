import { get, has, isArray, isEmpty } from "lodash"
import { ref, computed } from 'vue';
import Collection from '@/store/services/traits/collection';
import { getFlattenCollections } from '@/store/modules/global/services';
import { getParameterValue, getParameterBg as getParameterBackground } from '@/store/services/traits/parameter.js';

const sharedParameter = ref({});
const activeParameterId = computed(() => get(sharedParameter.value, 'parameter.id', 0));

export default function useSystemContent() {
    const collection = new Collection;
    const isMinimized = ref(false);
    const minimization = ref({
        l0: false,
        l1: false,
        l2: false,
        l3: false,
        l4: false
    });

    const getWidth              = (item, className = null) =>  hasChild(item) ? className ? className : 'w-345' : 'w-full';
    const getBackground         = (level, module) =>  {
        if(isEmpty(sharedParameter.value) || hasChild(level)) {
            return collection.getBackground(level, module);
        }
        let { widget, parameter } = sharedParameter.value;
        return getParameterBackground(getParameterValue(level, parameter), widget.definition_style)
    };
    const getModuleBackground   = module => collection.getModuleBg(module);
    const hasChild = (item) => {
        if(!has(item, 'child') && !isArray(item.child)) {
            return false;
        }
        return item.child.length > 0;
    }
    const hasOneChild = (item) => {
        if(!hasChild(item)) {
            return false;
        }
        return item.child.length > 1;
    }
    
    const setSharedParameter = item => {
        if(isEmpty(sharedParameter.value) || get(sharedParameter.value, 'parameter.id') !== get(item, 'parameter.id')) {
            sharedParameter.value = item;
            return;
        }
        sharedParameter.value = {};
    }

    const getCellHeight = (collection, key = 'child') => {
        let collections = getFlattenCollections(collection[key]);
        collections = collections.filter(item => item.child.length == 0);
        let length = collections.length > 0 ? collections.length : 1;
        return `${length * 30}px`
    }

    const getAtomicsCount = (collection, key = 'child') => {
        let collections = getFlattenCollections(collection[key]);
        collections = collections.filter(item => item.child.length == 0);
        return collections.length;
    }

    return {
        getWidth,
        hasChild,
        hasOneChild,
        isMinimized,
        getBackground,
        getCellHeight,
        minimization,
        getAtomicsCount,
        setSharedParameter,
        sharedParameter,
        activeParameterId,
        getModuleBackground
    }
}