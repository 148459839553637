<template>
    <div :class="isProcessing ? 'pointer-events-none' : 'pointer-events-auto'">
        <canvas ref="canvasElem"></canvas>
        <div v-if="isProcessing" class="absolute top-1/2 left-1/2 w-full h-full z-40 flex items-center justify-center transform -translate-x-1/2 -translate-y-1/2">
            <button class="flex justify-center gap-2">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary-one" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing...
            </button>
        </div>
    </div>
</template>

<script setup>
import useConceptCanvas from '@/components/roadmap/strategicRoadmap/roadmapConcept/useConceptCanvas.js'
import useRoadmapConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useRoadmapConcept.js'
import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'
import { onMounted, watch, ref } from 'vue'
import { get } from 'lodash'

const props = defineProps({
    from: {
        type: String,
        default: "main"
    }
})
const {
    strategy,
    systemData,
    callback
} = useHighLevelConcept(true)

const { canvasSize, createCircle, createLine, canvas, initCanvas, clearCanvas, isProcessing } = useConceptCanvas()

const { layoutSize, printCircles } = useRoadmapConcept()

layoutSize.value = {
    width: 1272,
    height: 582
}

const renderCircle = () => {
    if(get(strategy.value, 'parameters[0].property_id') && get(strategy.value, 'secondary_parameters[0].property_id')){
        clearCanvas()
        printCircles(systemData.value.children, createCircle, createLine)
    }
}

const canvasElem = ref()
onMounted(() => {
    canvas.value = canvasElem.value
    canvasSize.value = layoutSize
    initCanvas()
    renderCircle()

    callback.value = () => {
        renderCircle()
    }
})

watch(strategy, () => {
    renderCircle()
}, {deep: true})

watch(systemData, () => {
    renderCircle()
}, {deep: true})
</script>

<style scoped>
.CanvasForConcept{
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 10;
}
</style>