import { get, pick } from 'lodash';
import { useCollection } from '@/composable/useCollection.js';
import { useTemplate } from '@/views/screens/communicator/useTemplate.js';
import { getParameterValue, getDefinitionStyle, getParameterDefinition, getParameterDefinitionColor, getSystemProperties, getProperties, getDefinitionDescription, getParameterStyleWithoutIndex } from '@/store/services/traits/parameter.js';
import Definition from '@/store/services/traits/definition.js'

export const useDynamicField = () => {
    const { getCollectionById } = useCollection();
    const { getLevelId, getRootType } = useTemplate();

    const getBackground = (collections, template, property, index, key = 'bg') => {
        let collection  = getCollectionById(collections, getLevelId(template));
        let value       = getParameterValue(collection, property);
        let definition  = getDefinitionStyle(value, index, template.definition_style, 10);
        if(definition) {
            return definition[key];
        }
    }

    const getBackgroundByCollection = (collection, template, property, index, key = 'bg') => {
        let value       = getParameterValue(collection, property);
        let definition  = getDefinitionStyle(value, index, template.definition_style, 10);
        if(definition) {
            return definition[key];
        }
    }

    const _getParameterDefinition = (collections, template, property) => {
        let collection  = getCollectionById(collections, getLevelId(template));
        let value       = getParameterValue(collection, property);
        return getParameterDefinition(value, template.definition_style);
    }

    const _getParameterDefinitionColor = (collection, template, property) => {
        let value       = getParameterValue(collection, property);
        return getParameterDefinitionColor(value, template.definition_style);
    }

    const _getDefinitionDescription = (collection, template, property) => {
        let value       = getParameterValue(collection, property);
        return getDefinitionDescription(value, template.definition_style, "definition");
    }

    const getCollectionChildren = (collections, template, module) => {
        if(getRootType(template) === 'module') {
            console.log(module)
            // return module.collections;
        }

        let collection  = getCollectionById(collections, getLevelId(template));
        return get(collection, 'child', []);
    }

    const _getProperties = (parameters, module) => {
        let systemProperties = getSystemProperties(parameters, module?.system_properties);
        return getProperties(parameters, systemProperties).map(item =>  {
            return pick(item, ['title', 'id', 'definition_id']);
        });
    }

    const getParameterBg = (collection, template, parameter) => {
        let style = getParameterStyleWithoutIndex(getParameterValue(collection, parameter), template.definition_style);
        return style ? style["bg"] : "#C9C9C9";
    }
    const getAverage = (collection, properties) => {
        let summation = 0;
        properties.forEach((property) => {
          let value = getParameterValue(collection, property);
          summation += value ? value : 0;
        });
        return summation > 0 ? summation / properties.length : 0;
    }
    const getCollectionAverageBg = (value, template) => {
        let style = getParameterStyleWithoutIndex(value, template.definition_style);
        return style ? style["bg"] : "#C9C9C9";
    }
    const getCollectionAverageDefinition = (value, template) => {
        let style = getParameterStyleWithoutIndex(value,  template.definition_style);
        return style ? style["definition"] : "#C9C9C9";
    }
  
    return {
        getBackground,
        _getParameterDefinition,
        _getParameterDefinitionColor,
        _getDefinitionDescription,
        _getProperties,

        getCollectionChildren,
        getParameterBg,
        getAverage,
        getCollectionAverageBg,
        getCollectionAverageDefinition,
        getBackgroundByCollection
    }
}