import store from '@/store';
import axios from 'axios';
import { get } from 'lodash'
import { set, ref, computed } from 'vue'
import { deleteWidget } from '@/store/modules/roadmap/services';
// import { getPropertyCollection } from '@/store/services/traits/parameter';
import { getParameterValue } from '@/store/modules/roadmap/services'
import { initialData } from './column.data';

export default function useBusiness() {

    const isExpanded = ref(true)
    const showSetting = ref(false)
   
    const setInitialData = (widget) => {
        if(widget && widget.columns == null) {
            set(widget, 'columns', [])
            widget.columns.push(JSON.parse(JSON.stringify(initialData)))
        }
    }

    const project = computed(() => store.getters['project/project'])

    const handleCollapse = () => {
        isExpanded.value = !isExpanded.value
    }

    const updateWidgetTitle = (widget) => {
        store.dispatch('roadmap/updateWidgetSettings', widget)
    }

    const onDeleteWidget = (widget, layout) => {
        store.dispatch("roadmap/deleteWidget", widget).then(response => {
          deleteWidget(widget.id, layout);
        });
    }

    const toggleWidgetSettings = (event) => {
        event.preventDefault();
        showSetting.value = !showSetting.value
    }

    const getNote = (column, collectionId, parameterId) => {
        if (column.notes && (column.notes.constructor == Array)) {
            column.notes = {}
        }
        const note = get(column, `notes[${collectionId}_${parameterId}]`)
        if(!note) {
            set( column.notes, `${collectionId}_${parameterId}`, {
                title: '',
                description: ''
            })
        }
        return get(column, `notes[${collectionId}_${parameterId}]`)
    }

    //item is last item in collection
    const getFieldParameterValue = (item, parameter) => {
        return  Number(getParameterValue(item, parameter))
    }

    const getPropertyCollection = (properties, parameter) => {
        const property = properties.find(property => property.id == parameter.property_id)
        if(!(property && property.property_collections)) return
        return property.property_collections.find(collection => collection.id == parameter.id)
    }

    const updateSampleValue = (event, item, column, params) => {
        let collectionParam = {...params}
        collectionParam.value = event.target.value
        let itemProperties = item.properties.find(property => property.property_collection_id == column.id)
        
        store.dispatch("programmatic/updatePropertyItem", collectionParam)
        .then(() => itemProperties.sample_value = event.target.value)
    }

    return {
        isExpanded,
        showSetting,
        setInitialData,
        project,
        updateWidgetTitle,
        onDeleteWidget,
        toggleWidgetSettings,
        handleCollapse,
        getNote,
        getFieldParameterValue,
        getPropertyCollection,
        updateSampleValue
    }
}