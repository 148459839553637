<template>
    <div>
        <div class="content-wrapper">
            <div class="graph-box relative">
                <TopDefinitionPanel  
                    :activeTemplate="activeTemplate"
                    :definitionItems="activeDefinitionOne?.range_with_definition" 
                    :definitions="getDefinitions(definitions, 0)" 
                />
                <div class="content relative">
                    <GraphLineSvg class="absolute top-0 left-0 right-0 w-full h-full bottom-0 svg-graph pointer-events-none" />
                    <FourZonesInput />
                    <HintText />
                    <CanvasChart :from="from" />
                </div>
                
                <!-- bottom panel start -->
                <div class="bottom-panel top-full w-full relative">
                    <div class="number fs-14 fw-400 -mt-1">
                        <span>0</span>
                        <span>2</span>
                        <span>4</span>
                        <span>6</span>
                        <span>8</span>
                        <span>10</span>
                    </div>
                    <input class="extraBoxHorizontal" :style="{background: activeTemplate.customized_data[0]?.colorOne}" v-model="activeTemplate.customized_data[0].texts.bottomSideText" @input="updateTemplate(activeTemplate)">
                </div>
                <!-- bottom panel end -->
                
                <LeftDefinitionPanel
                    :activeTemplate="activeTemplate"
                    :definitionItems="activeDefinitionTwo?.range_with_definition" 
                    :definitions="getDefinitions(definitions, 1)" 
                />
            </div>
        </div>

        <!-- definition changer start -->
        <div class="absolute ml-14 grid gap-8" style="bottom: -70px;">
            <h1 class="fs-14 fw-500 flex items-center gap-8">
                <div class="w-48">{{ activeDefinitionOne?.title }}</div>
                <div class="flex gap-2">
                    <button @click="changeDefinitionData(-1, 'definitionItemsOne')">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.997713 6.01963L6.34937 11.3713L7.60937 10.1131L3.51326 6.01963L7.60937 1.92708L6.35027 0.667969L0.997713 6.01963Z" fill="currentColor"/>
                        </svg>                            
                    </button>
                    <button @click="changeDefinitionData(1, 'definitionItemsOne')">
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.61166 6.01553L1.26 0.663863L-4.12859e-07 1.92208L4.09611 6.01553L-5.50374e-08 10.1081L1.25911 11.3672L6.61166 6.01553Z" fill="currentColor"/>
                        </svg>                            
                    </button>
                </div>
            </h1>
            <h1 class="fs-14 fw-500 flex items-center gap-8">
                <div class="w-48">{{ activeDefinitionTwo?.title }}</div>
                <div class="flex gap-2">
                    <button @click="changeDefinitionData(-1, 'definitionItemsTwo')">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.997713 6.01963L6.34937 11.3713L7.60937 10.1131L3.51326 6.01963L7.60937 1.92708L6.35027 0.667969L0.997713 6.01963Z" fill="currentColor"/>
                        </svg>                            
                    </button>
                    <button @click="changeDefinitionData(1, 'definitionItemsTwo')">
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.61166 6.01553L1.26 0.663863L-4.12859e-07 1.92208L4.09611 6.01553L-5.50374e-08 10.1081L1.25911 11.3672L6.61166 6.01553Z" fill="currentColor"/>
                        </svg>                            
                    </button>
                </div>
            </h1>
        </div>
        <!-- definition changer end -->
    </div>
  </template>
  
  <script setup>
    import { ref, computed, watch } from 'vue'
    import LeftDefinitionPanel from './LeftDefinitionPanel.vue'
    import TopDefinitionPanel from './TopDefinitionPanel.vue'
    import FourZonesInput from './FourZonesInput.vue'
    import { useTemplate } from '@/views/screens/communicator/useTemplate'
    import GraphLineSvg from "@/components/communicator/v2/templates/roadmap/component/HighLevelComponent/GraphLineSVG.vue"
    import HintText from '@/components/communicator/v2/templates/roadmap/component/HighLevelComponent/HintText.vue'
    import CanvasChart from '@/components/communicator/v2/templates/roadmap/component/HighLevelComponent/CanvasChart.vue'
    import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'
    import { isEmpty } from 'lodash'
    import axios from 'axios'

    const { updateTemplate } = useTemplate()
    const { strategy } = useHighLevelConcept()

    const props = defineProps({
        activeTemplate: {
            default: new Object()
        },
        showTitle: {
            default: 1,
            type: [Boolean, Number]
        },
        from: {
            type: String,
            default: "main"
        }
    })

    const definitions = computed(() => {
        return strategy.value.definitions
    })
    const getDefinitions = (definitions, index) => {
        if(definitions && Array.isArray(definitions) && definitions.length > index){
            return definitions[index]
        }
        return []
    }

    const definitionItemsOne  = ref([])
    const activeDefinitionOne = ref({})
    const definitionItemsTwo  = ref([])
    const activeDefinitionTwo = ref({})

    const moduleTitle = ref('')
    const changeDefinitionData = (direction, definitionItemsName) => {
        let index = 0;
        switch(definitionItemsName) {
            case 'definitionItemsOne':
                if(direction<0){
                    index = isEmpty(activeDefinitionOne.value) ? 0 : activeDefinitionOne.value.activeIndex + direction
                    index = (index<0) ? 0 : index
                }
                if(direction>0){
                    index = isEmpty(activeDefinitionOne.value) ? 0 : activeDefinitionOne.value.activeIndex + direction
                    index = (index<=(definitionItemsOne.value.length-1)) ? index : definitionItemsOne.value.length-1
                }
                activeDefinitionOne.value = definitionItemsOne.value[index]
                // update the activeDefinition activeIndex for further use
                activeDefinitionOne.value.activeIndex = index
            break
            case 'definitionItemsTwo':
                if(direction<0){
                    index = isEmpty(activeDefinitionTwo.value) ? 0 : activeDefinitionTwo.value.activeIndex + direction
                    index = (index<0) ? 0 : index
                }
                if(direction>0){
                    index = isEmpty(activeDefinitionTwo.value) ? 0 : activeDefinitionTwo.value.activeIndex + direction
                    index = (index<=(definitionItemsTwo.value.length-1)) ? index : definitionItemsTwo.value.length-1
                }
                activeDefinitionTwo.value = definitionItemsTwo.value[index]
                // update the activeDefinition activeIndex for further use
                activeDefinitionTwo.value.activeIndex = index
            break
        }

        // store definition index to dataLayer
        let dataLayer = props.activeTemplate.customized_data
        if(dataLayer.length){
            const activeTemplateData = dataLayer.find(item => item.isActiveLayout)
            activeTemplateData["definitionItemsOneIndex"] = activeDefinitionOne.value?.activeIndex
            activeTemplateData["definitionItemsTwoIndex"] = activeDefinitionTwo.value?.activeIndex
            updateTemplate(props.activeTemplate)
        }
    }

    watch(strategy, async () => {
        if(strategy.value.parameters[0]?.id && strategy.value.secondary_parameters[0]?.id){            
            let payload = {
                parameters: [strategy.value.parameters[0].id, strategy.value.secondary_parameters[0].id],
                vertical_parameter: strategy.value.secondary_parameters[0].id
            }

            
            if(!isEmpty(definitionItemsOne.value) && !isEmpty(definitionItemsTwo)) return
            let { data } = await axios.post(`concepts/definitions`, payload)
            
            if(data.status){
                definitionItemsOne.value = data.data[0]?.items
                definitionItemsTwo.value = data.data[1]?.items
                printSelectedDefinitions()
            }
        }
    })

    const printSelectedDefinitions = () => {
        // get saved definitionIndex from data layer start
        let customized_data = props.activeTemplate.customized_data
        if(Array.isArray(customized_data) && customized_data.length)
        {
            const activeTemplateData = customized_data.find(item => item.isActiveLayout)            
            let definitionIndexOne = (activeTemplateData['definitionItemsOneIndex']) ? activeTemplateData['definitionItemsOneIndex'] : 0
            let definitionIndexTwo = (activeTemplateData['definitionItemsTwoIndex']) ? activeTemplateData['definitionItemsTwoIndex'] : 0
            
            if(!definitionItemsTwo.value || definitionItemsTwo.value.length <= 0) return
            if(definitionIndexOne >= 0) {
                activeDefinitionTwo.value = definitionItemsTwo.value[definitionIndexOne]
                // update the activeDefinition activeIndex for further use
                activeDefinitionTwo.value.activeIndex = definitionIndexOne
            }
            if(definitionIndexTwo >= 0) {
                activeDefinitionTwo.value = definitionItemsTwo.value[definitionIndexTwo]
                // update the activeDefinition activeIndex for further use
                activeDefinitionTwo.value.activeIndex = definitionIndexTwo
            }
        }
        // get saved definitionIndex from data layer end

        changeDefinitionData(1, 'definitionItemsOne')
        changeDefinitionData(1, 'definitionItemsTwo')
    }
  

  </script>
  
  <style scoped>
    .extraBoxHorizontal{
        background-color: #09196e;
        position: absolute;
        font-size: 20px;
        font-weight: 800;
        height: auto;
        width: 100%;
        text-align: center;
        color: white;
        padding: 7px;
        top: 27px;
        left: -5px;
    }
    .h-270px{
        height: 270px;
    }
    .header{
        margin-left: 72px;
        margin-top: 42px;
        margin-bottom: -11px;
    }
    .graph-box{
        display: grid;
        gap: 14px;
    }
    .graph-box .left-panel{
        grid-column: 1 / span 1;
        grid-row: 2;
        margin-left: 73px;
    }
    .graph-box .top-panel{
        grid-column: 1 / span 3;
        grid-row: 1;
        margin-left: 55px;
        margin-top: 65px;
    }
    .graph-box .content{
        grid-column: 2 / span 2;
        grid-row: 2;
    }
    .graph-box .bottom-panel{
        grid-column: 2 / span 2;
        grid-row: 3;
        margin-left: -45px;
        margin-top: -11px;
        width: 98%;
    }
    .graph-box .content{
        width: 1272px;
        height: 582px;
        position: relative;
        margin-left: -44px;
        margin-top: 5px;
    }
    
    .svg-graph{
        color: var(--borderColor);
        z-index: 1;
    }
    .bottom-panel{
        height: 20px;
    }
    .bottom-panel .number{
        display: flex;
        height: 100%;
        justify-content: space-between;
    }
    .arch-one{
        top: 0;
    }
    .arch-one>span{
        font-size: 150px;
        top: 35px;
        right: 245px;
    }
    .arch-two{
        width: calc(1020px * 2);
        height: calc(506px * 2);
        border-radius: 50%;
        bottom: 0;
        left: 0;
        transform: translate(-50%, 50%);
    }
    .arch-two>span{
        font-size: 150px;
        top: 126px;
        right: 480px;
    }
    .arch-three{
        width: 1020px;
        height: 504px;
        border-radius: 50%;
        bottom: 0;
        left: 0;
        transform: translate(-50%, 50%);
    }
    .arch-three>span{
        font-size: 150px;
        top: 50px;
        right: 290px;
    }
  </style>
