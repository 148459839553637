import axios from "axios";
import { filter } from 'lodash'
export default class ProjectController {
    constructor() {
        this.project = Object(null);
        this.properties = Array(null)
    }

    async getProject(projectId) {
        this.project = await axios.get(`workspaces/dashboard/projects/${projectId}`).then(({ data }) => data.data);
        await this.getProperties(projectId);
    }

    async getProperties(moduleId) {
        this.properties = await axios.get(`/properties/manage/${moduleId}`).then(({ data }) => data.data);
        return this.properties;
    }

    getModules() {
        return this.project.modules;
    }

    getScenarios() {
        return this.project.scenarios;
    }

    getDefinitions() {
        return this.project.definitions;
    }

    getAssessments(scenarioId) {
        return filter(this.project.assessments, {scenario_id: scenarioId});
    }

    getFullName(ownerObj) {
        let fullName = '';
        if (ownerObj.first_name != null) fullName = ownerObj.first_name
        if (ownerObj.last_name != null) {
            fullName += ' ' + ownerObj.last_name
        }

        return fullName;
    }
}
