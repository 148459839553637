<template>
    <div :class="[currentMode == 'fullscreen' ? 'overflow-y-scroll snap-y h-screen' : 'space-y-12']" id="full-screen-wrapper">
        <div class="snap-start relative" v-for="template in templates" :key="template.id">
            <div v-if="template && template.communicator_template_id" class="flex gap-x-8" :id="`snap-${template.id}`">
                <TemplateLeftSidebar
                    v-if="currentMode !== 'fullscreen'"
                    :activeTemplate="template"
                    :current-mode="currentMode"
                    :template-id="template.id" 
                    :layout-id="template.communicator_layout_id"
                />
                <div :class="currentMode == 'fullscreen' ? 'template-wrapper-width-fullscreen' : 'template-wrapper-width-normal'" class="rounded-sm relative">
                    <div
                        class="absolute h-full w-full inset-0"
                        :style="{opacity: template.settings.background_color_opacity+'%', backgroundColor: template.settings.background_color}"
                    >
                    </div>
                    <img
                        v-if="template.image && template.image.file_path"
                        class="absolute h-full inset-0 object-cover w-full"
                        :src="template.image.file_path"
                        :style="{opacity: template.settings.background_image_opacity+'%'}"
                        alt="inst-360"
                    >
                    <component
                        :module="module"
                        :activeTemplate="template"
                        :modules="modules"
                        :current-mode="currentMode"
                        :is="getTemplate(template.template.component)"
                    />
                </div>
            </div>
            <div class="absolute bottom-12 right-14">
                <div class="flex items-center gap-x-2">
                <div style="width: 180px; height: 24px">
                    <img :src="getFooterLogo(layout)" alt="" class="h-full w-full">
                </div>
                <!-- <TemplateFooterIcons :openFileBrowser="openFile" /> -->
                <MenuAltSolid class="w-5 h-3 transform rotate-180 cursor-pointer" />
            </div>
        </div>
            
        </div>
    </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import TemplateLeftSidebar from "@/components/communicator/v2/sidebar/TemplateSidebar";
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { useLayout } from '@/views/screens/communicator/useLayout'

const  { getTemplate } = useTemplate()
const  { getFooterLogo } = useLayout()

const props = defineProps({
    module: Object,
    modules: Array,
    currentMode: String,
    layout: Object
})

const templates = computed(() => {
    if(!props.module) return []
    let arr = []
    props.module.layouts.forEach(layout => arr= [...arr, ...layout.module_templates])
    return arr
})

</script>

<style>
.adjust-in-normal {
    transform: scale(0.654);
    transform-origin: 0 0;
    width: 1920px;
    bottom: 20px;
}
</style>