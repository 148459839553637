var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col justify-between w-10 cursor-pointer",on:{"click":function($event){return _vm.$emit('switchSidebarComponent', 'Settings')}}},[_c('div',{staticClass:"relative flex flex-col items-center shadow-one rounded bg-primary-three mb-1",staticStyle:{"height":"706px"}},[_c('div',{staticClass:"flex flex-col space-y-4 justify-center items-center"},[_c('router-link',{attrs:{"to":{
            name: 'communicators.library',
            params: {id: _vm.$route.params.id},
            query: {communicator_id: _vm.$route.params.communicator_id, template_id: _vm.templateId, layout_id: _vm.layoutId, module_id: _vm.$route.query.module_id}
          }}},[_c('img',{staticClass:"cursor-pointer icon-24 opacity-70 i-mt-15 i-icon",attrs:{"src":"/images/icons/build/create.svg","alt":"expand"}})]),_c('HalfRectIcon',{staticClass:"w-4 h-4 cursor-pointer opacity-80 i-icon",nativeOn:{"click":function($event){return _setup.changeConfig(_vm.activeTemplate)}}}),_c('router-link',{attrs:{"to":{
          name: 'communicators.mycommunication',
          params: _vm.$route.params, 
          query: {
            communicator_id: _vm.$route.params.communicator_id,
            template_id: _vm.templateId,
            layout_id: _vm.layoutId
          }
        }}},[_c('DownloadBarIcon',{staticClass:"w-5 h-5 cursor-pointer opacity-80 i-icon"})],1)],1),_c(_setup.VerticalText,{attrs:{"text":_vm.activeTemplate.title,"left":"10","bottom":"70","width":"200"}}),_c(_setup.VerticalText,{attrs:{"text":"1","left":"6","bottom":"24","width":"20","isRotate":false}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }