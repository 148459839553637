<template>
    <div class="relative custom-dropdown">
        <button @click="isOpen = !isOpen" class="flex items-center gap-1 px-5 py-2 pr-3 rounded bg-white text-black hover:opacity-50 shadow-one">
            {{ title }}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.99983 10.4754L12.0065 6.46872L11.0645 5.52539L7.99983 8.59206L4.93583 5.52539L3.99316 6.46806L7.99983 10.4754Z" fill="#C9C9C9"/>
            </svg>                
        </button>
        <div v-show="isOpen" 
            class="absolute top-full mt-2 rounded shadow-one z-10 bg-white"
            :class="(direction === 'right') ? 'right-0' : 'left-0' ">
            <label v-for="(item, index) in list" :key="index" class="custom-checkbox flex cursor-pointer py-2 px-5 text-black w-52">
                <input hidden type="checkbox" />
                <span></span>
                {{ item.value }}
            </label>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, onBeforeUnmount } from 'vue'
    defineProps(["title", "list", "direction"])
    const isOpen = ref(false)

    onMounted(() => {
        window.addEventListener('click', outSideClickClose)
    })
    onBeforeUnmount(() => {
        window.removeEventListener('click', outSideClickClose)
    })

    const outSideClickClose = (e) => {
        if(e.target.closest('.custom-dropdown')) return;
        isOpen.value = false
    }
</script>

<style scoped>
    .custom-checkbox{
        display: flex;
        align-items: center;
        gap: 9px;
        user-select: none;
    }
    .custom-checkbox span{
        width: 10px;
        height: 10px;
        display: block;
        border: 1px solid #3D3D3D;
        border-radius: 2px;
    }
    .custom-checkbox input:checked + span{
        background-color: #3D3D3D;
    }
</style>