import store from '@/store';
import {cloneDeep} from "lodash";
export default class SummaryController {
    constructor({workspace}) {
        this.workspace = workspace;
    }

    getReversedDefinition(definition) {
        let customDefinition = cloneDeep(definition);
        customDefinition.icon = customDefinition.icon.reverse();
        customDefinition.range = customDefinition.range.reverse();
        customDefinition.color = customDefinition.color.reverse();
        customDefinition.title = customDefinition.title.reverse();
        customDefinition.description = customDefinition.description.reverse();
        return customDefinition;
    }

    async toggleProjectProgress(project, definitionItem) {
        let projectProgress = project.progress.find(item => item.dashboard_definition_group_item_id === definitionItem.id);
        if(projectProgress) {
            projectProgress.progress = projectProgress.progress === 100 ? 0 : projectProgress.progress + 5;
        } else {
            projectProgress = {
                project_id: project.id,
                dashboard_definition_group_item_id: definitionItem.id,
                progress: 5,
            };
            project.progress.push(projectProgress);
        }
        projectProgress.progress = projectProgress.progress > 100 ? 100 : projectProgress.progress;
        projectProgress.action_to = 'project_id';
        await store.dispatch('dashboard/updateProjectProgress', projectProgress);
    }

    getProgress(project, definitionItem) {
        let projectProgress = project.progress?.find(item => item.dashboard_definition_group_item_id === definitionItem.id);
        return projectProgress ? projectProgress.progress : 0;
    }

    getProgressIndex(project, definitionItem) {
        let progress = this.getProgress(project, definitionItem);
        return progress > 0 ? progress / 25.0000001 : 0;
    }

    getProgressColor(project, definitionItem, index = null) {
        if(!index) {
            index = this.getProgressIndex(project, definitionItem);
        }
        index = parseInt(index);
        return this.getReversedDefinition(definitionItem.definition).color[index];
    }

    getProgressDefinitionTitle(project, definitionItem, index = null) {
        if(!index) {
            index = this.getProgressIndex(project, definitionItem);
        }
        index = parseInt(index);
        let description = this.getReversedDefinition(definitionItem.definition).title[index];
        return description ? description : 'Not found';
    }

    getProgressDefinitionDescription(project, definitionItem, index = null) {
        if(!index) {
            index = this.getProgressIndex(project, definitionItem);
        }
        index = parseInt(index);
        let description = this.getReversedDefinition(definitionItem.definition).description[index];
        return description ? description : 'Not found';
    }

    getProgressIcon(project, definitionItem, index = null) {
        if(!index) {
            index = this.getProgressIndex(project, definitionItem);
        }
        index = parseInt(index);
        return this.getReversedDefinition(definitionItem.definition).icon[index];
    }

    getAverageProjectProgressByDefinition(projects, definitionItem) {
        let summation = 0;
        projects.forEach(project => {
            summation += this.getProgress(project, definitionItem);
        });
        return summation > 0 ? (summation / projects.length).toFixed(2) : 0;
    }

    getAvgProgressColor(value, definitionItem) {
        let index = parseInt(value / 25.01);
        let color = this.getReversedDefinition(definitionItem.definition).color[index];
        return color ? color : '';
    }

    getAvgProgressDefinitionTitle(value, definitionItem) {
        let index = parseInt(value / 25.01);
        let title = this.getReversedDefinition(definitionItem.definition).title[index];
        return title ? title : 'Not found';
    }

    getAvgProgressDefinitionDescription(value, definitionItem) {
        let index = parseInt(value / 25.01);
        let description = this.getReversedDefinition(definitionItem.definition).description[index];
        return description ? description : 'Not found';
    }

    getAvgProgressIcon(value, definitionItem) {
        let index = parseInt(value / 25.01);
        let icon = this.getReversedDefinition(definitionItem.definition).icon[index];
        return icon ? icon : 'dashboard-progress';
    }

    updateWorkspace(data) {
        store.dispatch("workspace/updateWorkspace", data);
    }
}
