<template>
    <div class="h-full radius-2 grid place-items-center"
        :style="{
            backgroundColor: background,
            border: `1px solid ${color}`,
        }"
    >
       <span class=" fs-20 fw-800 font-bold"> {{ label ? label : 'Not found' }} </span>
    </div>
</template>

<script>
export default {
    props: {
        outline: Boolean,
        color: String,
        label: String
    },
    computed: {
        background() {
            if(this.outline) {
                return 'transparent';
            }
            return this.color || '#C9C9C9'
        }
    }
}
</script>
