<template>
    <div :style="{height: height}" class="relative">
        <div
            class="absolute h-full w-full inset-0"
            :style="getContentBgStyle()"
        >
        </div>
        <img
            v-if="getContentImage()"
            class="absolute h-full inset-0 object-cover w-full"
            :src="getContentImage()"
            :style="getContentImageStyle()"
            alt="inst-360"
        >
        <div class="relative">
            <slot></slot>
        </div>
        <div class="-bottom-14 absolute bg-white flex items-center pl-2 right-8 rounded shadow-one template-style">
            <StyleComponent
                class="ml-5"
                :getConfig="onChangeConfig"
                :config="template.customized_data.styleConfig"
                @onChangeImage="onFileSelect($event, template)"
                @onclickUploader="onClickUploader('customized_data.background_image', false)"
            />
        </div>
    </div>
</template>

<script setup>
import useImageUpload from "@/components/communicator/v2/templates/useImageUpload"
// components
import StyleComponent from "@/components/communicator/v2/BackgroundAndBorderController/index"

const props= defineProps({
    template: Object,
    height: String,
    templateMethods: Object
})

const { 
    getFileElement,
    onFileSelect,
    onClickUploader
} = useImageUpload()

const { onChangeConfig, getContentBgStyle, getContentImage, getContentImageStyle  } = props.templateMethods
</script>