<template>
    <svg width="1276" height="576" viewBox="0 0 1276 576" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g data-v-a7e51a30="" clip-path="url(#clip0_96_8643)">
            <path data-v-a7e51a30="" opacity="0.3" d="M639 1H1V286H639V1Z" :fill="colors[0] ? colors[0] : ''"></path>
            <path data-v-a7e51a30="" opacity="0.3" d="M1275 1H640V285H1275V1Z" :fill="colors[1] ? colors[1] : ''"></path>
            <path data-v-a7e51a30="" opacity="0.3" d="M638 287H1V575H638V287Z" :fill="colors[2] ? colors[2] : ''"></path>
            <path data-v-a7e51a30="" opacity="0.3" d="M1275 287H640V575H1275V287Z" :fill="colors[3] ? colors[3] : ''"></path>
            <path data-v-a7e51a30="" d="M0 575H1275" stroke="currentColor"></path>
            <path data-v-a7e51a30="" d="M764.5 1V575" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M893.5 1V575" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M1022.5 1V575" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M1148.5 1V575" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M1 115.5H1275" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M1 172.5H1275" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M1 229.5H1275" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M1 57.5H1275" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M0 0.5H1275" stroke="currentColor"></path>
            <path data-v-a7e51a30="" d="M1275 0V575" stroke="currentColor"></path>
            <path data-v-a7e51a30="" d="M1 516.5H1275" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M1 460.5H1275" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M1 345.5H1275" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M1 403.5H1275" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M127.5 1V575" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M253.5 1V575" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M381.5 1V575" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M509.5 1V575" stroke="#E2E2E2"></path>
            <path data-v-a7e51a30="" d="M639 1V575" stroke="currentColor" stroke-width="2"></path>
            <path data-v-a7e51a30="" d="M1 1V575" stroke="currentColor" stroke-width="2"></path>
            <path data-v-a7e51a30="" d="M0 286H1275" stroke="currentColor" stroke-width="2"></path>
        </g>
        <defs>
            <clipPath id="clip0_96_8643">
                <rect width="1276" height="576" fill="white"/>
            </clipPath>
        </defs>
    </svg>        
</template>

<script setup>
import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'
import { computed } from 'vue'
import { isEmpty } from 'lodash'

const { strategy } = useHighLevelConcept()
let colors = computed(() => {
    if(!strategy.value.settings?.colors || isEmpty(strategy.value.settings.colors)){
        strategy.value.settings = {
            ...strategy.value.settings,
            colors: ['','','','','','']
        }
    }
    return strategy.value.settings.colors
})
</script>