<template>
    <div :style="{height: height, paddingLeft: '30px', marginTop: '40px'}">
        <h2 v-if="title" class="title mb-4 fw-600 fs-24">{{ title }}</h2>
        <h4 v-if="subtitle" class="mb-2 fw-600 fs-20" :class="{'font-semibold': IsSemibold}">{{ subtitle }}</h4>
        <div class="flex gap-2 mb-3">
            <slot></slot>
        </div>
        <!-- :style="{color: colorCode}" -->
        <p v-if="description" class="fs-18 fw-500 i-mt-10 h-12 overflow-auto">{{ description }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: [Boolean, String]
            },
            subtitle: {
                type: [Boolean, String]
            },
            description: {
                type: [Boolean, String]
            },
            height: {
                type: String,
                default: 'auto'
            },
            IsSemibold: {
                type: Boolean,
                default: false
            },
            colorCode: {
                type: String,
                default: '#1C1C1D'
            }
        }
    }
</script>

<style scoped>
</style>
