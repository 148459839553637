import {set} from 'vue'
export default function useData() {
    const setInitialData = (template, updateTemplate) => {
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
            template.customized_data.title = {id: 'title', value: 'Headline'}
            template.customized_data.description = {id: 'description', value: 'Add text'}
        }

        let isTableConfig = "tableConfig" in template.customized_data;
        if(!isTableConfig) {
            set(template.customized_data, 'tableConfig',[
                {
                    th: 'Add text',
                    td: 'Add text'
                },
                {
                    th: 'Add text',
                    td: 'Add text'
                }
            ])
        }

        let isColorConfig = "colorConfig" in template.customized_data;
        if(!isColorConfig){
            set(template.customized_data, 'colorConfig', {
                thBg: '#002678',
                cellBg: '#DFEDF9',
                graphBg: '#fff'
            })
        }

        let isScenarioId = "scenarioId" in template.settings;
        if(!isScenarioId) {
            set(template.settings, 'scenarioId', null)
        }
        
        let isFilterTreeData = "filterTreeData" in template.settings;
        if(!isFilterTreeData) {
            set(template.settings, 'filterTreeData', [])
        }
        
        let isModuleId = "moduleId" in template.settings;
        if(!isModuleId) {
            set(template.settings, 'moduleId', null)
        }
        let isPhases = "phases" in template.customized_data;
        if(!isPhases) {
            set(template.customized_data, 'phases', [{title: 'Add text'}, {title: 'Add text'}, {title: 'Add text'}])
        }
    }

    return {
        setInitialData
    }
}

export const style = {
    title: {
        fontWeight: '700',
        fontSize: '40px'
    },

    subtitleInput: {
        width: '1203px',
        height: '66px',
        fontWeight: '400',
        fontSize: '22px',
        lineHeight: '33px',
    }
}