import { set } from 'vue'
import { useTemplate } from '@/views/screens/communicator/useTemplate'

export default function useConcept() 
{
    const { updateTemplate } = useTemplate()
    const setInitialData = (template) => 
    {
        if (template.settings.color === true || !template.settings.color) {
            set(template.settings, 'color', '#1C1C1D')
        }
        if (!('background_image_opacity' in template.settings)) {
            set(template.settings, 'background_image_opacity', 100);
        }
        if (!('background_color_opacity' in template.settings)) {
            set(template.settings, 'background_color_opacity', 100);
        }
        if (!('background_color' in template.settings)) {
            set(template.settings, 'background_color', '#fff');
        }
    }

    const getTitle = (activeTemplate) => {
        let selectedData = activeTemplate.customized_data.find(item => item.isActiveLayout === true)
        return selectedData.title
    }

    const getSubTitle = (activeTemplate) => {
        let selectedData = activeTemplate.customized_data.find(item => item.isActiveLayout === true)
        return selectedData.subTitle
    }

    let timeoutId = null
    const editTitle = (title, activeTemplate) => {
        let selectedData = activeTemplate.customized_data.find(item => item.isActiveLayout === true)
        set(selectedData, 'title', title)
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            updateTemplate(activeTemplate)
        }, 1000)
    }
    const editSubTitle = (title, activeTemplate) => {
        let selectedData = activeTemplate.customized_data.find(item => item.isActiveLayout === true)
        set(selectedData, 'subTitle', title)
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            updateTemplate(activeTemplate)
        }, 1000)
    }
    return {
        setInitialData,
        getTitle,
        editTitle,
        getSubTitle,
        editSubTitle
    }
}