<template>
    <div
      @click="$emit('switchSidebarComponent', 'Settings')"
      class="flex flex-col justify-between w-10 cursor-pointer"
      >
      <!-- style="height: var(--template-height)" -->
      <div
        style="height: 706px"
        class="relative flex flex-col items-center shadow-one rounded bg-primary-three mb-1"
      >
        <div class="flex flex-col space-y-4 justify-center items-center">
          <router-link 
            :to="{
                name: 'communicators.library',
                params: {id: $route.params.id},
                query: {communicator_id: $route.params.communicator_id, template_id: templateId, layout_id: layoutId, module_id: $route.query.module_id}
              }"
            >
            <img
              src="/images/icons/build/create.svg"
              alt="expand"
              class="cursor-pointer icon-24 opacity-70 i-mt-15 i-icon"
            />
          </router-link>
          <HalfRectIcon @click.native="changeConfig(activeTemplate)" class="w-4 h-4 cursor-pointer opacity-80 i-icon" />
          <!-- communicator_id=10&template_id=285&layout_id=50 -->
          <router-link 
            :to="{
              name: 'communicators.mycommunication',
              params: $route.params, 
              query: {
                communicator_id: $route.params.communicator_id,
                template_id: templateId,
                layout_id: layoutId
              }
            }"
          >
            <DownloadBarIcon class="w-5 h-5 cursor-pointer opacity-80 i-icon" />
          </router-link>
        </div>
        <VerticalText
          :text="activeTemplate.title"
          left="10"
          bottom="70"
          width="200"
        />
        <VerticalText
          text="1"
          left="6"
          bottom="24"
          width="20"
          :isRotate="false"
        />
      </div>
    </div>
</template>

<script setup>
    import VerticalText from "@/elements/atom/VerticalText.vue";
    const props = defineProps({
      templateId: [String, Number],
      layoutId: [String, Number],
      activeTemplate: Object
    })

    const changeConfig = (template) => {
      let configurationNo = template.settings.configuration_no
      if(!configurationNo) return
      if(configurationNo == 3) {
        template.settings.configuration_no = 1
        return
      }
      template.settings.configuration_no++
    }
</script>