<template>
    <!-- top panel start -->
    <div class="top-panel top-full w-full">
        <div class="level bottom-0 w-full left-0 text-center">
            <div class="flex-shrink-0 bloc text-left relative" style="width: 328px;">
                <div class="absolute top-3 left-1">
                    <h2 class="fw-400 fs-16 mt-4">Module Name</h2>
                    
                    <!-- color picker -->
                    <div class="flex gap-2 mt-4">
                        <label class="w-9 h-5 cursor-pointer rounded border" :style="{background: activeTemplate.customized_data[0]?.colorOne}">
                            <input type="color" class="invisible" v-model="activeTemplate.customized_data[0].colorOne" />
                        </label>
                        <label class="w-9 h-5 cursor-pointer rounded border" :style="{background: activeTemplate.customized_data[0]?.colorTwo}">
                            <input type="color" class="invisible" v-model="activeTemplate.customized_data[0].colorTwo" />
                        </label>
                    </div>
                </div>
            </div>
            <div class="flex justify-between level-container w-full">
                <span 
                    class="flex-shrink-0 fw-800 fs-14"
                    v-for="(definition, index) in definitions"
                    :key="`xdef-${index}`"
                >
                    {{ definition }}
                </span>
            </div>
        </div>
        <div class="definition pointer-events-none">
            <div class="h-99px p-4 relative" style="width: 295px;margin-left: 35px;"></div>
            <div v-for="(definitionItem, index) in definitionItems" :key="index">
                <p class="fs-14 fw-400 h-full overflow-y-auto">
                    {{ definitionItem.definition }}
                </p>
            </div>
        </div>
    </div>
    <!-- top panel end -->
</template>

<script setup>
    import { watch } from 'vue'
    import { useTemplate } from '@/views/screens/communicator/useTemplate'

    
    const { updateTemplate } = useTemplate()
    const props = defineProps({
        definitions: {
            type: Array
        },
        definitionItems: { 
            type: Array
        },
        activeTemplate: Object
    })

    let timeoutId;
    watch(props.activeTemplate, () => {
        clearTimeout(timeoutId)
        setTimeout(() => {
            updateTemplate(props.activeTemplate)
        }, 1500)
    }, {deep: true})
</script>

<style scoped>
    .border{
        border: 1px solid #C9C9C9;
    }
    .top-panel {
        width: 1610px;
    }
    .top-panel .level{
        display: flex;
        position: relative;
        transform: translateY(16px);
    }
    .top-panel .level .level-container span{
        width: 250px;
    }
    .top-panel .definition{
        display: flex;
        transform: translateY(25px);
    }
    .top-panel .definition>div{
        position: relative;
    }
    .top-panel .definition>div:not(:first-child){
        width: 254px;
        height: 90px;
        padding: 15px 15px;
    }
    .top-panel .definition>div::before{
        content: '';
        border-right: 1px solid var(--borderColor);
        position: absolute;
        right: 1px;
        top: -2px;
        height: calc(100% + 7px);
    }
    .h-99px{
        height: 99px;
    }
</style>