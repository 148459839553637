import { ref } from 'vue'
import axios from 'axios'
import { fabric } from "fabric"
import useRoadmapConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useRoadmapConcept.js'
import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'

const clickState = ref(null)
const parents = ref(null)
const { printCircles, layoutSize } = useRoadmapConcept()
const Callback = ref(()=>{})
const canvasSize = ref({width: null, height: null })
const showDetail = ref(false)
const isProcessing = ref(false)
const padding = ref(40)
const bigCircleWidth = 40 * 2
const { strategy, systemData, getStrategy } = useHighLevelConcept()

export default function useConceptCanvas()
{
  const canvas = ref(null)
  const init = ref({
    canvas: null,
    bg: 'transparent',
    canvasSelection: true,
    canvasDimension: {
      width: 600,
      height: 600
    }
  })

  var shadow = new fabric.Shadow({
    color: '#00000026',
    top: 6,
    blur: 15
  })
  
  const initCanvas = () => 
  {
    init.value.canvas = new fabric.Canvas(canvas.value, {
      backgroundColor: init.value.bg,
      containerClass: 'CanvasForConcept',
      uniformScaling: true,
      uniScaleKey: "altKey"
    })
    
    init.value.canvasDimension = {
      width: canvasSize.value.width + (padding.value * 2),
      height: canvasSize.value.height + (padding.value * 2)
    }
    init.value.canvas.setDimensions(init.value.canvasDimension)
    init.value.canvas.selection = false

    _startEventListener()
  }

  const createCircle = (config) => 
  {
    let level = `${config.levelData.index_id}${config.levelData.index_code} ${config.levelData.title}`
    let children = config.levelData.children

    let circle = new fabric.Circle({
      radius: config.radius,
      fill: config.background ? config.background : 'blue',
      stroke: 'transparent',
      strokeWidth: 0,
      selectable: true,
      originX: 'center',
      originY: 'center',
      shadow,
      hoverCursor: "pointer"
    })

    let text = new fabric.Text(config.circleText, {
      fontSize: 12,
      originX: 'center',
      originY: 'center',
      fill: '#f9f9f9',
      selectable: false,
      fontFamily: 'poppins',
      fontWeight: '600'
    })

    let levelText = new fabric.Text(level, {
      left: showDetail.value ? 27 : 32, 
      top: 0, 
      fontSize: showDetail.value ? 10 : 12,
      fontWeight: 600,
      selectable: false,
      originX: 'left',
      originY: 'center',
      fill: '#3D3D3D',
      fontFamily: 'poppins'
    })

    let rect = new fabric.Rect({
      left: 10, 
      top: 0, 
      width: levelText.width + 30, 
      height: showDetail.value ? 15 : 26, 
      fill: '#fff',
      originX: 'left',
      originY: 'center',
      centeredRotation: true,
      selectable: false,
      rx: 4,
      ry: 4,
      shadow
    })

    let smallRect = new fabric.Rect({
      left: showDetail.value ? 15 : 20, 
      top: 0, 
      width: 8, 
      height: 8, 
      fill: config.levelData.background_color,
      selectable: false,
      originX: 'left',
      originY: 'center'
    })

    const groupForDetail = new fabric.Group([ rect, levelText, smallRect ], {
      left: config.radius + 15,
      values: 'details',
      visible: showDetail.value,
      selectable: false,
    })

    const pointerCircleInner = new fabric.Circle({
      radius: 3,
      fill: '#00A7FE',
      stroke: 'transparent',
      strokeWidth: 0,
      selectable: false,
      originX: 'center',
      originY: 'center'
    })
    const pointerCircleOuter = new fabric.Circle({
      radius: 9,
      fill: 'transparent',
      stroke: '#00A7FE',
      strokeWidth: 2,
      selectable: false,
      originX: 'center',
      originY: 'center'
    })
    const pointerGroup = new fabric.Group([pointerCircleInner, pointerCircleOuter], {
      visible: false,
      values: 'pointers',
      left: config.radius,
      hoverCursor: "pointer",
      selectable: false,
      top: -40
    })


    let top = (init.value.canvasDimension.height) - (config.bottom+(circle.radius*2))
    const group = new fabric.Group([ circle, text, groupForDetail, pointerGroup ], {
      values: {
        scenario_collection_id: config.levelData.id,
        levelData: config.levelData,
        parents: config.parents
      },
      id: 'circle',
      left: config.left + ((bigCircleWidth/2) - (circle.height/2)),
      top: top - (((bigCircleWidth/2) - (circle.height/2))*2),
      lockUniScaling: true,
      hasControls: true,
      hasBorders: false,
      selectable: !children.length,
      // hoverCursor: children.length ? init.value.canvas.defaultCursor : init.value.canvas.move,
      originX: 'left',
      originY: 'top',
      hoverCursor: "pointer",
    })    
    
    group.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      bl: false,
      br: false,
      tl: false,
      tr: false,
      mtr: false,
    })
    
    // Define a custom containsPoint method for the group
    group.containsPoint = function(point, skipCorners) {
      var left = this.left,
          top = this.top,
          width = circle.width,
          height = circle.height

      // Check if the point is inside the selectable area
      if (point.x > left && point.x < left + width &&
          point.y > top && point.y < top + height) {
        return true;
      }
      return false;
    }

    init.value.canvas.add(group)
    init.value.canvas.renderAll()

    let positionObj = {left: group.left, top: group.top, radius: circle.radius }
    return positionObj
  }

  const createLine = (points, color='#d6d6d6') => 
  {
    drawTriangle(points, getAngle(points))

    points[2] = points[0] + 1 * (points[2] - points[0])
    points[3] = points[1] + 1 * (points[3] - points[1])

    let line = new fabric.Line(points, {
      stroke: color,
      values: "line",
      selectable: false,
      hoverCursor: init.value.canvas.defaultCursor,
      visible: true
    })
    init.value.canvas.add(line)
    init.value.canvas.renderAll()

    _lineSendToBack()
  }

  const getAngle = (points) => {
    const [x1, y1, x2, y2] = points
    const xDiff = (x2 - x1)
    const yDiff = (y2 - y1)
    let angleInRadian = Math.atan(yDiff/xDiff)

    let angle = angleInRadian * 180 / Math.PI
    return angle
  }

  const getArrowHeadPosition = ([x1, y1, x2, y2]) => {
    let point = {
      x: (x2 + x1)/2,
      y: (y2 + y1)/2
    }
    return point
  }

  const drawTriangle = (points, angle) => {
    const [x1, y1, x2, y2] = points

    const {x, y} = getArrowHeadPosition([x1, y1, x2, y2])

    let triangle = new fabric.Polygon([
      {x: 0, y: 0},
      {x: -6, y: -3},
      {x: -6, y: 3},
    ],{
      id: 'arrow-head',
      values: 'arrow-head',
      stroke: '',
      strokeWidth: 0,
      fill: '#d7d7d7',
      selectable: false,
      hasControls: false,
      top: y,
      left: x,
      angle: angle,
      originX: 'center',
      originY: 'center',
      flipX: true
    })
    
    // init.value.canvas.add(triangle)
    // init.value.canvas.renderAll()
  }

  const clearCanvas = () => {
    if(!init.value.canvas?.getObjects()) return
    init.value.canvas.remove(...init.value.canvas.getObjects());
    init.value.canvas.renderAll()
  }

  const _lineSendToBack = () => {
    init.value.canvas.getObjects().map(function(o) {
      if(o.values == 'line' || o.values == 'arrow-head'){
        init.value.canvas.sendToBack(o)
      }
    })
  }

  const _startEventListener = () => {
    init.value.canvas.on('object:modified', _updateFromScenario)
    init.value.canvas.on('object:moving', _checkCollision)
    init.value.canvas.on({
      'selection:updated': _showDetails,
      'selection:created': _showDetails
    })
    
    init.value.canvas.on('selection:cleared', _hideDetails)
    init.value.canvas.on('mouse:dblclick', _dblClickHandler)
  }

  
  const _updateFromScenario = async (e) => {
    isProcessing.value = true
    let { width, height } = init.value.canvasDimension
    let { left, top, height:THeight } = e.target
    let bottom = height - (top+THeight)

    let filterObj = e.target.getObjects().find(item => item.type == 'circle')
    let circleDiff = ((bigCircleWidth/2) - (filterObj.width/2))
    left = left - circleDiff
    bottom = bottom - circleDiff

    let horizontalSampleValue = (10 / (width - (padding.value * 2))) * left // convert this value to 0-10 range number
    let horizontalCollectionId = strategy.value.parameters[0].id
    
    let verticalSampleValue = (10 / (height - (padding.value * 2))) * bottom // convert this value to 0-10 range number
    let verticalCollectionId = strategy.value.secondary_parameters[0].id

    let module_id   = strategy.value.module_id
    let scenario_id = strategy.value.scenario_id
    let module_collection_id = null
    
    let payloadForHorizontal = {
      module_collection_id,
      module_id,
      property_collection_id: horizontalCollectionId,
      sample_value: horizontalSampleValue.toFixed(2),
      scenario_collection_id: e.target.values.scenario_collection_id,
      scenario_id
    }

    let payloadForVertical   = {
      module_collection_id,
      module_id,
      property_collection_id: verticalCollectionId,
      sample_value: verticalSampleValue.toFixed(2),
      scenario_collection_id: e.target.values.scenario_collection_id,
      scenario_id
    }

    await axios.post('properties/updateFromScenario', payloadForHorizontal)
    await axios.post('properties/updateFromScenario', payloadForVertical)

    getStrategy()
    Callback.value()
    setTimeout(() => {
      isProcessing.value = false
    }, 1000)
  }

  const _checkCollision = (o) => {
    const Height = o.target.height
    const Width  = o.target.width
    const left  = o.target.left
    const top  = o.target.top
    const radius = Height / 2
    let filterObj = o.target.getObjects().find(item => item.type == 'circle')
    let circleDiff = ((bigCircleWidth/2) - (filterObj.width/2))
        
    // left side check
    if(o.target.left <= circleDiff){
      o.target.set({
        left: circleDiff
      })
    }

    // right side check
    if((o.target.left + filterObj.width) >= (init.value.canvas.width - circleDiff)){
      o.target.set({
        left: (init.value.canvas.width - filterObj.width )- circleDiff
      })
    }

    // top side check
    if(o.target.top <= (filterObj.height - o.target.height) + circleDiff){
      o.target.set({
        top: (filterObj.height - o.target.height) + circleDiff
      })
    }

    // bottom side check
    if(o.target.top + o.target.height >= (init.value.canvas.height - circleDiff)){
      o.target.set({
        top: (init.value.canvas.height - o.target.height) - circleDiff
      })
    }
  }

  let filteredGroup = null
  let filteredPointerGroup = null
  const _showDetails = (e) => {
    if(filteredGroup){
      filteredGroup.set('visible', false)
      filteredPointerGroup.set('visible', false)
      init.value.canvas.renderAll()
    }

    let selectedObj = e.target?._objects
    if(selectedObj){
      filteredGroup = selectedObj.find(item => item.values == 'details')
      filteredPointerGroup = selectedObj.find(item => item.values == 'pointers')
      
      filteredGroup.set('visible', true)
      filteredPointerGroup.set('visible', true)
      init.value.canvas.renderAll()
    }
  }

  const _hideDetails = (e) => {
    filteredGroup.set('visible', false)
    filteredPointerGroup.set('visible', false)
    init.value.canvas.renderAll()
  }


  const _dblClickHandler = async (o) => {
    let { levelData, parents:parentLevelData } = o.target.values
    parents.value = parentLevelData
    if(clickState.value && clickState.value.id == levelData.id){
      clickState.value = null
    }else{
      clickState.value = levelData
    }

    layoutSize.value = canvasSize.value
    clearCanvas()
    init.value.canvas.renderAll()
    printCircles(systemData.value.children, createCircle, createLine, parents.value, clickState.value)
  }

  return {
    canvasSize,
    initCanvas,
    canvas,
    createCircle,
    init,
    createLine,
    Callback,
    showDetail,
    clearCanvas,
    isProcessing,
    clickState,
    parents
  }
}