import { ref } from 'vue'
import axios from 'axios'
import { cloneDeep } from 'lodash'

export const useShare = () => 
{
    const invitations = ref([])
    const placeholder = ref({
        "workspace_team_id": null,
        "valid_for_hour": null,
        "peoples": [
            {
                "email": null,
                "role": 4
            }
        ]
    })

    const getFullName = (invitation) => {
        if(!invitation.user) {
            return invitation.email;
        }
        let { user } = invitation;
        return `${user.first_name} ${user.last_name}`;
    }

    const getAvatar = (invitation) => {
        if(!invitation.user) {
            return '/images/profile.png';
        }
        return get(invitation.user, 'image.file_path', '/images/profile.png');
    }

    const form = ref(cloneDeep(placeholder.value))

    const invite = async (formData, teamId) => {
        form.value.workspace_team_id = teamId
        axios.post(`workspaces/teams/members/share-or-people`, formData)
        form.value = cloneDeep(placeholder.value)
        loadInvitations(teamId)
    }

    const loadInvitations = async (teamId) => {
        let { data } = await axios.get(`workspaces/teams/members/${teamId}`)
        invitations.value = data.data
    }

    const remove = async (invitationId, teamId) => {
        await axios.delete(`workspaces/teams/members/${invitationId}`)
        loadInvitations(teamId)
    }

    return {
        form,
        invite,
        invitations,
        loadInvitations,
        getFullName,
        getAvatar,
        remove
    }
}