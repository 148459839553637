<template>
    <select v-model="selectRole" class="text-secondary-two fs-14 bg-transparent focus:outline-primary-six w-full">
        <option :value="null" >Project manager</option>
        <option :value="null" >Sponsor</option>
    </select>
</template>

<script setup>
    import { ref } from 'vue';
    const selectRole = ref(null)
</script>