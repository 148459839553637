import LayoutThreeImg from '@/components/dashboard/components/image/LayoutThreeImg.vue'

let layout = {
    name: 'layout3',
    image: LayoutThreeImg,
    rows: [
        {id: 'row_5', class: 'grid gap-5 grid-cols-3'},
        { id: 'row_6', class: 'grid gap-5 grid-cols-3 grid-rows-2' }
    ],
    cards: [
        {
            rows_reference: 'row_5',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_5',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_5',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        
        {
            rows_reference: 'row_6',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_6',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_6',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: 'col-end-4 col-span-2 i-row-span-2 row-end-3',
                height: 546,
                componentName: 'LargeChart'
            }
        }
    ],
    isSelected: false
}

export default layout