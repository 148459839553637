<template>
    <div v-if="data" class="box-concept-title relative mb-10">
        <button 
            :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
            @click="showConfiguration('TextSource', ['title'], data.conf_id)" 
            class="absolute -left-20 top-2"
        >
            <svg width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M15.9993 7.1875L5.58579 18.1047C5.21071 18.4915 5 19.0161 5 19.5631C5 20.1101 5.21071 20.6347 5.58579 21.0215C5.96086 21.4083 6.46957 21.6256 7 21.6256C7.53043 21.6256 8.03914 21.4083 8.41421 21.0215L20.8277 8.04182C21.1991 7.65877 21.4938 7.20404 21.6948 6.70357C21.8958 6.2031 21.9993 5.6667 21.9993 5.125C21.9993 4.5833 21.8958 4.0469 21.6948 3.54643C21.4938 3.04596 21.1991 2.59123 20.8277 2.20818C20.4563 1.82514 20.0153 1.5213 19.53 1.314C19.0447 1.1067 18.5246 1 17.9993 1C17.474 1 16.9538 1.1067 16.4685 1.314C15.9832 1.5213 15.5423 1.82514 15.1708 2.20818L2.75736 15.1879C1.63214 16.3483 1 17.9221 1 19.5631C1 21.2042 1.63214 22.778 2.75736 23.9384C3.88258 25.0987 5.4087 25.7506 7 25.7506C8.5913 25.7506 10.1174 25.0987 11.2426 23.9384L21.4993 13.375" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>                
        </button>
        <h1 v-if="title(activeTemplate, data.conf_id, 'title')" class="fs-24 fw-800 bg-transparent">
            {{ title(activeTemplate, data.conf_id, 'title') }}
        </h1>
        <input 
            v-else
            class="fs-24 fw-800 bg-transparent w-full"
            placeholder="Title"
            @input="(e) => editTitle(e.target.value, data, activeTemplate)"
            :value="data.title"
        >
    </div>
</template>

<script setup>
    import { useTemplate } from '@/views/screens/communicator/useTemplate.js'
    import useBoxConcept from '../useBoxConcept';
    const props = defineProps({
        data: Object,
        switchSidebarComponent: Function,
        config_visibility: Boolean,
        currentMode: String,
        activeTemplate: Object,
        synchronize: Boolean
    })

    const { prepareRequest, getPersistance, prepareConfiguration, title } = useTemplate();
    const { editTitle } = useBoxConcept()
    
    const showConfiguration = (componentName, options, confId) => {
        props.switchSidebarComponent(componentName, {
            persistance: getPersistance(props.activeTemplate, confId),
            confId: confId,
            transform: (config) => {
                let inputs = prepareConfiguration(options, config);
                return prepareRequest(props.activeTemplate, confId, config, inputs, props.synchronize);
            }
        });
    }
</script>

<style scoped>
    .box-concept-title::before{
        border-color: var(--borderColor);   
    }
</style>