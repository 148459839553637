<template>
    <div class="">
        <div 
            class="top_bar relative z-10 flex gap-8 fs-14 items-center" 
            style="margin-left: 70px;">
            <div>
                <span class="fw-800">Active Scenario: </span>
                <select 
                    @change="(e) => setScenarioId(e.target.value)" 
                    class="fw-500 bg-transparent outline-none"
                    v-model="getActiveScenarioId"
                >
                    <option value="0" selected>-Select-</option>
                    <option v-for="scenario in scenarios" :key="scenario.id" :value="scenario.id">
                        {{ scenario.title }}
                    </option>
                </select>
            </div>

            <div>
                <span class="fw-800">Data Source:  </span>
                <select class="fw-500 bg-transparent outline-none">
                    <option selected value="Scenario Properties">Scenario Properties</option>
                </select>
            </div>
            <div class="w-60 ml-40"></div>
        </div>
        
        <div class="flex gap-2 justify-between items-baseline absolute top-14 z-40" style="width: 1650px">
            <div class="w-full">
                <h1 class="fs-40 fw-800 text-primary-one relative" style="margin-left: 61px;margin-top: 22px;">
                    <button @click="showConfiguration('TextSource', ['title', 'description'], activeTemplate.customized_data[0].id)" class="absolute" style="left: -64px;top: 20px;">
                        <svg width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.9993 7.31152L5.58579 18.2287C5.21071 18.6155 5 19.1401 5 19.6872C5 20.2342 5.21071 20.7588 5.58579 21.1456C5.96086 21.5324 6.46957 21.7497 7 21.7497C7.53043 21.7497 8.03914 21.5324 8.41421 21.1456L20.8277 8.16584C21.1991 7.7828 21.4938 7.32806 21.6948 6.82759C21.8958 6.32712 21.9993 5.79073 21.9993 5.24902C21.9993 4.70732 21.8958 4.17092 21.6948 3.67045C21.4938 3.16999 21.1991 2.71525 20.8277 2.33221C20.4563 1.94917 20.0153 1.64532 19.53 1.43802C19.0447 1.23072 18.5246 1.12402 17.9993 1.12402C17.474 1.12402 16.9538 1.23072 16.4685 1.43802C15.9832 1.64532 15.5423 1.94917 15.1708 2.33221L2.75736 15.3119C1.63214 16.4723 1 18.0461 1 19.6872C1 21.3282 1.63214 22.902 2.75736 24.0624C3.88258 25.2228 5.4087 25.8747 7 25.8747C8.5913 25.8747 10.1174 25.2228 11.2426 24.0624L21.4993 13.499" stroke="#1C1C1D" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>                            
                    </button>
       
                    <template v-if="title(activeTemplate, activeTemplate.customized_data[0].id)">
                        {{ title(activeTemplate, activeTemplate.customized_data[0].id) }}
                    </template>
                    <input 
                        v-else 
                        class="fs-40 fw-800 text-primary-one bg-transparent w-full"
                        :value="activeTemplate.customized_data[0].title"
                        placeholder="Title"
                        @input="(e) => editTemplateTitle(e.target.value, activeTemplate)"
                    />
                </h1>


                <h2 class="fs-20 fw-400 text-primary-one ml-16 -mt-1">
                    <template v-if="description(activeTemplate, activeTemplate.customized_data[0].id)">
                        {{ description(activeTemplate, activeTemplate.customized_data[0].id) }}
                    </template>
                    <textarea 
                        v-else 
                        class="fs-20 fw-400 text-primary-one w-full bg-transparent"
                        :value="activeTemplate.customized_data[0].description"
                        placeholder="Description"
                        @input="(e) => editTemplateDescription(e.target.value, activeTemplate)"
                    ></textarea>
                </h2>
            </div>

            <div class="grid gap-2 grid-cols-2 rounded overflow-hidden flex-shrink-0" style="width: 420px;">
                <div class="grid gap-1">
                    <input class="grid items-center text-center px-4 py-2 justify-center text-white" :style="{ background: activeTemplate.customized_data[0]?.colorOne }" v-model="activeTemplate.customized_data[0].texts.headingOne" @input="updateTemplate(activeTemplate)" />
                    <input class="grid items-center text-center px-4 py-2 justify-center" :style="{ background: activeTemplate.customized_data[0]?.colorTwo }" v-model="activeTemplate.customized_data[0].texts.dataOne" @input="updateTemplate(activeTemplate)" />
                </div>
                <div class="grid gap-1">
                    <input class="grid items-center text-center px-4 py-2 justify-center text-white truncate" :style="{ background: activeTemplate.customized_data[0]?.colorOne }" v-model="activeTemplate.customized_data[0].texts.headingTwo" @input="updateTemplate(activeTemplate)" />
                    <input class="grid items-center text-center px-4 py-2 justify-center" :style="{ background: activeTemplate.customized_data[0]?.colorTwo }" v-model="activeTemplate.customized_data[0].texts.dataTwo" @input="updateTemplate(activeTemplate)" /> 
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import axios from 'axios'
    import router from "@/routes/routes.js";
    import { isEmpty } from 'lodash'
    import { ref, onMounted, computed } from 'vue'
    import {useSidebar} from "@/views/screens/communicator/useSidebar"
    import { useTemplate } from '@/views/screens/communicator/useTemplate'
    import useRoadmapConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useRoadmapConcept.js'

    const { selectedConcept } = useRoadmapConcept()
    const { title, description, getPersistance, updateTemplate, prepareConfiguration, prepareRequest } = useTemplate()
    const route = router.currentRoute;
    const { getScenariosByProject } = useSidebar();
    const props = defineProps([
        'activeTemplate', 
        'layoutList', 
        'activeLayout',
        'loadConcept',
        'activeConceptId',
        'config_visibility',
        'activeScenarioId',
        'setScenarioId',
        'currentMode',
        'toggleShowTitle',
        'switchSidebarComponent',
        'synchronize'
    ])

    const scenarios = ref([])
    const concepts = ref([])
    const emit = defineEmits()

    const editTemplateTitle = (title, activeTemplate) => {
        activeTemplate.customized_data[0].title = title
        updateTemplate(activeTemplate)
    }

    const editTemplateDescription = (description, activeTemplate) => {
        activeTemplate.customized_data[0].description = description
        updateTemplate(activeTemplate)
    }

    
    const showConfiguration = (componentName, options, confId) => {
        props.switchSidebarComponent(componentName, {
            persistance: getPersistance(props.activeTemplate, confId),
            transform: (config) => {
                let inputs = prepareConfiguration(options, config);
                return prepareRequest(props.activeTemplate, confId, config, inputs, props.synchronize);
            } 
        });
    }

    const getActiveScenarioId = computed({
        get() {
            return props.activeScenarioId
        },
        set(activeScenarioId) {
            emit('input', activeScenarioId);
            emit('change', activeScenarioId);
        }
    })
    const getActiveConceptId = computed({
        get() {
            return props.activeConceptId
        },
        set(activeConceptId) {
            setSelectedConcept()
            emit('input', activeConceptId);
            emit('change', activeConceptId);
        }
    })

    const setSelectedConcept = () => {
        selectedConcept.value = concepts.value.find(item => item.id == getActiveConceptId.value)
    }
    onMounted(async () => {
        const projectId = route.params.id
        const res = await axios.get(`roadmaps/concepts/${projectId}`)
        concepts.value = res.data.data
        scenarios.value = await getScenariosByProject(projectId)
        setSelectedConcept()
    })
</script>

<style scoped>
    select option{
        background-color: var(--bg-color);
        color: var(--color);
    }
    .toggleBtn{
        background-color: var(--bg-color);
        color: inherit;
        width: 25px;
        height: 25px;
        display: grid;
        place-content: center;
        border: 1px solid var(--color);
    }
</style>