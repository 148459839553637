import { getFlattenCollections } from '@/store/modules/global/services';
import { find } from 'lodash';
import axios from 'axios';

export const useCollection = () => {
    
    const getCollectionById = (src, id) => find(src, ['id', id]);

    const getLastLevelCollections = (collections) => {
        return collections.filter(item => item.child.length == 0);
    }

    const getCollectionDataByScenarioAndModule = async (scenarioId, moduleId, params) => {
        return axios.get(`communicators/system/${scenarioId}/isolation-structured-data`, {
             params: {
                 module_id: moduleId,
                 ...params
             }
        }).then(({ data }) => data.data);
    }

    return {
        getFlattenCollections,
        getLastLevelCollections,
        getCollectionById,
        getCollectionDataByScenarioAndModule
    }
}

export const getClass = (item) => {
    if(item.i_am == 'team') return 'fw-800 fs-16'
    if(item.i_am == 'plan') return 'fw-900 fs-14'
    if(item.i_am == 'project') return 'fw-800 fs-14'
    if(item.i_am == 'module') return 'fw-800 fs-14'
    if(item.i_am == 'level') return 'fw-600 fs-14'
}

const weightSet = {
    plan: 'fw-800',
    project: 'fw-700',
    module: 'fw-700',
    level: {
        1: 'fw-600',
        2: 'fw-500',
        3: 'fw-400',
        4: 'fw-400',
        5: 'fw-400',
    },
    task: {
        1: 'fw-500',
        2: 'fw-500',
        3: 'fw-500',
        4: 'fw-400'
    }
}

export const getWeight = (item) => {
    if(['plan', 'project', 'module'].includes(item.i_am)) {
        return weightSet[item.i_am];
    }
    if(item.i_am == 'level') {
        return weightSet[item.i_am][item.level] || 'fw-400';
    }
    if(item.i_am == 'task') {
        return weightSet[item.i_am][item.level] || 'fw-400';
    }
    return 'fw-400'
}