<template>
<svg :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_15248_41390)">
        <path d="M58 31C58 44.8071 46.8071 56 33 56C19.1929 56 8 44.8071 8 31C8 17.1929 19.1929 6 33 6C46.8071 6 58 17.1929 58 31Z" />
        <path d="M57.0625 31C57.0625 44.2893 46.2893 55.0625 33 55.0625C19.7106 55.0625 8.9375 44.2894 8.9375 31C8.9375 17.7106 19.7106 6.9375 33 6.9375C46.2894 6.9375 57.0625 17.7106 57.0625 31Z" :stroke="color" stroke-width="1.875"/>
    </g>
    <path d="M40.5 23.5L25.5 38.5" :stroke="color" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M40.5 38.5L25.5 23.5" :stroke="color" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
        <filter id="filter0_d_15248_41390" x="0" y="0" width="66" height="66" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="4"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.172549 0 0 0 0 0.203922 0 0 0 0 0.254902 0 0 0 0.1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15248_41390"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15248_41390" result="shape"/>
        </filter>
    </defs>
</svg>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: '#2BAD7E'
            },
            width: {
              type: [Number, String],
              default: 66
            },
            height: {
              type: [Number, String],
              default: 66
            }
        }
    }
</script>
