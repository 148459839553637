import router from '@/routes/routes.js'
import axios from 'axios'
import { isEmpty, get } from 'lodash'
import { ref, onMounted, computed, watch, watchEffect } from 'vue'
import { useProjectData } from '@/components/dashboard/sidebar/useProjectData.js'
import store from '@/store'
import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'

const { strategy } = useHighLevelConcept()
const selectedConcept = ref({})
const collectionsData = ref([])
const systemData = ref([])
const phasesData = ref([])
const concepts = ref([])
const scenarios = ref([])
const modules = ref([])
const horizontalDefinitions = ref({})

export default function useRoadmapConcept(isMounted=false) 
{
    const layoutSize = ref({})
    const { getScenariosByProject, getModulesByProject} = useProjectData()
    
    let timeoutId = null
    const route = router.currentRoute
    const projectId = computed(()=> route.params.id)

    const createConcept = async (title) => {
        let payload = {
            project_id : projectId.value,
            title :  title,
            definitions: [['New Definition', 'New Definition'], ['New Definition', 'New Definition']],
            scenario_settings : {
                property_v_id: null,
                property_h_v_id: null,
                property_h_id: null,
                property_h_p_id: null,
            }
        }

        let { data } = await axios.post(`roadmaps/concepts`, payload).then(({ data }) => data)
        concepts.value = await getConcepts(projectId.value)
        selectedConcept.value = await getConcept(data.id)
    }

    const getConcepts = async (project_id) => {
        let { data } = await axios.get(`roadmaps/concepts/${project_id}`).then(({data}) => data)
        return data
    }

    const getConcept = async (conceptId) => {
        let { data } = await axios.get(`roadmaps/concepts/show/${conceptId}`).then(({data}) => data)
        return data
    }

    const setSelectedConcept = (concept) => {
        let activeProject = store.getters['project/project']
        if(isEmpty(activeProject) && concept) return

        if(!activeProject.settings){
            activeProject.settings = {
                activeConceptId: concept.id
            }
        }else{
            activeProject.settings.activeConceptId = concept.id
        }

        _saveConceptId(activeProject)
        selectedConcept.value = concept
    }

    const _saveConceptId = async (activeProject) => {
        store.commit('project/setProject', activeProject)
        let payload = {
            id: activeProject.id,
            workspace_id: activeProject.workspace_id,
            name: activeProject.name,
            settings: activeProject.settings
        }
        await axios.post(`projects/${projectId.value}/update`, payload)
    }

    const updateConcept = async (selectedConcept) => {
        let { data } = await axios.post(`roadmaps/concepts/update/${selectedConcept.id}`, selectedConcept).then(({data}) => data)
        return data
    }

    const getCollectionsData = async (activeScenarioId, moduleId) => {
        let collectionRes = await axios.get(`communicators/system/${activeScenarioId}/a-level-data?module_id=${moduleId}&with_parameter=1&response_type=nested`)
        if(collectionRes){
            collectionsData.value = collectionRes.concept
        }
    }

    const getSystemData = async () => {
        if(!selectedConcept.value.scenario_id || !selectedConcept.value.module_id) return
        let { data } = await axios.get(`roadmaps/strategies/system-data/${selectedConcept.value.scenario_id}?module_id=${selectedConcept.value.module_id}&synchronized=1&phases=1`).then(({data}) => data)
        if(data){
            systemData.value = data.children
            phasesData.value = data.phases
        }
    }

    const getHorizontalDefinitions = async () => {
        let payload = {
            "parameters": [get(selectedConcept.value, 'scenario_settings.property_h_p_id'), null]
        }

        let { data } = await axios.post(`roadmaps/concepts/definitions`, payload).then(({data}) => data)
        horizontalDefinitions.value = data[0]
    }

    if(isMounted){
        onMounted(async () => {
            scenarios.value = await getScenariosByProject(projectId.value)
            modules.value = await getModulesByProject(projectId.value)
            concepts.value = await getConcepts(projectId.value)

            await store.dispatch('project/getSolidObject', projectId.value)
            let project = store.getters['project/project']
            const { activeConceptId } = project.settings
            if(activeConceptId){
                let { data } = await axios.get(`roadmaps/concepts/show/${activeConceptId}`)
                let concept = data.data
                if(concept){
                    selectedConcept.value = concept
                }
            }
        })
    }

    watch(selectedConcept, () => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            if(selectedConcept.value?.scenario_id && selectedConcept.value?.module_id){
                getCollectionsData(selectedConcept.value.scenario_id, selectedConcept.value.module_id)
            }

            if(selectedConcept.value){
                updateConcept(selectedConcept.value)
            }

            getSystemData()
            getHorizontalDefinitions()
        }, 1500)
    }, {deep: true})

    watchEffect(() => {
        // if hit text and colors is null then set default data
        if(isEmpty(selectedConcept.value?.colors)){
            selectedConcept.value = { colors: [] }
            selectedConcept.value.colors.push('', '', '', '', '', '')
        }

        if(isEmpty(selectedConcept.value.hints_text)){
            selectedConcept.value.hints_text = []
            selectedConcept.value.hints_text.push(
                "Add text",
                "Add text",
                "Add text",
                "Add text"
            )
        }
    })


    const getPosition = (item, pos) => 
    {
        if(isEmpty(strategy.value.parameters)) return 0

        let canvasWidth = layoutSize.value.width;
        let canvasHeight = layoutSize.value.height;
        
        let tempX = 0;
        let tempY = 0;

        let hr_property_collection_id = get(strategy.value, 'parameters[0].id')
        let vr_property_collection_id = get(strategy.value, 'secondary_parameters[0].id')
        
        tempX = item.properties.find((hr) =>
            parseInt(hr.property_collection_id) === parseInt(hr_property_collection_id)
        );

        tempY = item.properties.find((vr) =>
            parseInt(vr.property_collection_id) === parseInt(vr_property_collection_id)
        );


        let x = tempX ? tempX.sample_value : 0;
        let y = tempY ? tempY.sample_value : 0;
        let currentLayout = [canvasWidth, canvasHeight];
        let xy = [
          (currentLayout[0] / 10) * x,
          (currentLayout[1] / 10) * y,
        ];


        return xy[pos];
    }


    let printCircles = (collections, createCircle, createLine, parents=[], item=null) => {
        if(!Array.isArray(collections)) return
        collections.forEach(l1 => {
            if(!item || parents.includes(l1.id) || item.id == l1.id){
                let circle1 = createCircle({
                    title: l1.title,
                    circleText: l1.index_code,
                    levelData: l1,
                    parents: [],
                    left: getPosition(l1, 0),
                    bottom: getPosition(l1, 1),
                    radius: 40,
                    background: l1.background_color
                })
    
                l1.children.forEach(l2 => {
                    if(!item || parents.includes(l2.id) || item.id == l2.id || l1.id == item.id){
                        let circle2 = createCircle({
                            title: l2.title,
                            circleText: l2.index_code,
                            levelData: l2,
                            parents: [l1.id],
                            left: getPosition(l2, 0),
                            bottom: getPosition(l2, 1),
                            radius: 35,
                            background: l2.background_color
                        })
                        
                        createLine([
                            circle1.left + 40,
                            circle1.top + 40,
                            circle2.left + 35,
                            circle2.top + 35
                        ])
        
                        l2.children.forEach(l3 => {
                            if(!item || parents.includes(l3.id) || item.id == l3.id || l2.id == item.id || l1.id == item.id){
                                let circle3 = createCircle({
                                    title: l3.title,
                                    circleText: l3.index_code,
                                    levelData: l3,
                                    parents: [l1.id, l2.id],
                                    left: getPosition(l3, 0),
                                    bottom: getPosition(l3, 1),
                                    radius: 30,
                                    background: l3.background_color
                                })
                                createLine([
                                    circle2.left + 35,
                                    circle2.top + 35,
                                    circle3.left + 30,
                                    circle3.top + 30,
                                ])
            
            
                                l3.children.forEach(l4 => {
                                    if(!item || parents.includes(l4.id) || item.id == l4.id || l3.id == item.id || l2.id == item.id || l1.id == item.id){
                                        let circle4 = createCircle({
                                            title: l4.title,
                                            circleText: l4.index_code,
                                            levelData: l4,
                                            parents: [l1.id, l2.id, l3.id],
                                            left: getPosition(l4, 0),
                                            bottom: getPosition(l4, 1),
                                            radius: 25,
                                            background: l4.background_color
                                        })
                
                                        createLine([
                                            circle3.left + 30,
                                            circle3.top + 30,
                                            circle4.left + 25,
                                            circle4.top + 25,
                                        ])
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }


    return {
        route,
        scenarios,
        modules,
        createConcept,
        concepts,
        selectedConcept,
        collectionsData,
        systemData,
        getSystemData,
        horizontalDefinitions,
        phasesData,
        setSelectedConcept,
        getPosition,
        layoutSize,
        printCircles,
        updateConcept
    }
}