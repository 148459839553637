import { unref, set, watchEffect, isRef } from 'vue'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { random } from 'lodash'

export default function useHighlevel(activeTemplate) {

    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getTemplateBorderColor } = useTemplate();

    const setInitialData = () => {
        const template = unref(activeTemplate)
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
            template.customized_data.title = 'Heading'
            template.customized_data.subtitle = 'OKR canvas 2023'
        }
        let isContents = "contents" in template.customized_data;
        let isItems = "items" in template.customized_data;
        if(!isContents){
            set(template.customized_data, 'contents', _makeContents())
            updateActiveTemplate()
        }
        if(!isItems){
            set(template.customized_data, 'items', _makeItems())
            updateActiveTemplate()
        }
    }

    const updateActiveTemplate = () => updateTemplate(activeTemplate.value)

    const _makeItems = () => {
        let items = []
        items.push({
            title: 'Objective',
            description: 'Lorem ipsum dolor sit amet  elit. Aenean bibendum. Lorem ipsu',
            bgColor: '#fff',
            children: _makeChildren()
        })
        return items
    }

    const _makeChildren = () => {
        let children = []
        for(let i=0; i<3; i++) {
            children.push([{
                id: random(3001,6000),
                title: 'Objective',
                description: 'Lorem ipsum dolor sit amet  elit. Aenean bibendum. Lorem ipsu',
            }])
        }
        return children
    }
    
    const _makeContents = () => {
        let contents = []
        for(let i =0; i<3; i++){
            contents.push({
                objective: {
                    letter: 'O',
                    text: 'Objective',
                    bgColor: '#2B80AD',
                },
                bgColor: '#fff',
            })
        }
        return contents
    }

    const createItem = (index) => {
        const template = unref(activeTemplate)
        template.customized_data.items.splice(index, 0, {
            title: 'Objective',
            description: 'Lorem ipsum dolor sit amet  elit. Aenean bibendum. Lorem ipsu',
            bgColor: '#fff',
            children: _makeChildren()
        })
        updateActiveTemplate()
    }
    const removeItem = (index) => {
        const template = unref(activeTemplate)
        template.customized_data.items.splice(index, 1)
        updateActiveTemplate()
    }
    const createChild = (items, index) => {
        const template = unref(activeTemplate)
        items.splice(index, 0, {
            title: 'Objective',
            description: 'Lorem ipsum dolor sit amet  elit. Aenean bibendum. Lorem ipsu',
            bgColor: '#fff',
        })
        updateActiveTemplate()
    }
    const removeChild = (items, index) => {
        const template = unref(activeTemplate)
       items.splice(index, 1)
       updateActiveTemplate()
    }
    const getBoxStyle = (type, index) => {
        if(type === 'content') {
            return {
                width: '1378px',
                height: index == 1 ?  '261px' : '190px',
                borderRadius: '20px',
            }
        }
    }

    if(isRef(activeTemplate)) {
        watchEffect(setInitialData)
    }

    return {
        updateActiveTemplate,
        getDataLayerItem,
        prepareConfiguration, 
        prepareRequest, 
        getPersistance,
        getTemplateBorderColor,
        getBoxStyle,
        createItem,
        removeItem,
        createChild,
        removeChild
    }
}