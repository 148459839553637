<template>
    <div>
        <div class="bg-primary-three border border-secondary-four rounded px-2.5 pb-3 py-2">
            <textarea 
                placeholder="Write your reply here"
                class="bg-transparent w-full text-secondary-two placeholder-secondary-two fs-12"
                spellcheck="false"
                v-model="formForComment.text"
            >
            </textarea>
        </div>
        <div class="flex gap-2 justify-end mt-2">
            <button @click="handleComment(formForComment); $emit('close');" class="fs-10 fw-600 text-primary-four">Send</button>
            <button @click="$emit('close')" class="fs-10 fw-600 text-red-500">Cancel</button>
        </div>
    </div>
</template>

<script setup>
import { watchEffect } from 'vue'
import useComment from './useComment'

const props = defineProps({
    item: Object,
    comment_id: [String, Number],
    handleComment: Function
})


const {
    formForComment
} = useComment(props.item)

watchEffect(() => {
    formForComment.value.comment_id = props.comment_id
})
</script>