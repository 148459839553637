var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex gap-2 pt-1 justify-between border-b border-secondary-four",staticStyle:{"height":"102px"}},[_c('div',{staticClass:"grid gap-5 w-full",style:({
              gridTemplateColumns: 
              _vm.from == 'progress' ? `42px 225px 86px 63px 81px 1fr`
              : '62px 243px 119px 83px 1fr'
          })},[_c('div',{staticClass:"flex items-center flex-shrink-0 h-full ml-3"},[_c('Flag',{staticClass:"w-4 h-4 cursor-pointer",style:({color: _setup.get(_setup.priorities[_vm.item.priority], 'color')}),nativeOn:{"click":function($event){return _setup.updateStatus(_vm.item.priority)}}})],1),_c('div',{staticClass:"flex-shrink-0",class:_vm.usedIn != 'progress' ? 'flex items-center' : ''},[(_vm.usedIn=='progress')?_c('p',{staticClass:"whitespace-nowrap mt-2 fs-11 fw-400 text-secondary-two"},[_c('span',{staticClass:"fw-800",style:({color: _vm.item.progress_achieve_color})},[_vm._v(" "+_vm._s(_vm.item.weight_progress_towards_target)+"%")]),_vm._v(" "+_vm._s(_vm.item.progress_goal_headline)+" ")]):_vm._e(),_c('div',{staticClass:"flex gap-1 items-center mt-2"},[_c('h2',{staticClass:"fs-12 fw-700"},[_vm._v(_vm._s(_vm.item.index_code))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.title),expression:"item.title"}],staticClass:"fs-12 fw-500 w-52 bg-transparent",staticStyle:{"height":"30px"},attrs:{"disabled":_vm.item.type == 1},domProps:{"value":(_vm.item.title)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.item, "title", $event.target.value)},() => {
                          _setup.hasUnSaveItem = true
                          _setup.transformGoal(_setup.goals, _setup.showActiveOnly)
                      }]}})])]),_c(_setup.ConfigureButton,{attrs:{"item":_vm.item,"goalId":_vm.goalId}}),_c('div',{staticClass:"flex items-center flex-shrink-0 justify-center h-full"},[_c('img',{staticClass:"rounded-full w-10 h-10 object-cover border-2 border-white shadow-two cursor-pointer",attrs:{"src":_setup.getOwnerImage(_vm.item),"alt":""},on:{"click":function($event){_setup.showModal = true}}})]),(_vm.from == 'progress')?_c('div',{staticClass:"flex items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.baseline),expression:"item.baseline"}],staticClass:"w-20 bg-primary-three text-center fs-12 flex items-center gap-2 px-2 border border-secondary-four rounded",staticStyle:{"height":"30px"},domProps:{"value":(_vm.item.baseline)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.item, "baseline", $event.target.value)},() => {
                      _setup.hasUnSaveItem = true
                      _setup.transformGoal(_setup.goals, _setup.showActiveOnly)
                  }]}})]):_vm._e(),_c('div',{staticClass:"w-full flex items-center fs-12 justify-center"},[_vm._t("progress",null,{"item":_vm.item})],2)],1),_c('div',{staticClass:"grid gap-4 pr-2 ml-1",staticStyle:{"grid-template-columns":"89px 71px"}},[_c('div',{staticClass:"w-96px flex items-center flex-shrink-0 gap-1 justify-center flex-col relative"},[_c('p',{staticClass:"fs-10 fw-500 absolute top-4"},[_vm._v(" Progress: "+_vm._s(_vm.item.weight_progress_towards_target)+"% ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.goal),expression:"item.goal"}],staticClass:"w-20 bg-primary-three text-center fs-12 flex items-center gap-2 px-2 border border-secondary-four rounded",staticStyle:{"height":"30px"},domProps:{"value":(_vm.item.goal)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.item, "goal", $event.target.value)},() => {
                      _setup.hasUnSaveItem = true
                      _setup.transformGoal(_setup.goals, _setup.showActiveOnly)
                  }]}})]),_c('div',{staticClass:"flex items-center flex-shrink-0 gap-3 justify-end"},[_c('label',{staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.weight),expression:"item.weight"}],staticClass:"w-12 bg-primary-three fs-12 flex pr-4 text-right border border-secondary-four rounded",staticStyle:{"height":"30px"},domProps:{"value":(_vm.item.weight)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.item, "weight", $event.target.value)},() => {
                          _setup.hasUnSaveItem = true
                          _setup.transformGoal(_setup.goals, _setup.showActiveOnly)
                      }]}}),_c('span',{staticClass:"absolute right-2 fs-12 fw-500",staticStyle:{"top":"5px"}},[_vm._v("%")])]),_c(_setup.TrashIcon,{staticClass:"cursor-pointer flex-shrink-0",nativeOn:{"click":function($event){return _vm.$emit('onDelete')}}})],1)]),_c(_setup.Modal,{model:{value:(_setup.showModal),callback:function ($$v) {_setup.showModal=$$v},expression:"showModal"}},[_c(_setup.UserList,{attrs:{"item":_vm.item,"goalId":_vm.goalId,"updateActivity":_setup.updateActivity,"updateGoalObjectForSpecificId":_setup.updateGoalObjectForSpecificId},on:{"close":function($event){_setup.showModal = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }