import axios from "axios"
import { ref } from "vue"
import { useGoal } from "@/views/screens/dashboard/useGoal.js"
import { sum } from 'lodash'

const outcomes = ref([])
export default function useOutcome()
{
    const { goals } = useGoal()
    const getTotalOutcome = (goal) => {
        const outcomes = goal.value_drivers.map(item => {
            return item.per_woptt * goal.weight_progress_towards_target
        })
        goal.total_outcome = sum(outcomes)
    }

    let timeoutId = null
    const loadOutcome = async (goal_id) => {
        const selectedGoal = goals.value.find(goal => goal.id == goal_id)
        let data = await axios.get(`workspaces/goals/outcomes/${goal_id}`).then(({data}) => data)
        if(data.status){
            outcomes.value = data.data

            selectedGoal.value_drivers = outcomes.value
            getTotalOutcome(selectedGoal)
            return
        }
        outcomes.value = []
        selectedGoal.value_drivers = outcomes.value
        getTotalOutcome(selectedGoal)
    }
    const createOutcome = async (goal_id, key_activity_id) => {
        const defaultData = {
            goal_id: goal_id,
            key_activity_id: key_activity_id,
            title: "Driver 1",
            equivalent_per_percent: 100,
            equivalent_per_percent_unit: "MAUS",
            expected_roi: 50,
            value_per_unit: 50,
            value_unit: "USD",
            exg_value_pool: 80,
            weight: 100
        }

        let data = await axios.post(`workspaces/goals/outcomes/create`, defaultData).then(({data}) => data)
        if(data.status){
            loadOutcome(goal_id)
        }
    }
    const updateOutcome = (item) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(async () =>{
            let data = await axios.post(`workspaces/goals/outcomes/${item.id}/update`, item).then(({data}) => data)
            if(data.status){
                loadOutcome(item.goal_id)
            }
        }, 1000)
    }
    const deleteOutcome = (id, goal_id) => {
        axios.delete(`workspaces/goals/outcomes/${id}`)
        loadOutcome(goal_id)
    }

    return {
        outcomes,
        loadOutcome,
        createOutcome,
        updateOutcome,
        deleteOutcome
    }
}