<template>
    <div class="flex-shrink-0 rounded-lg relative picture_template">
        <slot />
        <div class="content h-full"></div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { get, isEmpty } from 'lodash'
    import useBoxConcept from '../useBoxConcept'
    import { useFile } from '@/composable/useFile.js'

    const { updateImage } = useBoxConcept()
    const { onChange, file } = useFile()
    
    const props = defineProps({
        activeTemplate: Object,
        config_visibility: Boolean,
        switchSidebarComponent: Function,
        childTemplateType: String,
        box_type: String,
        synchronize: Boolean,
    })
</script>


<style>
    .bg_border_controller_wrapper {
        position: absolute;
        left: 0;
    }
</style>