import { unref, set, watchEffect, isRef } from 'vue'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { random } from 'lodash'

export default function useOkrConcept(activeTemplate) {

    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getTemplateBorderColor } = useTemplate();
    const onChangeConfig = (config) => updateTemplate(activeTemplate.value)

    const setInitialData = () => {
        const template = unref(activeTemplate)
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
            template.customized_data.title = 'Heading'
            template.customized_data.subtitle = 'OKR canvas 2023'
        }
        let isContents = "contents" in template.customized_data;
        let checkConfig = "styleConfig" in template.customized_data;
        if(!isContents){
            set(template.customized_data, 'contents', _makeContents())
            updateActiveTemplate()
        }
        if(!checkConfig) {
            set(template.customized_data, 'styleConfig', {
                background_image: '',
                background_image_opacity: 100,
                background_color: '#FBF9F1',
                background_color_opacity: 100
            })
        }
       
    }

    const updateActiveTemplate = () => updateTemplate(activeTemplate.value)

    const _makeContents = () => {
        let items = []
        for(let i =0; i<3; i++){
            items.push({
                objective: {
                    letter: 'O',
                    text: 'Objective',
                    bgColor: '#2B80AD',
                },
                id: random(1000, 5554),
                title: 'Key Result',
                bgColor: '#2B80AD',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum. Lorem ipsu'
            })
        }
        return items
    }

    const getBoxStyle = (type, index) => {
        if(type === 'content') {
            return {
                width: '735px',
                height: index == 1 ?  '261px' : '190px',
                borderRadius: '20px',
            }
        }
    }

    if(isRef(activeTemplate)) {
        watchEffect(setInitialData)
    }

    return {
        updateActiveTemplate,
        getDataLayerItem,
        prepareConfiguration, 
        prepareRequest, 
        getPersistance,
        getTemplateBorderColor,
        getBoxStyle,
        onChangeConfig
    }
}