import { unref, set, watchEffect, isRef } from 'vue'
import { get } from 'lodash'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { items } from './itemData'

export default function useIntroduction(activeTemplate) {

    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getTemplateBorderColor } = useTemplate();

    const getContentBgStyle = (i) => {
        if(i == 0) return
        const template = unref(activeTemplate)
        const {borderColor} =  template.settings
        return {
            backgroundColor: borderColor,
        }
    }

    const addTimelineItem = (items, index) => {
        items.splice(index+1, 0, {image: null, text: 'Founded in 1996'})
        updateActiveTemplate()
    }
    const removeTimelineItem = (items, index) => {
        items.splice(index, 1)
        updateActiveTemplate()
    }

    const setInitialData = () => {
        const template = unref(activeTemplate)
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
        }
        let isItems = "items" in template.customized_data;
        if(!isItems){
            set(template.customized_data, 'items', items)
            updateActiveTemplate()
        }
    }

    const updateActiveTemplate = () => updateTemplate(activeTemplate.value)

    if(isRef(activeTemplate)) {
        watchEffect(setInitialData)
    }

    return {
        updateActiveTemplate,
        getContentBgStyle,
        getDataLayerItem,
        prepareConfiguration, 
        prepareRequest, 
        getPersistance,
        addTimelineItem,
        removeTimelineItem,
        getTemplateBorderColor
    }
}