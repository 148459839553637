<template>
    <div v-if="!isEmpty(currentItem)" class="fs-14 fw-500 text-primary-one flex gap-4 items-center w-full">
        <p class="w-full">{{ currentItem?.action_text }}</p>
    </div>
</template>

<script setup>
    import { computed, onMounted, onUnmounted, ref } from 'vue'
    import { isEmpty } from 'lodash'

    const animationTime = 3000
    let intervalId = null
    const currentIndex = ref(0)
    const props = defineProps({
        activities: Array
    })
    const currentItem = computed(() => {
        if(props.activities){
            return props.activities[currentIndex.value]
        }
    })

    const handleText = () => {
        if(currentIndex.value >= 0 && currentIndex.value < (props.activities.length - 1)){
            currentIndex.value++
            return
        }

        currentIndex.value = 0
    }

    onMounted(() => {
        intervalId = setInterval(handleText, animationTime)
    })

    onUnmounted(() => {
        clearInterval(intervalId)
    })
</script>
