import { computed, unref, set, watchEffect, isRef } from 'vue'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { getCreateData } from './management.data';
import { get } from 'lodash'

export default function useOkrManagement(activeTemplate) {

    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getTemplateBorderColor, getRootValue, getParamForAlevelAPI } = useTemplate();

    const data = computed(() => get(activeTemplate.value, 'customized_data.contents', []))

    const updateActiveTemplate = () => updateTemplate(activeTemplate.value)

    const setInitialData = () => {
        const template = unref(activeTemplate)
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
            template.customized_data.title = 'Heading'
            template.customized_data.subtitle = 'OKR canvas 2023'
        }
        let isContents = "contents" in template.customized_data;
        if(!isContents){
            set(template.customized_data, 'contents', [])
            createItem(0) //0 in index
            updateActiveTemplate()
        }
        
    }

    const createItem = (index) => {
        activeTemplate.value.customized_data.contents.splice(index, 0, getCreateData())
    }
    const removeItem = (index) => {
        activeTemplate.value.customized_data.contents.splice(index, 1)
    }

    const getNote = (fourthLevelId) => {
        const template = unref(activeTemplate)
        let isNotes = template.customized_data.notes
        if (!isNotes || (template.customized_data.notes.constructor == Array)) {
            set(template.customized_data, 'notes', {})
        }
        const note = get(template.customized_data, `notes[${fourthLevelId}]`)
        if(!note) {
            set(template.customized_data.notes, `${fourthLevelId}`, {
                text: '',
            })
        }
        return get(template.customized_data, `notes[${fourthLevelId}]`)
    }

    if(isRef(activeTemplate)) {
        watchEffect(setInitialData)
    }

    return {
        updateActiveTemplate,
        getDataLayerItem,
        prepareConfiguration, 
        prepareRequest, 
        getPersistance,
        getTemplateBorderColor,
        getRootValue, 
        getParamForAlevelAPI,
        createItem,
        removeItem,
        getNote,
        data
    }
}