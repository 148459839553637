<template>
    <div class="i-mt-28">
        <Accordion
            title="Content Configuration"
            :active="true"
            bg="transparent"
            classes="shadow-none"
            titleClasses="text-primary-one fs-14"
            iconClasses="w-6 h-6"
            buttonClasses="i-right-n-2 i-top-0"
            accordionClasses="border-none"
            align="left"
        >
        <div>
            <InputSelect
                :items="rootDataSet"
                label="Select source"
                v-model="configuration.rootType"
            />
            <InputSelect
                v-if="configuration.rootType !== 'null' && configuration.rootType !== 'module'"
                :items="scenarios"
                label="Select scenario"
                v-model="configuration.scenario_id"
            />
            <InputSelect
                v-if="configuration.scenario_id !== 'null' || configuration.rootType === 'module'"
                :items="modules"
                label="Select module"
                v-model="configuration.module_id"
                @change="selectModule"
            />
            <!-- If rootType module so scenario will have to show after module dropdown -->
            <InputSelect
                v-if="configuration.module_id !== 'null' && configuration.rootType == 'module'"
                :items="scenarios"
                label="Select scenario"
                v-model="configuration.scenario_id"
                @change="selectModuleAfterScenario"
            />

            <div v-if="configuration.rootType >= 1 && configuration.module_id !== 'null'">
            <select class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                    v-model="configuration.first_level_id" @change="setLevelOne">
                <option value="null" selected disabled>Select Level 1</option>
                <option v-for="(l1, l1Index) in collections" :key="l1Index" :value="l1.id">{{ l1.title }}</option>
            </select>

            <div v-if="configuration.rootType >= 2 && configuration.first_level_id !== 'null'">
                <select class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                        v-model="configuration.second_level_id" @change="setLevelTwo">
                <option value="null" selected disabled>Select Level 2</option>
                <option v-for="(l2, l2Index) in l1Data" :key="l2Index" :value="l2.id">{{ l2.title }}</option>
                </select>

                <div v-if="configuration.rootType >= 3 && configuration.second_level_id !== 'null'">
                <select class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                        v-model="configuration.third_level_id" @change="setLevelThree">
                    <option value="null" selected disabled>Select Level 3</option>
                    <option v-for="(l3, l3Index) in l2Data" :key="l3Index" :value="l3.id">{{ l3.title }}</option>
                </select>

                <div v-if="configuration.rootType >= 4 && configuration.third_level_id !== 'null'">
                    <select class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                            v-model="configuration.fourth_level_id">
                    <option value="null" selected disabled>Select Level 4</option>
                    <option v-for="(l4, l4Index) in l3Data" :key="l4Index" :value="l4.id">{{ l4.title }}</option>
                    </select>
                </div>
            </div>
          </div>
        </div>
      </div>
    </Accordion>
  </div>
</template>

<script setup>
    import Accordion from "@/elements/atom/Accordion.vue";
    import InputSelect from '@/components/communicator/v2/sidebar/components/InputSelect';
    import { useSidebar } from '@/views/screens/communicator/useSidebar.js';
    import { useTemplate } from '@/views/screens/communicator/useTemplate.js';
    import { useConfiguration } from '@/views/screens/communicator/useConfiguration.js';
    import useModule from '@/composable/useModule.js';
    import { ref, watch, watchEffect } from 'vue';
    import { isEmpty } from 'lodash';
    import router from '@/routes/routes.js';
    const route = router.currentRoute;

    const rootDataSet = [
        {column: 'module_id', storeKey: 'module_id', action: 'getTitleFromModule', id: 'module', title: 'Module'},
        {column: 'level_id', storeKey: 'first_level_id', action: 'getTitleFromFirstLevel', id: 1, title: 'Group Level 1'},
        {column: 'level_id', storeKey: 'second_level_id', action: 'getTitleFromSecondLevel', id: 2, title: 'Group Level 2'},
        {column: 'level_id', storeKey: 'third_level_id', action: 'getTitleFromThirdLevel', id: 3, title: 'Group Level 3'},
        {column: 'level_id', storeKey: 'fourth_level_id', action: 'getTitleFromFourthLevel', id: 4, title: 'Group Level 4'}
    ];

    const { getLevelDataByScenarioAndModule } = useSidebar();
    const { removeIfExist, getPersistance } = useTemplate();
    const { l1Data, l2Data, l3Data, collections, scenarios, setLevelOne, setLevelTwo, setLevelThree, prepareLevelData } = useConfiguration();
    const { getModuleIdFromDataLayer } = useModule();

    const props = defineProps({
        template: Object,
        modules: Array,
        setActiveModule: Function,
        index: Number
    });

    const emit = defineEmits(['updateTemplate']);

    const configuration = ref({});

    watch(configuration, () => {
        // props.template.customized_data.contents[props.index].data_layer.splice(0, 1, {
        props.template.customized_data.contents[props.index].data_layer[0] = {
            conf_id: 'data_source',
            persistance: {
                ...configuration.value
            }
        }
        emit("updateTemplate");
    }, {deep: true});

    const resetConfiguration = () => {
       configuration.value = {
            rootType: 'null',
            scenario_id: 'null',
            module_id: 'null',
            first_level_id: 'null',
            second_level_id: 'null',
            third_level_id: 'null',
            fourth_level_id: 'null',
        }
    }
    const selectModule = async (moduleId) => {
        let module = props.modules.find(item => item.id == moduleId);
        if(configuration.value.rootType !== 'module') {
            props.setActiveModule(module);
        }
        if(!isNaN(parseInt(configuration.value.rootType))) {
            collections.value = await getLevelDataByScenarioAndModule(configuration.value.scenario_id, moduleId, 'nested');
        }
    }

    const selectModuleAfterScenario = () => {
        let module = props.modules.find(item => item.id == configuration.value.module_id);
        props.setActiveModule(module);
    }

    watchEffect(async () => {
        let persistance = getPersistance(props.template.customized_data.contents[props.index], 'data_source')
        prepareLevelData(persistance, route.params.id);
        let module = props.modules.find(item => item.id == getModuleIdFromDataLayer(props.template.customized_data.contents[props.index]));
        props.setActiveModule(module);
        if(!isEmpty(persistance)) {
            configuration.value = persistance;
            return;
        }
        resetConfiguration();
    });

</script>
