export default function useBlob() {
    const blobCreationFromURL = (inputURL) => {
        const blobBin = atob(inputURL.split(',')[1]);
        const array = [];
        for(var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: 'image/png'});
    }

    return {
        blobCreationFromURL
    }
}