import { ref } from 'vue';
import { useSidebar } from '@/views/screens/communicator/useSidebar.js';

export const useConfiguration = () => {
    const { getScenariosByProject, getLevelDataByScenarioAndModule } = useSidebar();

    const l1Data = ref([]);
    const l2Data = ref([]);
    const l3Data = ref([]);
    const collections =  ref([]);
    const scenarios =  ref([]);


    const setLevelOne = (e) => {
        let { child } = collections.value.find(item => item.id == e.target.value);
        l1Data.value = child || [];
    }
    const setLevelTwo = (e) => {
        let { child } = l1Data.value.find(item => item.id == e.target.value);
        l2Data.value = child || [];
    }
    const setLevelThree = (e) => {
        let { child } = l2Data.value.find(item => item.id == e.target.value);
        l3Data.value = child || [];
    }

    const prepareLevelData = async (configuration, projectId) => {
        scenarios.value = await getScenariosByProject(projectId);
        if(configuration?.module_id) {
            collections.value = await getLevelDataByScenarioAndModule(configuration.scenario_id, configuration.module_id);
        }
        if(configuration?.first_level_id) {
            let l1 = collections.value.find(item => item.id == configuration?.first_level_id);
            l1Data.value = l1?.child || [];
            if(l1Data.value.length > 0) {
                let l2 = l1Data.value.find(item => item.id == configuration?.second_level_id);
                l2Data.value = l2?.child || [];
                if(l2Data.value.length > 0) {
                   let l3 = l2Data.value.find(item => item.id == configuration?.third_level_id);
                   l3Data.value = l3?.child || [];
                }
            }
        }
    }

    return {
        l1Data,
        l2Data,
        l3Data,
        scenarios,
        collections,

        setLevelOne,
        setLevelTwo,
        setLevelThree,
        prepareLevelData
    }
}