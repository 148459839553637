import { getUnixTimeStamp } from '@/plugins/lodash'

export const items = [
    {
        title: {
            id: getUnixTimeStamp(),
            value: 'IMPLEMENT CONSULTING GROUP'
        },
        subTitle: {
            id: getUnixTimeStamp()+1,
            value: 'Create interactive data driven experiences'
        },
        description: {
            id: getUnixTimeStamp()+2,
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum. Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur dolor vitus dolor.'
        },
        contentTitle: {
            id: getUnixTimeStamp()+3,
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum. Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur dolor vitus dolor.'
        },
        content: 'Lorem ipsum',
        items: [
            {
                image: null,
                text: 'Founded in 1969'
            }
        ]
    },
    {
        title: {
            id: getUnixTimeStamp()+7,
            value: 'INSTRAT TECHNOLOGY'
        },
        subTitle: {
            id: getUnixTimeStamp()+4,
            value: 'Create interactive data driven experiences'
        },
        description: {
            id: getUnixTimeStamp()+5,
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum. Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur dolor vitus dolor.'
        },
        contentTitle: {
            id: getUnixTimeStamp()+6,
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum. Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur dolor vitus dolor.'
        },
        content: 'Lorem ipsum',
        items: [
            {
                image: null,
                text: 'Founded in 1969'
            }
        ]
    }
]