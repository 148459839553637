<template>
    <div :style="{...columnStyle.box, backgroundColor: item.bgColor}" class="relative">
        <div class="rounded" :style="{color: headerTextColor,backgroundColor: headerBg}">
            <input
                :style="{...columnStyle.input}"
                @input="updateActiveTemplate"
                class="bg-transparent h-10 px-6"
                v-model="item.title"
                placeholder="Add Text"
                :disabled="isDisabled" 
                type="text"
            >
        </div>
        <textarea
            :style="{...columnStyle.textarea}"
            v-model="item.description"
            :disabled="isDisabled"
            @input="updateActiveTemplate"
            placeholder="Add Text"
            class="bg-transparent mt-2 ml-7"
        ></textarea>
        <ColorIcon
            style="bottom: 12px; right: 20px;"
            :class="{'invisible': !showIcon}"
            @click.native="clickThColorPicker"
            class="w-4 h-4 absolute cursor-pointer"
        />
        <input
            @input="updateActiveTemplate"
            ref="colorPickerElem" 
            type="color" 
            v-model="item.bgColor"
            class="absolute invisible"
            style="bottom: 13px; right: 10px;"
        />
    </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
    item: Object,
    columnStyle: Object,
    headerBg: String,
    headerTextColor: String,
    showIcon: Boolean,
    isDisabled: Boolean,
    updateActiveTemplate: Function,
})

const colorPickerElem = ref()
const clickThColorPicker = () => colorPickerElem.value.click()
</script>