<template>
    <div class="flex flex-col layoutTwoBySevenWrapper">
        <div class="grid border-2 borderColor rounded">
            <div class="flex">
                <div class="topInner h-418px relative col flex-grow-0 flex-1">
                    <LayoutComponent 
                        class="layoutWrapper relative i-p-15 h-full border borderColor  flex-1"
                        style="--backgroundImage:url(\/img\/2x7-1\.e31be77c\.webp); --opacityForImg:calc(1 / 100 * 5);"
                        :category="getCategoryData('key-partner')" 
                    />
                </div>

                <div class="topInner relative col flex-grow-0 flex flex-col flex-1">
                    <div class="h-full">
                        <LayoutComponent 
                            class="layoutWrapper relative i-p-15 h-full border borderColor  flex-1"
                            :category="getCategoryData('key-activity')" 
                        />
                    </div>

                    <div class="h-full">
                        <LayoutComponent 
                            class="layoutWrapper relative i-p-15 h-full border borderColor  flex-1"
                            :category="getCategoryData('key-resource')" 
                        />
                    </div>
                </div>

                <div class="topInner col flex-grow-0 flex-1">
                    <LayoutComponent 
                        class="layoutWrapper relative i-p-15 h-full border borderColor  flex-1"
                        style="--backgroundImage:url(\/img\/2x7-1\.e31be77c\.webp); --opacityForImg:calc(1 / 100 * 5);"
                        :category="getCategoryData('value-proportion')" 
                    />
                </div>

                <div class="topInner col flex-grow-0 flex flex-col flex-1">
                    <div class="h-full">
                        <LayoutComponent 
                            class="layoutWrapper relative i-p-15 h-full border borderColor  flex-1"
                            :category="getCategoryData('customer-relationship')" 
                        />
                    </div>
                    <div class="h-full">
                        <LayoutComponent 
                            class="layoutWrapper relative i-p-15 h-full border borderColor  flex-1"
                            :category="getCategoryData('channel')" 
                        />
                    </div>
                </div>

                <div class="topInner col flex-grow-0 flex-1" >
                    <LayoutComponent 
                        class="layoutWrapper relative i-p-15 h-full border borderColor  flex-1"
                        style="--backgroundImage:url(\/img\/2x7-1\.e31be77c\.webp); --opacityForImg:calc(1 / 100 * 5);"
                        :category="getCategoryData('customer-segment')" 
                    />
                </div>
            </div>

            <div class="flex flex-grow-0 h-310px">
                <div class="w-1/2">
                    <LayoutComponent 
                        class="relative i-p-15 h-full border borderColor  flex-1 layoutWrapper" 
                        style="--backgroundImage:url(\/img\/2x7-4\.9bff292f\.webp); --opacityForImg:calc(1 / 100 * 5);"
                        :category="getCategoryData('cost-structure')" 
                    />
                </div>
                <div class="w-1/2">
                    <LayoutComponent 
                        class="relative i-p-15 h-full border borderColor  flex-1 layoutWrapper" 
                        style=" --backgroundImage:url(\/img\/2x7-5\.f0c84bde\.webp); --opacityForImg:calc(1 / 100 * 5);"
                        :category="getCategoryData('revenue-stream')" 
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import LayoutComponent from '@/components/communicator/v2/templates/canvas/components/LayoutComponent.vue';
    const props = defineProps(['gridLayout'])
    const getCategoryData = (category) => {
        if(!props.gridLayout){ return Object(null) }
        const { layout_grid_category: categoryObjects } = props.gridLayout;

        if(!categoryObjects) return Object(null);
        return categoryObjects.find((item)=> item.category === category);
    }
</script>

<style scoped>
    .layoutTwoBySevenWrapper{
        width: 1599px;
        margin-left: 71px;
        overflow: auto;
    }
    .layoutWrapper::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: var(--backgroundImage);
        opacity: var(--opacityForImg);
        pointer-events: none;
    }
    .i-left-panel+div>.i-right-panel .layoutTwoBySevenWrapper{
        min-width: unset;
    }
    .topInner{
        flex: 1;
    }
    .list{
        list-style: disc;
        display: grid;
        gap: 15px;
        margin-left: 20px;
        font-size: 16px;
        font-weight: 300px;
        color: #1C1C1D;
    }

    .borderColor{
        border-color: var(--borderColor)
    }
</style>