<template>
    <div :style="{...boxStyle, backgroundColor: item.bgColor}" class="border px-3 pt-4 pb-5 relative">
        <div  class="flex items-center gap-x-2">
            <AttachmentIcon
                @click.native="$emit('onConfigureList', {'itemIndex': index})"
                :class="{'invisible': !showAttachment && item.attachable}"
                class="w-5 h-5 flex-shrink-0 cursor-pointer mt-2"
            />
            <input
                v-model="item.result.title"
                type="text"
                class="bg-transparent fs-24 fw-700 w-full"
                placeholder="Add text"
                @input="updateActiveTemplate"
                :disabled="isDisabled"
            >
        </div>
        
        <div style="height: 400px" class="mt-1 ml-8 overflow-y-auto fs-16 space-y-5">
            <p v-for="(item, i) in item.result.list" :key="i">{{ item }}</p>
        </div>

        <ColorIcon
            style="bottom: 12px; right: 20px;"
            :class="{'invisible': !showIcon}"
            @click.native="clickThColorPicker"
            class="w-4 h-4 absolute cursor-pointer"
        />
        <input
            @input="updateActiveTemplate"
            ref="colorPickerElem" 
            type="color" 
            v-model="item.bgColor"
            class="absolute invisible"
            style="bottom: 13px; right: 10px;"
        />
    </div>
</template>

<script setup>
import { ref } from 'vue'


const props = defineProps({
    item: Object,
    boxStyle: Object,
    showAttachment: Boolean,
    showIcon: Boolean,
    isDisabled: Boolean,
    templateMethods: Object,
    showConfiguration: Function,
    activeTemplate: Object,
    index: Number
})
const {
    updateActiveTemplate,
} = props.templateMethods

const colorPickerElem = ref()
const clickThColorPicker = () => colorPickerElem.value.click()
</script>