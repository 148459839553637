<template>
  <textarea
    focused="true"
    ref="input"
    @blur="$emit('blur', localValue)"
    :style="{
      fontSize: `${fontSize}px`,
      color: color,
      letterSpacing: `${spacing}em`,
      height: getHeight()
    }"
    class="bg-transparent placeholder-secondary-five"
    :class="[isBlock ? 'block' : '', classes]"
    type="text"
    v-model="localValue"
    :placeholder="placeholder"
    :disabled="isDisable"
  ></textarea>
</template>

<script>
import { mapGetters } from "vuex";

export default {

  name: "TextComponent",

  computed: {
    ...mapGetters({
      presentationState: "communicator/presentationState",
    }),
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    innerHeight() {
      return this.$refs.input.scrollHeight
    }
  },

  methods: {
    getHeight() {
      this.$nextTick(() => {
        const target = this.$refs.input
        target.style.height = target.scrollHeight+'px'
      })
    }
  },

  props: {
    text: {
      type: String,
      default: "Title text",
    },
    placeholder: {
      type: String,
      default: "Add text",
    },
    color: String,
    classes: String,
    fontSize: [String, Number],
    isBlock: {
      type: Boolean,
      default: true,
    },
    spacing: {
      default: 0.05,
    },
    value: {
      type: String,
    },
    isDisable: Boolean
  }
};
</script>

<style>
textarea {
  resize: none;
}
</style>
