import { ref, set } from 'vue'
import { get } from 'lodash'
import SimpleBulletPoint from '@/components/communicator/v2/templates/boxConcept/template/SimpleBulletPoint.vue'
import MetricComparison from '@/components/communicator/v2/templates/boxConcept/template/MetricComparison.vue'
import MetricVisualization from '@/components/communicator/v2/templates/boxConcept/template/MetricVisualization.vue'
import Picture from '@/components/communicator/v2/templates/boxConcept/template/Picture.vue'
import { useTemplate } from '@/views/screens/communicator/useTemplate'

const templateList = [
    {
        name: 'SimpleBulletPoint',
        value: 'Simple Bullet Point',
        component: SimpleBulletPoint
    },
    {
        name: 'MetricComparison',
        value: 'Metric Comparison',
        component: MetricComparison
    },
    {
        name: 'MetricVisualization',
        value: 'Metric Visualization',
        component: MetricVisualization
    },
    {
        name: 'Picture',
        value: 'Picture',
        component: Picture
    }
]


export default function useBoxConcept()
{
    const initialCustomizedData = {
        activeTemplate: {
            box_one: 'SimpleBulletPoint',
            box_two: 'MetricComparison',
            box_three: 'Picture',
            box_four: 'SimpleBulletPoint',
            box_five: 'Picture',
        },

        templateConfig: [
            {
                box_type: 'box_one',
                background: {
                    path: '',
                    imageOpacity: '',
                    color: '',
                    colorOpacity: ''
                },
                color: 'inherit'
            },
            {
                box_type: 'box_two',
                background: {
                    path: '',
                    imageOpacity: '',
                    color: '',
                    colorOpacity: ''
                },
                color: 'inherit'
            },
            {
                box_type: 'box_three',
                background: {
                    path: '',
                    imageOpacity: '',
                    color: '',
                    colorOpacity: ''
                },
                color: 'inherit'
            },
            {
                box_type: 'box_four',
                background: {
                    Path: '',
                    imageOpacity: '',
                    color: '',
                    colorOpacity: ''
                },
                color: 'inherit'
            },
            {
                box_type: 'box_five',
                background: {
                    Path: '',
                    imageOpacity: '',
                    color: '',
                    colorOpacity: ''
                },
                color: 'inherit'
            }
        ],

        title: 'Box Concept',
        subtitle: 'Task: validate Goals',
        elements: [
            {
                conf_id: 'box_one-mv-subtitle-'+Date.now(),
                title: 'Title',
                box_type: 'box_one',
                subtitle: [{
                    childTemplate: 'simple-bullet-point',
                    value: 'Title',
                    conf_id: 'box_one-sbp-title-'+Date.now(),
                    description:[
                        {
                            conf_id: 'box_one-sbp-description-'+Date.now(),
                            value: 'Description',
                        }
                    ]
                }],
                parameter: [
                    { conf_id: 'box_one-mc-parameter-'+Date.now() }
                ],
                image: '',
            },
            {
                conf_id: 'box_two-mv-subtitle-'+Date.now(),
                title: 'Title',
                box_type: 'box_two',
                subtitle: [{
                    childTemplate: 'simple-bullet-point',
                    value: 'Title',
                    conf_id: 'box_two-sbp-title-'+Date.now(),
                    description:[
                        {
                            conf_id: 'box_two-sbp-description-'+Date.now(),
                            value: 'Description',
                        }
                    ]
                }],
                parameter: [
                    { conf_id: 'box_two-mc-parameter-'+Date.now() }
                ],
                image: '',
            },
            {
                conf_id: 'box_three-mv-subtitle-'+Date.now(),
                title: 'Title',
                box_type: 'box_three',
                subtitle: [{
                    childTemplate: 'simple-bullet-point',
                    value: 'Title',
                    conf_id: 'box_three-sbp-title-'+Date.now(),
                    description:[
                        {
                            conf_id: 'box_three-sbp-description-'+Date.now(),
                            value: 'Description',
                        }
                    ]
                }],
                parameter: [
                    { conf_id: 'box_three-mc-parameter-'+Date.now() }
                ],
                image: '',
            },
            {
                conf_id: 'box_four-mv-subtitle-'+Date.now(),
                title: 'Title',
                box_type: 'box_four',
                subtitle: [{
                    childTemplate: 'simple-bullet-point',
                    value: 'Title',
                    conf_id: 'box_four-sbp-title-'+Date.now(),
                    description:[
                        {
                            conf_id: 'box_four-sbp-description-'+Date.now(),
                            value: 'Description',
                        }
                    ]
                }],
                parameter: [
                    { conf_id: 'box_four-mc-parameter-'+Date.now() }
                ],
                image: '',
            },
            {
                conf_id: 'box_five-mv-subtitle-'+Date.now(),
                title: 'Title',
                box_type: 'box_five',
                subtitle: [{
                    childTemplate: 'simple-bullet-point',
                    conf_id: 'box_five-sbp-title-'+Date.now(),
                    value: 'Title',
                    description:[
                        {
                            conf_id: 'box_five-sbp-description-'+Date.now(),
                            value: 'Description',
                        }
                    ]
                }],
                parameter: [
                    { conf_id: 'box_five-mc-parameter-'+Date.now() }
                ],
                image: '',
            }
        ]
    }

    let timeoutId = null
    const { updateTemplate } = useTemplate()

    const getDataByBoxType = (box_type, activeTemplate) => {
        let data = get(activeTemplate, 'customized_data.elements')
        let filteredData = data.find(item => item.box_type == box_type)
        return filteredData
    }


    const changeTemplate = (selectedTemplateName, innerTemplateLocation, activeTemplate) => {
        activeTemplate.customized_data.activeTemplate[innerTemplateLocation] = selectedTemplateName
        updateTemplate(activeTemplate)
    }

    const setInitialData = (template) => 
    {
        if (template.settings.color === true || !template.settings.color) {
            set(template.settings, 'color', '#1C1C1D')
        }
        if (!('background_image_opacity' in template.settings)) {
            set(template.settings, 'background_image_opacity', 100);
        }
        if (!('background_color_opacity' in template.settings)) {
            set(template.settings, 'background_color_opacity', 100);
        }
        if (!('background_color' in template.settings)) {
            set(template.settings, 'background_color', '#fff');
        }
    }

    const getTemplateTitle = (activeTemplate) => 
    {
        return activeTemplate.customized_data.title
    }

    const editTemplateTitle = (title, activeTemplate) => 
    {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            set(activeTemplate.customized_data, 'title', title)
            updateTemplate(activeTemplate)
        }, 500)
    }    
    const getTemplateSubTitle = (activeTemplate) => 
    {
        return activeTemplate.customized_data.subtitle
    }
    const editTemplateSubTitle = (title, activeTemplate) => 
    {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            set(activeTemplate.customized_data, 'subtitle', title)
            updateTemplate(activeTemplate)
        }, 500)
    }

    const editTitle = (text, data, activeTemplate) => 
    {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            set(data, 'title', text)
            updateTemplate(activeTemplate)
        }, 500)
    }

    const addSubtitle = (activeTemplate, box_type) => {
        let elements = get(activeTemplate, 'customized_data.elements')
        let myElement = elements.find(item => item.box_type == box_type)

        let demoData  = {
            conf_id: 'title-'+Date.now(),
            value: 'Title',
            description: [
                {
                    conf_id: 'description-'+Date.now(),
                    value: 'Description'
                }
            ]
        }
        myElement.subtitle.push(demoData)
        updateTemplate(activeTemplate)
    }

    const removeSubtitle = (activeTemplate, box_type, config_id) => {
        let elements = get(activeTemplate, 'customized_data.elements')
        let myElement = elements.find(item => item.box_type == box_type)

        let subtitles = myElement.subtitle
        subtitles.forEach((item, index) => {
            if(item.conf_id == config_id && subtitles.length>1){
                subtitles.splice(index, 1)
                updateTemplate(activeTemplate)
            }
        })
    }

    const editSubtitle = (text, activeTemplate, box_type, config_id) => 
    {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            let elements = get(activeTemplate, 'customized_data.elements')
            let myElement = elements.find(item => item.box_type == box_type)

            let subtitles = myElement.subtitle
            subtitles.forEach((item, index) => {
                if(item.conf_id == config_id){
                    set(item, 'value', text)
                    updateTemplate(activeTemplate)
                }
            })
        }, 500)
    }

    const addDescription = (activeTemplate, box_type, subtitleConfId) => {
        let elements = get(activeTemplate, 'customized_data.elements')
        let myElement = elements.find(item => item.box_type == box_type)

        let subtitle = myElement.subtitle.find(item => item.conf_id == subtitleConfId)
        if(subtitle){
            subtitle.description.push({
                conf_id: 'description-'+Date.now(),
                value: 'Demo Description'
            })
        }
        updateTemplate(activeTemplate)
    }

    const removeDescription = (activeTemplate, box_type, subtitleConfId, config_id) => {
        let elements = get(activeTemplate, 'customized_data.elements')
        let myElement = elements.find(item => item.box_type == box_type)
        let subtitle = myElement.subtitle.find(item => item.conf_id == subtitleConfId)

        if(subtitle){
            let descriptions = subtitle.description
            descriptions.forEach((item, index) => {
                if(item.conf_id === config_id && descriptions.length>1){
                    descriptions.splice(index, 1)
                    updateTemplate(activeTemplate)
                }
            })
        }
    }

    const editDescription = (text, activeTemplate, box_type, subtitleConfId, config_id) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            let elements = get(activeTemplate, 'customized_data.elements')
            let myElement = elements.find(item => item.box_type == box_type)
            let subtitle = myElement.subtitle.find(item => item.conf_id == subtitleConfId)

            if(subtitle){
                let descriptions = subtitle.description
                descriptions.forEach((item, index) => {
                    if(item.conf_id == config_id){
                        set(item, 'value', text)
                        updateTemplate(activeTemplate)
                    }
                })
            }
        }, 500)
    }

    const addParameterData = (activeTemplate, box_type) => {
        let elements = get(activeTemplate, 'customized_data.elements')
        let myElement = elements.find(item => item.box_type == box_type)

        console.log(box_type)
        if(myElement){
            myElement.parameter.push({
                conf_id: 'mc-parameter-'+Date.now()
            })
            updateTemplate(activeTemplate)
        }
    }

    const removeParameterData = (activeTemplate, box_type, conf_id) => {
        let elements = get(activeTemplate, 'customized_data.elements')
        let myElement = elements.find(item => item.box_type == box_type)

        if(myElement){
            let parameter = myElement.parameter
            parameter.forEach((item, index) => {
                if(item.conf_id == conf_id){
                    parameter.splice(index, 1)
                }
            })
            updateTemplate(activeTemplate)
        }
    }

    const updateImage = (activeTemplate, box_type, image) => {
        let data = get(activeTemplate, 'customized_data.elements')
        let filteredData = data.find(item => {
            return item.box_type == box_type
        })
        
        if(filteredData){
            set(filteredData, 'image', image)
        }
        updateTemplate(activeTemplate)
    }

    return {
        getDataByBoxType,
        changeTemplate,
        templateList,
        initialCustomizedData,
        setInitialData,
        editTitle,
        addSubtitle,
        removeSubtitle,
        editSubtitle,
        addDescription,
        removeDescription,
        editDescription,
        updateImage,
        addParameterData,
        removeParameterData,
        getTemplateTitle,
        editTemplateTitle,
        getTemplateSubTitle,
        editTemplateSubTitle,
    }
}