import { find } from 'lodash';
import store from '@/store';

export default class Definition {
    constructor(template) {
        this.template = template;
    }
    getDefinition = (parameter, value) => {
        if(!parameter.id) {
            return null;
        }
        return this.getDefinitionByRange(this.getBusinessDefinition(this.getDefinitionId(parameter.id)), value)
    }
    getDefinitionId = (parameterId) => {
        return find(this.template.parameters, { id: parameterId })?.definition_id
      }
    getDefinitionByRange(definitionRangeArray, value) {
        if(!definitionRangeArray || !definitionRangeArray.range_with_definition || !definitionRangeArray.range_with_definition.length){
            return;
        }
        value = value > 0 ? (value - .000001) : value;
        value = Math.floor(value / 2);
        value = value > 4 ? 4 : value;
        return definitionRangeArray.range_with_definition[value].definition;
    }
    getBusinessDefinition = (definitionId, defDefaultSets = null) => {
        let definitionSets = store.getters['definition/definitionSets'];
        if(defDefaultSets) {
            definitionSets = defDefaultSets;
        }
        let definition = definitionSets.find(data => parseInt(data.id) === parseInt(definitionId));
        if(definition) {
            return definition.items.find(item => item.type == 3); // 3 is business type value
        }
    }

    getSliderDefinition = (definitionId, defDefaultSets = null) => {
        let definitionSets = store.getters['definition/definitionSets'];
        if(defDefaultSets) {
            definitionSets = defDefaultSets;
        }
        let definition = definitionSets.find(data => parseInt(data.id) === parseInt(definitionId));
        if(definition) {
            return definition.items.find(item => item.type == 1); // 1 is slider type value
        }
    }

    getDefinitionTitle(definitionId, defDefaultSets = null) {
        let businessDefinition = this.getBusinessDefinition(definitionId, defDefaultSets);
        return businessDefinition ? businessDefinition.title : undefined;
    }
}


export const getDefinition = (value, widget, definitionSets) => {
    if(widget.definition_id) {
        let definition = definitionSets.find(data => parseInt(data.id) === parseInt(widget.definition_id));
        if(definition && definition.items.length > 0) {
            let definitionRangeArray = definition.items[0];
            if(definitionRangeArray && definitionRangeArray.range_with_definition && definitionRangeArray.range_with_definition.length > 0) {
            if(value > 0) {
                value = value - .000001;
            }
            value = Math.floor(value / 2);
            if(value > 4) {
                value = 4;
            }
                return definitionRangeArray.range_with_definition[value].definition;
            }
        }
        return null;
    }
    return null;
}