import { get } from 'lodash'
import { ref, set } from 'vue'
import { useSidebar } from '@/views/screens/communicator/useSidebar'
import { useFile } from '@/composable/useFile'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import store from '@/store';
import useTextContent from './useTextContent';


export default function useSystemContent() {

    const { createDataItem } = useTextContent()
    const { saveTemplateConfiguration } = useSidebar();
    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getIconVisibility, getTemplateBorderColor } = useTemplate();
    const { onChange, previewURL, file, appendFile, formData, hasFile} = useFile()

    const configuration = {
        1: {
            itemLength: 9,
            grid: 'grid-cols-1 gap-5',
            showBio: true,
            imageDimension: 290,
        },
        2:{
            itemLength: 3,
            grid: 'grid-cols-2 gap-5',
            showBio: true,
            imageDimension: 290
        },
        3:{
            itemLength: 9,
            grid: 'grid-cols-3 gap-2',
            showBio: false,
            imageDimension: 197
        }
    }

    const getDataLayer = (template) => {
        const configurationNo = get(template, 'settings.configuration_no', null)
        if(!configurationNo) return []
        return template.data_layer.slice(0, configuration[configurationNo].itemLength)
    }

    const isBiography = (template) => {
        const configurationNo = get(template, 'settings.configuration_no', null)
        if(configurationNo) {
            return configuration[configurationNo].showBio
        }
    }

    const getGridClass = (template) => {
        const configurationNo = get(template, 'settings.configuration_no', null)
        if(configurationNo) {
            return configuration[configurationNo].grid
        }
    }

    const getConfig = (template, key) => {
        const configurationNo = get(template, 'settings.configuration_no', null)
        if(configurationNo) {
            return configuration[configurationNo][key]
        }
    }

    const removeItem = (template, itemId) => {
        template.customized_data.descriptions.splice(template.customized_data.descriptions.findIndex(({id}) => id == itemId), 1);
        let dataLayerIndex = template.data_layer.findIndex(({conf_id}) => conf_id == itemId)
        if(dataLayerIndex != -1) {
            template.data_layer.splice(dataLayerIndex, 1);
        }
        updateTemplate(template)
    }

    const createItem = (template, index) => {
        if(template.customized_data.descriptions.length == 9) return
        template.customized_data.descriptions.splice(index, 0, {
            id: (Math.random() + 1).toString(36).substring(2),
            title: "Headline",
            description: "This is an integral part of any presentation. This simple agenda slide template will help you to highlight key points in your presentation",
            image: "",
        });
        updateTemplate(template)
    }

    const setInitialData = (template) => {
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
            set(template.customized_data, 'descriptions', [])
        }
        if (template.customized_data?.descriptions?.length == 0) {
            createItem(template, 0);
        }

        let checkData = "heading" in template.customized_data;

        if (!checkData) {
            template.customized_data.heading = {
                id: (Math.random() + 1).toString(36).substring(2),
                value: 'System content'
            };
        }
        if(!template.customized_data.items) {
            set(template.customized_data, 'items', [])
        }

        if (template.customized_data.items.length == 0) {
            createDataItem(template, 0);
        }
    }

    const fileElement = ref(null)
    const currentItem = ref({})
    const getFileElement = elm => fileElement.value= elm

    const onChangeContentImage = async (event, template, type) => {
        if(!hasFile(event.target)) return
        onChange(event)
        //currentItem.value.image = previewURL
        appendFile('image')
        let {data: {data, status}} =  await store.dispatch('communicator/updatePeopleImage',{id:template.id, data: formData});

        if(status && type == 'people') {
            currentItem.value.image = data
        }

        if(status && type == 'content') {
            set(template.customized_data, 'middleImage', data)
        }

        updateTemplate(template)
    }

    const openFile = () => {
        fileElement.value.value = null
        fileElement.value.click()
    }
    const setCurrentItem = (data) => currentItem.value = data

    return {
        getDataLayer,
        createItem,
        setInitialData,
        removeItem,
        isBiography,
        getGridClass,
        getConfig,
        getFileElement,
        openFile,
        setCurrentItem,
        getDataLayerItem,
        updateTemplate,
        prepareConfiguration,
        prepareRequest,
        getPersistance,
        onChangeContentImage,
        getIconVisibility,
        getTemplateBorderColor
    }
}
