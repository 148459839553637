import axios from "axios"
import { ref } from "vue"
import useRoadmapConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useRoadmapConcept.js'


const { selectedConcept } = useRoadmapConcept()

const conceptData = ref({})
const collections = ref([])
const activeScenarioId = ref(null)

const getCollectionsData = async (activeScenarioId, moduleId) => {        
    if(!activeScenarioId || !moduleId) return
    
    let {data} = await axios.get(`roadmaps/strategies/system-data/${activeScenarioId}?module_id=${moduleId}&synchronized=1&phases=1`).then(({data}) => data)
    if(data && data.children){
        collections.value = data.children
    }
}

export default function useHighLevel(){

    return {
        activeScenarioId,
        getCollectionsData,
        conceptData,
        collections,
        selectedConcept
    }
}