<template>
    <div>
        <TopIcons 
            :levels="data.child"
            :code="data.code"
            :getCode="getCode"
            :expansionMethods="expansionMethods"
            :parameters=" item.parameters"
        />

        <div class="flex gap-x-0.5" :style="{color: template.settings.borderColor}">
            <div class="mr-5">
                <div :style="{color: template.settings.color}" class="flex fs-30 fw-700 space-x-2 items-center">
                    <AttachmentIcon
                        @click.native="$emit('onConfigure', {itemIndex: index})"
                        class="h-7 w-7 flex-shrink-0 cursor-pointer"
                    />
                    <p
                        class="cursor-pointer"
                        @click="createItem(index+1)"
                    >+</p>
                    <p
                        class="cursor-pointer"
                        @click="removeItem(index)"
                    >-</p>
                </div>
            </div>
            <Level
                :item="{title: data.title}"
                :code="getCode(data.code, 0)"
                :bgColor="getCode(data.colors, 0)"
                :level="0"
                :index="index"
                :indexCode="getCode(data.code, 0)"
                :showTopIcon="index == 0"
                :expansionMethods="expansionMethods"
            />
            <template v-for="(parameter, parameterIndex) in item.parameters" >
                <component
                    v-if="parameter.id && parameter.type && !isFolded(0)"
                    :key="parameterIndex"
                    :is="getComponent(parameter.type)"
                    :bgColor="getParameterBg(getParameterValue(data, parameter), parameter.definition_style)"
                    :text="getParameterTextDefinition(getParameterValue(data, parameter), parameter.definition_style)"
                ></component>
            </template>
            <div class="space-y-1">
                <template
                    v-for="(firstLevel, firstLevelIndex) in data.child"
                >
                    <div :key="firstLevelIndex" class="flex space-x-1">
                        <Level
                            :item="{title: firstLevel.title, description: firstLevel.description}"
                            :code="getCode(data.code, 1)"
                            :bgColor="getCode(data.colors, 1)"
                            :indexCode="`${index+1}.${firstLevelIndex+1}`"
                            :level="1"
                            :index="index"
                            :showTopIcon="index == 0 && firstLevelIndex==0"
                            :expansionMethods="expansionMethods"
                        />
                        <template v-for="(parameter, parameterIndex) in item.parameters" >
                            <component
                                v-if="parameter.id && parameter.type && !isFolded(1)"
                                :key="parameterIndex"
                                :is="getComponent(parameter.type)"
                                :bgColor="getParameterBg(getParameterValue(firstLevel, parameter), parameter.definition_style)"
                                :text="getParameterTextDefinition(getParameterValue(firstLevel, parameter), parameter.definition_style)"
                            ></component>
                        </template>
                        <div class="space-y-1">
                            <template v-for="(secondLevel, secondLevelIndex) in firstLevel.child">
                                <div :key="secondLevelIndex" class="flex space-x-1">
                                    <Level
                                        :item="{title: secondLevel.title, description: secondLevel.description}"
                                        :code="getCode(data.code, 2)"
                                        :bgColor="getCode(data.colors, 2)"
                                        :indexCode="`${index+1}.${firstLevelIndex+1}.${secondLevelIndex+1}`"
                                        :level="2"
                                        :index="index"
                                        :showTopIcon="index == 0 && firstLevelIndex==0 && secondLevelIndex == 0"
                                        :expansionMethods="expansionMethods"
                                    />
                                    <template v-for="(parameter, parameterIndex) in item.parameters" >
                                        <component
                                            v-if="parameter.id && parameter.type && !isFolded(2)"
                                            :key="parameterIndex"
                                            :is="getComponent(parameter.type)"
                                            :bgColor="getParameterBg(getParameterValue(secondLevel, parameter), parameter.definition_style)"
                                            :text="getParameterTextDefinition(getParameterValue(secondLevel, parameter), parameter.definition_style)"
                                        ></component>
                                    </template>
                                    <div class="space-y-1">
                                        <template v-for="(thirdLevel, thirdLevelIndex) in secondLevel.child">
                                            <div :key="thirdLevelIndex" class="flex space-x-1">
                                                <Level
                                                    :item="{title: thirdLevel.title, description: thirdLevel.description}"
                                                    :code="getCode(data.code, 3)"
                                                    :bgColor="getCode(data.colors, 3)"
                                                    :indexCode="`${index+1}.${firstLevelIndex+1}.${secondLevelIndex+1}.${thirdLevelIndex+1}`"
                                                    :level="3"
                                                    :index="index"
                                                    :showTopIcon="index == 0 && firstLevelIndex==0 && secondLevelIndex == 0 && thirdLevelIndex == 0"
                                                    :expansionMethods="expansionMethods"
                                                />
                                                <template v-for="(parameter, parameterIndex) in item.parameters" >
                                                    <component
                                                        v-if="parameter.id && parameter.type && !isFolded(3)"
                                                        :key="parameterIndex"
                                                        :is="getComponent(parameter.type)"
                                                        :bgColor="getParameterBg(getParameterValue(thirdLevel, parameter), parameter.definition_style)"
                                                        :text="getParameterTextDefinition(getParameterValue(thirdLevel, parameter), parameter.definition_style)"
                                                    ></component>
                                                </template>
                                                <div class="space-y-1">
                                                    <template v-for="(fourthLevel, fourthLevelIndex) in thirdLevel.child">
                                                        <div :key="fourthLevelIndex" class="flex space-x-1">
                                                            <Level
                                                                :item="{title: fourthLevel.title, description: fourthLevel.description}"
                                                                :code="getCode(data.code, 4)"
                                                                :bgColor="getCode(data.colors, 4)"
                                                                :indexCode="`${index+1}.${firstLevelIndex+1}.${secondLevelIndex+1}.${thirdLevelIndex+1}.${fourthLevelIndex+1}`"
                                                                :level="4"
                                                                :index="index"
                                                                :showTopIcon="index == 0 && firstLevelIndex==0 && secondLevelIndex == 0 && thirdLevelIndex == 0 && fourthLevelIndex == 0"
                                                                :expansionMethods="expansionMethods"
                                                            />
                                                            <Note
                                                                :note="getNote(fourthLevel.id)"
                                                                @onType="updateActiveTemplate"
                                                            />
                                                            <template v-for="(parameter, parameterIndex) in item.parameters" >
                                                                <component
                                                                    v-if="parameter.id && parameter.type && !isFolded(4)"
                                                                    :key="parameterIndex"
                                                                    :is="getComponent(parameter.type)"
                                                                    :bgColor="getParameterBg(getParameterValue(fourthLevel, parameter), parameter.definition_style)"
                                                                    :text="getParameterTextDefinition(getParameterValue(fourthLevel, parameter), parameter.definition_style)"
                                                                    :value="getParameterValue(fourthLevel, parameter)"
                                                                ></component>
                                                            </template>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, watchEffect} from 'vue'
import { getParameterTextDefinition, getParameterValue, getParameterBg} from '@/store/modules/roadmap/services'
import useManagementData from './management.data'
import useHorizontalExpansion from './useHorizontalExpansion'
import { getComponent } from './parameterComponentImpoter'
//components
import Level from './Level.vue'
import Note from './parameterComponent/Note.vue'
import TopIcons from './TopIcons.vue';


const props = defineProps({
    item: Object,
    templateMethods: Object,
    index: Number,
    showIcon: Boolean,
    showAttachment: Boolean,
    template: Object
})

const { updateActiveTemplate, createItem, removeItem, getNote} = props.templateMethods
const expansionMethods = useHorizontalExpansion()
const { getExpansionStatesByLevel } = expansionMethods
const data = ref({})
const { getCollectionData } = useManagementData()
const getCode = (codeArr, index) => (codeArr && codeArr.length) ? codeArr[index] : ''
const isFolded = (level) => getExpansionStatesByLevel(level) == 'normal'
watchEffect(async () => {
    const collectionData = await getCollectionData(props.item)
    data.value = collectionData
})
</script>