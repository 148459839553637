<template>
    <div :class="[isBlock ? 'block' : '']">
        <p
            @click="switchMode"
            v-if="!editMode"
            :style="{
                fontSize: fontSize+'px',
                color: color,
                letterSpacing: spacing+'em',
                fontWeight: fontWeight
            }"
            :class="[classes]"
        >{{ text }}</p>

        <input
            focused="true"
            @blur="switchMode"
            :style="{
                fontSize: fontSize+'px',
                color: color,
                letterSpacing: spacing+'em'
            }"
            class="bg-transparent border border-gray-100"
            :class="[classes]"
            v-if="editMode"
            ref="myinput"
            type="text"
        >
    </div>
</template>

<script>
export default {
    name: 'TextComponent',
    data: () => ({
        editMode: false
    }),
    computed: {

    },
    props: {
        editable: {
            default: false
        },
        text: {
            type: String,
            default: 'Title text'
        },
        color: String,
        classes: [String, Array],
        fontSize: [String, Number],
        fontWeight: [String, Number],
        isBlock: {
            type: Boolean,
            default: true
        },
        spacing: {
            default: 0.05
        }
    },
    methods: {
        switchMode() {
            if(this.editable) this.editMode = !this.editMode
        }
    }
}
</script>
