<template>
    <div>
        <div class="flex gap-3 items-center">
            <img
                :src="comment.created_by.image.file_path"
                alt=""
                class="rounded-full"
                style="width: 32px; height: 32px"
            >
            <p class="fs-12 fw-500 text-primary-one i-n-mt-4">{{ comment.created_by.first_name }} {{ comment.created_by.last_name }}</p>
        </div>
        <p class="fw-400 fs-10 mt-1 text-secondary-one">{{ comment.text }}</p>
        <div class="flex gap-1 items-center mt-3">
            <button v-if="replay" @click="showReplay = !showReplay" class="fs-10 fw-500 cursor-pointer text-primary-four">
                Reply
            </button>
            <svg v-if="replay" viewBox="0 0 1 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline w-3 h-3 text-secondary-two">
                <line x1="0.5" y1="0.5" x2="0.499999" y2="12.5" stroke="currentColor"></line>
            </svg>
            <button @click="deleteComment(comment)">
                <svg class="w-3 h-3" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-3a1b44d3="" d="M13.5 3.5h-11m4 3v4m3-4v4m-4-9h5m2 2V13a.5.5 0 0 1-.5.5H4a.5.5 0 0 1-.5-.5V3.5" stroke="#C9C9C9" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            </button>
            <svg v-if="replay" viewBox="0 0 1 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline w-3 h-3 text-secondary-two">
                <line x1="0.5" y1="0.5" x2="0.499999" y2="12.5" stroke="currentColor"></line>
            </svg>
            <span 
                v-if="comment.replies.length" @click="toggleReplies = !toggleReplies" 
                class="fs-10 fw-400 cursor-pointer"
                :class="toggleReplies ? 'text-gray-700' : 'text-secondary-two'"
            >
                {{ comment.replies.length }} Replies
            </span>

            <svg v-if="comment.replies.length" viewBox="0 0 1 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline w-3 h-3 text-secondary-two">
                <line x1="0.5" y1="0.5" x2="0.499999" y2="12.5" stroke="currentColor"></line>
            </svg>
            <span class="fs-10 fw-400 text-secondary-two">{{ $lodash.ago(comment.created_at) }}</span>
        </div>
        <div v-if="toggleReplies">
            <div v-for="(replay, index) in comment.replies" :key="index">
                <Message 
                    :comment="replay" 
                    :item="item" 
                    :replay="false" 
                    class="ml-2 mt-2"
                    :deleteComment="deleteComment"
                />
            </div>
        </div>
        
        <Replay 
            v-if="showReplay" 
            @close="showReplay = false" 
            class="mt-2"
            :item="item"
            :comment_id="comment.id"
            :handleComment="handleComment"
        />
    </div>
</template>

<script setup>
import Replay from './Replay.vue'
import { ref } from 'vue'


const props = defineProps({
    comment: Object,
    item: Object,
    replay: {
        type: Boolean,
        default: true
    },
    formForComment: Object,
    handleComment: Function,
    deleteComment: Function,
})

const showReplay = ref(false)
const toggleReplies = ref(false)
</script>