<template>
    <div :class="[currentMode != 'fullscreen' ? 'scale-down' : '']" 
    :style="{color: activeTemplate.settings.color, overflow: 'hidden'}" class="relative rounded i-pr-133 flex flex-col justify-center i-pl-100 overflow-y-scroll communicator__template--wrapper">
      <!-- <div></div> -->
      <div 
        class="flex space-x-7 items-center"
        :class="[getTitleMarginTop(activeTemplate.settings.configuration_no)]"
      >
          <AttachmentIcon :class="{'invisible' : hasIconVisibility(currentMode, config_visibility, synchronize)}" @click.native="showConfiguration('TextSource', ['title'], 'title')" class="w-7 h-7 cursor-pointer" />
          <p v-if="getDataLayerItem(activeTemplate, 'title')" class= "fw-700 fs-64 w-full" :class="{'text-center': activeTemplate.settings.configuration_no == 3}"> {{ getDataLayerItem(activeTemplate, 'title').title.value }} </p>
          <!-- <p v-else  class= "fw-700 fs-40">Heading</p> -->
          <input @input="updateTemplate(activeTemplate)" v-else :disabled="currentMode == 'fullscreen'" :class="{'text-center': activeTemplate.settings.configuration_no == 3}"  class="fw-700 fs-64 w-full bg-transparent" type="text" v-model="activeTemplate.customized_data.title.value">
      </div>
      <div class="flex space-x-7 items-center -mt-3 mb-2">
        <AttachmentIcon :class="{'invisible' : hasIconVisibility(currentMode, config_visibility, synchronize)}" @click.native="showConfiguration('TextSource', ['subTitle'], 'subTitle')" class="w-7 h-7 cursor-pointer" />
        <p v-if="getDataLayerItem(activeTemplate, 'subTitle')" :class="{'text-center': activeTemplate.settings.configuration_no == 3}" class=" fw-500 fs-48 w-full">{{ getDataLayerItem(activeTemplate, 'subTitle').subTitle.value }}</p>
        <input  @input="updateTemplate(activeTemplate)" v-else :class="{'text-center': activeTemplate.settings.configuration_no == 3}" :disabled="currentMode == 'fullscreen'"  class="fw-500 fs-48 w-full bg-transparent" type="text" v-model="activeTemplate.customized_data.subTitle.value">
      </div>
      <div class="flex space-x-7" :class="{'mt-1': activeTemplate.settings.configuration_no == 2}">
        <AttachmentIcon :class="{'invisible' : hasIconVisibility(currentMode, config_visibility, synchronize)}" @click.native="showConfiguration('TextSource', ['description'], 'description')" class="w-7 h-7 mt-2 cursor-pointer" />
        <p 
          v-if="getDataLayerItem(activeTemplate, 'description')" 
          :class="{'text-center px-4': activeTemplate.settings.configuration_no == 3}" 
          class=" fw-500 fs-28 w-full"
        >
          {{ getDataLayerItem(activeTemplate, 'description').description.value || 'No Description' }}
        </p>
        <EditableComponent  
          @input="updateTemplate(activeTemplate)" 
          :class="{'text-center pl-20 pr-28': activeTemplate.settings.configuration_no == 3}" 
          v-else 
          :isDisable="currentMode == 'fullscreen'" 
          v-model="activeTemplate.customized_data.description.value" 
          class=" fw-500 fs-28 w-full overflow-y-auto bg-transparent"
        />
        <!-- <textarea class=" fw-500 fs-16 w-full h-28 overflow-y-auto bg-transparent" v-else v-model="activeTemplate.customized_data.descriptions"></textarea> -->
      </div>
      <div v-if="activeTemplate.settings.configuration_no && activeTemplate.settings.configuration_no == 2" class="flex flex-wrap gap-5 ml-14 -mb-4">
        <div style="width: 310px" v-for="(title, i) in activeTemplate.customized_data.titleArr" :key="i">
            <div class="flex items-center fs-20 fw-700 gap-x-5 ml-2" :class="{'invisible' : currentMode == 'fullscreen' || !config_visibility}">
              <p @click="createTitleItem(activeTemplate, activeTemplate.customized_data.titleArr, i+1), activeTemplate" class="cursor-pointer">+</p>
              <p @click="removeTitleItem(activeTemplate, activeTemplate.customized_data.titleArr, i), activeTemplate" class="cursor-pointer">-</p>
            </div>
            <input  
              @input="updateTemplate(activeTemplate)" :disabled="currentMode == 'fullscreen'" 
              type="text" class="fw-600 fs-30 w-full bg-transparent" v-model="title.name">
            <input  @input="updateTemplate(activeTemplate)" :disabled="currentMode == 'fullscreen'" type="text" class="fw-300 fs-26 w-full bg-transparent" v-model="title.title">
        </div>
      </div>
      <!-- </div> -->
    </div>
</template>

<script setup>
import TemplateContainer from "../TemplateContainer.vue";
import EditableComponent from "@/elements/atom/EditableComponent.vue";
import { set, onMounted, watch } from 'vue';
import { get } from 'lodash'
import useCoverPage from './useCoverPage'

const { getData, updateTemplate, removeTitleItem, createTitleItem, getDataLayerItem, prepareConfiguration, prepareRequest, getPersistance } = useCoverPage()

const props = defineProps({
  activeTemplate: Object,
  switchSidebarComponent: Function,
  currentMode: String,
  config_visibility: Boolean,
  synchronize: Boolean,
})

const emit = defineEmits(['onClickLink'])

const showConfiguration = (componentName, options, confId) => {
    props.switchSidebarComponent(componentName, {
        persistance: getPersistance(props.activeTemplate, confId),
        transform: (config) => {
            let inputs = prepareConfiguration(options, config);
            return prepareRequest(props.activeTemplate, confId, config, inputs, props.synchronize);
        } 
    });
}

const hasIconVisibility = (mode, showIcon, synchronize) => {
  return mode == 'fullscreen' || !showIcon || !synchronize
}

const marginTopArr = {1: '-mt-4', 2: 'mt-24', 3: '-mt-4'}
const getTitleMarginTop = (configNo) => {
  return marginTopArr[configNo]
}

//have to refactor
const setInitialData = () => {
  let data = props.activeTemplate.customized_data;
  if (data && data.constructor == Array) {
    props.activeTemplate.customized_data = {
      title: {
        id: 'title',
        value: 'Title'
      },
      subTitle: {
        id: 'subTitle',
        value: 'Subtitle'
      },
      description: {
        id: 'description',
        value: 'This is an integral part of any presentation. This simple cover page template will help you to highlight key points in your presentation'
      }
    };
  }

  if (!("titleArr" in data)) {
    data.titleArr = []
    data.titleArr.push({
      name: 'Name',
      title: 'Title'
    })
  }

  if("titleArr" in data && data.titleArr.length == 0) {
    data.titleArr.push({
      name: 'Name',
      title: 'Title'
    })
  }
  
}

watch(
  () => props.activeTemplate,
  (newVal) => {
      setInitialData(newVal)
  },
  { deep: true, immediate: true },
)

</script>

<style scoped>
.h-74 {
  height: 74px;
}
</style>
