<template>
    <div style="margin-left: 102px" class="flex gap-x-1">
        <template v-for="n in 5">
            <div
                v-if="hasLevel(n-1)"
                :key="n"
                :style="{width: getWidth(getExpansionStatesByLevel(n-1))}"
                class="flex-shrink-0"
            >
                <p class="w-12 text-center truncate fs-16 fw-700">{{ getCode(code, n-1) }}</p>
                <div
                    class="fs-20 fw-700 opacity-50 -mt-1"
                >
                    <p
                        v-if="getExpansionStatesByLevel(n-1) != 'expanded'"
                        @click="increaseWidth(n-1)"
                        class="cursor-pointer ml-4"
                    >+</p>
                    <p
                        style="margin-left: 222px"
                        v-if="getExpansionStatesByLevel(n-1) != 'normal'" 
                        @click="decreaseWidth(n-1)"
                        class="cursor-pointer"
                    >-</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { getFlattenCollections } from '@/store/modules/global/services';

const props = defineProps({
    levels: Array,
    code: Array,
    getCode: Function,
    expansionMethods: Object,
    parameters: Array
})

const { getExpansionStatesByLevel, getWidthClass, decreaseWidth, increaseWidth } = props.expansionMethods

const flatData = computed(() => {
    if(!props.levels || !props.levels.length) return []
    return getFlattenCollections(props.levels)
})

const activeParameterCount = computed(() => {
    if(!props.parameters || !props.parameters.length) return 0
    return props.parameters.filter(p => p.id != null && p.property_id != null).length
})

const hasLevel = (identifier) => flatData.value.find(({level}) => {
    if(identifier == 0) return true
    return level == identifier
})

const getWidth = (expansionState) => {
    if(expansionState == 'normal') return '48px'
    const expandedWidth = 244 //in pixel
    const eachParameterGap = 3 //in pixels
    const eachParameterWidth = 85 //in pixels
    const parameterGap = eachParameterGap * activeParameterCount.value
    const parametersWidth = parameterGap + (activeParameterCount.value * eachParameterWidth)
    return `${expandedWidth + parametersWidth}px`
}
</script>