import LayoutSevenImg from '@/components/dashboard/components/image/LayoutSevenImg.vue'

let layout = {
    name: 'layout7',
    image: LayoutSevenImg,
    rows: [
        { id: 'row_16', class: 'grid gap-5 grid-cols-3' },
        { id: 'row_17', class: 'grid gap-5 grid-cols-2' },
        { id: 'row_18', class: 'grid gap-5 grid-cols-3 grid-rows-2' },
    ],
    cards: [
        {
            rows_reference: 'row_16',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_16',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_16',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },

        {
            rows_reference: 'row_17',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        },
        {
            rows_reference: 'row_17',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        },
        
        {
            rows_reference: 'row_18',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: 'i-row-span-2 col-span-2',
                height: 546,
                componentName: 'LargeChart'
            }
        },
        {
            rows_reference: 'row_18',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_18',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        }
    ],
    isSelected: false
}


export default layout