import CollaboratorController from '@/components/dashboard/collaborator/collaboratorController';
import ProjectController from '@/components/dashboard/projects/projectController';
import ProgressViewController from '@/components/dashboard/progressView/progressViewController';
import SummaryController from '@/components/dashboard/summary/summaryController';
import DefinitionController from '@/components/dashboard/modalData/definitionController';
import { has } from 'lodash';
import store from '@/store';
export default class Dashboard {
    constructor({ workspace }) {
        if(!workspace) return
        this.workspace = workspace;
        this.projects = [];
        this.selectedProject = Object(null)
        this.collaborator = new CollaboratorController({ workspace });
        this.project = new ProjectController;
        this.progressView = new ProgressViewController;
        this.summary = new SummaryController({ workspace: this.workspace });
        this.definition = new DefinitionController({ workspace: this.workspace });
        this.configuredProjects = [];
        this.isFullScreen = false;
    }
    getProjects($route) {
        let { workspace } = this.workspace;
        let { projectId } = $route.params;
        this.projects = has(workspace, 'projects') ? workspace.projects : [];
        this.selectedProject = this.projects.find(project => project.id == projectId);
        if( this.selectedProject) {
            this.project.getProject(this.selectedProject.id);
        }
    }
    switchProject(project, $route, $router) {
        let { workspaceId, projectId } = $route.params;
        this.selectedProject = project;
        store.commit('project/setProject', project);
        this.project.getProject(this.selectedProject.id);
        if(projectId !==  project.id) {
            $router.push({
                name: 'projectDashboard',
                params: {
                    workspaceId,
                    projectId: project.id
                }
            });
        }
    }

    getActivity(key) {
        let { activity } = this.workspace;
        if (!activity) {
            return;
        }
        return {
            notifications: activity.notifications,
            brainstorm: activity.brainstorm,
            intel: activity.intel,
            comments: activity.comments
        }[key];
    }

    getDisplayableProjects() {
        if(this.configuredProjects.length > 0) {
            return this.projects.filter(item => this.configuredProjects.includes(item.id));
        }
        return this.projects;
    }

    manageConfiguredProjects(project, $route) {
        let index = this.configuredProjects.findIndex(data => data === project.id);
        if(index >= 0) {
            this.configuredProjects.splice(index, 1);
        } else {
            this.configuredProjects.push(project.id);
        }
    }
}
