<template>
    <div
      :class="[currentMode != 'fullscreen' ? 'scale-down' : '']"
      :style="{color: activeTemplate.settings.color, overflow: 'hidden'}"
      class="relative communicator__template--wrapper"
    >
        <div class="flex">
            <div class="main-content-box relative">
                <!--start background image and color -->
                <div
                    class="absolute h-full w-full inset-0"
                    :style="getContentBgStyle()"
                >
                </div>
                <img
                    v-if="getContentImage()"
                    class="absolute h-full inset-0 object-cover w-full"
                    :src="getContentImage()"
                    :style="getContentImageStyle()"
                    alt="inst-360"
                >
                <!--end background image and color -->
                <!--start content -->
                <div class="mt-40 main-content relative panzoom-exclude">
                    <div class="content-heading flex items-start gap-x-8">
                        <AttachmentIcon
                            :class="{'invisible' : currentMode == 'fullscreen' || !config_visibility || !synchronize}"
                            @click.native="showConfiguration('TextSource', ['title'], 'title')"
                            class="w-7 h-7 mt-6 cursor-pointer"
                        />
                        <div>
                            
                            <p 
                                v-if="getDataLayerItem(activeTemplate, 'title')" 
                                class="fw-500 fs-64 title-width line-height-70">
                                {{ getDataLayerItem(activeTemplate, 'title').title.value }}
                            </p>
                            <EditableComponent
                                v-else
                                :spacing="0"
                                @input="updateTemplate(activeTemplate)"
                                :isDisable="currentMode == 'fullscreen'"
                                v-model="activeTemplate.customized_data.title.value"
                                class="line-height-70 fw-500 fs-64 title-width overflow-y-auto bg-transparent" 
                            />
                        </div>
                    </div>
                    <div class="flex items-start gap-x-8 fw-300 fs-42 mt-3">
                        <AttachmentIcon
                            :class="{'invisible' : currentMode == 'fullscreen' || !config_visibility || !synchronize}"
                            @click.native="showConfiguration('TextSource', ['subTitle'], 'subTitle')"
                            class="w-7 h-7 mt-6 cursor-pointer"
                        />
                        <div>
                            <p 
                                v-if="getDataLayerItem(activeTemplate, 'subTitle')" 
                                class="title-width">
                                {{ getDataLayerItem(activeTemplate, 'subTitle').subTitle.value }}
                            </p>
                            <EditableComponent
                                v-else
                                @input="updateTemplate(activeTemplate)"
                                :spacing="0"
                                :isDisable="currentMode == 'fullscreen'"
                                v-model="activeTemplate.customized_data.subTitle.value"
                                class="overflow-y-auto bg-transparent" 
                            />
                        </div>
                    </div>
                    <div class="flex line-height-50 items-start gap-x-8 fw-300 fs-26 mt-10">
                        <AttachmentIcon
                            :class="{'invisible' : currentMode == 'fullscreen' || !config_visibility || !synchronize}"
                            @click.native="showConfiguration('TextSource', ['description'], 'description')"
                            class="w-7 h-7 mt-3"
                        />
                        <div class="description-width">
                            <p 
                                v-if="getDataLayerItem(activeTemplate, 'description')" 
                                class="title-width">
                                {{ getDataLayerItem(activeTemplate, 'description').description.value }}
                            </p>
                            <EditableComponent
                                v-else
                                :spacing="0"
                                :isDisable="currentMode == 'fullscreen'"
                                v-model="activeTemplate.customized_data.description.value"
                                @input="updateTemplate(activeTemplate)"
                                class="bg-transparent w-full" 
                            />
                        </div>
                    </div>
                </div>
                <!--end content -->
                <div
                    :class="{'invisible' : currentMode == 'fullscreen' || !config_visibility}"
                    class="-bottom-12 absolute bg-white flex items-center pl-2 right-0 rounded shadow-one template-style"
                >
                    <StyleComponent
                        class="ml-5"
                        :getConfig="onChangeConfig"
                        :config="activeTemplate.customized_data.styleConfig"
                        @onChangeImage="onFileSelect($event, activeTemplate)"
                        @onclickUploader="onClickUploader('customized_data.background_image', false)"
                    />
                </div>
            </div>
            <div :style="{backgroundColor: activeTemplate.settings.borderColor}" class="flex-grow relative">
                <div class="absolute flex gap-x-8 left-10 rotate-90 transform side-logo-top">
                    <div class="flex items-center gap-x-2">
                        <div style="width: 180px; height: 24px">
                            <img :src="getLogo('firstLogo')" alt="" class="h-full w-full">
                            <p class="uppercase fs-12 mt-1.5">Consulting group_</p>
                        </div>
                        <MenuAltSolid
                            :class="{'invisible' : currentMode == 'fullscreen' || !config_visibility}"
                            @click.native="onClickUploader('customized_data.firstLogo')" 
                            class="w-5 h-3 transform rotate-180 cursor-pointer"
                        />
                    </div>
                    <div class="flex items-center gap-x-2">
                        <div style="width: 180px; height: 24px">
                            <img :src="getLogo('secondLogo')" alt="" class="h-full w-full">
                        </div>
                        <MenuAltSolid
                            :class="{'invisible' : currentMode == 'fullscreen' || !config_visibility}"
                            @click.native="onClickUploader('customized_data.secondLogo')"
                            class="w-5 h-3 transform rotate-180 cursor-pointer"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex bottom-box-wrapper">
            <div class="box-bottom-left"></div>
            <div
                :style="{backgroundColor: activeTemplate.settings.borderColor}"
                class="flex-grow box-bottom-right">
            </div>
        </div>
        <input hidden @input="onFileSelect($event, activeTemplate)" :ref="getFileElement" type="file">
    </div>
</template>

<script setup>
import useConsultingFrontpage from "./useConsultingFrontpage";
import useImageUpload from "@/components/communicator/v2/templates/useImageUpload"
import { ref } from 'vue'

//components
import StyleComponent from "@/components/communicator/v2/BackgroundAndBorderController/index"
import EditableComponent from "@/elements/atom/EditableComponent";

const props = defineProps({
  activeTemplate: Object,
  switchSidebarComponent: Function,
  currentMode: String,
  config_visibility: Boolean,
  synchronize: Boolean,
})

const template = ref(props.activeTemplate)
const { 
    onChangeConfig,
    updateTemplate,
    getContentBgStyle,
    getContentImage,
    getContentImageStyle,
    getDataLayerItem,
    prepareConfiguration, 
    prepareRequest, 
    getPersistance,
    getLogo
} = useConsultingFrontpage(template)

const { 
    getFileElement,
    onFileSelect,
    onClickUploader
} = useImageUpload(template)


const showConfiguration = (componentName, options, confId) => {
    props.switchSidebarComponent(componentName, {
        persistance: getPersistance(props.activeTemplate, confId),
        transform: (config) => {
            let inputs = prepareConfiguration(options, config);
            return prepareRequest(props.activeTemplate, confId, config, inputs, props.synchronize);
        } 
    });
}
</script>

<style scoped>
    @import './consulting.css'
</style>
