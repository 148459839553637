import store from '@/store';
import router from '@/routes/routes.js';
import { findIndex, get, debounce } from 'lodash';
import axios from 'axios'

export const useLayout = () => {
    const route = router.currentRoute;

    const defaultJsonData = {
        parameters: [{ property_id: null, id: null, definition_id: null }],
        data_layer: { root_type: 'scenario', root_id: null, first_level_id: null, second_level_id: null, third_level_id: null, not_in_list: [] },
        settings: { theme: 'light' },
        definition_style: [
            { range: '0-2', bg: '#AD2B81', definition: 'Very Low' },
            { range: '2-4', bg: '#ABA62B', definition: 'Low' },
            { range: '4-6', bg: '#8D8D8D', definition: 'Medium' },
            { range: '6-8', bg: '#2BAD7E', definition: 'High' },
            { range: '8-10', bg: '#2B80AD', definition: 'Extreme' }
        ]
    };

    const createLayout = async (module) => {
        let data = {
            communicator_id: route.params.communicator_id,
            module_id: module.id,
            title: 'Default',
            settings: { color: true },
            json_data: defaultJsonData
        };

        let response = await store.dispatch("communicator/storeLayout", data);
        if (response.data.data) {
            response.data.data.widgets = [];
            module.layouts.push(response.data.data);
        }
    };
    const deleteLayout = async (module, layout, cb=null) => {
        let layouts = module.layouts
        let {data: {status}} = await store.dispatch("communicator/deleteLayout", {layout_id: layout.id});
        if (status) {
            if(cb) cb(module, layout)
            layouts.splice(layouts.findIndex(({id}) => id == layout.id), 1);
        }
    };

    const updateLayoutImage = async (layout, payload) => {
        try {
            let {data: {data, status}} = await store.dispatch("communicator/updateLayoutImage", payload)
            if(status) layout.file = data
            
        }catch(err) {
            //
        }
       //console.log(res, 'res')
    } 
    
    const removeLayoutImage = async (layout) => {
        layout.file = null
        // updateLayout()
        axios.delete(`communicators/layout/${layout.id}/image-delete`)
    } 

    const updateLayout = debounce(function (layout) {
        store.dispatch("communicator/updateLayoutSetting", layout);
    }, 2000)

    const getFooterLogo = (layout) => {
        return get(layout, 'file.file_path', '')
    }

    return {
        createLayout,
        deleteLayout,
        updateLayoutImage,
        getFooterLogo,
        updateLayout,
        removeLayoutImage
    }
}