import { concat, isEmpty, isArray, flatten } from "lodash"
export const useCustomData = () => 
{
    const customDataReceiver = (config) => {
        let categories = []
        let series = []
        let emptyArrayForColumn = []
        let emptyArrayForLine = []
        if(config.forms && config.forms.length > 0) {
            config.forms.forEach(async (form,  index) => {                
                if(form.selectedComponentName == 'CustomData') 
                {
                    form.seriesData.map(i => {
                        categories.push(i.x)
                    })
                    if(form.chartTypes == 'column'){
                        let data = form.seriesData.filter(item => !isEmpty(item.x) && !isEmpty(item.y))
                        series.splice(index, 1, {
                            name: !isEmpty(form.legendTitle) ? form.legendTitle : 'Legend',
                            type: form.chartTypes,
                            color: !isEmpty(form.color) ? form.color : '#2385b3',
                            data: concat(emptyArrayForColumn, data)
                        })

                        data.forEach(() => emptyArrayForColumn.push({x: '', y: null}))
                    }

                    if(form.chartTypes == 'line'){
                        let data = form.seriesData.filter(item => !isEmpty(item.x) && !isEmpty(item.y))
                        series.splice(index, 1, {
                            name: 'Test',
                            type: form.chartTypes,
                            color: !isEmpty(form.color) ? form.color : '#2385b3',
                            data: concat(emptyArrayForLine, data)
                        })

                        data.forEach(() => emptyArrayForLine.push({x: '', y: null}))
                    }
                }
            })

            if(isArray(series)) {
                return {
                    series: series,
                    categories: flatten(categories)
                }
            }

        }
    }

    return {
        customDataReceiver
    }
}