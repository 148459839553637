// import { get } from 'lodash'
import { set } from 'vue'
import { get } from 'lodash'
import { useTemplate } from '@/views/screens/communicator/useTemplate'

export default function useCoverPage() {
    const { updateTemplate } = useTemplate()

    //have to refactor
    const getData = (template, key) => {
        return (template.data_layer && template.data_layer[0] && key in template.data_layer[0])
    }

    const configuration = {
        1: {
            agendaShowLength: 1,
            grid: 'grid-cols-1 gap-y-12',
            descripTionFontSize: 28,
            serialFontSize: 74,
            serialGap: 'gap-x-16',
            hrClass: 'mt-7',
            serialWidth: 'i-w-75',
            indexMargin: 'mb-3',
            textAreaHeight: '95px',
            paddingRight: '340px'
        },
        2:{
            agendaShowLength: 2,
            grid: 'grid-cols-2 gap-y-10 grid-flow-column grid-rows-3',
            descripTionFontSize: 20,
            serialFontSize: 74,
            serialGap: 'gap-x-16',
            hrClass: 'mt-7',
            serialWidth: 'i-w-75',
            indexMargin: 'mb-4',
            textAreaHeight: '86px',
            paddingRight: '49px'
        },
        3:{
            agendaShowLength: 9,
            grid: 'grid-cols-2 gap-y-10 grid-flow-column grid-rows-3',
            descripTionFontSize: 20,
            serialFontSize: 36,
            serialGap: 'gap-x-8',
            hrClass: 'mt-8 ',
            serialWidth: 'w-7',
            indexMargin: 'mb-5',
            textAreaHeight: '86px',
            paddingRight: '49px'
        }
    }

    const getConfig = (template, key) => {
        const configurationNo = get(template, 'settings.configuration_no', null)
        if(configurationNo) {
            return configuration[configurationNo][key]
        }
    }

    const removeByKey = (e, index, template) => {
        if (
            e.keyCode === 46 &&
            template.customized_data.descriptionsLength > 1
        ) {
            removeItem(index);
        }
    }

    const removeItem = (template, index) => {
        template.customized_data.descriptions.splice(index, 1);
        updateTemplate(template)
    }

    const createItem = (template, index) => {
        template.customized_data.descriptions.splice(index, 0, {
            id: (Math.random() + 1).toString(36).substring(2),
            title: "Sample text",
            body: "This is an integral part of any presentation. This simple agenda slide template will help you to highlight key points in your presentation",
        });
        updateTemplate(template)
    }

    const setInitialData = (template) => {
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
            set(template.customized_data, 'descriptions', [])
        }

        let checkData = "heading" in template.customized_data;
        if (!checkData) {
            template.customized_data.heading = "Agenda";
        }

        if (template.customized_data?.descriptions?.length == 0) {
            createItem(template, 0);
        }

    }

    const getSerial = (index) => {
        return index < 10 ? "0" + (index + 1) : index + 1;
    }

    return {
        getData,
        removeByKey,
        createItem,
        setInitialData,
        getSerial,
        removeItem,
        getConfig
    }
}
