import { computed } from 'vue'

export default function useLayoutStyle(template) {
    const configNo = computed(() => template.value?.settings.configuration_no)
    const gridClass = {
        1: 'grid grid-cols-5 five-grid-style',
        2: 'grid grid-cols-3 three-grid-style',
    }
    const arrowClass = {
        1: 'w-6 bold mx-auto',
        2: 'w-6 ml-32',
    }
    const getGridClass = () => gridClass[configNo.value]
    const getArrowClass = () => arrowClass[configNo.value]
    return {configNo, getGridClass, getArrowClass}
}