import { render, staticRenderFns } from "./HighLevelContent.vue?vue&type=template&id=0a85fa02&scoped=true&"
import script from "./HighLevelContent.vue?vue&type=script&setup=true&lang=js&"
export * from "./HighLevelContent.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./HighLevelContent.vue?vue&type=style&index=0&id=0a85fa02&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a85fa02",
  null
  
)

export default component.exports