import { get } from 'lodash'
import { set, ref } from 'vue'
import { useFile } from '@/composable/useFile'
import store from '@/store';
import { useTemplate } from '@/views/screens/communicator/useTemplate'

export default function useTitleContent() {
    const { onChange, previewURL, file, appendFile, formData, clear } = useFile()
    const { updateTemplate, saveScreenshot } = useTemplate()

    const fileElement = ref(null)
    const fileIndex = ref(null)
    
    const getElement = elm => fileElement.value= elm
    //have to refactor
    const configuration = {
        1: {
            agendaShowLength: 1,
            grid: 'grid-cols-1 gap-5',
            descripTionFontSize: 28
        },
        2:{
            agendaShowLength: 2,
            grid: 'grid-cols-1',
            descripTionFontSize: 20
        },
        3:{
            agendaShowLength: 9,
            grid: 'grid-cols-2 gap-2',
            descripTionFontSize: 20
        }
    }

    const openFileForTemplate = () => fileElement.value.click()
    const setCurrentFileIndex = (data) => {
        fileIndex.value = data
    }
    

    const onChangeImage = async (event, template) => {
        onChange(event)
        // currentDataLayer.value.image = previewURL
        appendFile('image')
        let {data: {data, status}} =  await store.dispatch('communicator/updateTemplateImage',{id:template.id, data: formData});
        if(status) {
            template.customized_data.images.splice(fileIndex.value -1, 1, data)
        }
        updateTemplate(template)
    }

    const getItemFromDataLayer = (dataLayer, id) => {
        return dataLayer.find(({conf_id}) => conf_id == id)
    }

    const getConfig = (template, key) => {
        const configurationNo = get(template, 'settings.configuration_no', null)
        if(configurationNo) {
            return configuration[configurationNo][key]
        }
    }

    const removeByKey = (template, e, index) => {
        if (e.keyCode === 46 && template.customized_data.descriptionsLength > 1) {
            removeItem(template, index);
        }
        updateTemplate(template)
    }

    const removeItem = (template, itemId) => {
        template.customized_data.descriptions.splice(template.customized_data.descriptions.findIndex(({id}) => id == itemId), 1);
        let dataLayerIndex = template.data_layer.findIndex(({conf_id}) => conf_id == itemId)
        if(dataLayerIndex != -1) {
            template.data_layer.splice(dataLayerIndex, 1);
        }
        updateTemplate(template)
    }

    const removeBodyText = (template, index, bodyIndex) => {
        template.customized_data.descriptions[index].listItems.splice(bodyIndex, 1)
        updateTemplate(template)
    }

    const removeBodyTextKey = (e, index, bodyIndex) => {
        // if (e.keyCode === 46) {
        //   this.removeBodyText(index, bodyIndex);
        // }
    }

    const createNestedBodyText = (template, i, bodyIndex) => {
        template.customized_data.descriptions[i].listItems.splice(
          bodyIndex,
          0,
          {
            title: "Innovative, knowledgeable and pragmatic digital transformation leader. Leads through motivation and empowerment of high performing individuals.",
          }
        );
        updateTemplate(template)
    }

    const createItem = (template, index) => {
        if(template.settings.configuration_no == 2 && template.customized_data.descriptions.length >=2) return
        template.customized_data.descriptions.splice(index, 0, {
            id: (Math.random() + 1).toString(36).substring(2),
            title: "Sample text",
            listItems: [
                {
                title:
                    "Innovative, knowledgeable and pragmatic digital transformation leader. Leads through motivation and empowerment of high performing individuals.",
                },
            ],
        });
        updateTemplate(template)
    }

    const setInitialData = (template) => {
        if (template.customized_data.constructor == Array) {
            template.customized_data = {};
            set(template.customized_data, "descriptions", []);
            updateTemplate(template)
        }
        let checkData = "heading" in template.customized_data;
        if (!checkData) {
            template.customized_data.heading = {
                id: (Math.random() + 1).toString(36).substring(2),
                value: 'Sample Title'
            };
            updateTemplate(template)
        }

        if(!('images' in template.customized_data)) {
            set(template.customized_data, 'images', [null, null]);
            updateTemplate(template)
        }
    
        if (template.customized_data.descriptions.length == 0) {
            createItem(template, 0);
        }
    }

    const getSerial = (index) => {
        return index < 10 ? "0" + (index + 1) : index + 1;
    }

    return {
        removeByKey,
        createItem,
        setInitialData,
        getSerial,
        removeItem,
        removeBodyText,
        removeBodyTextKey,
        createNestedBodyText,
        getConfig,
        getElement,
        openFileForTemplate,
        onChangeImage,
        setCurrentFileIndex,
        getItemFromDataLayer
    }
}