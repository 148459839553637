import {set} from 'vue'
export default function useData() {
    const secondColData = {
        firstRow: {
            title: 'Lorem ipsum',
            data: [
                {
                    title: 'Lorem',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipescent alit. Atenean Bibendum. Lorem ipsum dolor sit amet, consectetur adipescent.  Lorem ipsum dolor sit amet. Atenean Bibendum.'
                },
                {
                    title: 'Lorem',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipescent alit. Atenean Bibendum. Lorem ipsum dolor sit amet, consectetur adipescent.  Lorem ipsum dolor sit amet. Atenean Bibendum.'
                },
            ],
            bg: '#fff'
        },

        secondRow: {
            title: 'Lorem',
            description: 'Lorem ipsum dolor sit amet, consectetur adipescent alit. Atenean Bibendum. Lorem ipsum dolor sit amet, consectetur adipescent.  Lorem ipsum dolor sit amet. Atenean Bibendum.',
            bg: '#fff'
        }
       
    }
    
    const thirdColumnData = {
        firstRow: {
            data: [
                
            ],
            bg: '#fff'
        },

        secondRow: {
            title: 'Lorem ipsum',
            data: [
                {
                    title: 'Lorem',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipescent alit. Atenean Bibendum. Lorem ipsum dolor sit amet, consectetur adipescent.  Lorem ipsum dolor sit amet. Atenean Bibendum.'
                },
                {
                    title: 'Lorem',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipescent alit. Atenean Bibendum. Lorem ipsum dolor sit amet, consectetur adipescent.  Lorem ipsum dolor sit amet. Atenean Bibendum.'
                },
            ],
            bg: '#fff'
        },
       
    }

    const setInitialData = (template, updateTemplate) => {
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
            template.customized_data.title = {id: 'title', value: 'Lorem dollar ipsum ammet'}
            template.customized_data.description = {id: 'description', value: 'Lorem ipsum dolor sit amet, consectetur adipescent alit. Atenean Bibendum. Lorem ipsum dolor sit amet, consectetur adipescent.  Lorem ipsum dolor sit amet. Atenean Bibendum.'}
        }

        let isTableConfig = "tableConfig" in template.customized_data;
        if(!isTableConfig) {
            set(template.customized_data, 'tableConfig',[
                {
                    th: 'Lorem',
                    td: 'Ipsum'
                },
                {
                    th: 'Lorem',
                    td: 'Ipsum'
                }
            ])
        }

        let isColorConfig = "colorConfig" in template.customized_data;
        if(!isColorConfig){
            set(template.customized_data, 'colorConfig', {
                thBg: '#002678',
                cellBg: '#DFEDF9',
                graphBg: '#fff'
            })
        }

        let isScenarioId = "scenarioId" in template.settings;
        if(!isScenarioId) {
            set(template.settings, 'scenarioId', null)
        }
        
        let isModuleId = "moduleId" in template.settings;
        if(!isModuleId) {
            set(template.settings, 'moduleId', null)
        }

        let isFirstColumn = "firstColumn" in template.customized_data;
        if(!isFirstColumn) {
            set(template.customized_data, 'firstColumn', _getFirstColumn())
        }
        
        let isSecondColumn = "secondColumn" in template.customized_data;
        if(!isSecondColumn) {
            set(template.customized_data, 'secondColumn', secondColData)
        }

        let isThirdColumn = "thirdColumn" in template.customized_data;
        if(!isThirdColumn) {
            set(template.customized_data, 'thirdColumn', thirdColumnData)
        }
        let isMilestone = "milestone" in template.customized_data;
        if(!isMilestone) {
            set(template.customized_data, 'milestone', {
                bgColor: '#fff',
                title: 'lorem ipsum',
                data: _getMilestoneData()
            })
        }
    }

    const _getFirstColumn = () => {
        let row = []
        for(let i = 0; i< 3; i++) {
            row.push(_getData())
        }
        return row
    }

    const _getData = () => {
        return {
            title: 'Lorem Ipsum',
            bg: '#fff',
            description: 'Lorem ipsum dolor sit amet, consectetur adipescent alit. Atenean Bibendum. Lorem ipsum dolor sit amet, consectetur adipescent.  Lorem ipsum dolor sit amet. Atenean Bibendum.'
        }
    }

    const _getMilestoneData = () => {
        let data = []
        for(let i = 0; i< 5; i++) {
            data.push({
                title: 'Lorem ipsum dollar shit ammett ogtia',
                boxes: [
                    {value: true},
                    {value: false},
                    {value: true},
                    {value: false},
                    {value: true}
                ]
            })
        }
        return data
    }

    return {
        setInitialData
    }
}

export const style = {
    title: {
        fontWeight: '700',
        fontSize: '40px'
    },

    subtitleInput: {
        width: '1203px',
        height: '66px',
        fontWeight: '400',
        fontSize: '22px',
        lineHeight: '33px',
    },

    firstColumn: {
        box: {
            height: '220px',
            width: '500px',
            boxShadow: '0px 3.05732px 12.2293px rgba(44, 52, 65, 0.1)',
            borderRadius: '0px 0px 4px 4px',

        },
        textarea: {
            height: '143px',
            width: '467px',
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: '400'
        },
        input: {
            fontSize: '18px',
            fontWeight: '400'
        }
    },

    secondColumn: {
        firstRow: {
            box: {
                height: '463px',
                width: '500px',
                boxShadow: '0px 3.05732px 12.2293px rgba(44, 52, 65, 0.1)',
                borderRadius: '0px 0px 4px 4px',
    
            },
            input: {
                fontSize: '18px',
                fontWeight: '400'
            },
            innerInput: {
                fontSize: '14px',
                fontWeight: '700'
            },
            innerTextarea: {
                height: '153px',
                width: '450px',
                fontSize: '14px',
                fontWeight: '400'   
            }
        },

        secondRow: {
            box: {
                height: '220px',
                width: '500px',
                boxShadow: '0px 3.05732px 12.2293px rgba(44, 52, 65, 0.1)',
                borderRadius: '0px 0px 4px 4px',
    
            },
            textarea: {
                height: '143px',
                width: '467px',
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: '400'
            },
            input: {
                fontSize: '18px',
                fontWeight: '400'
            }
        }
    },

    thirdColumn: {
        firstRow: {
            box: {
                height: '348px',
                width: '500px',
                boxShadow: '0px 3.05732px 12.2293px rgba(44, 52, 65, 0.1)',
                borderRadius: '0px 0px 4px 4px',
    
            },
        },

        secondRow: {
            box: {
                height: '334px',
                width: '500px',
                boxShadow: '0px 3.05732px 12.2293px rgba(44, 52, 65, 0.1)',
                borderRadius: '0px 0px 4px 4px',
    
            },
            input: {
                fontSize: '18px',
                fontWeight: '400'
            },
            innerInput: {
                fontSize: '14px',
                fontWeight: '700'
            },
            innerTextarea: {
                height: '67px',
                width: '439px',
                fontSize: '14px',
                fontWeight: '400'   
            }
        }
    }
}