<template>
    <div
        :class="{'scale-down' : currentMode != 'fullscreen'}"
        class="relative outer-container communicator__template--wrapper pt-12 pl-14"
        :style="`color: ${ activeTemplate.settings.color }; --borderColor: ${ activeTemplate.settings.borderColor }`"
    >
      <Header
        :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
        :textColor="getTemplateTextColor(activeTemplate, '#fff')"
        :modules="modules"
        :activeTemplate="activeTemplate"
        @selectScenario="value => scenarioId = value"
      />
      <div class="inner-container">
        <div class="flex items-center relative z-10 mt-0">
            <AttachmentIcon
              :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
              @click.native="showConfiguration(['title'], 'main_title')"
              class="w-5 h-5 cursor-pointer flex-shrink-0 absolute"
              style="left: -94px; top: 45px; transform: scale(1.2);"
            />
            <h2 v-if="title(activeTemplate, 'main_title')" class="fw-900 w-full mt-10 text-4xl left-gap" style="font-size: 63px;" >
              {{ title(activeTemplate, 'main_title') }}
            </h2>
            <input
              v-else
              class="fw-900 w-full mt-3 text-4xl left-gap bg-transparent"
              style="font-size: 63px; margin-bottom: -31px;"
              @input="(e) => editTitle(e.target.value, activeTemplate)"
              :value="getTitle(activeTemplate)"
            />
        </div>

        <div class="relative flex gap-3 items-center">
          <div class="absolute plus-icon">
            <PlusSquareIcon
              :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
              class="w-5 h-5 cursor-pointer flex-shrink-0 "
              @click.native="switchSidebarComponent('DataSource')"
            />
          </div>
        </div>

        <div ref="header" @scroll="(e) => ($refs.body.scrollLeft = e.target.scrollLeft)" class="overflow-auto" style="margin-top: 30px;">
          <!-- Sticky header start -->
          <div class="dynamic-field-grid-header mt-4 mb-5">
            <!-- Label header start -->
            <div class="sticky top-0 left-0"></div>
            <div
              class="text-center fs-20 fw-500 font-light"
              v-for="(property, index) in _getProperties(activeTemplate.parameters, module)"
              :key="index"
            >
              {{ get(property, 'title') }}
            </div>
            <div
              class="sticky top-0 right-0 text-center fs-20 fw-500 font-light"
              v-if="activeTemplate.settings.configuration_no % 2 !== 0"
              :class="{ 'col-start-5': _getProperties(activeTemplate.parameters, module).length < 3 }"
            >
              Summary
            </div>
          </div>
          <!-- Label header End -->

          <div class="dynamic-field-grid h-20 label-header-border">
            <!-- Collection header start -->
            <div class="sticky left-0 top-0 w-full flex items-center fs-24 fw-800 font-bold">
              <h1 class="-mt-3">{{ get(collection, 'title') }}</h1>
            </div>
            <div
              v-for="(property, index) in _getProperties(activeTemplate.parameters, module)"
              :key="index"
              :style="{'color': activeTemplate.settings.borderColor ? activeTemplate.settings.borderColor : ''}"
            >
              <ContentBox
                  :label="_getDefinitionDescription(collection, activeTemplate, property)"
                  :color="_getParameterDefinitionColor(collection, activeTemplate, property)"
              />
            </div>
            <div
              class="sticky right-0 top-0"
              :class="[_getProperties(activeTemplate.parameters, module).length < 3 ? 'col-start-5': '']"
              :style="{'color': activeTemplate.settings.borderColor ? activeTemplate.settings.borderColor : ''}"
            >
              <ContentBox
                outline
                v-if="activeTemplate.settings.configuration_no % 2 !== 0"
                :label="getCollectionAverageDefinition(getAverage(collection, _getProperties(activeTemplate.parameters, module)), activeTemplate)"
                :color="getCollectionAverageBg(getAverage(collection, _getProperties(activeTemplate.parameters, module)), activeTemplate)"
              />
            </div>
          </div>
          <!-- Collection header end -->
        </div>
        <!-- Sticky header End -->
        <div
          class="body overflow-auto"
          @scroll="(e) => ($refs.header.scrollLeft = e.target.scrollLeft)"
          ref="body"
          style="max-height: 615px;display: grid;"
        >
          <div
            class="dynamic-field-grid h-20 body-grid"
            v-for="(child, index) in collections"
            :key="index"
          >
            <!-- Body start -->
            <div class="sticky top-0 left-0 flex items-center fs-20 fw-600 font-bold">
              <h1>{{ get(child, 'title') }}</h1>
            </div>
            <div
              v-for="(property, index) in _getProperties(activeTemplate.parameters, module)"
              :key="index"
              :style="{'color': activeTemplate.settings.borderColor ? activeTemplate.settings.borderColor : ''}"
            >
              <ContentBox
                  :label="_getDefinitionDescription(child, activeTemplate, property)"
                  :color="_getParameterDefinitionColor(child, activeTemplate, property)"
              />
            </div>
            <div
              class="sticky right-0 top-0"
              :class="[_getProperties(activeTemplate.parameters, module).length < 3 ? 'col-start-5': '']"
              :style="{'color': activeTemplate.settings.borderColor ? activeTemplate.settings.borderColor : ''}"
            >
              <ContentBox
                theme-text-color="#fff"
                outline
                v-if="activeTemplate.settings.configuration_no % 2 !== 0"
                :label="getCollectionAverageDefinition(getAverage(child, _getProperties(activeTemplate.parameters, module)), activeTemplate)"
                :color="getCollectionAverageBg(getAverage(child, _getProperties(activeTemplate.parameters, module)), activeTemplate)"
              />
            </div>
          </div>
          <!-- Body end -->
        </div>
      </div>
    </div>
</template>

<script setup>
    import ContentBox from "./ContentBox.vue";
    import TextInput from "@/components/communicator/templates/TextInput.vue";
    import Header from '@/components/communicator/v2/templates/Header.vue';
    import { useDynamicField } from '@/components/communicator/v2/templates/dynamicField/useDynamicField.js';
    import { useTemplate } from '@/views/screens/communicator/useTemplate.js'
    import { useCollection } from '@/composable/useCollection.js';
    import useModule from '@/composable/useModule.js'
    import store from '@/store';
    import { find, get } from 'lodash';
    import useTemplateTitle from '../useTemplateTitle'

    import { ref, onMounted, watchEffect } from 'vue';


    const props = defineProps({
        modules: Array,
        activeTemplate: Object,
        currentMode: String,
        switchSidebarComponent: Function,
        config_visibility: Boolean,
        synchronize: Boolean
    });

    const { initTitle, editTitle, getTitle } = useTemplateTitle();
    const collections = ref([]);
    const scenarioId = ref(null);
    const collection = ref({
        title: '',
        description: ''
    });
    const module = ref({});

    const { getBackground, getParameterBg, getAverage, getCollectionAverageBg, getCollectionAverageDefinition, _getParameterDefinition, _getParameterDefinitionColor, _getProperties, _getDefinitionDescription, } = useDynamicField();
    const { title, getLevelId, getRootType, loadCollection, prepareConfiguration, prepareRequest, getPersistance, getTemplateTextColor } = useTemplate();
    const { getCollectionById } = useCollection();
    const { prepareModuleProperties, getModuleIdFromDataLayer } = useModule();

    const showConfiguration = (options, confId) => {
        props.switchSidebarComponent('TextSource', {
            persistance: getPersistance(props.activeTemplate, confId),
            confId: confId,
            transform: (config) => {
                let inputs = prepareConfiguration(options, config);
                return prepareRequest(props.activeTemplate, confId, config, inputs, props.synchronize);
            }
        })
    }
    watchEffect(async () => {
        let response = await loadCollection(props.activeTemplate, scenarioId.value);
        collection.value = getCollectionById(response, getLevelId(props.activeTemplate));
        collections.value = collection.value?.child || [];

        if(getRootType(props.activeTemplate) === 'module') {
            collections.value = response;
            let module = find(props.modules, ['id', parseInt(getLevelId(props.activeTemplate))])
            collection.value = prepareModuleProperties(module, collections.value);
        }
        module.value = find(props.modules, ['id', getModuleIdFromDataLayer(props.activeTemplate)]);
    });

  onMounted(() => {
    initTitle(props.activeTemplate, 'Headline')
  })
</script>

<style scoped>
.outer-container {
  padding-left: 88px;
  padding-top: 44px;
}
.inner-container{
  padding-left: 68px;
  padding-right: 89px;
  padding-top: 20px;
}
.plus-icon{
  top: 121px;
  left: -61px;
}
.label-header-border{
  height: 89px;
}
.dynamic-field-grid,
.dynamic-field-grid-header {
  display: grid;
  grid-template-columns: 482px repeat(4, 301px);
  grid-auto-flow: column;
  grid-auto-columns: 225px;
}
.label-header-border.dynamic-field-grid > div:not(:first-child) {
  padding: 9px 30px 22px;
}
.label-header-border{
  border-bottom: 0.5px solid var(--borderColor);
}
.dynamic-field-grid > div:not(:first-child) {
  padding: 10px 30px;
  /* background-color: #fff; */
}
.dynamic-field-grid > div:not(:last-child) {
  border-right: 0.5px solid var(--borderColor);
  box-sizing: border-box;
}
.body > div:not(:last-child),
.header > .dynamic-field-grid {
  border-bottom: 0.5px solid var(--borderColor);
  width: fit-content;
}
.body-grid{
  height: 100px;
}
.dynamic-field-grid.body-grid > div:not(:first-child) {
  padding: 19px 25px;
}
</style>
