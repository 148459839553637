<template>
    <div 
        class="wrapper flex-grow-0 relative"
        :class="[currentMode != 'fullscreen' ? 'layout-scale-down' : '']" 
        :style="{
            '--color': activeTemplate.settings.color,
            color: activeTemplate.settings.color,
            '--borderColor':  activeTemplate.settings.borderColor
        }"
    >
        <component 
            :activeTemplate="activeTemplate"
            :switchSidebarComponent="switchSidebarComponent"
            :is='activeTheme'
            :progressSentiment="progressSentiment.definition"
            :config_visibility="config_visibility"
            :currentMode="currentMode"
            :synchronize="synchronize"
        />
    </div>
</template>

<script setup>
    import { watch, watchEffect, set, onMounted, ref } from 'vue'
    import { useTemplate } from '@/views/screens/communicator/useTemplate.js'
    import Roadmap from './Roadmap.vue'
    import Roadmap2 from './Roadmap2.vue'
    import Roadmap3 from './Roadmap3.vue'
    import useRoadMap from "./useRoadMap"
    import routes from '@/routes/routes.js'

    const activeTheme = ref(Roadmap)
    const { setInitialData, getProgressSentiment } = useRoadMap()
    const { getTemplateBg, updateTemplate } = useTemplate()
    const props = defineProps({
        activeTemplate: Object,
        currentMode: {
            default: 'normal'
        },
        switchSidebarComponent: Function,
        config_visibility: Boolean,
        synchronize: Boolean,
    })
    const progressSentiment = ref({})
    const changeTemplate = (config_no) => {
        switch(config_no){
            case 1:
                activeTheme.value = Roadmap
            break;
            case 2:
                activeTheme.value = Roadmap2
            break;
            case 3:
                activeTheme.value = Roadmap3
            break;
        }
    }

    onMounted(async () => {
        if(!props.activeTemplate?.settings?.configuration_no){
            set(props.activeTemplate.settings, 'configuration_no', 1)
            updateTemplate(props.activeTemplate)
        }
 
        if(!Object.keys(props.activeTemplate.customized_data).length){
            const initialData = {
                titleOne: {
                    id: 1,
                    title: 'Roadmap'
                },
                titleTwo: {
                    id: 2,
                    subtitle: 'Sample text',
                    description: 'Innovative, knowledgeable and pragmatic digital transformation leader. Leads through motivation and empowerment of high performing individuals.',
                },
                element: [{
                    id: Date.now(),
                    title: 'Headline',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum. Lorem ipsum dolor sit amet, consectetur adipiscing. ',
                    range: 0,
                    progressColor: '#AD2B81',
                    progressIconName: 'dashboard_close'
                }]
            }
            set(props.activeTemplate, 'customized_data', initialData)
            updateTemplate(props.activeTemplate)
        }
        
        progressSentiment.value = await getProgressSentiment(routes.currentRoute.params.id)        
    })


    watchEffect(() => {
        const config_no = props.activeTemplate.settings?.configuration_no
        changeTemplate(config_no)
    })

    watch(
        () => props.activeTemplate,
        (newVal) => {
            setInitialData(props.activeTemplate)
        },
        { deep: true, immediate: true },
    )
</script>

<style scoped>
    .layout-scale-down{
        transform: scale(0.654);
        transform-origin: 0 0;
    }
    .wrapper{
        padding: 113px 106px 90px 157px;
        width: 1920px;
        height: 1080px;
    }
</style>