
import { useSidebar } from '@/views/screens/communicator/useSidebar.js';
import peopleWidget from '@/components/communicator/v2/templates/roadmapVisualization/peopleWidget/Index.vue';
import timelineWidget from '@/components/communicator/v2/templates/roadmapVisualization/timelineWidget/Visualization.vue'
import Association from '@/components/roadmap/canvas/widgets/AssociatedContent/Visualization.vue'
import Basic from '@/components/roadmap/canvas/widgets/metrics/basic/Visualization.vue'
import Compare from '@/components/roadmap/canvas/widgets/metrics/compare/Visualization.vue'
import Definition from '@/components/roadmap/canvas/widgets/metrics/definition/Visualization.vue'
import LogicBuilder from '@/components/roadmap/canvas/widgets/LogicBuilder/Visualization.vue'
import DynamicFieldVisualization from '@/components/roadmap/canvas/widgets/DynamicField/Visualization.vue'

// All string will be replace by component instance
const components = {
    1: Basic,
    2: Compare,
    3: Definition,
    4: peopleWidget,
    5: DynamicFieldVisualization,
    6: LogicBuilder,
    7: timelineWidget,
    8: 'group-data',
    9: Association
}
export const useRoadmapVisualization = () => {
  
    const { getLevelDataByScenarioAndModule } = useSidebar();

    const loadCollection = async (scenarioId, moduleId) => {
        return await getLevelDataByScenarioAndModule(scenarioId, moduleId, 'nested');
    }

    const getComponentByWidgetType = (type) => components[type];


    return {
        loadCollection,
        getComponentByWidgetType
    }
}