<template>
    <div class="w-72 py-1 rounded-lg custom_shadow overflow-hidden absolute top-10 right-0 bg-white z-10">
        <!-- <div class="flex justify-between items-center p-5 border-b border-secondary-three">
            <span class="fw-500 fs-14 text-primary-one">Layout Size</span>
            <div class="relative">                  
                <select class="bg-secondary-four text-color-616161 rounded w-28 px-2 py-1 appearance-none relative">
                    <option value="Small" selected>Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                </select>
                <svg class="absolute top-3 right-2" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 1L6 6L1 1" stroke="#616161" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>  
            </div>
        </div>
        <div v-if="config" class="flex justify-between items-center p-5 border-b border-secondary-three">
            <span class="fw-500 fs-14 text-primary-one">Definition</span>
            <div class="">
                <SwitchComponent v-model="config.definition" class="transform scale-75 origin-top-right" />
            </div>
        </div> -->
        <div v-if="config" class="flex justify-between items-center p-5 border-b border-secondary-three">
            <span class="fw-500 fs-14 text-primary-one">Legends</span>
            <div class="">
                <SwitchComponent v-model="config.legend" class="transform scale-75 origin-top-right" />
            </div>
        </div>
        <div 
            @click="handleDataClear(); $emit('closeDropdown', false)"
            class="relative flex justify-between text-danger-one items-center p-5 border-b border-secondary-three"
        >
            <span class="fw-500 fs-14">Clear Data</span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#E93D3D" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M12.5 7.5L7.5 12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.5 12.5L7.5 7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>                
        </div>
        <div 
            @click="handleCardDelete(); $emit('closeDropdown', false)"
            class="relative flex justify-between text-danger-one items-center p-5"
        >
            <span class="fw-500 fs-14">Delete</span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#E93D3D" stroke-width="1.5" stroke-miterlimit="10"/>
                <path d="M12.5 7.5L7.5 12.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.5 12.5L7.5 7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    </div>
</template>

<script setup>
    import SwitchComponent from '@/components/communicator/SwitchComponent.vue'
    import { useTeamDashboard } from '@/views/screens/dashboard/useTeamDashboard.js'
    import routes from '@/routes/routes.js'

    const { teamId } = routes.currentRoute.params
    const { dashboardId } = routes.currentRoute.query
    const {
        setDashboard,
        deleteCard,
        clearChartData
    } = useTeamDashboard({mountable: true});
    const props = defineProps({
        cardId: [String, Number],
        config: Object
    })

    const handleCardDelete = () => {
        if(confirm('Are you sure to delete this card?')){
            deleteCard(props.cardId)
            setDashboard(dashboardId, teamId)
        }
    }

    const handleDataClear = () => {
        if(confirm('Are you sure to clear this data?')){
            clearChartData(props.cardId)
            setDashboard(dashboardId, teamId)
        }
    }
</script>

<style scoped>
    .custom_shadow{
        box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.12);
    }
</style>