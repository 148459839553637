<template>
  <div class="i-mt-30">
    <Accordion :title="`Style & Logic`"
               :active="true"
               bg="transparent"
               classes="shadow-none"
               titleClasses="text-primary-one fs-14"
               iconClasses="w-6 h-6"
               buttonClasses="i-right-n-2 i-top-0"
               accordionClasses=""
               :align="'left'">
      <div class="mt-2">
        <div class="flex justify-between">
          <h4 class="fs-12 fw-500 text-primary-one">Levels</h4>
          <h4 class="fs-12 fw-500 text-primary-one">Definition</h4>
        </div>
        <div class="" v-click-outside="hidePicker">
          <div :style="{top : pickerPositionOffset+'px'}" class="absolute color-picker-div l-80" v-if="showPicker">
            <div class="flex flex-col items-center justify-center mt-3">
              <color-panel v-model="color" @change="onColorChange"></color-panel> </div>
          </div>
          <div v-for="(definitionStyle, index) in styles" :key="index" class="flex justify-between items-center mt-2" :class="{'mt-4': index > 0}" >
            <div @click.prevent="openColorPicker(definitionStyle, index)" class="range-section fs-10 text-primary-three shadow-two rounded-sm flex justify-center items-center mr-5 cursor-pointer"
                 :style="{'background': definitionStyle.bg}">{{ definitionStyle.range }}</div>
            <div class="flex justify-between">
              <div class="value-identifier shadow-two" :style="{'background': definitionStyle.bg}"></div>
              <div class="value-identifier shadow-two ml-3" :style="{'background': getMinRange(definitionStyle.range) >= 2 ? definitionStyle.bg : ''}"></div>
              <div class="value-identifier shadow-two ml-3" :style="{'background': getMinRange(definitionStyle.range) >= 4 ? definitionStyle.bg : ''}"></div>
              <div class="value-identifier shadow-two ml-3" :style="{'background': getMinRange(definitionStyle.range) >= 6 ? definitionStyle.bg : ''}"></div>
              <div class="value-identifier shadow-two ml-3" :style="{'background': getMinRange(definitionStyle.range) >= 8 ? definitionStyle.bg : ''}"></div>
            </div>
            <div class="range-section fs-10 text-primary-three shadow-two rounded-sm flex justify-center items-center ml-5" :style="{'background': definitionStyle.bg}">
              <input type="text" class="bg-transparent definition-section text-center" v-model="definitionStyle.definition" />
            </div>
          </div>
        </div>
      </div>
    </Accordion>
  </div>
</template>

<script>
import Accordion from "@/elements/atom/Accordion.vue";
export default {
  name: "StyleLogic",
  props: {
    styles: Array,
    template: {
      type: Object,
      required: true
    }
  },
  components: {
    Accordion
  },
  data() {
    return {
      color: '',
      showPicker: false,
      current: {},
      currentIndex: 0,
    }
  },
  computed: {
    pickerPositionOffset () {
      return 399 + (30 * this.currentIndex)
    }
  },
  methods: {
    hidePicker() {
      this.showPicker = false;
      this.updateTemplate(this.template);
    },
    openColorPicker(obj, index) {
      this.currentIndex = index
      this.current = obj
      this.showPicker = !this.showPicker
    },
    onColorChange(color)  {
      this.current.bg = color
    },
    getMinRange(rangeText) {
      let rangeArray = rangeText.split("-");
      if(rangeArray[0]) {
        return rangeArray[0];
      }
      return 0;
    },
    updateTemplate(template) {
      // let data = this.$lodash.cloneDeep(template);
      // delete data.module;
      // this.$store.dispatch("communicator/updateTemplate", data);
    }
  }
}
</script>

<style scoped>
.range-section {
  width: 64px;
  height: 20px;
}
.definition-section {
  width: 47px;
}
.value-identifier {
  width: 10px;
  height: 20px;
  border-radius: 1px;
  background: #C9C9C9;
}
</style>
