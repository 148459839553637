<template>
    <div>
        <div class="p-10 text-primary-one modal-body" style="width: 680px" v-if="dashboard">
            <div class="i-border-b-1 border-secondary-four mb-7">
                <p class="fs-20 text-primary-one mb-5">Create New Dashboard</p>
            </div>
            <div class="mb-11">
            <h4 class="font-poppins text-secondary-one fs-14 mb-2">Dashboard Title</h4>
                <input
                    v-model="form.title"
                    type="text"
                    class="font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 mt-2 i-border-1 border-solid border-secondary-five rounded"
                    placeholder="Placeholder"
                >
            </div>
            <!-- <div class="flex items-center gap-5 mb-11">
                <div class="flex-auto">
                    <h2 class="fw-500 fs-14 text-secondary-one mb-2">Timeline</h2>
                    <div class="relative">
                        <Datepicker
                            v-model="form.start_time"
                            input-class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two px-4 h-10 i-border-1 border-solid border-secondary-five rounded w-full"
                            placeholder="Select Date"
                            :format="'dd-MM-yyyy'"
                        />
                        <svg class="absolute right-4 top-4 pointer-events-none" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.25 3.125H3.75C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125Z" stroke="#616161" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.75 1.875V4.375" stroke="#616161" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.25 1.875V4.375" stroke="#616161" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3.125 6.875H16.875" stroke="#616161" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div class="flex-auto">
                    <label class="fw-500 fs-14 text-secondary-one mb-2 flex justify-end gap-1">
                        <input type="checkbox" />
                        All time Data
                    </label>
                    <div class="relative">
                        <Datepicker
                            v-model="form.end_time"
                            input-class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two px-4 h-10 i-border-1 border-solid border-secondary-five rounded w-full"
                            placeholder="Select Date"
                            :format="'dd-MM-yyyy'"
                        />
                        <svg class="absolute right-4 top-4 pointer-events-none" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.25 3.125H3.75C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125Z" stroke="#616161" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.75 1.875V4.375" stroke="#616161" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.25 1.875V4.375" stroke="#616161" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3.125 6.875H16.875" stroke="#616161" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div> -->
            <div class="mb-5">
                <h4 class="font-poppins text-secondary-one fs-14">Collaborator</h4>
                <div class="flex justify-between items-center mt-2 relative">
                    <div class="modal-collaborator flex-3 pr-3 relative">
                        <input
                            type="text"
                            class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-secondary-five rounded"
                            placeholder="Type here"
                        >
                        <select class="absolute right-5 top-2 bg-transparent text-secondary-two">
                            <option value="2" selected>Editor</option>
                            <option value="4">Viewer</option>
                        </select>
                    </div>
                    <button class="font-poppins fs-14 fw-600 h-10 w-40 flex-1 text-primary-four rounded border-primary-four bg-transparent i-border-1" >Send Invite</button>
                </div>
            </div>
            <div class="gap-4 flex">
                <button @click="(dashboard = false)" class="px-8 py-4 rounded bg-secondary-one text-white">Create Now</button>
                <button @click="$emit('close')" class="px-8 py-4 rounded border border-secondary-two text-color-616161">Cancel</button>
            </div>
        </div>

        <SampleLayouts @getSelectedLayout="layout => (currentLayout = layout)" v-else>
            <template #confirmation>
                <div class="gap-4 flex mt-8">
                    <button @click="confirmLayout" class="px-8 py-4 rounded bg-secondary-one text-white">Confirm</button>
                    <button @click="create" class="px-8 py-4 rounded border border-secondary-two text-color-616161">Skip</button>
                </div>
            </template>
        </SampleLayouts>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import Datepicker from 'vuejs-datepicker';
    import SampleLayouts from '@/components/dashboard/components/SampleLayouts.vue'
    import { useTeamDashboard } from '@/views/screens/dashboard/useTeamDashboard.js';
    import { useTeam } from '@/views/screens/dashboard/useTeam.js';
    import defaultLayout from '@/mock/dashboard/default/medium.js'

    const { currentTeam, getTeams } = useTeam();
    const { createDashboard } = useTeamDashboard();

    const emit = defineEmits(['close']);
    const dashboard = ref(true);
    const currentLayout = ref({});
    const isFulfilled = ref(false);
    const form = ref({
        workspace_team_id: currentTeam.value.id,
        title: '',
        start_data: '',
        end_data: '',
        cards: []
    });

    const members  = [
        {id: 1, role: '2', name: 'Member one'},
        {id: 2, role: '2', name: 'Member one'},
    ];

    const getAvatar = () => {
        return '/images/profile.png';
    }


    const create = async () => {
        if(!isFulfilled.value) {
            let { rows, cards } = defaultLayout;
        
            if(rows) {
                form.value['rows'] = rows
            }
            if(cards) {
                form.value['cards'] = cards
            }

            await  createDashboard(form.value)
            emit('close')
            getTeams()
        }
    }


    const getFullName = (invitation) => {
        return 'Member one';
        // if(!invitation.user) {
        //     return invitation.email;
        // }
        // let { user } = invitation;
        // return `${user.first_name} ${user.last_name}`;
    }
    const confirmLayout = async () => {
        let { rows, cards } = currentLayout.value;
        if(rows) {
            form.value['rows'] = rows;
        }
        if(cards) {
            form.value['cards'] = cards;
        }
        await createDashboard(form.value);
        isFulfilled.value = true;
        emit('close');
        getTeams();
    }


    const remove = () => {

    }

    defineExpose({ create, dashboard });
</script>

<style scoped>
input[type=checkbox] {
    visibility: visible;
}
.message-box {
    height: 80px;
}
.input-box {
    height: 40px;
}
</style>
