<template>
    <div class="relative p-5" style="width: 500px;">
        <div class="fw-700 fs-20 mb-2 pb-2 border-b">
            Users List
        </div>
        <h1 
            v-for="(user, index) in users" 
            :key="index" 
            class="py-1 cursor-pointer text-gray-500 hover:text-black hover:bg-gray-50"
            @click="changeOwner(user)"
        >
            {{ getName(user) }}
        </h1>
        <div class="i-mt-30">
            <button @click="$emit('close')" class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one rounded text-primary-one h-10 w-40 block ml-auto">
                Cancel
            </button>
        </div>
    </div>
</template>

<script setup>
    import { computed, onMounted, ref } from 'vue'
    import router from '@/routes/routes.js'
    import axios from 'axios'

    const route = router.currentRoute
    const props = defineProps({
        item: Object,
        goalId: [String, Number],
        updateActivity: Function,
        updateGoalObjectForSpecificId: Function
    })
    const users = ref([])
    const emit  = defineEmits()
    const workspaceId = computed(() => route.params.workspaceId)
    const getUsers = async() => {
        let { data } = await axios.get(`workspaces/${workspaceId.value}/users`)
        if(data.status){
            users.value = data.data.filter(item => item.w_user_id)
            return
        }
        users.value = []
    }
    const getName = (user) => {
        let userName = `${user.w_user_first_name} ${user.w_user_last_name}`
        if(user.w_user_first_name || user.w_user_last_name){
            return userName
        }
        return user.w_r_email
    }
    const changeOwner = (user) => {
        props.item.owner_id = user.w_user_id
        props.updateActivity({item: props.item, workspaceId: workspaceId.value, goalId: props.goalId})
        props.updateGoalObjectForSpecificId(props.goalId)
        emit('close')
    }
    onMounted(getUsers)
</script>