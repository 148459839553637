<template>
    <div style="gap: 10px" class="flex flex-col">
        <div style="gap: 10px" class="flex">
            <div
                style="height: 61px; width: 559px"
                :style="{backgroundColor: template.customized_data.thBackgroundColor}"
                class="bg-white rounded flex items-center pl-6 pr-10 relative"
            >
                <TextInput v-model="template.customized_data.first_th" font-size="20" classes="fw-400 w-full" />
                <input @input="updateTemplate(template)" ref="thColorPickerElem" type="color" v-model="template.customized_data.thBackgroundColor" class="absolute right-0 bottom-0 invisible" />
                <ColorIcon @click.native="clickThColorPicker" class="w-4 h-4 cursor-pointer absolute bottom-3 right-4" />
            </div>
            <div 
                style="height: 61px; width: 358px"
                :style="{backgroundColor: template.customized_data.thBackgroundColor}"
                class="bg-white rounded fs-20 flex items-center px-6"
            >
                <TextInput v-model="template.customized_data.second_th" font-size="20" classes="fw-400 w-full" />
            </div>
            <div 
                :style="{backgroundColor: template.customized_data.thBackgroundColor}"
                style="height: 61px; width: 660px" 
                class="bg-white rounded fs-20 flex items-center px-6"
            >
                <TextInput v-model="template.customized_data.third_th" font-size="20" classes="fw-400 w-full" />
            </div>
        </div>

        <div
            v-for="(item, index) in template.customized_data.items"
            :key="item.id+index"
            style="gap: 10px" class="flex"
        >
            <div
                :style="getStyle(item, 'backgroundColor')"
                style="height: 152px; width: 559px" 
                class="rounded fs-20 px-6 pb-6 relative"
            >
                <div class="flex items-center justify-between">
                    <p class="fs-20 fw-700" v-if="getItemFromDataLayer(template.data_layer, item.id)">
                        {{ getItemFromDataLayer(template.data_layer, item.id).title.value }}
                    </p>
                    <TextInput v-else v-model="item.title" font-size="20" classes="fw-700 w-full" />
                    <div :class="{'invisible' : getIconVisibility(currentMode, config_visibility)}" class="flex items-center gap-x-3">
                        <AttachmentIcon :class="{'invisible' : !synchronize}" @click.native="$emit('onClickConfig', item)" class="h-5 w-5 cursor-pointer" />
                        <p @click="createItem(template, index+1)" class="fw-700 cursor-pointer fs-30" >+</p>
                        <p @click="removeItem(template, item.id)" class="fw-700 cursor-pointer fs-30">-</p>
                    </div>
                </div>
                <textarea 
                    v-if="getItemFromDataLayer(template.data_layer, item.id)" 
                    disabled 
                    placeholder="No description" 
                    style="height: 80px" 
                    v-model="getItemFromDataLayer(template.data_layer, item.id).description.value" 
                    class="fs-18 fw-400 bg-transparent w-full">
                </textarea>
                <textarea 
                    v-else 
                    @input="updateTemplate(template)" 
                    style="height: 80px" 
                    v-model="item.description"
                    :disabled="currentMode == 'fullscreen'"
                    class="fs-400 fw-400 bg-transparent w-full">
                </textarea>
                <input @input="updateTemplate(template)" ref="colorPickerElem" type="color" v-model="item.backgroundColor" class="absolute right-0 bottom-0 invisible" />
                <ColorIcon @click.native="clickColorPicker(index)" class="w-4 h-4 cursor-pointer absolute bottom-3 right-4" />
            </div>
            <div
                :style="[
                    getStyle(item, 'backgroundColor')
                ]"
                style="height: 152px; width: 358px" 
                class="rounded fs-20 px-4 pt-2 pb-6 relative cursor-pointer"
                @click="changeStatus(item, item.status)"
            >
                <p class="fw-700">{{ item.status }}</p>
            </div>

            <div
                :style="getStyle(item, 'backgroundColor')"
                style="height: 152px; width: 660px" 
                class="rounded fs-20 px-4 pt-2 pb-6 relative"
            >
               <p class="fw-700">Notes</p>
               <textarea 
                    v-if="getItemFromDataLayer(template.data_layer, item.id)" 
                    :disabled="currentMode == 'fullscreen'" 
                    placeholder="No Note" 
                    style="height: 80px" 
                    @input="updateTemplate(template)"
                    v-model="getItemFromDataLayer(template.data_layer, item.id).note" 
                    class="fs-18 fw-400 bg-transparent w-full">
                </textarea>
                <textarea 
                    v-else 
                    @input="updateTemplate(template)"
                    :disabled="currentMode == 'fullscreen'"
                    style="height: 80px" 
                    v-model="item.note" 
                    class="fs-400 fw-400 bg-transparent w-full">
                </textarea>
            </div>
            
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import TextInput from "@/components/communicator/templates/TextInput.vue";
import EditableComponent from "@/elements/atom/EditableComponent.vue";
import { nextIndex  } from '@/plugins/lodash'

const statusList = ['Yes', 'No', 'Tbd']
const colorPickerElem = ref()
const thColorPickerElem = ref()

const props = defineProps({
    getItemFromDataLayer: Function,
    createItem: Function,
    getStyle: Function,
    removeItem: Function,
    template: Object,
    updateTemplate: Function,
    getIconVisibility: Function,
    config_visibility: Boolean,
    synchronize: Boolean,
    currentMode: String,
})

const changeStatus = (item, status) => {
    item.status = statusList[nextIndex(statusList.indexOf(status), statusList.length)]
    props.updateTemplate(props.template)
}

const clickColorPicker = (i) => colorPickerElem.value[i].click()
const clickThColorPicker = () => thColorPickerElem.value.click()
</script>