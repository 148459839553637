import LayoutEightImg from '@/components/dashboard/components/image/LayoutEightImg.vue'

let layout = {
    name: 'layout8',
    image: LayoutEightImg,
    rows: [
        { id: 'row_19', class: 'grid gap-5' },
        { id: 'row_20', class: 'grid gap-5 grid-cols-3 i-grid-rows-2' },
        { id: 'row_21', class: 'grid gap-5 grid-cols-2' },
    ],
    cards: [
        {
            rows_reference: 'row_19',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        },
        
        {
            rows_reference: 'row_20',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: 'i-row-span-2 col-span-2',
                height: 546,
                componentName: 'LargeChart'
            }
        },
        {
            rows_reference: 'row_20',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_20',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },

        {
            rows_reference: 'row_21',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        },
        {
            rows_reference: 'row_21',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        }
    ],
    isSelected: false
}

export default layout