<template>
    <div class="">
        <div class="overflow-y-auto" style="height: 352px;">
            <table class="fs-12 fw-500 text-color-616161">
                <THead :left="width" />
                <tbody>
                    <Row v-for="item in outcomes" :key="item.id" :item="item" />
                </tbody>
            </table>
        </div>
        <div class="pb-4 pt-2 flex justify-end">
            <button @click="createOutcome(goalId, parent.id)" class="ml-auto mr-5 fs-12 fw-600 text-primary-four">
                + Add Value Driver
            </button>
        </div>
    </div>
</template>

<script setup>
import THead from './THead.vue'
import Row from './Row.vue'
import useOutcome from './useOutcome'
import { onMounted } from 'vue'

const props = defineProps({
    width: String,
    parent: Object,
    goalId: [String, Number],
})
const { createOutcome, outcomes, loadOutcome } = useOutcome()
onMounted(() => {
    loadOutcome(props.goalId)
})
</script>