<template>
    <div class="relative template-wrapper-width-normal">
        <div class="absolute right-0 -top-7 flex items-center gap-x-4 opacity-60">
            <CopyIcon class="w-4 h-4 cursor-pointer" @click.native="copyTemplate(activeLayout, activeTemplate.id)" />
            <TrashIcon @click.native="deleteTemplate(activeLayout, activeTemplate.id, module, handleAfterTemplateDelete)" class="w-4 h-4 cursor-pointer" />
        </div>

        <PanzoomComponent >
            <div id="capture" class="rounded-sm h-full">

                <div
                    class="absolute h-full w-full inset-0"
                    :style="{opacity: activeTemplate.settings.background_color_opacity+'%', backgroundColor: activeTemplate.settings.background_color}"
                >
                </div>
                <img
                    v-if="activeTemplate.image && activeTemplate.image.file_path"
                    class="absolute h-full inset-0 object-cover w-full"
                    :src="activeTemplate.image.file_path"
                    :style="{opacity: activeTemplate.settings.background_image_opacity+'%'}"
                    alt="inst-360"
                >
                    <component
                        class="panzoom-exclude"
                        v-if="activeTemplate && activeTemplate.communicator_template_id"
                        :current-mode="currentMode"
                        :module="module"
                        :modules="modules"
                        :activeTemplate="activeTemplate"
                        :switchSidebarComponent="switchSidebarComponent"
                        :config_visibility="activeLayout.settings.config_visibility"
                        :synchronize="activeLayout.settings.synchronize"
                        :is="getTemplate(activeTemplate.template.component)"
                    />
                <!-- <div v-if="!activeTemplate.image && !activeTemplate.image.file_path" class=" bg-white h-full w-full"></div> -->
            </div>
        </PanzoomComponent>

        <Switcher class="absolute right-0 -bottom-6" @next="setNextTemplate" @prev="setPrevTemplate"/>
        <div class="absolute bottom-6 -right-6" style="transform: scale(0.654);transform-origin: 0 0;">
            <div class="flex items-center gap-x-2">
                <XCircle v-if="activeLayout.file && activeLayout.file.file_path" @click.native="removeLayoutImage(activeLayout)" class="w-4 h-4 cursor-pointer" />
                <div style="width: 180px; height: 24px">
                    <img v-if="activeLayout.file && activeLayout.file.file_path" :src="getFooterLogo(activeLayout)" alt="" class="h-full w-full">
                </div>
                <!-- <TemplateFooterIcons :openFileBrowser="openFile" /> -->
                <MenuAltSolid @click.native="handleFileOpen('footerLogo')" class="w-5 h-3 transform rotate-180 cursor-pointer" />
            </div>
        </div>

        <div class="absolute right-16 -bottom-7">
            <TemplateStyle
                :activeTemplate="activeTemplate"
                :setTemplateBg="setTemplateBg"
                @onClickCoverImage="handleFileOpen('templateBg')"
            />
        </div>

        <div class="mt-1.5 text-primary-one" style="width: 948px" :class="[currentMode != 'fullscreen' ? 'footer-scale' : '']">
            <div class="flex items-center fw-400 fs-22 gap-x-5 pl-2">
                <div class="flex items-center gap-x-3">
                    <p class="flex-shrink-0">Synchronize Scenario Data</p>
                    <SwitchComponent
                        :value="activeLayout.settings.synchronize"
                        @onClick="_changeSynchronize"
                    />
                </div>
                <p class="flex-shrink-0">Last updated: {{ activeTemplate && activeTemplate.updated_at ? $lodash.format(activeTemplate.updated_at, 'dd.MM.yyyy') : null }}</p>
                <div class="flex items-center gap-x-3">
                    <p class="flex-shrink-0">Page Configuration</p>
                    <SwitchComponent
                        :value="activeLayout.settings.config_visibility"
                        @onClick="_changeConfigVisibility"
                    />
                </div>

            </div>
        </div>
        <input type="file" hidden :ref="getFileElement" @input="e => onFileChange(activeTemplate, e)">
    </div>
</template>

<script setup>
import { ref, watch, set } from 'vue'
import usePermission from "@/composable/usePermission.js";
import SwitchComponent from '@/components/communicator/SwitchComponent'
import TemplateStyle from "@/components/communicator/v2/templates/TemplateStyle.vue";
import { useLayout } from '@/views/screens/communicator/useLayout'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import Switcher from "@/elements/atom/Switcher";
import PanzoomComponent from '@/elements/panzoom/PanzoomComponent'
import router from '@/routes/routes.js';

const uploadTarget = ref('')
const route = router.currentRoute;
const  { getTemplate } = useTemplate()
const  { updateLayoutImage, removeLayoutImage, getFooterLogo, deleteLayout, updateLayout } = useLayout()
const { changeConfigVisibility, getFileElement, openFile, updateTemplate,  onSelectBgImage, previewURL, setTemplateBg, deleteTemplate, copyTemplate, onChange, file } = useTemplate()
  // const { canEdit, canCreate, canDelete } = usePermission();
const props = defineProps({
    activeTemplate: Object,
    activeLayout: Object,
    switchSidebarComponent: Function,
    setNextTemplate: Function,
    setPrevTemplate: Function,
    currentMode: String,
    module: Object,
    modules: Array,
    getPdfElement: Function,

})

const handleFileOpen = (type) => {
    uploadTarget.value = type
    openFile()
}

const _changeConfigVisibility = (value) => {
    props.activeLayout.settings.config_visibility = value
    updateLayout({data: {settings: props.activeLayout.settings}, id: props.activeLayout.id})
}
const _changeSynchronize = (value) => {
    props.activeLayout.settings.synchronize = value
    updateLayout({data: {settings: props.activeLayout.settings}, id: props.activeLayout.id})
}

const onFileChange = (activeTemplate, e) => {
    if(!e.target.files.length>0) return;
    if(uploadTarget.value == 'templateBg') {
        onSelectBgImage(activeTemplate, e)
    }
    if(uploadTarget.value == 'footerLogo') {
        onChange(e)
        // updateLayoutImage(props.activeLayout, {id: props.activeLayout.id, data: {image: file.value}})

        let formData = new FormData()
        formData.append('image', file.value)
        updateLayoutImage(props.activeLayout, {id: props.activeLayout.id, data: formData})
    }
}

const handleAfterTemplateDelete = (layout, templateId, module) => {
    const totalTemplate = layout.module_templates.length
    const templateIndex = layout.module_templates.findIndex(({id}) => id == templateId)
    //if only one one template
    if(totalTemplate == 1) {
        deleteLayout(activeLayout, module)
        return
    }
    //if it's last template
    if(totalTemplate-1 == templateIndex) {
        const perviousTemplate = layout.module_templates[templateIndex-1]
        router.push({
            name: 'communicator',
            params: route.params,
            query: {...route.query, ...{template_id: perviousTemplate.id}}
        })
    }
    props.setPrevTemplate()
}

watch(
    () => props.activeTemplate,
    (newVal) => {
        if(newVal && !('borderColor' in newVal.settings)) {
            set(newVal.settings, 'borderColor', null)
            updateTemplate(newVal)
        }
    },
    {immediate: true}
)

watch(
    () => props.activeLayout,
    (newVal) => {
        if(newVal && !('config_visibility' in newVal.settings)) {
            set(newVal.settings, 'config_visibility', true)
            updateLayout({data: {settings: newVal.settings}, id: newVal.id})
        }
        if(newVal && !('synchronize' in newVal.settings)) {
            set(newVal.settings, 'synchronize', true)
            updateLayout({data: {settings: newVal.settings}, id: newVal.id})
        }
    },
    {immediate: true}
)

</script>

<style>
.footer-scale {
    transform: scale(0.654);
    transform-origin: 0 0;

}
#capture{
    height: 100%;
}
</style>
