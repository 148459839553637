var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.isBlock ? 'block' : '']},[(!_vm.editMode)?_c('p',{class:[_vm.classes],style:({
            fontSize: _vm.fontSize+'px',
            color: _vm.color,
            letterSpacing: _vm.spacing+'em',
            fontWeight: _vm.fontWeight
        }),on:{"click":_vm.switchMode}},[_vm._v(_vm._s(_vm.text))]):_vm._e(),(_vm.editMode)?_c('input',{ref:"myinput",staticClass:"bg-transparent border border-gray-100",class:[_vm.classes],style:({
            fontSize: _vm.fontSize+'px',
            color: _vm.color,
            letterSpacing: _vm.spacing+'em'
        }),attrs:{"focused":"true","type":"text"},on:{"blur":_vm.switchMode}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }