<template>
    <div
        class="bg-white shadow-two rounded-sm p-1"
        :class="{'invisible': width == 0}"
        :style="{
            width: reduceWidth +'px',
            marginLeft: marginLeft + 'px'
        }"
    >
        <div
            class="rounded-sm p-1"
            :class="{'invisible': progressWidth == '0%'}"
            :style="{ backgroundColor: background, width: progressWidth }"
        ></div>
    </div>
</template>

<script>
    export default {
        name: 'ProgressBar',
        props: {
            width: {
                type: Number,
                default: 0
            },
            marginLeft: {
                type: Number,
                default: 0
            },
            progress: {
                type: Number,
                default: 0
            },
            background: {
                type: String
            },
            progressWidth: {
                type: String
            }
        },
         computed: {
            reduceWidth() {
                let width = this.width;
                if(width < 200) {
                    return width;
                }
                let pa = parseInt(String(width).charAt(0));
                return this.width - Math.round(this.width * ( pa / 100));
            }
        }
    }
</script>