import { render, staticRenderFns } from "./TopDefinitionPanel.vue?vue&type=template&id=68cc08ba&scoped=true&"
import script from "./TopDefinitionPanel.vue?vue&type=script&setup=true&lang=js&"
export * from "./TopDefinitionPanel.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./TopDefinitionPanel.vue?vue&type=style&index=0&id=68cc08ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68cc08ba",
  null
  
)

export default component.exports