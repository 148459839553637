let row_id = `default_row-${ Date.now() }`

let layout =  {
    name: 'mediumLayout',
    rows: [
        { id: row_id, class: 'grid gap-5 grid-cols-2' }
    ],
    cards: [
        {
            rows_reference: row_id,
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        },
        {
            rows_reference: row_id,
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        }
    ],
    isSelected: false
}

export default layout