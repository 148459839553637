<template>
  <!-- :class="[currentMode != 'fullscreen' ? 'scale-down' : '']" -->
    <div :class="[currentMode != 'fullscreen' ? 'scale-down' : '']" :style="{ color: activeTemplate.settings.color }"
      class="relative rounded flex flex-col justify-center i-gap-10 overflow-hidden communicator__template--wrapper">
      <div :style="{paddingRight: getConfig(activeTemplate, 'paddingRight')}" style="padding-top: 100px" class=" h-full">
        <div class="mb-14 ml-40">
            <TextInput @input="updateTemplate(activeTemplate)" v-model="activeTemplate.customized_data.heading" font-size="64"
                :color="activeTemplate.settings.color" classes="fw-900 w-full"
            />
        </div>
        <div style="height: calc(100% - 290px);" class="overflow-y-scroll ml-14">
          <div class="grid" :class="[getConfig(activeTemplate, 'grid')]">
            <template v-for="(item, index) in activeTemplate.customized_data.descriptions">
              <div :key="index" @keyup.delete="removeByKey($event, index, activeTemplate)">
                <div class="flex items-center" :class="[getConfig(activeTemplate, 'serialGap')]">
                  <div  class="flex items-center gap-x-4" :class="[getConfig(activeTemplate, 'indexMargin')]">

                    <div class="flex items-center gap-x-4" :class="{'invisible' : getIconVisibility(currentMode, config_visibility)}">
                      <AttachmentIcon 
                        :class="{'invisible' : !synchronize}"
                        @click.native="showConfiguration('TextSource', ['title', 'description'], item.id)" class="w-7 h-7 cursor-pointer"
                      />
                      <p :style="{ color: activeTemplate.settings.color }"
                        @click="createItem(activeTemplate, index + 1)"
                        class="fw-800 fs-30 text-secondary-one cursor-pointer">
                        +
                      </p>
                      <p :style="{ color: activeTemplate.settings.color }"
                        @click="removeItem(activeTemplate, index)"
                        class="fw-800 fs-30 text-secondary-one cursor-pointer"
                        :class="{'invisible' : activeTemplate.customized_data.descriptions.length < 2}"
                      >
                        -
                      </p>
                    </div>

                    <p :style="{ color: activeTemplate.settings.color, fontSize: getConfig(activeTemplate, 'serialFontSize')+'px' }" 
                      class="fw-800 text-secondary-one"
                      :class="[presentationState > 0 ? 'i-ml-76' : '', getConfig(activeTemplate, 'serialWidth')]">
                      {{ getSerial(index) }}
                    </p>
                  </div>
                  <div v-if="getDataLayerItem(activeTemplate, item.id)" class="w-full flex flex-col">
                    <p class="fw-700 fs-36 w-full">{{ getDataLayerItem(activeTemplate, item.id).title.value }}</p>
                    <p class="fw-400 w-full" 
                      :style="{
                        fontSize: `${getConfig(activeTemplate, 'descripTionFontSize')}px`,
                        height: getConfig(activeTemplate, 'textAreaHeight')
                      }"
                    >
                        {{ getDataLayerItem(activeTemplate, item.id).description.value || 'No description'}}</p>
                  </div>
                  <div v-else class="w-full flex flex-col">
                    <TextInput 
                      @input="updateTemplate(activeTemplate)" 
                      v-model="item.title" 
                      :color="activeTemplate.settings.color"
                      classes="fw-700 fs-36 w-full" 
                    />
                    
                    <textarea
                      :style="{
                          fontSize: `${getConfig(activeTemplate, 'descripTionFontSize')}px`,
                          height: getConfig(activeTemplate, 'textAreaHeight')
                        }"
                      v-model="item.body"
                      @input="updateTemplate(activeTemplate)"
                      class="fw-400 w-full bg-transparent"
                    ></textarea>
                  </div>
                </div>
                <hr :style="getTemplateBorderColor(activeTemplate, '#fff')" 
                  class="border-t ml-24" :class="[getConfig(activeTemplate, 'hrClass')]"/>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
    import TemplateContainer from "../TemplateContainer.vue";
    import TemplateStyle from "../TemplateStyle.vue";
    import TemplateFooterIcons from '@/components/communicator/v2/templates/TemplateFooterIcons'
    import { useTemplate } from '@/views/screens/communicator/useTemplate'
    import { set, computed, watch } from 'vue';
    import { get } from 'lodash'
    import useAgenda from './useAgenda'

    import TextInput from "@/components/communicator/templates/TextInput.vue";
    import EditableComponent from "@/elements/atom/EditableComponent.vue";

    const {
        getData,
        removeByKey,
        createItem,
        setInitialData,
        removeItem,
        getConfig,
        getSerial
    } = useAgenda()

    const { getConfigNo, getFileElement, openFile, getTemplateBg, onChangeFile, previewURL, updateTemplate, setTemplateBg, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getTemplateBorderColor, getIconVisibility } = useTemplate()

    const props = defineProps({
      activeTemplate: Object,
      switchSidebarComponent: Function,
      currentMode: String,
      config_visibility: Boolean,
      synchronize: Boolean,
    })

    const presentationState = 0
    const emit = defineEmits(['onClickLink'])

    const showConfiguration = (componentName, options, confId) => {
        props.switchSidebarComponent(componentName, {
            persistance: getPersistance(props.activeTemplate, confId),
            transform: (config) => {
              let inputs = prepareConfiguration(options, config);
              return prepareRequest(props.activeTemplate, confId, config, inputs, props.synchronize);
            }
        });
    }

    const descriptionsLength = computed(() => {
      return props.activeTemplate.customized_data?.descriptions?.length;
    });

    watch(
        () => props.activeTemplate,
        (newVal) => {
            // delete newVal.image
            // updateTemplate(newVal)
            setInitialData(props.activeTemplate)
        },
        { deep: true, immediate: true }
    )

</script>

<style scoped>
.h-74 {
  height: 74px;
}
</style>
