<template>
    <div class="flex gap-x-1">
        <div
            class="relative w-12 px-3 bg-red-400 rounded-sm border border-black flex items-center justify-center"
            :style="{
                backgroundColor: bgColor,
                minHeight: minHeight
            }"
        >
            <span class="text-white fs-14">{{ indexCode }}</span>
        </div>
        <div
            v-if="getExpansionStatesByLevel(level) != 'normal'"
            class="relative px-3 bg-red-400 rounded-sm border border-black flex items-center justify-center"
            :style="{
                backgroundColor: bgColor,
                minHeight: minHeight
            }"
            :class="[getWidthClass(level)]"
        >
          <div class="w-full text-white fw-400">
            <p class="text-white fs-14 overflow-y-auto truncate fw-600 w-full">{{ code }}{{ indexCode }} {{ item.title }}</p>
          </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    item: Object,
    level: Number,
    indexCode: String,
    bgColor: String,
    code: String,
    showTopIcon: Boolean,
    expansionMethods: Object,
    minHeight: {
        default: '40px'
    }
})
const { getExpansionStatesByLevel, getWidthClass } = props.expansionMethods

const getText = (item) => item.title
</script>
