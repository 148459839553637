<template>
    <header class="flex border-b border-secondary-four relative" style="height: 44px;">
        <div class="flex justify-between items-center w-full">
            <div 
                class="grid w-full gap-5"
                :style="{
                    gridTemplateColumns: 
                    from == 'progress' ? 
                            `42px 225px 86px 63px 81px 138px 1fr`
                            : '62px 243px 119px 83px 135px 1fr'
                }"
            >
                <div class="flex items-center h-full fs-12">
                    Status
                </div>
                <div class="flex items-center h-full fs-12">
                    Activity
                </div>
                <div class="flex items-center h-full fs-12">
                    Type
                </div>
                <div class="flex items-center justify-center h-full fs-12">
                    Owner
                </div>

                <div v-if="from == 'activityLog'" class="flex items-center h-full fs-12">
                    Activity
                </div>

                <div v-if="from == 'progress'" class="flex items-center justify-center h-full fs-12">
                    Baseline
                </div>
                <div v-if="from == 'progress'" class="flex items-center h-full fs-12">
                    {{  from == 'progress' ? 'Notify Owner' : 'Activity'  }}
                    <SwitchComponent class="transform origin-center-left mr-auto mt-1 -ml-1" style="transform: scale(0.6)" v-model="switcher"/>
                </div>
                <label class="flex items-center fs-12 fw-600">
                    Reviews: 
                    <select class="bg-transparent">
                        <option value="">
                            Weekly
                        </option>
                    </select>
                </label>
            </div>
            <div class="grid gap-4 pr-2" style="grid-template-columns: 89px 89px 71px;">
                <button @click="updateBulkActivities(activities)" v-if="from=='progress'" class="flex items-center justify-center h-full fs-12 text-primary-four">
                    Save Updates
                </button>
                <div class="flex items-center justify-center h-full fs-12">
                    Goal
                </div>
                <div class="flex items-center justify-center h-full fs-12">
                    Weight
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import axios from 'axios'
import SwitchComponent from '@/elements/atom/SwitchComponent.vue'
import { setLoader } from '@/views/screens/dashboard/useLoader.js'
import { useGoal } from '@/views/screens/dashboard/useGoal.js'
import { ref } from 'vue'
import UseProgressCalculation from '@/components/dashboard/goal/components/UseProgressCalculation.js'

const props = defineProps({
    from: String,
    activities: [Array],
    goalId: [Number, String]
})

const { transformGoal } = UseProgressCalculation()
const { showActiveOnly, goals, updateGoalObjectForSpecificId, hasUnSaveItem } = useGoal()
const switcher = ref(true)

const updateBulkActivities = async (activities) => {
    setLoader(true)
    const payload = {
        activities
    }
    await axios.post(`workspaces/goals/objectives/key-results/key-activities/activities/bulk-update`, payload)
    await updateGoalObjectForSpecificId(props.goalId)
    goals.value.forEach(item => transformGoal(item, showActiveOnly.value))
    hasUnSaveItem.value = false
    document.body.onbeforeunload = null
    setLoader(false)
}
</script>


<style scoped>
.w-58px {
    width: 58px;
}
.w-187px {
    width: 187px;
}
.w-136px {
    width: 136px;
}
.w-96px {
    width: 96px;
}
.w-64px {
    width: 64px;
}
.w-100px {
    width: 100px;
}
.w-600px {
    width: 600px;
}
</style>