<template>
    <div class="space-y-1 mt-1">
        <div v-for="item in filteredCollections" :key="item.id">
            <div :class="getHeightClass(getExpansionStatesByLevel(item.id))" class="flex gap-x-1">
                <ParameterText
                    :showNoteComment="true"
                    :parameterBg="getParameterBg(getParameterValue(item, column), widget.definition_style)"
                    :parameterText="getDefinition(getParameterValue(item, column), column, definitionSets)"
                />
                <Description
                    v-if="column.showNoteComment"
                    :horizontalExpansionState="horizontalExpansionState"
                    :verticalExpansionState="getExpansionStatesByLevel(item.id)"
                    :bg="getParameterBg(getParameterValue(item, column), widget.definition_style)"
                    :note="getNote(column, item.id, column.id)"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import store from '@/store'
import { ref, computed } from 'vue'
import useBusiness from '@/components/roadmap/canvas/widgets/Business/useBusiness'
import { getParameterTextDefinition, getParameterValue, getParameterBg} from '@/store/modules/roadmap/services'
import { getDefinition } from '@/store/services/traits/definition';
import Description from './Description';
import ParameterText from './ParameterText';
import useVerticalExpansion from '@/components/communicator/v2/templates/coordinate/useVerticalExpansion'

const props = defineProps({
    column: Object,
    widget: Object,
    module: Object,
    layout: Object,
    filteredCollections: Array,
    flatData: Array,
    isolatedModules: Array,
    horizontalExpansionState: String
})

const { getNote } =useBusiness()
const definitionSets = computed(() => store.getters['definition/definitionSets'])
const {getHeightClass, getExpansionStatesByLevel} = useVerticalExpansion()
</script>