<template>
    <div class="i-mt-28 px-10">
        <Accordion
            title="Content Configuration"
            :active="true"
            bg="transparent"
            classes="shadow-none"
            titleClasses="text-primary-one fs-14"
            iconClasses="w-6 h-6"
            buttonClasses="i-right-n-2 i-top-0"
            accordionClasses="border-none"
            align="left"
        >
        <div>
            <InputSelect
                :items="rootDataSet"
                label="Select source"
                v-model="configuration.rootType"
            />
            <InputSelect
                v-if="configuration.rootType !== 'null'"
                :items="projects"
                text-key="name"
                label="Select project"
                v-model="configuration.project_id"
                @change="(value) => (getScenariosByProject(value), getModulesByProject(value))"
            />
            <InputSelect
                v-if="configuration.project_id !== 'null'"
                :items="scenarios"
                label="Select scenario"
                v-model="configuration.scenario_id"
            />
            <InputSelect
                v-if="configuration.project_id !== 'null'"
                :items="modules"
                label="Select module"
                v-model="configuration.module_id"
                @change="selectModule"
            />
            <div v-if="configuration.rootType >= 1 && configuration.module_id !== 'null'">
                <select class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                        v-model="configuration.first_level_id" @change="setLevelOne">
                    <option value="null" selected disabled>Select Level 1</option>
                    <option v-for="(l1, l1Index) in collections" :key="l1Index" :value="l1.id">{{ l1.title }}</option>
                </select>

                <div v-if="configuration.rootType >= 2 && configuration.first_level_id !== 'null'">
                    <select class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                            v-model="configuration.second_level_id" @change="setLevelTwo">
                    <option value="null" selected disabled>Select Level 2</option>
                    <option v-for="(l2, l2Index) in l1Data" :key="l2Index" :value="l2.id">{{ l2.title }}</option>
                    </select>

                    <div v-if="configuration.rootType >= 3 && configuration.second_level_id !== 'null'">
                    <select class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                            v-model="configuration.third_level_id" @change="setLevelThree">
                        <option value="null" selected disabled>Select Level 3</option>
                        <option v-for="(l3, l3Index) in l2Data" :key="l3Index" :value="l3.id">{{ l3.title }}</option>
                    </select>

                    <div v-if="configuration.rootType >= 4 && configuration.third_level_id !== 'null'">
                        <select class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                                v-model="configuration.fourth_level_id">
                        <option value="null" selected disabled>Select Level 4</option>
                        <option v-for="(l4, l4Index) in l3Data" :key="l4Index" :value="l4.id">{{ l4.title }}</option>
                        </select>
                    </div>
                </div>
          </div>
        </div>
      </div>
    </Accordion>
    <div v-if="configuration.module_id !== 'null'">
        <p class="text-primary-one fs-14 mt-5">Parameter set</p>
        <InputSelect
            :items="properties"
            label="Select Property"
            v-model="configuration.property_id"
            @change="getParameterByProperty"
        />
        <InputSelect
            :items="parameters"
            label="Select Parameter"
            v-model="configuration.parameter_id"
        />
    </div>
    <div class="flex justify-between">
        <button @click="updateTemplate" class="bg-primary-four mt-5 flex items-center justify-center h-8 px-4 rounded text-white text-sm">Save Configuration</button>
        <button class="bg-white mt-5 flex items-center justify-center h-8 px-4 rounded text-secondary-one text-sm border border-primary-four" @click="showConfiguration = false">Close</button>
    </div>
    <p class="fs-12 text-primary-four mt-2">{{ message }}</p>
    </div>
</template>

<script setup>
    import Accordion from "@/elements/atom/Accordion.vue";
    import InputSelect from '@/components/communicator/v2/sidebar/components/InputSelect';
    import { useSidebar } from '@/views/screens/communicator/useSidebar.js';
    import { useConfiguration } from '@/views/screens/communicator/useConfiguration.js';
    import { useProjectData } from '@/components/dashboard/sidebar/useProjectData.js';
    import { useGoal } from '@/views/screens/dashboard/useGoal.js'
    import { ref, onMounted, watch, set } from 'vue';
    import { isEmpty } from 'lodash';
    import router from '@/routes/routes.js';

    const route = router.currentRoute;
    const rootDataSet = [
        {column: 'module_id', storeKey: 'module_id', action: 'getTitleFromModule', id: 'module', title: 'Module'},
        {column: 'level_id', storeKey: 'first_level_id', action: 'getTitleFromFirstLevel', id: 1, title: 'Group Level 1'},
        {column: 'level_id', storeKey: 'second_level_id', action: 'getTitleFromSecondLevel', id: 2, title: 'Group Level 2'},
        {column: 'level_id', storeKey: 'third_level_id', action: 'getTitleFromThirdLevel', id: 3, title: 'Group Level 3'},
        {column: 'level_id', storeKey: 'fourth_level_id', action: 'getTitleFromFourthLevel', id: 4, title: 'Group Level 4'}
    ];

    const { getLevelDataByScenarioAndModule } = useSidebar();
    const { updateConfiguration, showConfiguration, hasUnSaveItem } = useGoal();
    const {
        getProjectsByWorkspace,
        projects,
        getScenariosByProject,
        scenarios,
        getModulesByProject,
        modules,
        getPropertiesByModule,
        properties,
        getParameterByProperty,
        parameters
    } = useProjectData();
    const { l1Data, l2Data, l3Data, collections, setLevelOne, setLevelTwo, setLevelThree, prepareLevelData } = useConfiguration();

    const props = defineProps({
        activity: Object,
        setActiveModule: Function
    });

    const emit = defineEmits(['updateTemplate']);

    const configuration = ref({});
    const message = ref('');

    watch(configuration, (value) => {
        setLevelDataId();
        props.activity.system_data = {
            ...props.activity.system_data,
            ...value
        }
        emit("updateTemplate");
    }, {deep: true});

    const resetConfiguration = () => {
       configuration.value = {
            rootType: 'null',
            project_id: 'null',
            scenario_id: 'null',
            module_id: 'null',
            first_level_id: 'null',
            second_level_id: 'null',
            third_level_id: 'null',
            fourth_level_id: 'null',
            property_id: 'null',
            parameter_id: 'null',
            level_id: null
        }
    }

    const selectModule = async (moduleId) => {
        await getPropertiesByModule(moduleId);
        if(!isNaN(parseInt(configuration.value.rootType))) {
            collections.value = await getLevelDataByScenarioAndModule(configuration.value.scenario_id, moduleId, 'nested');
        }
    }

    const setLevelDataId = () => {
        let item = rootDataSet.find(item => item.id == configuration.value.rootType);
        if(item && item.id !== 'module') {
            configuration.value.level_id = configuration.value[item.storeKey]
        }else{
            configuration.value.level_id = null
        }
    }

    const updateTemplate = async () => {
        let response = await updateConfiguration(props.activity, route.params.workspaceId);
        if(response.status) {
            message.value = response.message
        }
        hasUnSaveItem.value = false
        document.body.onbeforeunload = null
        setTimeout(() => {
            message.value = ''
        }, 3000)
    }
    onMounted(async () => {
        getProjectsByWorkspace(route.params.workspaceId);
        const systemData = props.activity.system_data;
        if(!isEmpty(systemData)) {
           await getScenariosByProject(systemData['project_id'])
           await getModulesByProject(systemData['project_id']);
           await getPropertiesByModule(systemData['module_id']);
           getParameterByProperty(systemData['property_id'])
           await prepareLevelData(systemData, systemData['project_id']);
        
            configuration.value = systemData;
            return;
        }

        resetConfiguration();
    });

</script>
