<template>
    <div class="SidebarTextWrapper overflow-y-auto">
        <h3 class="font-semibold fw-800 fs-36 capitalize truncate" :style="titleStyle"> {{ title }} </h3>
        <p
            v-if="description"
            class="fs-12 fs-20 fw-500"
            :class="isFullWidth ? 'w-full' : 'i-max-w-215'">
                {{ description }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: [String, Boolean]
        },
        isFullWidth: {
            type: Boolean,
            default: false
        },
        titleStyle: Object
    }
}
</script>

<style scoped>
    .SidebarTextWrapper{
        height: 92%;
    }
</style>
