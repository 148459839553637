<template>
    <div>
        <div :class="[componentName != 'Association' ? widthClass : '']" class="gap-x-4 relative">
            <div v-if="componentName != 'Association'" class="sticky top-0">
                <div :class="[widthClass, {'invisible': showIcon}]" class="flex justify-between px-2 fs-20 fw-700 opacity-40 h-10">
                    <p class="cursor-pointer" v-if="expansionState != 'normal'" @click="shrink(stateIndex)">-</p>
                    <p class="cursor-pointer" v-if="expansionState != 'expanded'" @click="expand(stateIndex)">+</p>
                </div>
            
                <div class="i-h-145 w-full bg-primary-four rounded-sm flex items-center justify-center text-white">
                    <span class="fs-16 fw-500">{{ getTitle(column) }}</span>
                </div>
            </div>
            <slot :expandState="expansionState"/>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { prevIndex, nextIndex  } from '@/plugins/lodash'
import useBusiness from '@/components/roadmap/canvas/widgets/Business/useBusiness.js'

const props = defineProps({
    title: String,
    componentName: String,
    column: Object,
    module: Object,
    showIcon: Boolean,
    expansionWidthClass:  {
        default() {
            return {
                normal: 'i-w-181',
                semiexpanded: 'w-72',
                expanded: 'i-w-540'
            }
        }
    }
})
const stateIndex = ref(0)
const expansionStates = ['normal', 'semiexpanded', 'expanded']
const { getPropertyCollection } = useBusiness()

const expansionState = computed(() => expansionStates[stateIndex.value])
const widthClass = computed(() => props.expansionWidthClass[expansionState.value])

const shrink = (index) => stateIndex.value = prevIndex(index, 3)
const expand = (index) => stateIndex.value = nextIndex(index, 3)
const getTitle = (column) => {
    return getParameterExplanation(column) ?? column.title
}

const getParameterExplanation = (column) => {
    if(!column.id || !column.property_id) return
    let collection = getPropertyCollection(props.module.properties, column)
    if(collection && collection.explanation) return 
    return
}
</script>