import Dynamic from './parameterComponent/Dynamic.vue'
import Percentage from './parameterComponent/Percentage.vue'
import Value from './parameterComponent/Value.vue'
import Status from './parameterComponent/Status.vue'
import Blank from './parameterComponent/Blank.vue'
const components = {
    Dynamic,
    Status,
    Percentage,
    Value
}

export const getComponent = (name) => components[name] ? components[name] : Blank