<template>
        <component 
            v-if="activeChildTemplate"
            :is="activeChildTemplate.component"
            :config_visibility="config_visibility"
            :currentMode="currentMode"
            :activeTemplate="activeTemplate"
            :switchSidebarComponent="switchSidebarComponent"
            :box_type="box_type"
            :childTemplateType="getType(activeChildTemplate)"
            :synchronize="synchronize"
            :data="getDataByBoxType(box_type, activeTemplate)"
            class="relative"
            :style="{
                color: getTextColor(props.activeTemplate, box_type)
            }"
        >
            <TemplateSelector 
                :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
                :activeTemplate="activeTemplate"
                :activeChildTemplate="activeChildTemplate"
                :box_type="box_type"
                :switchTemplate="(selectedTemplate) => changeTemplate(selectedTemplate, box_type, activeTemplate)"
            />
            
            <Title 
                :config_visibility="config_visibility"
                :activeTemplate="activeTemplate"
                :data="getDataByBoxType(box_type, activeTemplate)"
                :switchSidebarComponent="switchSidebarComponent"
                :synchronize="synchronize"
            />

            <label 
                v-if="activeChildTemplate.name != 'Picture'"
                title="Change Text Color" class="absolute -bottom-6 -right-8 cursor-pointer"
            >
                <svg style="transform: scale(1)" width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_21438_99114" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="9">
                        <rect width="16" height="8.78871" fill="#C4C4C4"/>
                    </mask>
                    <g mask="url(#mask0_21438_99114)">
                        <rect x="8.2583" width="7.74194" height="4.39435" fill="#19A77C"/>
                        <rect width="7.74194" height="3.84506" transform="matrix(1 0 0 -1 8.2583 8.78906)" fill="#0085FF"/>
                        <rect width="7.74194" height="4.39435" transform="matrix(-1 0 0 1 7.7417 0)" fill="#D6D6D6"/>
                        <rect x="7.7417" y="8.78906" width="7.74194" height="3.84506" transform="rotate(-180 7.7417 8.78906)" fill="#FE5656"/>
                    </g>
                </svg>
                <input 
                    type="color" 
                    @input="(e) => setTextColor({e, activeTemplate, box_type})" 
                    class="absolute top-0 right-0 invisible"
                />
            </label>
        </component>
</template>

<script setup>
    import Title from '@/components/communicator/v2/templates/boxConcept/components/Title.vue'
    import useBoxConcept from '@/components/communicator/v2/templates/boxConcept/useBoxConcept.js'
    import TemplateSelector from '@/components/communicator/v2/templates/boxConcept/components/TemplateSelector.vue'
    import useTemplateConfiguration from '../useTemplateConfiguration'

    const { setTextColor, getTextColor } = useTemplateConfiguration()
    const props = defineProps({
        activeTemplate: Object,
        activeChildTemplate: Object,
        synchronize: Boolean,
        box_type: String,
        templateLocation: String,
        config_visibility: Boolean,
        currentMode: String,
        switchSidebarComponent: Function
    })

    const { changeTemplate, getDataByBoxType } = useBoxConcept()
    const getType = (activeChildTemplate) => {
        let name = activeChildTemplate.value
        if(!name) return
        return name.replaceAll(/\s/g, '-').toLowerCase()
    }
</script>