import { unref, set, watchEffect, isRef } from 'vue'
import { useTemplate } from '@/views/screens/communicator/useTemplate'

export default function useOkrIntroduction(activeTemplate) {
    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getTemplateBorderColor } = useTemplate();
    const onChangeConfig = (config) => {
        updateTemplate(activeTemplate.value)
    }
    const setInitialData = () => {
        const template = unref(activeTemplate)
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
            set(template.customized_data, "descriptions", []);
            template.customized_data.title = {id: 'title', value: 'Lorem dollar ipsum ammet'}
        }
        let checkConfig = "styleConfig" in template.customized_data;
        if(!checkConfig) {
            set(template.customized_data, 'styleConfig', [{
                background_image: '',
                background_image_opacity: 100,
                background_color: '#fff',
                background_color_opacity: 100
            },{
                background_image: '',
                background_image_opacity: 100,
                background_color: '#2B80AD',
                background_color_opacity: 100
            }])
        }
        if (template.customized_data.descriptions.length == 0) {
            createItem(template, 0);
        }
    
    }


    /*==============text related========================*/
    const createItem = (template, index) => {
        template.customized_data.descriptions.splice(index, 0, {
            id: (Math.random() + 1).toString(36).substring(2),
            title: "Sample text",
            listItems: [
                {
                title:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ante tellus, commodo id pharetra sit amet, Nullam ante tellus, commodo id pharetra sit amet,",
                },
            ],
        });
        updateTemplate(template)
    }
    const createNestedBodyText = (template, i, bodyIndex) => {
        template.customized_data.descriptions[i].listItems.splice(
          bodyIndex,
          0,
          {
            title: "Innovative, knowledgeable and pragmatic digital transformation leader. Leads through motivation and empowerment of high performing individuals.",
          }
        );
        updateTemplate(template)
    }
    const removeBodyText = (template, index, bodyIndex) => {
        template.customized_data.descriptions[index].listItems.splice(bodyIndex, 1)
        updateTemplate(template)
    }
    const removeItem = (template, itemId) => {
        template.customized_data.descriptions.splice(template.customized_data.descriptions.findIndex(({id}) => id == itemId), 1);
        let dataLayerIndex = template.data_layer.findIndex(({conf_id}) => conf_id == itemId)
        if(dataLayerIndex != -1) {
            template.data_layer.splice(dataLayerIndex, 1);
        }
        updateTemplate(template)
    }
    /*==============text related========================*/


    const getTitleStyle = () => {
        return {
            fontWeight: '700',
            fontSize: '48px',
            marginLeft: '69px',
            width: '800px'
        }
    }
    
    const updateActiveTemplate = () => updateTemplate(activeTemplate.value)

    if(isRef(activeTemplate)) {
        watchEffect(setInitialData)
    }

    return {
        updateActiveTemplate,
        getDataLayerItem,
        prepareConfiguration, 
        prepareRequest, 
        getPersistance,
        getTemplateBorderColor,
        getTitleStyle,
        onChangeConfig,
        //text related
        createItem,
        removeBodyText,
        createNestedBodyText,
        removeItem
    }
}