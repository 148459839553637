import { set } from 'lodash'
import { ref } from 'vue'
import store from '@/store';
import { useTemplate } from '@/views/screens/communicator/useTemplate'

export default function useImageUpload() {
    const { updateTemplate } = useTemplate()
    const _source = ref('')
    const _imageKeySource = ref('')
    const _fileElement = ref(null)

    const _setKeySource = (type) => _imageKeySource.value = type
    const _setSource = (source) => _source.value = source
    const getFileElement = (elm) => _fileElement.value = elm
    const handleImageUpload = async (target, successCallback = null) => {
        try {
            const { files } = target
            let formData = _appendFile(files[0])
            set(_source.value, _imageKeySource.value, URL.createObjectURL(files[0]))
            let { data: { data, status } } = await store.dispatch('communicator/updateTemplateImage', { id: _source.value.id, data: formData });
            if (status) {
                set(_source.value, _imageKeySource.value, data)
            }
            updateTemplate(_source.value)
        }
        catch (err) {
            console.log(err)
        }

    }
    const onFileSelect = ({ target }, template, successCallback = null) => {
        if (!hasFile(target)) return
        _setSource(template)
        handleImageUpload(target, successCallback = null)
    }
    const onClickUploader = (path, openFile = true) => {
        if (openFile) {
            _fileElement.value.value = null
            _fileElement.value.click()
        }
        _setKeySource(path)
    }
    const hasFile = ({ files }) => files.length > 0;
    const _appendFile = (file, key = null) => {
        let formData = new FormData
        formData.append(key ?? 'image', file)
        return formData
    }

    return {
        onFileSelect,
        onClickUploader,
        getFileElement
    }
}