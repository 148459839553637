var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex gap-x-1"},[_c('div',{staticClass:"relative w-12 px-3 bg-red-400 rounded-sm border border-black flex items-center justify-center",style:({
            backgroundColor: _vm.bgColor,
            minHeight: _vm.minHeight
        })},[_c('span',{staticClass:"text-white fs-14"},[_vm._v(_vm._s(_vm.indexCode))])]),(_setup.getExpansionStatesByLevel(_vm.level) != 'normal')?_c('div',{staticClass:"relative px-3 bg-red-400 rounded-sm border border-black flex items-center justify-center",class:[_setup.getWidthClass(_vm.level)],style:({
            backgroundColor: _vm.bgColor,
            minHeight: _vm.minHeight
        })},[_c('div',{staticClass:"w-full text-white fw-400"},[_c('p',{staticClass:"text-white fs-14 overflow-y-auto truncate fw-600 w-full"},[_vm._v(_vm._s(_vm.code)+_vm._s(_vm.indexCode)+" "+_vm._s(_vm.item.title))])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }