<template>
    <div class="flex gap-1 justify-center flex-col">
        <div class="flex items-start gap-2">
            <div @click="traverseTypes" class="flex flex-col" style="width: 90px;">
                <div
                    class="bg-primary-four flex items-center justify-center px-2 text-white cursor-pointer relative"
                    style="height: 30px; border-radius: 2px;"
                >
                    <span class="fs-12 fw-500"> {{ activityTypeSet[item.type] || '' }}</span>
                    <button v-if="isSystem" class="px-2 absolute -right-6" @click.stop="handleConfiguration(item)" >
                        <GearIcon class="w-3 h-3 cursor-pointer block text-black" />
                    </button>
                </div>
                <p v-if="isSystem" class="fs-10 fw-500 whitespace-nowrap truncate" :title="parameterTitle">
                    {{ parameterTitle }}
                </p>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { useGoal } from '@/views/screens/dashboard/useGoal.js';
    import { nextIndex } from '@/plugins/lodash.js';
    import {ref, computed, watchEffect } from 'vue'
    import { useProjectData } from '@/components/dashboard/sidebar/useProjectData.js'
    
    const { getPropertiesByModule } = useProjectData();
    const { activityTypeSet, handleConfiguration, hasUnSaveItem } = useGoal();
    const props = defineProps({
        item: Object,
        goalId: [String, Number],
    })
    const isSystem = computed(() => props.item.type == 1);
    const traverseTypes = () => {
        let length = Object.keys(activityTypeSet).length;
        props.item.type = nextIndex((props.item.type - 1), length) + 1;
        hasUnSaveItem.value = true
        if(!isSystem.value){
            props.item.system_data = null
        }
    }
    const parameterTitle = ref('')
    watchEffect(async () => {
        parameterTitle.value = '';
        if(props.item.system_data && !!props.item.system_data['module_id']) {
            const properties = await getPropertiesByModule(parseInt(props.item.system_data['module_id']));
            const property = properties.find(item => item.id == parseInt(props.item.system_data['property_id']));
            if(property) {
                const parameter = property.property_collections.find(item => item.id == parseInt(props.item.system_data['parameter_id']));
                parameterTitle.value = parameter?.title || ''
            }
        }
    })
</script>