import { get } from 'lodash'
import axios from 'axios'
import { set } from 'vue'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { useFile } from '@/composable/useFile.js'

export default function useTemplateConfiguration(){
    const { updateTemplate } = useTemplate()
    const { onChange, file } = useFile()
    let timeoutId = null

    const _uploadImage = async (file, template_id) => {
        let formData = new FormData()
        formData.append('image', file)
        let imageResponse = await axios.post(`communicators/templates/${template_id}/template-image-update`, formData)
        let imageUrl = imageResponse.data.data
        return imageUrl
    }

    const configureBackground = async(config, activeTemplate, box_type) => {
        const { background_color, background_color_opacity, background_image, background_image_opacity, event} = config
        let templateConfig = get(activeTemplate, 'customized_data.templateConfig')
        if(!templateConfig) return
        
        let currentTemplateConfig = templateConfig.find(item => item.box_type == box_type)

        if(!currentTemplateConfig) return
        
        set(currentTemplateConfig.background, 'color', background_color)
        set(currentTemplateConfig.background, 'colorOpacity', background_color_opacity)
        set(currentTemplateConfig.background, 'imageOpacity', background_image_opacity)

        if(event && event.target.files.length){
            onChange(event)
            let imagePath = await _uploadImage(file.value, activeTemplate.id)
            set(currentTemplateConfig.background, 'path', imagePath)
        }

        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            updateTemplate(activeTemplate)
        }, 1000)
    }

    const getBackgroundCSS = (activeTemplate, box_type) => {
        let templateConfig = get(activeTemplate, 'customized_data.templateConfig')
        if(!templateConfig) return false
        
        let currentTemplateConfig = templateConfig.find(item => item.box_type == box_type)
        if(!currentTemplateConfig) return false;

        let { path, imageOpacity, color, colorOpacity } = get(currentTemplateConfig, 'background')
        let style = {
            '--background-image': `url(${path})`,
            '--background-image-opacity': imageOpacity ? parseInt(imageOpacity)/100 : 1,
            '--background-color': color,
            '--background-color-opacity': colorOpacity ? parseInt(colorOpacity)/100 : 1,
        }
        return style
    }

    const getBackgroundConfig = (activeTemplate, box_type) => {
        let templateConfig = get(activeTemplate, 'customized_data.templateConfig')
        if(!templateConfig) return false
        
        let currentTemplateConfig = templateConfig.find(item => item.box_type == box_type)
        if(!currentTemplateConfig) return false;

        let { path, imageOpacity, color, colorOpacity } = get(currentTemplateConfig, 'background')
        let config = {
            background_image: path,
            background_image_opacity: imageOpacity ? parseInt(imageOpacity) : 100,
            background_color: color,
            background_color_opacity: colorOpacity ? parseInt(colorOpacity) : 100,
        }
        return config
    }

    const setTextColor = ({e, activeTemplate, box_type}) => {
        let color = e.target.value
        let templateConfig = get(activeTemplate, 'customized_data.templateConfig')

        if(templateConfig){
            let currentTemplateConfig = templateConfig.find(item => item.box_type == box_type)
            set(currentTemplateConfig, 'color', color)
            updateTemplate(activeTemplate)
        }
    }

    const getTextColor = (activeTemplate, box_type) => {
        let templateConfig = get(activeTemplate, 'customized_data.templateConfig')

        if(templateConfig){
            let currentTemplateConfig = templateConfig.find(item => item.box_type == box_type)
            if(currentTemplateConfig){
                return currentTemplateConfig.color
            }
        }
    }

    return {
        configureBackground,
        setTextColor,
        getTextColor,
        getBackgroundConfig,
        getBackgroundCSS
    }
}