var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"flex border-b border-secondary-four relative",staticStyle:{"height":"44px"}},[_c('div',{staticClass:"flex justify-between items-center w-full"},[_c('div',{staticClass:"grid w-full gap-5",style:({
                gridTemplateColumns: 
                _vm.from == 'progress' ? 
                        `42px 225px 86px 63px 81px 138px 1fr`
                        : '62px 243px 119px 83px 135px 1fr'
            })},[_c('div',{staticClass:"flex items-center h-full fs-12"},[_vm._v(" Status ")]),_c('div',{staticClass:"flex items-center h-full fs-12"},[_vm._v(" Activity ")]),_c('div',{staticClass:"flex items-center h-full fs-12"},[_vm._v(" Type ")]),_c('div',{staticClass:"flex items-center justify-center h-full fs-12"},[_vm._v(" Owner ")]),(_vm.from == 'activityLog')?_c('div',{staticClass:"flex items-center h-full fs-12"},[_vm._v(" Activity ")]):_vm._e(),(_vm.from == 'progress')?_c('div',{staticClass:"flex items-center justify-center h-full fs-12"},[_vm._v(" Baseline ")]):_vm._e(),(_vm.from == 'progress')?_c('div',{staticClass:"flex items-center h-full fs-12"},[_vm._v(" "+_vm._s(_vm.from == 'progress' ? 'Notify Owner' : 'Activity')+" "),_c(_setup.SwitchComponent,{staticClass:"transform origin-center-left mr-auto mt-1 -ml-1",staticStyle:{"transform":"scale(0.6)"},model:{value:(_setup.switcher),callback:function ($$v) {_setup.switcher=$$v},expression:"switcher"}})],1):_vm._e(),_vm._m(0)]),_c('div',{staticClass:"grid gap-4 pr-2",staticStyle:{"grid-template-columns":"89px 89px 71px"}},[(_vm.from=='progress')?_c('button',{staticClass:"flex items-center justify-center h-full fs-12 text-primary-four",on:{"click":function($event){return _setup.updateBulkActivities(_vm.activities)}}},[_vm._v(" Save Updates ")]):_vm._e(),_c('div',{staticClass:"flex items-center justify-center h-full fs-12"},[_vm._v(" Goal ")]),_c('div',{staticClass:"flex items-center justify-center h-full fs-12"},[_vm._v(" Weight ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"flex items-center fs-12 fw-600"},[_vm._v(" Reviews: "),_c('select',{staticClass:"bg-transparent"},[_c('option',{attrs:{"value":""}},[_vm._v(" Weekly ")])])])
}]

export { render, staticRenderFns }