var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wrapper layout-scale-down relative bg-primary-four w-full h-full"},[_c(_setup.Header),_c('div',{staticClass:"relative z-10"},[_c('h1',{staticClass:"fs-64 fw-800 text-white mt-4 mb-4"},[_vm._v("Concept Headline")]),_c('div',{staticClass:"text-white flex"},[_c('div',{staticClass:"flex gap-4 w-370px flex-shrink-0"}),_c('div',{staticClass:"flex justify-between items-center w-full"},[_vm._m(0),_c('div',{staticClass:"flex gap-3"},[_c('button',{staticClass:"px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one"},[_vm._v("Groups")]),_c('button',{staticClass:"px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one"},[_vm._v("Atomic")]),_c('button',{staticClass:"px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one"},[_vm._v("3m")]),_c('button',{staticClass:"px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one"},[_vm._v("6m")]),_c('button',{staticClass:"px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one"},[_vm._v("9m")]),_c('button',{staticClass:"px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one"},[_vm._v("YTD")]),_c('button',{staticClass:"px-3 py-2 rounded bg-white text-black hover:opacity-50 shadow-one"},[_vm._v("All")]),_c('button',{staticClass:"px-5 py-2 rounded bg-white text-black hover:opacity-50 shadow-one"},[_vm._v("Animate")]),_c(_setup.Dropdown,{attrs:{"title":"Location","list":[
                            {key: 'UK', value: 'UK'},
                            {key: 'Denmark', value: 'Denmark'},
                            {key: 'Ireland', value: 'Ireland'},
                            {key: 'Sweden', value: 'Sweden'}
                        ]}}),_c(_setup.Dropdown,{attrs:{"title":"Function","list":[
                            {key: 'Function One', value: 'Function One'},
                            {key: 'Function Two', value: 'Function Two'},
                            {key: 'Function Three', value: 'Function Three'},
                            {key: 'Function Four', value: 'Function Four'}
                        ]}}),_c(_setup.Dropdown,{attrs:{"title":"People","list":[
                            {key: 'People one', value: 'People one'},
                            {key: 'People Two', value: 'People Two'},
                            {key: 'People Three', value: 'People Three'},
                            {key: 'People Four', value: 'People Four'}
                        ],"direction":"right"}})],1)])]),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"graph-box"},[_c(_setup.LeftDefinitionPanel),_vm._m(1),_vm._m(2)],1)]),_c('div',{staticClass:"absolute bottom-0"},[_c('h1',{staticClass:"fs-14 fw-500 text-white flex items-center gap-8 mb-3"},[_vm._v(" D1.001 Definition name "),_c('div',{staticClass:"flex gap-2"},[_c('button',[_c('svg',{attrs:{"width":"8","height":"12","viewBox":"0 0 8 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0.997713 6.01963L6.34937 11.3713L7.60937 10.1131L3.51326 6.01963L7.60937 1.92708L6.35027 0.667969L0.997713 6.01963Z","fill":"white"}})])]),_c('button',[_c('svg',{attrs:{"width":"7","height":"12","viewBox":"0 0 7 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6.61166 6.01553L1.26 0.663863L-4.12859e-07 1.92208L4.09611 6.01553L-5.50374e-08 10.1081L1.25911 11.3672L6.61166 6.01553Z","fill":"white"}})])])])])]),_c(_setup.Footer,{staticStyle:{"margin-top":"25px"}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex gap-10"},[_c('div',{staticClass:"flex gap-2 items-center"},[_c('span',{staticClass:"h-4 w-8 block bg-white"}),_vm._v(" Current state ")]),_c('div',{staticClass:"flex gap-2 items-center"},[_c('span',{staticClass:"h-4 w-8 block bg-white"}),_vm._v(" Future state ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content relative"},[_c('div',{staticClass:"chart-wrapper absolute bottom-0 w-full h-full"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bottom-panel top-full w-full"},[_c('div',{staticClass:"number fs-14 fw-400 text-white"},[_c('span',[_vm._v("0")]),_c('span',[_vm._v("2")]),_c('span',[_vm._v("4")]),_c('span',[_vm._v("6")]),_c('span',[_vm._v("8")]),_c('span',[_vm._v("10")])])])
}]

export { render, staticRenderFns }