<template>
    <div class="flex-grow">
        <div 
            v-if="horizontalExpansionState == 'normal'"
            :style="{backgroundColor: bg}"
            class="rounded-sm border border-primary-one truncate h-full w-full flex py-2 justify-center"
        >
            <EditIcon v-if="note.description" class="w-5 h-5" />
        </div>
        <div :style="{backgroundColor: bg}" v-else class="rounded-sm border border-primary-one bg-white px-2 py-2 fs-16 h-full w-full">
            <p :class="[getClamp(verticalExpansionState)]" class="line-clamp">{{ note.description }}</p>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    note: Object,
    horizontalExpansionState: String,
    verticalExpansionState: String,
    bg: String
})

const clamps = {
    'normal': 'clamp-1',
    'semiexpanded': 'clamp-3',
    'expanded': 'clamp-7',
}

const getClamp = (verticalExpansionState) => clamps[verticalExpansionState]
</script>