import axios from 'axios'
import { isEmpty } from 'lodash'
import { ref } from 'vue'


export default function useComment(item){
    const comments = ref([])
    const formForComment = ref({
        i_am: item.i_am,
        text: "",
        id: item.id
    })

    const getComments = async () => {
        const data = await axios.get(`workspaces/goals/comments/${item.id}?i_am=${item.i_am}`).then(({ data }) => data)
        if(data.status){
            comments.value = data.data
        }
    }

    const handleComment = async (formForComment) => {
        if(isEmpty(formForComment.text)) return
        const data = await axios.post(`workspaces/goals/comments/store`, formForComment).then(({ data }) => data)
        if(data.status){
            formForComment.text = ''
            getComments()
        }
    }

    const deleteComment = async (comment) => {
        let user = JSON.parse(localStorage.getItem('_user'))
        if(comment?.created_by?.id === user.id){
            if(confirm('Are you sure you want to delete this comment?')){
                await axios.delete(`workspaces/goals/comments/${comment.id}`)
                getComments()
            }
        }
    }
    return {
        getComments,
        handleComment,
        formForComment,
        comments,
        deleteComment
    }
}