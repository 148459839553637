var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"header"},[_c('h1',{staticClass:"fs-20 fw-800 mb-3 mt-6"},[_vm._v("Diagram Canvas: "+_vm._s(_setup.moduleTitle))])]),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"graph-box"},[(_vm.conceptData.definitions)?_c(_setup.LeftPanel,{attrs:{"definitions":_vm.conceptData.definitions[1]}}):_vm._e(),_c('div',{staticClass:"content relative"},[_c(_setup.GraphLineSvg,{staticClass:"absolute top-0 left-0 right-0 bottom-0 svg-graph pointer-events-none"}),_c('div',{staticClass:"h-full relative overflow-hidden z-10 pointer-events-none"}),_c('div',_vm._l((_setup.getData(_vm.collections)),function(l1,l1Index){return _c('div',{key:l1Index},[((_setup.getXValue(l1)>=_setup.horizontal_filter.minValue)
                                && (_setup.getXValue(l1)<=_setup.horizontal_filter.maxValue)
                                && (_setup.getYValue(l1)>=_setup.vertical_filter.minValue)
                                && (_setup.getYValue(l1)<=_setup.vertical_filter.maxValue)
                        )?[_c('div',[_c(_setup.CircleTitle,{style:({
                                        position: 'absolute',
                                        left: _setup.getPosition(l1, 0, 40) + 'px',
                                        bottom: _setup.getPosition(l1, 1, 40) + 'px',
                                        color: _vm.activeTemplate.settings.color,
                                        background: _setup.getBg(1, l1)
                                    }),attrs:{"showTitle":_vm.showTitle,"title":`${_setup.getIndexId(1)} ${_setup.getRootIndexNumber(1, l1Index)} ${l1.title}`,"circleText":_setup.getRootIndexNumber(1, l1Index),"levelData":l1}})],1),_vm._l((_setup.getData(l1.child)),function(l2,l2Index){return _c('div',{key:l2Index,staticClass:"relative"},[((_setup.getXValue(l2)>=_setup.horizontal_filter.minValue)
                                            && (_setup.getXValue(l2)<=_setup.horizontal_filter.maxValue)
                                            && (_setup.getYValue(l2)>=_setup.vertical_filter.minValue)
                                            && (_setup.getYValue(l2)<=_setup.vertical_filter.maxValue)
                                        )?[_c('div',[_c(_setup.CircleTitle,{style:({
                                                position: 'absolute',
                                                left: _setup.getPosition(l2, 0, 35) + 'px',
                                                bottom: _setup.getPosition(l2, 1, 35) + 'px',
                                                color: _vm.activeTemplate.settings.color,
                                                background: _setup.getBg(1, l2)
                                            }),attrs:{"showTitle":_vm.showTitle,"title":`
                                                ${ _setup.getIndexId(2) }
                                                ${ _setup.getRootIndexNumber(1, l1Index) }.
                                                ${ l2Index + 1 }
                                                ${ l2.title }
                                            `,"circleText":`${ l1Index + 1 }.${ l2Index + 1 }`,"levelData":l2}}),_vm._l((_setup.getData(l2.child)),function(l3,l3Index){return _c('div',{key:l3Index},[((_setup.getXValue(l3)>=_setup.horizontal_filter.minValue)
                                                    && (_setup.getXValue(l3)<=_setup.horizontal_filter.maxValue)
                                                    && (_setup.getYValue(l3)>=_setup.vertical_filter.minValue)
                                                    && (_setup.getYValue(l3)<=_setup.vertical_filter.maxValue)
                                            )?[_c('div',[_c(_setup.CircleTitle,{style:({
                                                            position: 'absolute',
                                                            left: _setup.getPosition(l3, 0, 30) + 'px',
                                                            bottom: _setup.getPosition(l3, 1, 30) + 'px',
                                                            color: _vm.activeTemplate.settings.color,
                                                            background: _setup.getBg(1, l3)
                                                        }),attrs:{"showTitle":_vm.showTitle,"title":`
                                                            ${ _setup.getIndexId(3) }
                                                            ${ _setup.getRootIndexNumber(1, l1Index) }.
                                                            ${ l2Index + 1 }
                                                            ${ l3Index + 1 }
                                                            ${ l3.title }
                                                        `,"circleText":`${ l1Index + 1 }.${ l2Index + 1 }.${ l3Index + 1 }`,"levelData":l3}}),_vm._l((_setup.getData(l3.child)),function(l4,l4Index){return _c('div',{key:l4Index},[((_setup.getXValue(l4)>=_setup.horizontal_filter.minValue)
                                                                && (_setup.getXValue(l4)<=_setup.horizontal_filter.maxValue)
                                                                && (_setup.getYValue(l4)<=_setup.vertical_filter.minValue)
                                                                && (_setup.getYValue(l4)<=_setup.vertical_filter.maxValue)
                                                        )?[_c('div',[_c(_setup.CircleTitle,{style:({
                                                                        position: 'absolute',
                                                                        left: _setup.getPosition(l4, 0, 25) + 'px',
                                                                        bottom: _setup.getPosition(l4, 1, 25) + 'px',
                                                                        color: _vm.activeTemplate.settings.color,
                                                                        background: _setup.getBg(1, l4)
                                                                    }),attrs:{"showTitle":_vm.showTitle,"title":`
                                                                        ${ _setup.getIndexId(3) }
                                                                        ${ _setup.getRootIndexNumber(1, l1Index) }.
                                                                        ${ l2Index + 1 }
                                                                        ${ l3Index + 1 }
                                                                        ${ l4Index + 1 }
                                                                        ${ l4.title }
                                                                    `,"circleText":`${ l1Index + 1 }.${ l2Index + 1 }.${ l3Index + 1 }.${ l4Index + 1 }`,"levelData":l4}})],1)]:_vm._e()],2)})],2)]:_vm._e()],2)})],2)]:_vm._e()],2)})]:_vm._e()],2)}),0)],1),(_vm.conceptData.definitions)?_c(_setup.BottomPanel,{attrs:{"definitions":_vm.conceptData.definitions[0]}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }