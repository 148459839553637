<template>
    <div 
        class="h-full relative"
        :style="{
            color: activeTemplate.settings.color, 
            '--color': activeTemplate.settings.color,
            '--bg-color': activeTemplate.settings.background_color,
            '--borderColor': activeTemplate.settings.borderColor
        }">
        <div 
            class="wrapper flex-grow-0 relative"
            :class="[currentMode != 'fullscreen' ? 'layout-scale-down' : '']" 
        >
            <div  
                :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
                class="relative z-10 w-full flex gap-5 top-bar"
            >
                <div :class="currentMode == 'fullscreen' && 'invisible'" >
                    <span class="fw-800 fs-14">Definition Set: </span>
                    <select 
                        @change="(event)=>getDefinition(event, 1)" 
                        class="bg-transparent outline-none fw-600 fs-14"
                        v-model="activeDefinitionIds[0]"
                    >
                        <option value="0" selected>-Select-</option>
                        <option v-for="set in definitionSets" :key="set.id" :value="set.id">{{ set.title }}</option>
                    </select>
                </div>
                <div>
                    <span class="fw-800 fs-14">Definition Set: </span>
                    <select 
                        @change="(event)=>getDefinition(event, 2)" 
                        class="bg-transparent outline-none fw-600 fs-14"
                        v-model="activeDefinitionIds[1]"
                    >
                        <option value="0" selected>-Select-</option>
                        <option v-for="set in definitionSets" :key="set.id" :value="set.id">{{ set.title }}</option>
                    </select>
                </div>
                <div>
                    <span class="fw-800 fs-14">Definition Set: </span>
                    <select 
                        @change="(event)=>getDefinition(event, 3)" 
                        class="bg-transparent outline-none fw-600 fs-14"
                        v-model="activeDefinitionIds[2]"
                    >
                        <option value="0" selected>-Select-</option>
                        <option v-for="set in definitionSets" :key="set.id" :value="set.id">{{ set.title }}</option>
                    </select>
                </div>
            </div>

            <Title :activeTemplate="activeTemplate" />
            <div class="definitions relative z-10 overflow-x-auto">
                <div>
                    <h2 v-if="definitionSetOne.length" class="fs-30 fw-600 mb-6 mt-8">{{ definitionSetOne[0]['title'] }}</h2>
                    <div v-if="definitionSetOne.length" class="flex flex-col-reverse border-b borderColor">
                        <div v-for="(range_item, index) in definitionSetOne[0]['range_with_definition']" :key="'ruuid'+index" 
                            class="flex items-center gap-4 fs-22 fw-600 border-t borderColor">
                            <div class="flex items-center gap-4 item">
                                <span v-if="range_item.range" class="whitespace-nowrap">{{ range_item.range }}:</span>
                                <span 
                                    v-if="range_item.range && range_item.range.indexOf('Level')<0" 
                                    class="max-h-full overflow-y-auto"
                                >
                                    {{ range_item.definition }}
                                </span>
                                <span v-else class="max-h-full overflow-y-auto fs-18 fw-600">
                                    {{ range_item.definition }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 v-if="definitionSetTwo.length" class="fs-30 fw-600 mb-6 mt-8">{{ definitionSetTwo[0]['title'] }}</h2>
                    <div v-if="definitionSetTwo.length" class="flex flex-col-reverse border-b borderColor">
                        <div v-for="(range_item, index) in definitionSetTwo[0]['range_with_definition']" :key="'ruuid'+index" 
                            class="flex items-center gap-4 fs-22 fw-600 border-t borderColor">
                            <div class="flex items-center gap-4 item">
                                <span v-if="range_item.range" class="whitespace-nowrap">{{ range_item.range }}:</span>
                                <span 
                                    v-if="range_item.range && range_item.range.indexOf('Level')<0" 
                                    class="max-h-full overflow-y-auto"
                                >
                                    {{ range_item.definition }}
                                </span>
                                <span v-else class="max-h-full overflow-y-auto fs-18 fw-600">
                                    {{ range_item.definition }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 v-if="definitionSetThree.length" class="fs-30 fw-600 mb-6 mt-8">{{ definitionSetThree[0]['title'] }}</h2>
                    <div v-if="definitionSetThree.length" class="flex flex-col-reverse border-b borderColor">
                        <div v-for="(range_item, index) in definitionSetThree[0]['range_with_definition']" :key="'ruuid'+index" 
                            class="flex items-center gap-4 fs-22 fw-600 border-t borderColor">
                            <div class="flex items-center gap-4 item">
                                <span v-if="range_item.range" class="whitespace-nowrap">{{ range_item.range }}:</span>
                                <span 
                                    v-if="range_item.range && range_item.range.indexOf('Level')<0" 
                                    class="max-h-full overflow-y-auto"
                                >
                                    {{ range_item.definition }}
                                </span>
                                <span v-else class="max-h-full overflow-y-auto fs-18 fw-600">
                                    {{ range_item.definition }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import store from '@/store'
    import { onMounted, ref, watch } from 'vue'
    import routes from '@/routes/routes.js'
    import { useTemplate } from '@/views/screens/communicator/useTemplate'
    import useDefinition from "./useDefinition"
    import Title from './components/Title.vue'

    const { setInitialData } = useDefinition()
    const props = defineProps({
        activeTemplate: Object,
        config_visibility: Boolean,
        currentMode: {
            default: 'normal'
        }
    })
    const { getTemplateBg, updateTemplate } = useTemplate()
    const projectId = ref(routes.currentRoute.params?.id)
    const definitionSets     = ref([])
    const definitionSetOne   = ref([])
    const definitionSetTwo   = ref([])
    const definitionSetThree = ref([])
    const activeDefinitionIds = ref([])


    const getDefinition = (e, sl, id=null) => {
        const definitionId = (id) ? id : e && e.target.value
        if(!definitionId) return;
        store.dispatch("definition/getDefinitionSet", { definition_id: definitionId })
        .then(res=>{
            switch(sl){
                case 1:
                    activeDefinitionIds.value[0] = definitionId
                    definitionSetOne.value = res.data.data?.items
                break
                case 2:
                    activeDefinitionIds.value[1] = definitionId
                    definitionSetTwo.value = res.data.data?.items
                break
                case 3:
                    activeDefinitionIds.value[2] = definitionId
                    definitionSetThree.value = res.data.data?.items
                break
            }


            // set selected definition as active
            if(props.activeTemplate.data_layer.length)
            {
                props.activeTemplate.data_layer[0]['activeDefinitionId'] = activeDefinitionIds.value
                updateTemplate(props.activeTemplate)
            }
        })
    }

    onMounted(() => {
        store.dispatch("definition/getDefinitionSets", { project_id: projectId.value })
        .then(res=>{
            definitionSets.value = res.data.data
        })
        
        // load active definition set
        if(props.activeTemplate.data_layer.length){
            const activeDefinitionIds = props.activeTemplate.data_layer[0]['activeDefinitionId']
            if(activeDefinitionIds){
                getDefinition(null, 1, activeDefinitionIds[0])
                getDefinition(null, 2, activeDefinitionIds[1])
                getDefinition(null, 3, activeDefinitionIds[2])
            }
        }
    })

    watch(
        () => props.activeTemplate,
        (newVal) => {
            // delete newVal.image
            // updateTemplate(newVal)
            setInitialData(props.activeTemplate)
        },
        { deep: true, immediate: true },
    )
</script>

<style scoped>
    .layout-scale-down{
        transform: scale(0.654);
        transform-origin: 0 0;
    }
    .wrapper{
        padding: 50px 157px;
        width: 1920px;
        height: 1080px;
    }
    .top-bar>div{
        width: 550px;
        flex-shrink: 0;
    }

    .set-name{
        font-size: 62px;
    }
    .definitions{
        display: flex;
        gap: 84px;
    }
    .definitions>div{
        width: 480px;
        flex-shrink: 0;
        height: 780px;
        overflow-y: auto;
    }
    .item{
        display: flex;
        align-items: center;
        height: 130px;
        padding: 20px 0;
        overflow-x: auto;
    }
    .borderColor{
        border-color: var(--borderColor);
    }
    select option{
        background-color: var(--bg-color);
        color: var(--color);
    }
</style>