import smallLayout from '@/mock/dashboard/default/small.js'
import mediumLayout from '@/mock/dashboard/default/medium.js'
import largeLayout from '@/mock/dashboard/default/large.js'
import wideLayout from '@/mock/dashboard/default/wide.js'
import extraLargeLayout from '@/mock/dashboard/default/extraLarge.js'
import { useTeamDashboard } from '@/views/screens/dashboard/useTeamDashboard.js'
import axios from 'axios'
import router from '@/routes/routes.js';

const useAddRow = () => {
    const route = router.currentRoute

    const rowTypes = [
        {
            key: 'smallLayout',
            value: 'Small'
        },
        {
            key: 'mediumLayout',
            value: 'Medium'
        },
        {
            key: 'largeLayout',
            value: 'Large'
        },
        {
            key: 'wideLayout',
            value: 'Wide'
        },
        {
            key: 'extraLargeLayout',
            value: 'Extra Large'
        },
    ]

    const _getDashboardData = async (dashboardId) => {
        const { data } = await axios.get(`workspaces/teams/dashboards/${ dashboardId }`)
        return data.data
    }

    const handleRow = async (layout, dashboardId) => {
        let dashboard = await _getDashboardData(dashboardId)
        const { rows, cards } = layout
        dashboard.rows.push(...rows)
        
        await axios.post(`workspaces/teams/dashboards/update/${ dashboardId }`, dashboard)

        // card create
        let cardPayload = {
            workspace_team_dashboard_id: dashboardId,
            cards: [...cards]
        }
        await axios.post(`workspaces/teams/dashboards/cards`, cardPayload)

        _updateDashboardData()
    }

    const _updateDashboardData = () => {
        const dashboardId = route.query.dashboardId
        const teamId = route.params.teamId
        const { setDashboard } = useTeamDashboard()
        setDashboard(dashboardId, teamId)
    }

    return {
        rowTypes,
        handleRow,
        layout: [
            smallLayout,
            mediumLayout,
            largeLayout,
            wideLayout,
            extraLargeLayout
        ]
    }
}



export { useAddRow }