export default {
    content: {
        textarea: {
            // height: '121px',
            width: '670px',
            fontWeight: '400',
            fontSize: '18px',
            lineHeight: '27px',
            marginTop: '9px',
            marginLeft: '30px',
        },
        input: {
            fontWeight: '700',
            fontSize: '24px',
            width: '100%',
            marginTop: '15px',
        },
        icon: {
            bottom: '13px',
            right: '15px',
        }
    },

    mainConcept: {
        textarea: {
            // height: '121px',
            width: '602px',
            fontWeight: '300',
            fontSize: '14px',
            lineHeight: '21px',
            marginTop: '-1px',
            textAlign: 'center'
        },
        input: {
            fontWeight: '700',
            fontSize: '28px',
            width: '100%',
            textAlign: 'center',
            marginTop: '12px',
        },
        icon: {
            bottom: '13px',
            right: '15px',
        }
    },

    items: {
        textarea: {
            // height: '121px',
            width: '185px',
            fontWeight: '300',
            fontSize: '14px',
            lineHeight: '21px',
            marginTop: '1px',
            textAlign: 'center'
        },
        input: {
            fontWeight: '700',
            fontSize: '20px',
            width: '100%',
            marginTop: '19px',
        },
        icon: {
            right: '13px',
            top: '5px',
        }    
    },

    imageWrapper: {
        height: '681px',
        width: '638px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '20px',
        opacity: '0.9',
        borderColor: '#000000',
        borderStyle: 'dashed',
    }
}