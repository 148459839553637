import CoverPageTemplate from '@/components/communicator/v2/templates/frontPage/CoverPageTemplate'
import BlankTemplate from '@/components/communicator/v2/templates/blank/BlankTemplate'
import SectionCover from '@/components/communicator/v2/templates/sectionCover/SectionCover'
import AgendaTemplate from '@/components/communicator/v2/templates/agenda/AgendaTemplate'
import TitleContent from '@/components/communicator/v2/templates/titleContent/TitleContent'
import SystemContent from '@/components/communicator/v2/templates/systemContent/SystemContent'
import ProfileComponent from '@/components/communicator/v2/templates/profile/ProfileComponent'
import Canvas from '@/components/communicator/v2/templates/canvas/index.vue'
import Concept from '@/components/communicator/v2/templates/concept/index.vue'
import Definition from '@/components/communicator/v2/templates/definition/index.vue'
import TableConcept from '@/components/communicator/v2/templates/tableConcept/Index.vue'
import coordinateComponents from './templateImporter/coordinate'
import orkComponents from './templateImporter/okr'
import roadmap from './templateImporter/roadmap'

import ChartBar from '@/components/communicator/v2/templates/businessIntelligence/ChartBar.vue'
import WorkzoneTimeline from '@/components/communicator/v2/templates/businessIntelligence/WorkzoneTimeline.vue'

import MetricSummary from '@/components/communicator/v2/templates/metricSummary/MetricSummary.vue'
import MetricDetails from '@/components/communicator/v2/templates/metricDetails/MetricDetails.vue'
import DynamicField from '@/components/communicator/v2/templates/dynamicField/DynamicField.vue'
import roadmapVisualization from '@/components/communicator/v2/templates/roadmapVisualization/Index.vue'
import Blank from '@/components/communicator/v2/templates/Blank'
import Roadmap from '@/components/communicator/v2/templates/roadmap/Index.vue'
import businessIntelligence from '@/components/communicator/v2/templates/businessIntelligence/index.vue'
import boxConcept from '@/components/communicator/v2/templates/boxConcept/ThreeBoxConcept.vue'
import FiveBoxConcept from '@/components/communicator/v2/templates/boxConcept/FiveBoxConcept.vue'
import HighLevel from '@/components/communicator/v2/templates/roadmap/HighLevel.vue'

const templates = {
    ...coordinateComponents,
    ...orkComponents,
    ...roadmap,
    'cover-page-template': CoverPageTemplate,
    'blank-template': BlankTemplate,
    'section-cover': SectionCover,
    'profile-component': ProfileComponent,
    'title-content': TitleContent,
    'system-content': SystemContent,
    'agenda': AgendaTemplate,
    'canvas': Canvas,
    'concept': Concept,
    'table-concept': TableConcept,
    'definition': Definition,
    'chart-bar': ChartBar,
    'work-zone-timeline': WorkzoneTimeline,
    'metric-summary': MetricSummary,
    'metric-details': MetricDetails,
    'dynamic-field': DynamicField,
    'roadmap-visualization': roadmapVisualization,
    'roadmap': Roadmap,
    'business-intelligence': businessIntelligence,
    'box-concept': boxConcept,
    'box-concept-5': FiveBoxConcept,
    'high-level-roadmap': HighLevel,
}
export const getTemplate = (name) => templates[name] ? templates[name] : Blank
// export const getTemplate = () => templates['highlevel-plan']
