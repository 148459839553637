<template>
    <apexchart
        :type="type"
        :options="options"
        :height="chartHeight"
        :series="series"
    />
</template>

<script setup>
    import apexchart from 'vue-apexcharts';
    import { computed } from 'vue'
    import { get, merge } from 'lodash';

    const props = defineProps({
        card: Object,
        type: {
            type: String,
            required: true
        },
        series: {
            type: [Array, Object],
            required: true
        },
        chartOptions: {
            type: Object,
            required: true
        }
    });
    const chartHeight = computed(() => get(props.card, 'settings.height') - 117);

    const options = computed(() => merge({
        chart: {
            type: props.type,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: props.card.configuration.legend,
            horizontalAlign: 'left',
            offsetX: 38,
            position: 'top',
            markers: {
                radius: 2
            }
        }
    }, props.chartOptions))
</script>