import { ref, set } from 'vue'
import store from '@/store';
import router from '@/routes/routes.js';
import { get, find, debounce, isFunction, isArray, isNull, isString, at } from 'lodash'
import { getTemplate } from '@/views/screens/communicator/useTemplateImporter'
import useScreenshot from '@/composable/useScreenshot'
import { useSidebar } from '@/views/screens/communicator/useSidebar.js';
import { useFile } from '@/composable/useFile'

const levelDataMapping = {
    1: 'first_level_id',
    2: 'second_level_id',
    3: 'third_level_id',
    4: 'fourth_level_id',
    'module': 'module_id'
}

export const useTemplate = () => {
    const { getLevelDataByScenarioAndModule } = useSidebar();

    const { getBlobImageFile } = useScreenshot()

    const { onChange, previewURL, file, appendFile, formData, clear } = useFile()
    const fileElement = ref(null)
    const route = router.currentRoute;

    const getTemplateBg = template => {
        return get(template, 'image.file_path', null)
    }

    const setTemplateBg = async (template, data) => {
        set(template, 'image', data)
    }
    
    const deleteBgImage = async (template, data) => {
        set(template, 'image', data)
        await store.dispatch("communicator/deleteTemplateBackground", {id: template.id});
    }

    const getFileElement = elm => fileElement.value= elm
    const openFile = () => {
        fileElement.value.value = null
        fileElement.value.click()
    }

    const onSelectBgImage = async (template, event) => {
        onChange(event)
        setTemplateBg(template, {file_path: previewURL.value})
        // appendFile('image') //function on usefile
        try{
            let fData = new FormData()
            fData.append('image', file.value)
            const {data: {data, status}} = await store.dispatch("communicator/updateTemplateBackground", {id: template.id, data: fData});
            if(status) {
                setTemplateBg(template, data)
            }
        }catch(err) {
            //err
        }
        clear()
    }

    const createTemplate = async (selectedLayout, activeTemplateId) => {
        const data = createTemplateData(route.params.communicator_id, {title: 'Default'}, selectedLayout)
        data.current_template_id = activeTemplateId
        let response = await store.dispatch("communicator/addTemplate", data);
        if(response.data.data) {
            pushTemplate(selectedLayout, activeTemplateId, response.data.data)
            // selectedLayout.module_templates.push();
        }
    }

    const pushTemplate = (layout, templateId, data) => {
        const templateIndex = layout.module_templates.findIndex(({id}) => id == templateId)
        if(templateIndex == undefined) return
        layout.module_templates.splice(templateIndex+1, 0, data)
    }

    const deleteTemplate = (selectedLayout, templateId, module, cb=null) => {
        store.dispatch("communicator/deleteTemplate", {id: templateId})
        .then(() => {
            if(cb) cb(selectedLayout, templateId, module)
            selectedLayout.module_templates.splice(selectedLayout.module_templates.findIndex(({id}) => id == templateId), 1);
        });
    }

    const copyTemplate = (selectedLayout, templateId) => {
        store.dispatch("communicator/copyTemplate", {id: templateId})
        .then((response) => {
            selectedLayout.module_templates.push(response.data.data);
        });
    }

    const staticData = {
        parameters: [{ property_id: null, id: null, definition_id: null }],
        data_layer: { root_type: 'module', root_id: null, first_level_id: null, second_level_id: null, third_level_id: null, fourth_level_id: null, not_in_list: [] },
        settings: { theme: 'light' }, // study
        definition_style: [
            { range: '0-2', bg: '#AD2B81', definition: 'Very Low' },
            { range: '2-4', bg: '#ABA62B', definition: 'Low' },
            { range: '4-6', bg: '#8D8D8D', definition: 'Medium' },
            { range: '6-8', bg: '#2BAD7E', definition: 'High' },
            { range: '8-10', bg: '#2B80AD', definition: 'Extreme' }
        ],
        customized_data: []
    }

    const createTemplateData = (communicatorId, template, layout) => {
        return {
            communicator_id: Number(communicatorId),
            communicator_layout_id: layout.id,
            title: template.title,
            order: layout?.module_templates?.length > 0 ? layout.module_templates[layout.module_templates?.length - 1].order + 1 : 1,
            ...staticData
        }
    }
    const updateTemplate = debounce((template) => {
        try {
            store.dispatch('communicator/modifyTemplate', template)
        }catch(err) {
        //
        }
    }, 500);

    const uploadTemplate = (payload) => store.dispatch("communicator/uploadTemplateToLibrary", payload);


    const getLevelId = (template) => {
        let dataConfiguration = find(template.data_layer, ['conf_id', 'data_source']);
        dataConfiguration = get(dataConfiguration, 'persistance', {});
        return dataConfiguration[levelDataMapping[get(dataConfiguration, 'rootType')]]
    };
    const getRootType = (template) => {
        let dataConfiguration = find(template.data_layer, ['conf_id', 'data_source']);
        return get(dataConfiguration, 'persistance.rootType', null);
    };

    // Template configuration helper methods
    const loadCollection = async (template, scenarioId = null) => {
        let dataConfiguration = get(find(template.data_layer, ['conf_id', 'data_source']), 'persistance');
        let [scenario_id, module_id, rootType] = at(dataConfiguration, ['scenario_id', 'module_id', 'rootType']);
        if(!isNull(scenarioId)) {
            scenario_id = scenarioId;
        }
      
        let response = [];
        let params = {
            with_parameter: true
        }
        if(rootType !== 'module') {
            params['level_id'] = getLevelId(template)
        }
        if(scenario_id && module_id) {
            response = await getLevelDataByScenarioAndModule(scenario_id, module_id, params);
        }
        return response;
    }
    const _getConfiguredContent = (template, confId, key) => {
        if(!isArray(template.data_layer)) {
            throw new Error('template.data_layer is not a valid array');
        }
        let conf = find(template.data_layer, ['conf_id', confId]);
        return get(conf, [key, 'value'], null);
    }
    const title = (template, confId, key = 'title') => {
        return _getConfiguredContent(template, confId, key);
    }
    const subtitle = (template, confId, key = 'subtitle') => {
        return _getConfiguredContent(template, confId, key);
    }
    const description = (template, confId, key = 'description') => {
        return _getConfiguredContent(template, confId, key);
    }

    const prepareConfiguration = (options, config, cb, cbKey = null) => {
        let data = {};
        if(!isArray(options)) {
            throw new Error('Options must be an array');
        }
        options.forEach(key => {
            // Default case if cb and cbKey not defined
            data[key] = {
                [config.column]: config[config.storeKey],
                value: null
            }
            // Only selected item will be modified if has callback function and cbKey
            if(cbKey && isString(cbKey) && key == cbKey && isFunction(cb)) {
                data[key] = cb(config, key);
            }
            if(cbKey && isArray(cbKey) && cbKey.includes(key) && isFunction(cb)) {
                data[key] = cb(config, key);
            }

            // All items will be modified if has callback function only and not mention cbKey
            if(isFunction(cb) && !cbKey) {
                data[key] = cb(config, key);
            }
        });
        return data;
    }
    const removeIfExist = (template, confId) => {
        if(!isArray(template.data_layer)) {
            return;
        }
        let confIndex = template.data_layer.findIndex(item => item.conf_id == confId);
        if(confIndex != -1) {
            template.data_layer.splice(confIndex, 1);
        }
    }
    const prepareRequest = (template, confId, config, inputs, synchronize) => {
        removeIfExist(template, confId);
        return {
            confId,
            synchronize,
            configuration: [
                ...template.data_layer,
                ...[
                    {
                        conf_id: confId,
                        persistance: config,
                        ...inputs
                    }
                ]
            ]
        }
    }
    const getPersistance = (template, confId) => {
        if(!isArray(template.data_layer)) {
            throw new Error('template.data_layer is not a valid array');
        }
        let item = template.data_layer.find(item => item.conf_id == confId);
        return get(item, 'persistance', {});
    }
    const saveScreenshot = debounce((template) => {
        handleScreenShotData(template)
    }, 10000);

    const handleScreenShotData = async (template) => {
        let formData = new FormData()
        const file =  await getBlobImageFile()
        formData.append('image', file)
        formData.append('old_path', template.settings.cover_image || null)

        try{
            const {data: {data, status}} =  await store.dispatch("communicator/uploadScreenshot", {
                id: template.id,
                formData:formData
            })
            if(status) {
                template.settings.cover_image = data
            }
        }catch(err){
            //
        }
    }
    
    const getRootValue = (persistance, rootType) => {
        const rootKey = levelDataMapping[rootType]
        return persistance[rootKey]
    }
    
    const getParamForAlevelAPI = (rootType, rootValue) => {
        let params = {with_nested_module: true, response_object: true, with_parameter: true}
        if(rootType != 'module' ) {
            params.level_id = rootValue
        }
        return params
    }

    const onRearrangeTemplate = (e, layout) => {
        let reorderedTemplateIds = []
        layout.module_templates.map(template => reorderedTemplateIds.push(template.id))
        store.dispatch("communicator/orderTemplate", {
            layout_id: layout.id,
            data: reorderedTemplateIds
        })
    }

    const changeConfigVisibility = (value, template) => {
        template.settings.config_visibility = value
        updateTemplate(template)
    }

    const getDataLayerItem = (template, id) => {
        return template.data_layer.find(({conf_id}) => conf_id == id)
    }
    const getTemplateBackground = (template, colorCode) => {
        return {backgroundColor: get(template, 'settings.background_color', colorCode)}
    }
    const getTemplateTextColor = (template, colorCode) => {
        return {color: get(template, 'settings.color', colorCode)}
    }
    const getTemplateBorderColor = (template, colorCode) => {
        let color =  get(template, 'settings.borderColor', colorCode)
        return isNull(color) ? {borderColor: '#fff'} : {borderColor: color}
    } 
    const getIconVisibility = (mode, isVisible) => {
        return mode == 'fullscreen' || !isVisible
    }
    const getConfigNo = (template) => {
        return get(template, 'settings.configuration_no')
    }
    return {
        createTemplate,
        createTemplateData,
        getTemplate,
        getTemplateBg,
        getFileElement,
        openFile,
        onSelectBgImage,
        previewURL,
        updateTemplate,
        setTemplateBg,
        getLevelId,
        getRootType,
        deleteTemplate,
        copyTemplate,
        onChange,
        deleteBgImage,
        file,
        uploadTemplate,
        saveScreenshot,
        changeConfigVisibility,
        onRearrangeTemplate,

        title,
        subtitle,
        description,
        removeIfExist,
        prepareRequest,
        getPersistance,
        loadCollection,
        prepareConfiguration,
        getDataLayerItem,
        getTemplateBackground,
        getTemplateTextColor,
        getTemplateBorderColor,
        getIconVisibility,
        getConfigNo,
        getRootValue,
        getParamForAlevelAPI
    }
}