import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { useCollection } from '@/composable/useCollection.js'

const createData = {
    title: '',
    child: [],
    data_layer: [],
    parameters: [
        { 
            property_id: null,
            id: null,
            definition_id: null,
            type: null,
            definition_style:[
                { range: '0-2', bg: '#AD2B81', definition: 'Very Low' },
                { range: '2-4', bg: '#ABA62B', definition: 'Low' },
                { range: '4-6', bg: '#8D8D8D', definition: 'Medium' },
                { range: '6-8', bg: '#2BAD7E', definition: 'High' },
                { range: '8-10', bg: '#2B80AD', definition: 'Extreme' }
            ]
        }
    ]
}

export const getCreateData = () => JSON.parse(JSON.stringify(createData))

export default function useManagementData () {
    const { getPersistance, getRootValue } = useTemplate()
    const { getCollectionDataByScenarioAndModule } = useCollection()
    const getCollectionData = (item) => {
        // return item
        const persistance = getPersistance(item, 'data_source')
        if(!persistance) return item
        const { module_id, scenario_id, rootType} = persistance
        if(module_id == 'null' || scenario_id == 'null' || rootType == 'null') return item
        const rootValue = getRootValue(persistance, rootType)
        if(!rootValue) return item

        let params = {}
        if(rootType != 'module') {
            params.level_id = rootValue
        }

        return getCollectionDataByScenarioAndModule(scenario_id, module_id, params)
    }

    return {
        getCollectionData
    }
}