<template>
    <div
        class="grid mt-5"
        :class="[getGridClass()]"
    >
        <template v-for="(item, i) in activeTemplate.customized_data.items">
            <div :key="i">
                <img 
                    :class="[getArrowClass()]"
                    src="/images/color-arrow.svg" 
                >
                <div 
                    style="height: 157.35px; width:280px"
                    @click="onClickUploader(`customized_data.items[${i}].secondImage`)"
                    class="cursor-pointer flex items-center justify-center border border-dashed border-secondary-two rounded mt-7"
                    :style="{pointerEvents: currentMode == 'fullscreen' ? 'none' : 'auto'}"
                >   
                    <img v-if="item.secondImage" :src="item.secondImage" class="w-full h-full object-contain">
                    <span v-if="!item.secondImage" class="fw-700 fs-35">+</span>
                </div>
                <textarea
                    style="height: 99px; width: 280px;"
                    :disabled="currentMode == 'fullscreen'"
                    v-model="item.secondDescription"
                    class="fs-14 fw-300 bg-transparent mt-5"
                    @input="updateTemplate"
                ></textarea>
            </div>
        </template>
        <input
          hidden
          @input="onFileSelect($event, activeTemplate)"
          :ref="getFileElement"
          type="file"
          accept="image/*"
        >
    </div>
</template>

<script setup>
import {ref} from 'vue'
import useLayoutStyle from './layouts/useLayoutStyle'
import useImageUpload from "@/components/communicator/v2/templates/useImageUpload"

const props = defineProps({
  activeTemplate: Object,
  currentMode: String,
  config_visibility: Boolean,
  synchronize: Boolean,
  updateTemplate: Function
})
const template = ref(props.activeTemplate)
const {getGridClass, getArrowClass} = useLayoutStyle(template)
const { 
    getFileElement,
    onFileSelect,
    onClickUploader
} = useImageUpload()
</script>

<style scoped>
.five-grid-style{
    margin-left: 162px;
    margin-right: 88px;
    column-gap: 63px;
}
.three-grid-style{
    margin-left: 162px;
    margin-right: 88px;
    column-gap: 113px;
}
</style>