<template>
    <div v-if="conceptData">
        <div class="header">
            <h1 class="fs-20 fw-800 mb-3 mt-6">{{ moduleTitle ? moduleTitle : '&nbsp;' }}</h1>
        </div>
        <div class="content-wrapper">
            <div class="graph-box">
                <TopDefinitionPanel
                    v-if="activeDefinitionOne"
                    :definitionItems="activeDefinitionOne.range_with_definition" 
                    :definitions="(definitions && definitions.length>0) && definitions[0]" 
                />
                <LeftDefinitionPanel
                    v-if="activeDefinitionTwo"
                    :definitionItems="activeDefinitionTwo.range_with_definition" 
                    :definitions="(definitions && definitions.length>0) && definitions[1]" 
                />

                <div class="content relative">
                    <GraphLineSvg
                        class="absolute top-0 left-0 right-0 bottom-0 svg-graph pointer-events-none"
                    />

                    <FourZonesInput v-if="hintsText&&hintsText.length>0" :hintsText="hintsText && hintsText" />

                    <div class="grid h-full">
                        <div class="flex h-318px">
                            <div class="h-full w-full grid content-center" :style="{backgroundColor: conceptData.colors && conceptData.colors[0]}">
                                <span 
                                    class="text-center fw-800 relative z-10" 
                                    style="font-size: 187px;"
                                >
                                    {{ getHindText(conceptData.hints_text, 0) }}
                                </span>
                            </div>
                            <div class="h-full w-full grid content-center" :style="{backgroundColor: conceptData.colors && conceptData.colors[1]}">
                                <span 
                                    class="text-center fw-800 relative z-10" 
                                    style="font-size: 187px;"
                                >
                                    {{ getHindText(conceptData.hints_text, 1) }}
                                </span>
                            </div>
                        </div>
                        <div class="flex h-318px">
                            <div class="h-full w-full grid content-center" :style="{backgroundColor: conceptData.colors && conceptData.colors[2]}">
                                <span 
                                    class="text-center fw-800 relative z-10" 
                                    style="font-size: 187px;"
                                >
                                    {{ getHindText(conceptData.hints_text, 2) }}
                                </span>
                            </div>
                            <div class="h-full w-full grid content-center" :style="{backgroundColor: conceptData.colors && conceptData.colors[3]}">
                                <span 
                                    class="text-center fw-800 relative z-10" 
                                    style="font-size: 187px;"
                                >
                                    {{ getHindText(conceptData.hints_text, 3) }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="z-20 relative">
                        <div
                            v-for="(l1, l1Index) in getData(collections)"
                            :key="l1Index"
                        >

                            <template
                                v-if="(getXValue(l1)>=horizontal_filter.minValue)
                                    && (getXValue(l1)<=horizontal_filter.maxValue)
                                    && (getYValue(l1)>=vertical_filter.minValue)
                                    && (getYValue(l1)<=vertical_filter.maxValue)
                            ">

                                <!-- v-if="filterLevel[0].visibility" -->
                                <div>

                                    <CircleTitle
                                        :showTitle="showTitle"
                                        :title="`${getIndexId(1)} ${getRootIndexNumber(1, l1Index)} ${l1.title}`"
                                        :circleText="getRootIndexNumber(1, l1Index)"
                                        :levelData="l1"
                                        :style="{
                                            position: 'absolute',
                                            left: getPosition(l1, 0, 40) + 'px',
                                            bottom: getPosition(l1, 1, 40) + 'px',
                                            color: activeTemplate.settings.color,
                                            background: getBg(1, l1)
                                        }"
                                    />
                                </div>

                                <div
                                    v-for="(l2, l2Index) in getData(l1.child)"
                                    :key="l2Index"
                                    class="relative"
                                >
                                    <template
                                        v-if="(getXValue(l2)>=horizontal_filter.minValue)
                                                && (getXValue(l2)<=horizontal_filter.maxValue)
                                                && (getYValue(l2)>=vertical_filter.minValue)
                                                && (getYValue(l2)<=vertical_filter.maxValue)
                                            "
                                    >
                                        <div>
                                            <CircleTitle
                                                :showTitle="showTitle"
                                                :title="`
                                                    ${ getIndexId(2) }
                                                    ${ getRootIndexNumber(1, l1Index) }.
                                                    ${ l2Index + 1 }
                                                    ${ l2.title }
                                                `"
                                                :circleText="`${ l1Index + 1 }.${ l2Index + 1 }`"
                                                :levelData="l2"
                                                :style="{
                                                    position: 'absolute',
                                                    left: getPosition(l2, 0, 35) + 'px',
                                                    bottom: getPosition(l2, 1, 35) + 'px',
                                                    color: activeTemplate.settings.color,
                                                    background: getBg(1, l2)
                                                }"
                                            />

                                            <div
                                                v-for="(l3, l3Index) in getData(l2.child)"
                                                :key="l3Index"
                                            >
                                                <template
                                                    v-if="(getXValue(l3)>=horizontal_filter.minValue)
                                                        && (getXValue(l3)<=horizontal_filter.maxValue)
                                                        && (getYValue(l3)>=vertical_filter.minValue)
                                                        && (getYValue(l3)<=vertical_filter.maxValue)
                                                ">
                                                    <div>
                                                        <CircleTitle
                                                            :showTitle="showTitle"
                                                            :title="`
                                                                ${ getIndexId(3) }
                                                                ${ getRootIndexNumber(1, l1Index) }.
                                                                ${ l2Index + 1 }
                                                                ${ l3Index + 1 }
                                                                ${ l3.title }
                                                            `"
                                                            :circleText="`${ l1Index + 1 }.${ l2Index + 1 }.${ l3Index + 1 }`"
                                                            :levelData="l3"
                                                            :style="{
                                                                position: 'absolute',
                                                                left: getPosition(l3, 0, 30) + 'px',
                                                                bottom: getPosition(l3, 1, 30) + 'px',
                                                                color: activeTemplate.settings.color,
                                                                background: getBg(1, l3)
                                                            }"
                                                        />

                                                        <div
                                                            v-for="(l4, l4Index) in getData(l3.child)"
                                                            :key="l4Index"
                                                        >
                                                            <template
                                                                v-if="(getXValue(l4)>=horizontal_filter.minValue)
                                                                    && (getXValue(l4)<=horizontal_filter.maxValue)
                                                                    && (getYValue(l4)<=vertical_filter.minValue)
                                                                    && (getYValue(l4)<=vertical_filter.maxValue)
                                                            ">
                                                                <div>
                                                                    <CircleTitle
                                                                        :showTitle="showTitle"
                                                                        :title="`
                                                                            ${ getIndexId(3) }
                                                                            ${ getRootIndexNumber(1, l1Index) }.
                                                                            ${ l2Index + 1 }
                                                                            ${ l3Index + 1 }
                                                                            ${ l4Index + 1 }
                                                                            ${ l4.title }
                                                                        `"
                                                                        :circleText="`${ l1Index + 1 }.${ l2Index + 1 }.${ l3Index + 1 }.${ l4Index + 1 }`"
                                                                        :levelData="l4"
                                                                        :style="{
                                                                            position: 'absolute',
                                                                            left: getPosition(l4, 0, 25) + 'px',
                                                                            bottom: getPosition(l4, 1, 25) + 'px',
                                                                            color: activeTemplate.settings.color,
                                                                            background: getBg(1, l4)
                                                                        }"
                                                                    />
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>

                                        </div>
                                    </template>
                                </div>

                            </template>
                        </div>
                    </div>
                </div>
                
                <!-- bottom panel start -->
                <div class="bottom-panel top-full w-full">
                    <div class="number fs-14 fw-400">
                        <span>0</span>
                        <span>2</span>
                        <span>4</span>
                        <span>6</span>
                        <span>8</span>
                        <span>10</span>
                    </div>
                </div>
                <!-- bottom panel end -->
            </div>
        </div>

        <!-- definition changer start -->
        <div class="absolute -bottom-10 ml-14">
            <h1 class="fs-14 fw-500 flex items-center gap-8">
                <div class="w-48">{{ activeDefinitionOne?.title }}</div>
                <div class="flex gap-2">
                    <button @click="changeDefinitionData(-1, 'definitionItemsOne')">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.997713 6.01963L6.34937 11.3713L7.60937 10.1131L3.51326 6.01963L7.60937 1.92708L6.35027 0.667969L0.997713 6.01963Z" fill="currentColor"/>
                        </svg>                            
                    </button>
                    <button @click="changeDefinitionData(1, 'definitionItemsOne')">
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.61166 6.01553L1.26 0.663863L-4.12859e-07 1.92208L4.09611 6.01553L-5.50374e-08 10.1081L1.25911 11.3672L6.61166 6.01553Z" fill="currentColor"/>
                        </svg>                            
                    </button>
                </div>
            </h1>
            <h1 class="fs-14 fw-500 flex items-center gap-8">
                <div class="w-48">{{ activeDefinitionTwo?.title }}</div>
                <div class="flex gap-2">
                    <button @click="changeDefinitionData(-1, 'definitionItemsTwo')">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.997713 6.01963L6.34937 11.3713L7.60937 10.1131L3.51326 6.01963L7.60937 1.92708L6.35027 0.667969L0.997713 6.01963Z" fill="currentColor"/>
                        </svg>                            
                    </button>
                    <button @click="changeDefinitionData(1, 'definitionItemsTwo')">
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.61166 6.01553L1.26 0.663863L-4.12859e-07 1.92208L4.09611 6.01553L-5.50374e-08 10.1081L1.25911 11.3672L6.61166 6.01553Z" fill="currentColor"/>
                        </svg>                            
                    </button>
                </div>
            </h1>
        </div>
        <!-- definition changer start -->
    </div>
  </template>
  
  <script setup>
    import {cloneDeep, isEmpty} from "lodash"
    import store from '@/store'
    import { ref, onMounted, watchEffect } from 'vue'
    import LeftDefinitionPanel from './components/LeftDefinitionPanel.vue'
    import TopDefinitionPanel from './components/TopDefinitionPanel.vue'
    import FourZonesInput from './components/FourZonesInput.vue'
    import { useTemplate } from '@/views/screens/communicator/useTemplate'
    import GraphLineSvg from "./GraphLineSvg.vue";
    import CircleTitle from "./components/CircleTitle.vue"

    const { updateTemplate } = useTemplate()

    const props = defineProps({
        activeTemplate:{
            type: Object
        },
        conceptData:{
            type: Object
        },
        collections:{
            type: Array
        },
        parameterDefinitions:{
            type: Array
        },
        showTitle: {
            default: 1,
            type: [Boolean, Number]
        }
    })

    const hintsText   = ref([])
    const definitions = ref([])
    const definitionItemsOne  = ref([])
    const activeDefinitionOne = ref({})
    const definitionItemsTwo  = ref([])
    const activeDefinitionTwo = ref({})
    const horizontal_filter = { minValue: 0, maxValue: 10 }
    const vertical_filter = { minValue: 0, maxValue: 10 }
    const defaultColors = ["#2B80AD", "#3D6A83", "#6A7881", "#818487"]
    const colorCodes = ref({})
    const activeCommunicator = () => store.getters['communicator/communicator']
    const currentItem = ref()
    const moduleTitle = ref('')
    const viewDetails = ''
    const filterLevel = []
    const layoutSize = {
        width: 1273,
        height: 638
    }

    const getHindText = (hintsTextArray, index) => {
        if(hintsTextArray[index]){
            return hintsTextArray[index].charAt(0)
        }
    }

    function isObjectEmpty(obj) {
        if(!obj) return false
        return Object.keys(obj).length === 0
    }

    const changeDefinitionData = (direction, definitionItemsName) => {
       
        let index = 0;
        switch(definitionItemsName) {
            case 'definitionItemsOne':
                if(activeDefinitionOne.value == false) return
                if(direction<0){
                    index = isEmpty(activeDefinitionOne.value) ? 0 : activeDefinitionOne.value.activeIndex + direction
                    index = (index<0) ? 0 : index
                }
                if(direction>0){
                    index = isEmpty(activeDefinitionOne.value) ? 0 : activeDefinitionOne.value.activeIndex + direction
                    index = (index<=(definitionItemsOne.value.length-1)) ? index : definitionItemsOne.value.length-1
                }
                activeDefinitionOne.value = definitionItemsOne.value[index]
                // update the activeDefinition activeIndex for further use
                activeDefinitionOne.value.activeIndex = index
            break
            case 'definitionItemsTwo':
                if(activeDefinitionTwo.value == false) return
                if(direction<0){
                    index = isObjectEmpty(activeDefinitionTwo.value) ? 0 : activeDefinitionTwo.value.activeIndex + direction
                    index = (index<0) ? 0 : index
                }
                if(direction>0){
                    index = isObjectEmpty(activeDefinitionTwo.value) ? 0 : activeDefinitionTwo.value.activeIndex + direction
                    index = (index<=(definitionItemsTwo.value.length-1)) ? index : definitionItemsTwo.value.length-1
                }
                activeDefinitionTwo.value = definitionItemsTwo.value[index]
                // update the activeDefinition activeIndex for further use
                activeDefinitionTwo.value.activeIndex = index
            break
        }

        // store definition index to dataLayer
        let dataLayer = props.activeTemplate.customized_data
        if(dataLayer.length){
            const activeTemplateData = dataLayer.find(item => item.isActiveLayout)
            activeTemplateData["definitionItemsOneIndex"] = activeDefinitionOne.value?.activeIndex
            activeTemplateData["definitionItemsTwoIndex"] = activeDefinitionTwo.value?.activeIndex
            updateTemplate(props.activeTemplate)
        }
    }



    // ************
    const getXValue = (item) => {
      let xObj = item.properties.find(
        (hr) =>
            parseInt(hr.property_collection_id) ===
            parseInt(props.conceptData.hr_property_collection_id)
        )
        return xObj ? xObj.sample_value : 0;
    }

    const getYValue = (item) => {
        let yObj = item.properties.find((vr) =>
            parseInt(vr.property_collection_id) ===
            parseInt(props.conceptData.vr_property_collection_id)
        );
        return yObj ? yObj.sample_value : 0;
    }

    const getPosition = (item, pos, selfMargin) => {
        let canvasWidth = layoutSize.width;
        let canvasHeight = layoutSize.height;
        let level = item.level;
        let id = item.id;

        let tempX = 0;
        let tempY = 0;

        tempX = item.properties.find(
        (hr) =>
            parseInt(hr.property_collection_id) ===
            parseInt(props.conceptData.hr_property_collection_id)
        );
        tempY = item.properties.find(
        (hr) =>
            parseInt(hr.property_collection_id) ===
            parseInt(props.conceptData.vr_property_collection_id)
        );

      let x = tempX ? tempX.sample_value : 0;
      let y = tempY ? tempY.sample_value : 0;
      let currentLayout = [canvasWidth, canvasHeight];
      let xy = [
        (currentLayout[0] / 10) * x - selfMargin,
        (currentLayout[1] / 10) * y - selfMargin,
      ];

      return xy[pos];
    }

    const getLevelWiseColorCode = (level) => {
      let indexLevel = level - 1;
      if(!colorCodes.value.slice) return
      return colorCodes.value.slice(indexLevel * 9, indexLevel * 9 + 9);
    }
    
    const moduleSettings = () => {
        let mySettings = props.conceptData.scenario_settings
        if(mySettings && mySettings.module_id){
            let targetModule = activeCommunicator().modules.find((item) => {
                    parseInt(item.id) === parseInt(mySettings.module_id)
            });

            if (targetModule) {
                let module = cloneDeep(targetModule)
                let localSettings = JSON.parse(module.collection_settings);
                if (localSettings) {
                    return localSettings;
                }
            }
            moduleTitle.value = targetModule?.title
        }
        
        return {}
    }

    const getBg = (level, item) => {
      if (item && item.background_color) {
        return item.background_color;
      } else {
        let localSettings = moduleSettings()
        if (localSettings && localSettings?.collections) {
            let collection = localSettings.collections[level];
            if (collection) {
              if (collection?.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = getLevelWiseColorCode(level);

              if(colorCodes && colorCodes.length){
                return colorCodes[collection.color]
              }
            }
        }
      }
      return defaultColors[level - 1];
    }

    const getRootIndexNumber = (level, index) => {
        let settings = moduleSettings();
        if (settings && settings?.collections) {
          let indexing = "";
          let collection = settings.collections[level];
          if (settings.index_number) {
            indexing = parseFloat(collection.index_number) + index;
          }
          return indexing;
        }
    }

    const getIndexId = (level) => {
        let localSettings = moduleSettings();
        if (localSettings && localSettings?.collections) {
            let collection = localSettings.collections[level];
            if (collection) {
                return collection.index_id;
            }
        }
        return "";
    }

    const getData = (item) => {
        return item
    }
    // **********


    onMounted(() => 
    {
        // get saved definitionIndex from data layer start
        let customized_data = props.activeTemplate.customized_data
        if(customized_data.length){
            const activeTemplateData = customized_data.find(item => item.isActiveLayout)
            let definitionIndexOne = activeTemplateData['definitionItemsOneIndex']
            let definitionIndexTwo = activeTemplateData['definitionItemsTwoIndex']

            if(definitionIndexOne >= 0 && definitionItemsTwo.value && definitionItemsTwo.value.length) {
                activeDefinitionTwo.value = definitionItemsTwo.value[definitionIndexOne]
                // update the activeDefinition activeIndex for further use
                activeDefinitionTwo.value.activeIndex = definitionIndexOne
            }
            if(definitionIndexTwo >= 0 && definitionItemsTwo.value && definitionItemsTwo.value.length>0) {
                activeDefinitionTwo.value = definitionItemsTwo.value[definitionIndexTwo]
                // update the activeDefinition activeIndex for further use
                activeDefinitionTwo.value.activeIndex = definitionIndexTwo
            }
        }
        // get saved definitionIndex from data layer end

        colorCodes.value = store.getters['global/colorCodes']
    })

    watchEffect(() => {
        // work with definition items start
        definitionItemsOne.value = !isEmpty(props.parameterDefinitions) ? props.parameterDefinitions[0]?.items : {}
        definitionItemsTwo.value = !isEmpty(props.parameterDefinitions) ? props.parameterDefinitions[1]?.items  : {}


        let dataLayer = props.activeTemplate.customized_data
        if(dataLayer.length){
            const activeTemplateData = dataLayer.find(item => item.isActiveLayout)
            
            activeDefinitionOne.value = definitionItemsOne.value[activeTemplateData["definitionItemsOneIndex"]]
            activeDefinitionTwo.value = definitionItemsOne.value[activeTemplateData["definitionItemsTwoIndex"]]
            
            // update the activeDefinition activeIndex for further use
            activeDefinitionOne.value = {
                ...activeDefinitionOne.value,
                activeIndex: activeTemplateData["definitionItemsOneIndex"]
            }
            activeDefinitionTwo.value = {
                ...activeDefinitionTwo.value,
                activeIndex: activeTemplateData["definitionItemsTwoIndex"]
            }
        }
        // work with definition items end

        if(!props.conceptData) return;
        hintsText.value = props.conceptData.hints_text
        definitions.value = props.conceptData.definitions
    })
  </script>
  
  <style scoped>
    .h-318px{
        height: 318px;
    }
    .header{
        margin-left: 72px;
        margin-top: 30px;
        margin-bottom: -11px;
    }
    .content-wrapper{
        margin-left: 58px;
    }
    .graph-box{
        display: grid;
        gap: 14px;
    }
    .graph-box .left-panel{
        grid-column: 1 / span 1;
        grid-row: 2;
    }
    .graph-box .top-panel{
        grid-column: 1 / span 3;
        grid-row: 1;
    }
    .graph-box .content{
        grid-column: 2 / span 2;
        grid-row: 2;
    }
    .graph-box .bottom-panel{
        grid-column: 2 / span 2;
        grid-row: 3;
        margin-left: -30px;
        width: 98%;
    }
    .graph-box .content{
        width: 1273px;
        height: 638px;
        position: relative;
        margin-left: -41px;
    }
    
    .svg-graph{
        transform: scaleY(0.838) scaleX(0.77);
        transform-origin: top left;
        color: var(--borderColor);
        z-index: 1;
    }
    .bottom-panel{
        height: 20px;
    }
    .bottom-panel .number{
        display: flex;
        height: 100%;
        justify-content: space-between;
    }


    .l1div {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        z-index: 2;
    }
    .l2div {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        z-index: 2;
    }
    .l3div {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        z-index: 2;
    }
    .l4div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        z-index: 2;
    }
    .l1c {
        background: #2b80ad;
    }
    .l2c {
        background: #3d6a83;
    }
    .l3c {
        background: #6a7881;
    }
    .l4c {
        background: #818487;
    }
  </style>
  