<template>
    <div class="relative flex">
        <div v-show="sidebarVisibility" class="i-left-panel shadow-one min-h-screen">
            <div class="relative">
                <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
                <NavToggle v-if="projectId" />
            </div>
            <slot name="sidebar"></slot>
        </div>
        <img
            v-if="!sidebarVisibility"
            src="/images/icons/build/navigation.svg"
            class="absolute cursor-pointer opacity-50 hover:opacity-100 hover:bg-primary-three i-w-30 i-h-30"
            title="Full screen"
            style="top: 104px; left: 40px;"
            @click="$emit('exitFullScreen')"
        />
        <div class="pl-10 i-right-panel">
            <div class="z-max ws-heading-box">
                <workspace-menu />
            </div>
            <div class="mt-4" :style="[!sidebarVisibility ? {'margin-left' : '67px'} : {}]">
                <slot name="toolbar"></slot>
            </div>
            <div>
                <slot></slot>
            </div>
        </div>

        <Nav v-model="showNavigation" @close="showNavigation=false"/>
    </div>
</template>

<script setup>
import NavToggle from '@/components/navigation/components/NavToggle.vue'
import useNavigation from '@/components/navigation/useNavigation.js'
import Nav from '@/components/navigation/Nav.vue'

defineProps({
    sidebarVisibility: Boolean,
    useFor: String
});
const { projectId, showNavigation } = useNavigation()
</script>
