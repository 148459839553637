<template>
  <div>
    <div v-click-outside="hideMenu">
      <MenuAltSolid @click.native="showMenu = !showMenu" class="w-3 h-3 transform rotate-180 cursor-pointer" />
    </div>

    <transition name="dropdown">
        <div v-if="showMenu" class="absolute menu-position">
          <div  class="flex items-center bg-secondary-four rounded-sm p-2 gap-x-2">
            <XCircle @click.native="removeTemplateImage()" class="w-3 h-3 cursor-pointer" title="Delete Image" />
            <Photograph @click.native="openFileBrowser()" class="w-3 h-3 cursor-pointer" title="Upload Image"/>
          </div>
        </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const showMenu = ref(false)

const props = defineProps({
  bgColor: String,
  openFileBrowser: Function,
  removeTemplateImage: Function,
})

const hideMenu = (state) => {
  if(showMenu.value) {
    showMenu.value = false
  }
}
</script>

<style>
.menu-position {
  bottom: 41px;
  right: 36px;
}
</style>
