<template>
  <BaseLayout>
    <div class="teamwork-dashboard-wrapper">
        <LayoutCreateNav />
        <div class="mt-10">
          <div v-if="(!isEmpty(dashBoardData) && !isEmpty(dashBoardData.rows))" class="grid gap-5">
            <div
              v-for="(row, index) in dashBoardData.rows" 
              :key="`row-${index}`"
              :class="row.class"
            >
              <div
                v-for="(card, i) in getCards(dashBoardData.cards, row.id)"
                :key="('card-'+i)"
                :class="`${ get(card, 'settings.class')} h-${get(card, 'settings.height')}px`"
              >
                <template v-if="get(card, 'settings.componentName')">
                  <component
                    :card="card"
                    :dashboardId="dashBoardData.id"
                    :is="panels.find(item => item.__name == get(card, 'settings.componentName'))"
                  >
                      <component
                        :card="card"
                        :is="charts.find(item => item.__name == get(card, 'configuration.chartType'))"
                      />
                    </component>
                </template>
              </div>
            </div>
          </div>
          <NoDashboard
            v-else
            @showDashboardCreateModal="(showDashboardCreateModal = true)"
          />
        </div>
    </div>

    <Modal
      v-model="showDashboardCreateModal"
      @close="closeModal"
    >
        <CreateDashboard
          ref="createDashboardRef"
          @close="(showDashboardCreateModal = false)"
        />
    </Modal>
  </BaseLayout>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { get, isEmpty } from 'lodash'
    import BaseLayout from "@/views/layouts/dashboard/BaseLayout.vue"
    import LayoutCreateNav from "@/components/dashboard/LayoutCreateNav.vue"
    import Modal from '@/elements/atom/Modal'
    import CreateDashboard from '@/components/dashboard/CreateDashboard.vue'
    import NoDashboard from "@/components/dashboard/NoDashboard.vue"
    import { useTeamDashboard } from '@/views/screens/dashboard/useTeamDashboard.js'
    const {
        panels,
        charts,
        getCards,
        dashBoardData
    } = useTeamDashboard({mountable: true});
    
    const createDashboardRef = ref(null);
    const showDashboardCreateModal = ref(false);
    
    const closeModal = () => {
      if(!createDashboardRef.value.dashboard) {
        createDashboardRef.value.create();
      }
    }

</script>
