<template>
    <div :style="{...columnStyle.box, backgroundColor: item.bgColor}" class="relative rounded-tr     rounded-tl">
        <div class="rounded" :style="{color: headerTextColor,backgroundColor: headerBg}">
            <input
                @input="updateActiveTemplate"
                class="bg-transparent h-10 px-6 fs-18"
                v-model="item.title"
                :disabled="isDisabled"
                placeholder="Add Text"
                :style="{...columnStyle.input}"
                type="text"
            >
        </div>
        <div style="padding: 19px 23px 31px 22px; ">
            <div class="flex gap-x-0.5 fs-18 fw-400 i-mr-30 justify-end">
                <div 
                    v-for="n in 5"
                    style="height: 35.59px; width: 42.75px"
                    :style="{backgroundColor: cellBg}"
                    :key="n" 
                    class="flex items-center justify-center"
                >M{{ n }}</div>
            </div>
            <div class="space-y-0.5 mt-0.5">
                <div 
                    v-for="(data, i) in item.data"
                    :key="i"
                    class="flex gap-x-0.5"
                >
                    <div
                        :style="{
                            backgroundColor: '#F2F2F2',
                            width: '201px',
                            height: '41.49px',
                        }"
                        class=""
                    >
                        <textarea 
                            type="text"
                            @input="updateActiveTemplate"
                            placeholder="Add Text"
                            class="bg-transparent w-full px-1 h-10 fs-13 fw-400"
                            v-model="data.title"
                            :disabled="isDisabled"
                        ></textarea>
                    </div>
                    <div
                        v-for="(box, boxIndex) in data.boxes"
                        style="height: 41.59px; width: 42.75px; background-color: #F2F2F2;"
                        class="flex items-center justify-center fs-18 cursor-pointer"
                        @click="handleBoxClick(box)"
                        :key="boxIndex"
                    ><span v-if="box.value">X</span></div>
                </div>
            </div>
        </div>
        
        <ColorIcon
            style="bottom: 12px; right: 20px;"
            :class="{'invisible': !showIcon}"
            @click.native="clickThColorPicker"
            class="w-4 h-4 absolute cursor-pointer"
        />
        <input
            @input="updateActiveTemplate"
            ref="colorPickerElem" 
            type="color" 
            v-model="item.bgColor"
            class="absolute invisible"
            style="bottom: 13px; right: 10px;"
        />
    </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
    item: Object,
    columnStyle: Object,
    headerBg: String,
    headerTextColor: String,
    cellBg: String,
    showIcon: Boolean,
    isDisabled: Boolean,
    updateActiveTemplate: Function,
})

const colorPickerElem = ref()
const clickThColorPicker = () => colorPickerElem.value.click()
const handleBoxClick = (box) => {
    box.value=!box.value
    props.updateActiveTemplate()
}
</script>