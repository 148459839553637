import { render, staticRenderFns } from "./ModuleAccordion.vue?vue&type=template&id=6554fa30&scoped=true&"
import script from "./ModuleAccordion.vue?vue&type=script&setup=true&lang=js&"
export * from "./ModuleAccordion.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ModuleAccordion.vue?vue&type=style&index=0&id=6554fa30&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6554fa30",
  null
  
)

export default component.exports