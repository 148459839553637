<template>
    <div>
        <div class="header relative">
            <button
                class="absolute cursor-pointer top-8" 
                style="margin-left: -100px;"
                :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
                @click="showConfiguration('TextSource', ['title', 'subtitle'], 'title-id')"
            >
                <svg width="23" height="27" viewBox="0 0 23 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M15.9993 7.3125L5.58579 18.2297C5.21071 18.6165 5 19.1411 5 19.6881C5 20.2351 5.21071 20.7597 5.58579 21.1465C5.96086 21.5333 6.46957 21.7506 7 21.7506C7.53043 21.7506 8.03914 21.5333 8.41421 21.1465L20.8277 8.16682C21.1991 7.78377 21.4938 7.32904 21.6948 6.82857C21.8958 6.3281 21.9993 5.7917 21.9993 5.25C21.9993 4.7083 21.8958 4.1719 21.6948 3.67143C21.4938 3.17096 21.1991 2.71623 20.8277 2.33318C20.4563 1.95014 20.0153 1.6463 19.53 1.439C19.0447 1.2317 18.5246 1.125 17.9993 1.125C17.474 1.125 16.9538 1.2317 16.4685 1.439C15.9832 1.6463 15.5423 1.95014 15.1708 2.33318L2.75736 15.3129C1.63214 16.4733 1 18.0471 1 19.6881C1 21.3292 1.63214 22.903 2.75736 24.0634C3.88258 25.2237 5.4087 25.8756 7 25.8756C8.5913 25.8756 10.1174 25.2237 11.2426 24.0634L21.4993 13.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>                
            </button>
            <h1 v-if="title(activeTemplate, 'title-id')" class="fs-64 fw-800 mt-8 -mb-1">{{ title(activeTemplate, 'title-id') }}</h1>
            <input 
                v-else 
                class="fs-64 fw-800 mt-8 -mb-1 bg-transparent block w-full"
                :value="getTitle(activeTemplate)" 
                placeholder="Enter title"
                @input="(e) => editTitle(e.target.value, activeTemplate)"
            />

            <h1 v-if="subtitle(activeTemplate, 'title-id')" class="fs-14 fw-800 mb-6">{{ subtitle(activeTemplate, 'title-id') }}</h1>
            <input 
                v-else
                class="fs-14 fw-800 mb-6 bg-transparent block w-full"
                :value="getSubTitle(activeTemplate)"
                @input="(e) => editSubTitle(e.target.value, activeTemplate)"
            >
        </div>
        <div class="content-wrapper">
            <div class="graph-box">
                <LeftPanel :definitions="definitions && definitions[1]" />
                <div class="content relative border" style="border-color: var(--borderColor)">
                    <div class="grid h-full">
                        <div class="h-full flex">
                            <div class="border h-full w-full text-white" style="background-color: #55ff9933; border-color: var(--borderColor)">
                                <table class="w-full">
                                    <tr class="border">
                                        <td style="width: 200px; height: 64px;" class="border px-5 py-4 fw-900 fs-20 uppercase">{{ hintsText&&hintsText[0] }}</td>
                                        <td style="width: 200px;" class="border px-5 py-4 fs-14 fw-500 text-center">
                                            {{ getTableHeading(conceptData, 'x') }}
                                        </td>
                                        <td style="width: 200px;" class="border px-5 py-4 fs-14 fw-500 text-center">
                                            {{ getTableHeading(conceptData, 'y') }}
                                        </td>
                                    </tr>
                                    
                                    <template v-for="(item, index) in 10">
                                        <tr 
                                            :key="`topLeftData-${index}`"
                                            class="border" 
                                        >
                                            <td class="border fs-16 fw-800 td-padding truncate">
                                                {{ getData(topLeftData, index, 'xProperty.index_code') }}
                                                {{ getData(topLeftData, index, 'xProperty.title') }}
                                            </td>
                                            <td class="border fs-14 fw-600 td-padding truncate">
                                                {{ getData(topLeftData, index, 'xProperty.sample_value') }}
                                            </td>
                                            <td class="border fs-14 fw-600 td-padding truncate">
                                                {{ getData(topLeftData, index, 'yProperty.sample_value') }}
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                            </div>
                            <div class="border h-full w-full text-white" style="background-color: #ff559933;border-color: var(--borderColor)">
                                <table class="w-full">
                                    <tr class="border">
                                        <td style="width: 200px; height: 60px;" class="border px-5 py-4 fw-900 fs-20 uppercase">{{ hintsText&&hintsText[1] }}</td>
                                        <td style="width: 200px;" class="border px-5 py-4 fs-14 fw-500 text-center">
                                            {{ getTableHeading(conceptData, 'x') }}
                                        </td>
                                        <td style="width: 200px;" class="border px-5 py-4 fs-14 fw-500 text-center">
                                            {{ getTableHeading(conceptData, 'y') }}
                                        </td>
                                    </tr>
                                    
                                    <template v-for="(item, index) in 10">
                                        <tr 
                                            :key="`topRightData-${index}`"
                                            class="border" 
                                        >
                                            <td class="border fs-16 fw-800 td-padding truncate">
                                                {{ getData(topRightData, index, 'xProperty.index_code') }}
                                                {{ getData(topRightData, index, 'xProperty.title') }}
                                            </td>
                                            <td class="border fs-14 fw-600 td-padding truncate">
                                                {{ getData(topRightData, index, 'xProperty.sample_value') }}
                                            </td>
                                            <td class="border fs-14 fw-600 td-padding truncate">
                                                {{ getData(topRightData, index, 'yProperty.sample_value') }}
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                            </div>
                        </div>
                        <div class="h-full flex">
                            <div class="border h-full w-full text-white" style="background-color: #95aaf933;border-color: var(--borderColor)">
                                <table class="w-full">
                                    <tr class="border">
                                        <td style="width: 200px; height: 60px;" class="border px-5 py-4 fw-900 fs-20 uppercase">{{ hintsText&&hintsText[2] }}</td>
                                        <td style="width: 200px;" class="border px-5 py-4 fs-14 fw-500 text-center">
                                            {{ getTableHeading(conceptData, 'x') }}
                                        </td>
                                        <td style="width: 200px;" class="border px-5 py-4 fs-14 fw-500 text-center">
                                            {{ getTableHeading(conceptData, 'y') }}
                                        </td>
                                    </tr>
                                    <template v-for="(item, index) in 10">
                                        <tr 
                                            :key="`bottomLeftData-${index}`"
                                            class="border" 
                                        >
                                            <td class="border fs-16 fw-800 td-padding truncate">
                                                {{ getData(bottomLeftData, index, 'xProperty.index_code') }}
                                                {{ getData(bottomLeftData, index, 'xProperty.title') }}
                                            </td>
                                            <td class="border fs-14 fw-600 td-padding truncate">
                                                {{ getData(bottomLeftData, index, 'xProperty.sample_value') }}
                                            </td>
                                            <td class="border fs-14 fw-600 td-padding truncate">
                                                {{ getData(bottomLeftData, index, 'yProperty.sample_value') }}
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                            </div>
                            <div class="border h-full w-full text-white" style="background-color: #1155ff33;border-color: var(--borderColor)">
                                <table class="w-full">
                                    <tr class="border">
                                        <td style="width: 200px; height: 60px;" class="border px-5 py-4 fw-900 fs-20 uppercase">{{ hintsText&&hintsText[3] }}</td>
                                        <td style="width: 200px;" class="border px-5 py-4 fs-14 fw-500 text-center">
                                            {{ getTableHeading(conceptData, 'x') }}
                                        </td>
                                        <td style="width: 200px;" class="border px-5 py-4 fs-14 fw-500 text-center">
                                            {{ getTableHeading(conceptData, 'y') }}
                                        </td></tr>
                                    
                                    <template v-for="(item, index) in 10">
                                        <tr 
                                            :key="`bottomRightData-${index}`"
                                            class="border" 
                                        >
                                            <td class="border fs-16 fw-800 td-padding truncate">
                                                {{ getData(bottomRightData, index, 'xProperty.index_code') }}
                                                {{ getData(bottomRightData, index, 'xProperty.title') }}
                                            </td>
                                            <td class="border fs-14 fw-600 td-padding truncate">
                                                {{ getData(bottomRightData, index, 'xProperty.sample_value') }}
                                            </td>
                                            <td class="border fs-14 fw-600 td-padding truncate">
                                                {{ getData(bottomRightData, index, 'yProperty.sample_value') }}
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomPanel :definitions="definitions && definitions[0]" />
            </div>
        </div>
    </div>
  </template>
  
  <script setup>
    import { isEmpty, get } from 'lodash'
    import store from '@/store'
    import { watchEffect, ref } from 'vue';
    import LeftPanel from '@/components/communicator/v2/templates/concept/components/LeftPanel.vue'
    import BottomPanel from '@/components/communicator/v2/templates/concept/components/BottomPanel.vue'
    import { useTemplate } from '@/views/screens/communicator/useTemplate'
    import useConcept from './useConcept'


    const { getTitle, editTitle, getSubTitle, editSubTitle } = useConcept()
    const props = defineProps({
        activeTemplate: Object,
        conceptData: Object,
        collections: Array, 
        currentMode: String, 
        config_visibility: Boolean, 
        synchronize: Boolean,
        switchSidebarComponent: Function,
    })
    const { title, subtitle, prepareConfiguration, prepareRequest, getPersistance,updateTemplate  } = useTemplate()
    const hintsText   = ref([])
    const definitions = ref([])
    const moduleTitle = ref('')

    const topLeftData     = ref([])
    const topRightData    = ref([])
    const bottomLeftData  = ref([])
    const bottomRightData = ref([])

    const activeCommunicator = () => store.getters['communicator/communicator']
    
    const getTableHeading = (conceptData, coordinate) => {
        let mySettings = conceptData.scenario_settings
        if(!mySettings) return false
        let targetModule = activeCommunicator().modules.find((item) => {
            return parseInt(item.id) === parseInt(mySettings.module_id)
        })

        if(targetModule){
            moduleTitle.value = targetModule.title
            let properties = targetModule.system_properties.map(property => property.property_collections);
            if(properties.length > 0) {
                let parameters = [];
                    properties.forEach(item => {
                    parameters = [...parameters, ...item.map(data => data)];
                });


                let moduleParamX = parameters.find(param => param.id == conceptData.hr_property_collection_id)
                let moduleParamY = parameters.find(param => param.id == conceptData.vr_property_collection_id)
                if(moduleParamX && moduleParamY) {
                    return (coordinate == 'x') ? moduleParamX.title : moduleParamY.title
                }
            }
        }
        return null;
    }

    const prepareDataForTable = (collections) => {
        if(!collections) return false
        collections.forEach(item => {
            let xProperty = item.properties.find(data => data.property_collection_id == props.conceptData.hr_property_collection_id);
            let yProperty = item.properties.find(data => data.property_collection_id == props.conceptData.vr_property_collection_id);

            // top left and right data
            if(yProperty.sample_value > 5 && yProperty.sample_value <= 10)
            {
                if(xProperty.sample_value >= 0 && xProperty.sample_value <= 5)
                {
                    xProperty.title      = item.title
                    xProperty.index_code = item.index_code
                    topLeftData.value.push({xProperty, yProperty})
                }
                if(xProperty.sample_value > 5 && xProperty.sample_value <= 10)
                {
                    xProperty.title      = item.title
                    xProperty.index_code = item.index_code
                    topRightData.value.push({xProperty, yProperty})
                }
            }

            // bottom left and right data
            if(yProperty.sample_value >= 0 && yProperty.sample_value <=5){
                if(xProperty.sample_value >= 0 && xProperty.sample_value <= 5){
                    xProperty.title      = item.title
                    xProperty.index_code = item.index_code
                    bottomLeftData.value.push({xProperty, yProperty})
                }
                if(xProperty.sample_value > 5 && xProperty.sample_value <= 10){
                    xProperty.title      = item.title
                    xProperty.index_code = item.index_code
                    bottomRightData.value.push({xProperty, yProperty})
                }
            }
        })
    }

    const showConfiguration = (componentName, options, confId) => {
        props.switchSidebarComponent(componentName, {
            persistance: getPersistance(props.activeTemplate, confId),
            confId: confId,
            transform: (config) => {
                let inputs = prepareConfiguration(options, config);
                return prepareRequest(props.activeTemplate, confId, config, inputs, props.synchronize);
            }
        });
    }

    const getData = (dataSource, index, objectKey) => {
        return get(dataSource[index], objectKey)
    }

    watchEffect(() => {
        if(!props.conceptData) return;
        hintsText.value = props.conceptData.hints_text
        definitions.value = props.conceptData.definitions
        prepareDataForTable(props.collections)
    })
  </script>
  
  <style scoped>
    .header{
        margin-left: 68px;
    }
    .graph-box{
        display: grid;
        gap: 14px;
    }
    .graph-box .left-panel{
        grid-column: 1 / span 1;
        grid-row: 1;
    }
    .graph-box .content{
        grid-column: 2 / span 2;
        grid-row: 1;
    }
    .graph-box .bottom-panel{
        grid-column: 2 / span 2;
        grid-row: 2;
    }
    .graph-box .content{
        width: 1606px;
        height: 660px;
        margin-left: -32px;
        position: relative;
    }
    .graph-box .content::before{
        pointer-events: none;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
    }
    
    table tr,
    table td{
        border-color: var(--borderColor);
        color: var(--color);
    }
    .td-padding{
        padding: 0 8px;
        height: 26.4px;
        max-width: 85px;
    }
  </style>