<template>
    <div>
      <div class="flex mt-10">
        <slot name="header">
            <Navbar 
              @switchSidebarComponent="switchSidebarComponent"
              :inactiveItems="inactiveItems"
              :items="sidebarItems"
            ></Navbar>
        </slot>
      </div>
      <slot></slot>
    </div>
</template>
<script setup>
import Navbar from '@/elements/leftSidebar/Navbar.vue';
defineProps({
    switchSidebarComponent: Function,
    sidebarItems: Array,
    inactiveItems: {
      type: Array,
      default:() =>([])
    }
})
</script>