let row_id = `default_row-${ Date.now() }`

let layout =  {
    name: 'largeLayout',
    rows: [
        {id: row_id, class: 'grid gap-5 grid-cols-3 grid-rows-2'},
    ],
    cards: [
        {
            rows_reference: row_id,
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: 'i-row-span-2 col-span-2 ',
                height: 546,
                componentName: 'LargeChart'
            }
        },
        {
            rows_reference: row_id,
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: row_id,
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        }
    ],
    isSelected: false
}

export default layout