import { set } from 'vue'
import { useTemplate } from '@/views/screens/communicator/useTemplate'

export default function useDefinition() 
{
    const { updateTemplate } = useTemplate()
    const setInitialData = (template) => 
    {
        if (template.settings.color === true || !template.settings.color) {
            set(template.settings, 'color', '#1C1C1D')
        }
        if (!('background_image_opacity' in template.settings)) {
            set(template.settings, 'background_image_opacity', 100);
        }
        if (!('background_color_opacity' in template.settings)) {
            set(template.settings, 'background_color_opacity', 100);
        }
        if (!('background_color' in template.settings)) {
            set(template.settings, 'background_color', '#fff');
        }
        if (!('configuration_no' in template.settings)) {
            set(template.settings, 'configuration_no', 1);
        }
    }

    let timeoutId = null
    const editTitle = (text, activeTemplate) => {
        set(activeTemplate.customized_data, 'title', text)
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            updateTemplate(activeTemplate)
        }, 1000)
    }

    return {
        setInitialData,
        editTitle
    }
}