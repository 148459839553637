<template>
     <div class="i-mt-30 font-poppins" v-if="template">
        <input type="text" @keyup="updateTemplate" v-model="template.title" class="i-border-1 i-h-30 border-secondary-five px-2 text-primary-one w-full rounded-sm fs-14 fw-500" />
        <input type="text" :value="template.title" disabled class="i-border-1 i-h-30 border-secondary-five bg-primary-three px-2 text-primary-one w-full rounded-sm fs-14 fw-500 mt-5" />
        <div class="i-mt-28">
            <Accordion
                title="Content Configuration"
                :active="true"
                bg="transparent"
                classes="shadow-none"
                titleClasses="text-primary-one fs-14"
                iconClasses="w-6 h-6"
                buttonClasses="i-right-n-2 i-top-0"
                accordionClasses=""
                align="left"
            >
                <div>
                    <InputSelect
                        :items="rootDataSet"
                        label="Select source"
                        v-model="configuration.rootType"
                    />
                    <InputSelect
                        v-if="configuration.rootType == 'project'"
                        :items="[project]"
                        label="Select project"
                        text-key="name"
                        v-model="configuration.project_id"
                    />
                    <InputSelect
                        v-if="['1', '2', '3', '4', 'scenario'].includes(configuration.rootType)"
                        :items="scenarios"
                        label="Select scenario"
                        v-model="configuration.scenario_id"
                    />
                    <InputSelect
                        v-if="['1', '2', '3', '4', 'module'].includes(configuration.rootType) && (configuration.scenario_id !== 'null' || configuration.rootType == 'module')"
                        :items="modules"
                        label="Select module"
                        v-model="configuration.module_id"
                        @change="selectModule"
                    />
                    <!-- If rootType module so scenario will show after module dropdown -->
                    <InputSelect
                        v-if="['module'].includes(configuration.rootType) && configuration.module_id !== 'null'"
                        :items="scenarios"
                        label="Select scenario"
                        v-model="configuration.scenario_id"
                    />

                    <div v-if="configuration.rootType >= 1 && configuration.module_id !== 'null'">
                        <select class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                                v-model="configuration.first_level_id" @change="setLevelOne">
                            <option value="null" selected disabled>Select Level 1</option>
                            <option v-for="(l1, l1Index) in collections" :key="l1Index" :value="l1.id">{{ l1.title }}</option>
                        </select>

                        <div v-if="configuration.rootType >= 2  && configuration.first_level_id !== 'null'">
                            <select class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                                    v-model="configuration.second_level_id" @change="setLevelTwo">
                            <option value="null" selected disabled>Select Level 2</option>
                            <option v-for="(l2, l2Index) in l1Data" :key="l2Index" :value="l2.id">{{ l2.title }}</option>
                            </select>

                            <div v-if="configuration.rootType >= 3  && configuration.second_level_id  !== 'null'">
                                <select class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                                        v-model="configuration.third_level_id" @change="setLevelThree">
                                    <option value="null" selected disabled>Select Level 3</option>
                                    <option v-for="(l3, l3Index) in l2Data" :key="l3Index" :value="l3.id">{{ l3.title }}</option>
                                </select>

                                <div v-if="configuration.rootType >= 4  && configuration.third_level_id  !== 'null'">
                                    <select class="mt-5 w-full i-border-1 border-secondary-five px-1 rounded-sm fs-14 fw-500 text-primary-one i-h-30 inst-dropdown param-dropdown"
                                            v-model="configuration.fourth_level_id" @change="updateTemplate">
                                    <option value="null" selected disabled>Select Level 4</option>
                                    <option v-for="(l4, l4Index) in l3Data" :key="l4Index" :value="l4.id">{{ l4.title }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Parameter v-if="['roadmap','box-concept', 'box-concept-5'].includes(currentTemplateName) && selectedModule" :module="selectedModule" :configuration="configuration" />
                </div>
            </Accordion>
        </div>

        <StyleLogic
            v-if="['roadmap','box-concept', 'box-concept-5'].includes(currentTemplateName)"
            :template="template"
            :styles="getStyleLogic"
            @updateTemplate="updateTemplate"
        />
    </div>
</template>

<script setup>
    import Accordion from "@/elements/atom/Accordion.vue";
    import InputSelect from '@/components/communicator/v2/sidebar/components/InputSelect';
    import Parameter from '@/components/communicator/v2/sidebar/components/Parameter.vue';
    import StyleLogic from "@/components/communicator/v2/sidebar/components/StyleLogic";
    import { useTemplate } from  '@/views/screens/communicator/useTemplate.js';
    import { useSidebar } from '@/views/screens/communicator/useSidebar.js';
    import { useConfiguration } from '@/views/screens/communicator/useConfiguration.js';
    import { watch, ref, computed, onMounted } from 'vue';
    import { get, isFunction, isEmpty } from 'lodash'
    import store from '@/store';
    import router from '@/routes/routes.js';

    const route = router.currentRoute;

    const rootDataSet = [
        {column: 'sample_id', storeKey: 'sample_id', id: 'simpleText', title: 'Simple Text'},
        {column: 'project_id', storeKey: 'project_id', id: 'project', title: 'Project'},
        {column: 'scenario_id', storeKey: 'scenario_id', id: 'scenario', title: 'Scenario'},
        {column: 'module_id', storeKey: 'module_id', id: 'module', title: 'Module'},
        {column: 'level_id', storeKey: 'first_level_id', id: 1, title: 'Group Level 1'},
        {column: 'level_id', storeKey: 'second_level_id', id: 2, title: 'Group Level 2'},
        {column: 'level_id', storeKey: 'third_level_id', id: 3, title: 'Group Level 3'},
        {column: 'level_id', storeKey: 'fourth_level_id', id: 4, title: 'Group Level 4'}
    ];
    const props = defineProps({
        template: Object,
        modules: Array,
        transform: Function,
        onFinish: Function,
        persistance: Object,
        confId: String
    });

    const { getScenariosByProject, getLevelDataByScenarioAndModule, saveTemplateConfiguration } = useSidebar();
    const { removeIfExist } = useTemplate();
    const { l1Data, l2Data, l3Data, collections, scenarios, setLevelOne, setLevelTwo, setLevelThree, prepareLevelData } = useConfiguration();

    const configuration =  ref({});

    const selectedModule = ref(null);
    const project  = computed(() => store.getters['project/project']);
    const currentTemplateName = computed(() => get(props.template, 'template.component', ''));
    const getStyleLogic = computed(() => {
        if(props.template && props.template.definition_style && props.template.definition_style.length > 0) {
          return props.template.definition_style;
        }
        return [
          { range: '0-2', bg: '#AD2B81', definition: 'Very Low' },
          { range: '2-4', bg: '#ABA62B', definition: 'Low' },
          { range: '4-6', bg: '#8D8D8D', definition: 'Medium' },
          { range: '6-8', bg: '#2BAD7E', definition: 'High' },
          { range: '8-10', bg: '#2B80AD', definition: 'Extreme' }
        ]
    });
    // Watchers
    watch(configuration, () => {
        if(configuration.value.rootType == 'simpleText') {
            let index = props.template.data_layer.findIndex(item => item.conf_id == props.confId);
            props.template.data_layer.splice(index, 1);
            store.dispatch("communicator/modifyTemplate", props.template);
            return;
        }
        let selectedSource = rootDataSet.find(item => item.id == configuration.value.rootType);
        if(configuration.value.rootType !== 'module') {
            if(selectedSource && configuration.value[selectedSource.storeKey]) {
                saveConfiguration();
            }
        }
        // If rootType is module
        if(selectedSource && configuration.value['scenario_id']) {
            saveConfiguration();
        }
       
    }, {deep: true});
    watch(
        () => props.persistance,
        (value) => {
            prepareLevelData(props.persistance, route.params.id);
            if(!isEmpty(value)) {
                configuration.value = value;
                return;
            }
            resetConfiguration();
    }, {deep: true});

    // Methods

    const saveConfiguration = async () => {
        let { column, storeKey } = rootDataSet.find(item => item.id == configuration.value.rootType);
        
        configuration.value['column'] = column;
        configuration.value['storeKey'] = storeKey;

        let { confId, ...modifiedData } = props.transform ? props.transform(configuration.value) : {};

       if(modifiedData) {
            let { configuration: conf } = await saveTemplateConfiguration(props.template.id, modifiedData);
            if(isFunction(props.onFinish)) {
                props.onFinish(conf);
                return;
            }
            // removeIfExist(props.template, confId);
            props.template.data_layer = conf;
        }
    }
    const selectModule = async (moduleId) => {
        selectedModule.value = props.modules.find(module => module.id == moduleId);
        if(!isNaN(parseInt(configuration.value.rootType)) && configuration.value.scenario_id) {
            collections.value = await getLevelDataByScenarioAndModule(configuration.value.scenario_id, moduleId);
        }
    }
    const resetConfiguration = () => {
        configuration.value = {
            rootType: 'null',
            project_id: 'null',
            scenario_id: 'null',
            module_id: 'null',
            first_level_id: 'null',
            second_level_id: 'null',
            third_level_id: 'null',
            fourth_level_id: 'null',
        }
    }
    const updateTemplate = (data) => {
        store.dispatch("communicator/updateTemplate", data);
    }
    // Lifecycle hook
    onMounted(async () => {
        prepareLevelData(props.persistance, route.params.id);
        if(!isEmpty(props.persistance)) {
            configuration.value = props.persistance;
            return;
        }
        resetConfiguration();
    });

</script>