import { isEmpty, sum } from "lodash"
import moment from "moment"
import { set } from 'vue'


export default function useProgressCalculation() 
{
    const _panelWeightProgressTowardsTarget = (data, showActiveOnly) => {
        if(data.i_am == 'activity') return;
        let value = 0;
        let filteredItems = data.children.filter(item => {
            if(!showActiveOnly) return item
            return item.status || item.i_am == "activity"
        })

        filteredItems.forEach(item => {
            value += Number(item.weight_progress_towards_target)
        })

        value = filteredItems.length ? value / filteredItems.length : 0
        value = value ? Math.round(value, 2) : 0;
        
        set(data, 'weight_progress_towards_target', value)
        
        return value
    }

    const _panelWeightTarget = (data, showActiveOnly) => {
        if(data.i_am == 'activity') return;
        let value = 0

        let filteredItem = data.children.filter(item => {
            if(!showActiveOnly){
                return item
            }
            return item.status || item.i_am == "activity"
        })

        filteredItem.forEach(item => {
            value += Number(item.weight_target)
        })
        
        value = filteredItem.length ? value / filteredItem.length : data.weight_target
        value = value ? Math.round(value, 2) : 0;

        set(data, 'weight_target', value)
        return value
    }

    const _panelWeight = (data, showActiveOnly) => {
        if(data.i_am == 'activity') return;
        let value = 0

        let filteredItem = data.children.filter(item => {
            if(!showActiveOnly){
                return item
            }
            return item.status || item.i_am == "activity"
        })

        filteredItem.forEach(item => {
            value += Number(item.weight)
        })
        
        value = filteredItem.length ? value / filteredItem.length : data.weight_target
        value = value ? Math.round(value, 2) : 0;
        set(data, 'weight', value)
        return value
    }
    
    const _getStartDateAndEndData = (data, showActiveOnly) => {
        if(data.i_am == 'activity') return
        let filteredItems = data.children.filter(item => {
            if(!showActiveOnly) return item
            return item.status || item.i_am == "activity"
        })

        let date = {
            start: [],
            end: []
        }
        filteredItems.forEach(item => {
            date.start.push(new Date(item.start_date))
            date.end.push(new Date(item.end_date))
        })

        let minDate = Math.min.apply(null, date.start)
        let maxDate = Math.max.apply(null, date.end)

        data.start_date = moment(minDate).format('YYYY-MM-DD')
        data.end_date   = moment(maxDate).format('YYYY-MM-DD')
    }
    const _getWeightActualProgressAttribute = (data, showActiveOnly) => {
        if(data.i_am == 'activity'){
            let value = (data.weight * data.actual_progress) / 100
            data.weight_actual_progress = value ? Math.round(value, 2) : 0
            return
        }
        let filteredItems = data.children.filter(item => {
            if(!showActiveOnly) return item
            return item.status || item.i_am == "activity"
        })

        let value = 0
        filteredItems.forEach(item => {
            value += Number(item.weight_actual_progress)
        })
        value = filteredItems.length ? value / filteredItems.length : 0
        value = value ? Math.round(value, 2) : 0;
        data.weight_actual_progress = value
        return value
    }
    const _getConfidence = (data, showActiveOnly) => {
        if(data.i_am == 'activity') return;
        let value = 0

        let filteredItem = data.children.filter(item => {
            if(!showActiveOnly){
                return item
            }
            return item.status || item.i_am == "activity"
        })

        if(data.i_am == "key-activity"){
            filteredItem = filteredItem.filter(item => (item.i_am == "activity" && item.type == 3))
        }

        filteredItem.forEach(item => {
            if(item.i_am == 'activity'){
                value += Number(item.weight_progress_towards_target)
            }else{
                value += Number(item.confidence_weight_progress_towards_target)
            }
        })
        
        value = filteredItem.length ? value / filteredItem.length : 0
        value = value ? Math.round(value, 2) : 0;
        
        data.confidence_weight_progress_towards_target = value
        return value
    }

    const _calculateProgress = (item) => {
        const initialDate = moment(item.start_date)
        const bottomDate  = moment(item.end_date)
        const totalDays = bottomDate.diff(initialDate, 'days')
        
        
        const today = new Date()
        const howManyDaysPassed = moment(today).diff(initialDate, 'days')
        const timePassedInPercent = howManyDaysPassed > 0 ? (howManyDaysPassed * 100) / totalDays : 0
        let progressStatus = "AHEAD"

        if(timePassedInPercent > item.weight_actual_progress) {
            progressStatus = "BEHIND"
        }

        const timeAlignProgress = Math.round(Math.abs(timePassedInPercent - item.weight_actual_progress))
        item.progress_headline = `ACTUAL PROGRESS ${timeAlignProgress}% ${progressStatus} TIME COMPLETED`
        item.progress_goal_headline = _getGoalProgressHeadline(item.weight_actual_progress, item.i_am)
        item.progress_time_color = timePassedInPercent > item.weight_actual_progress ? "#AD2B81" : "#2BAD7E"
        item.progress_achieve_color = _getGoalProgressAchieveColor(item.weight_actual_progress)
        item.progress_achieve_icon = _getGoalProgressAchieveIcon(item.weight_actual_progress)
        item.progress_with_time_text = "The goals actual progress towards target based all associated activities."
        item.progress_with_time = timeAlignProgress
    }

    const _getGoalProgressHeadline = (progress) => {
        if(progress > 75) {
            return "Close to being achieved 100%"
        } else if(progress > 50) {
            return "Significant progress has been made"
        } else if(progress > 25) {
            return "Some progress has been made"
        } else {
            return "No progress has been made"
        }
    }
    const _getGoalProgressAchieveColor = (progress) => {
        if(progress > 75) {
            return "#2BAD7E"
        } 
        if(progress > 50) {
            return "#55AD2B"
        } 
        if(progress > 25) {
            return "#2BAD9D"
        }
        
        return "#AD2B81"
    }
    const _getGoalProgressAchieveIcon = (progress) => {
        if(progress > 75) {
            return "Crown"
        }
        if(progress > 50) {
            return "Trophy"
        }
        if(progress > 25) {
            return "Medal"
        }
        
        return "ThumbsUp"
    }

    const getTotalOutcome = (goal) => {
        if(isEmpty(goal.value_drivers)) goal.total_outcome = 0
        const outcomes = goal.value_drivers.map(item => {
            return item.per_woptt * goal.weight_progress_towards_target
        })
        goal.total_outcome = sum(outcomes)
    }

    const transformGoal = (data, showActiveOnly) => {
        if(Array.isArray(data)){
            data.forEach(item => transformGoal(item))
            return
        }
        
        if(data && Object.keys(data).length){

            if(!isEmpty(data.children)){
                transformGoal(data.children)

                _panelWeightProgressTowardsTarget(data, showActiveOnly)
                _panelWeightTarget(data, showActiveOnly)
                _panelWeight(data, showActiveOnly)
                _getWeightActualProgressAttribute(data, showActiveOnly)
                _getStartDateAndEndData(data, showActiveOnly)
                _getConfidence(data, showActiveOnly)
                _calculateProgress(data)
    
                if(data.i_am == 'goal'){
                    getTotalOutcome(data)
                }
                return
            }
        }
    }


    const _getProgressTowardsTarget = (item) => {
        let value = 0;
        if(item.target > 0) {
            value = (getActivityActualProgress(item) / Number(item.target)) * 100;
        }
        return value ? Math.round(value, 2) : 0;
    }

    const getWeightTarget = (item) => {
        $value = (Number(item.weight) * Number(item.target)) / 100;
        return $value ? Math.round($value, 2) : 0;
    }

    const getActivityWeightProgressTowardsTarget = (item) => {
        let value = _getProgressTowardsTarget(item) * (Number(item.weight) / 100);
            value = value ? Math.round(value, 2) : 0;
        item.weight_progress_towards_target = value
        return value
    }

    const getActivityActualProgress = (item) => {
        if(item.goal > 0) {
            let initial = item.goal - item.baseline;
            if(initial > 0) {
                let value = (Number(item.actual_value) / Number(initial)) * 100;
                value = value ? Math.round(value, 2) : 0
                item.actual_progress = value
                return value
            }
        }
        return 0;
    }

    return {
        getWeightTarget,
        getActivityActualProgress,
        getActivityWeightProgressTowardsTarget,
        transformGoal,
        getTotalOutcome
    }
}