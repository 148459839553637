import { get } from 'lodash'
import { set } from 'vue'
import { useTemplate } from '@/views/screens/communicator/useTemplate.js';

export default function useTemplateTitle(){
    const { updateTemplate } = useTemplate();
    const initTitle = (activeTemplate, title='Title goes here') => 
    {
        if(!activeTemplate.customized_data.title){
            activeTemplate.customized_data = {title: title}
            updateTemplate(activeTemplate)
        }
    }

    let timeoutId = null
    const editTitle = (title, activeTemplate) => {
        set(activeTemplate.customized_data, 'title', title)
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            updateTemplate(activeTemplate)
        }, 1000)
    }
    const editSubTitle = (title, activeTemplate) => {
        set(activeTemplate.customized_data, 'subtitle', title)
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            updateTemplate(activeTemplate)
        }, 1000)
    }

    const getTitle = (activeTemplate) => {
        return get(activeTemplate, 'customized_data.title')
    }

    const getSubtitle = (activeTemplate) => {
        return get(activeTemplate, 'customized_data.subtitle')
    }

    return {
        initTitle,
        getTitle,
        editTitle,
        getSubtitle,
        editSubTitle
    }
}