<template>
    <div class="w-full flex flex-col three-by-three-layout">
        <div class="flex w-full border-4 borderColor rounded overflow-x-auto w-1460px">
            <template v-for="(data, index) in dataPattern">
                <div 
                    class="grid flex-1 h-full" 
                    style="grid-template-rows: 274px 160px 274px;"
                    :key="index" v-if="data<8"
                >
                    <template v-for="(category, i) in gridLayout.layout_grid_category" >
                        <div
                            v-if="(data) < i && i <= dataPattern[index+1]"
                            class="shrink-0"
                            :key="category.id"
                        >
                           <LayoutComponent 
                                class="layoutWrapper relative i-p-15 h-full border borderColor"
                                :style="{'--backgroundImage': `url(${backgroundImages[index][i]?.bg})`, '--opacityForImg': backgroundImages[index][i]?.opacity}"
                                :category="category" 
                            />
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import LayoutComponent from './components/LayoutComponent.vue';
    import imageOne from '@/assets/images/visualize/3x3-1.webp'
    import imageTwo from '@/assets/images/visualize/3x3-2.webp'
    import imageThree from '@/assets/images/visualize/3x3-3.webp'
    import imageFour from '@/assets/images/visualize/3x3-4.webp'
    import imageFive from '@/assets/images/visualize/3x3-5.webp'
    
    const dataPattern = [-1, 2, 5, 8];
    const props = defineProps(['gridLayout'])
    const backgroundImages = [[{
          bg: imageOne,
          opacity: 1/100*5
      },
      {
          bg: imageThree,
          opacity: 1/100*10
      },
      {}],

      [{}, {}, {}, {
          bg: imageTwo,
          opacity: 1/100*5
      },
      {
          bg: imageFour,
          opacity: 1/100*50
      }],

      [{}, {}, {}, {}, {}, {}, {}, {},
      {
          bg: imageFive,
          opacity: 1/100*10
    }]]
</script>

<style scoped>
    .three-by-three-layout{
        margin-left: 71px;
        width: 1600px;
        height: 720px;
    }
    .layoutWrapper::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: var(--backgroundImage);
        opacity: var(--opacityForImg);
        pointer-events: none;
    }
    .list{
        list-style: disc;
        display: grid;
        gap: 15px;
        margin-left: 20px;
        font-size: 16px;
        font-weight: 300px;
        color: #1C1C1D;
    }
    .borderColor{
        border-color: var(--borderColor);
    }
</style>