import Concept from '@/components/communicator/v2/templates/okr/concept/Index'
import Introduction from '@/components/communicator/v2/templates/okr/introduction/Index'
import Frontpage from '@/components/communicator/v2/templates/okr/frontPage/Index'
import Highlevel from '@/components/communicator/v2/templates/okr/highlevel/Index'
import Metrics from '@/components/communicator/v2/templates/okr/metrics/Index'
import DetailedMetrics from '@/components/communicator/v2/templates/okr/detailedMetrics/Index.vue'
import Management from '@/components/communicator/v2/templates/okr/management/Index.vue'

export default {
    'okr-frontpage': Frontpage,
    'okr-concept': Concept,
    'okr-introduction': Introduction,
    'okr-highlevel': Highlevel,
    'okr-metrics': Metrics,
    'okr-detailedmetrics': DetailedMetrics,
    'okr-management': Management,
}