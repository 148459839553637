<template>
    <Card
        :config="card.configuration"
        :cardId="card.id"
    >
        <slot></slot>
    </Card>
</template>

<script setup>
    import Card from '@/components/dashboard/components/Card.vue';
    import { watch } from 'vue';
    import { useConfiguration } from '@/views/screens/dashboard/useConfiguration.js'

    const props = defineProps({
        card: Object
    });
    const { updateChartData } = useConfiguration()
    watch(() => props.card.configuration, (value) => {
        let data = {
            configuration: value
        }
        updateChartData(data, props.card.id);
    }, {deep: true});
</script>