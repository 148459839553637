import { unref, set, watchEffect, isRef, computed } from 'vue'
import { useTemplate } from '@/views/screens/communicator/useTemplate'

export default function useOkrMetrics(activeTemplate) {

    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getTemplateBorderColor, getRootValue, getParamForAlevelAPI } = useTemplate();

    const updateActiveTemplate = () => updateTemplate(activeTemplate.value)
    const content = computed(() => activeTemplate.value.customized_data.contents[0])

    const setInitialData = () => {
        const template = unref(activeTemplate)
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
            template.customized_data.title = 'Heading'
            template.customized_data.subtitle = 'OKR canvas 2023'
        }
        let isContents = "contents" in template.customized_data;
        let isItems = "items" in template.customized_data;
        if(!isContents){
            set(template.customized_data, 'contents', _makeContents())
            // updateActiveTemplate()
        }
        if(!isItems){
            set(template.customized_data, 'items', _makeItems())
            // updateActiveTemplate()
        }
        
    }

    const _makeContents = () => {
        return [{
            objective: {
                letter: 'O',
                text: 'Objective',
                bgColor: '#2B80AD',
            },
            
            parameters: [{ property_id: '', id: '', definition_id: '' }],
            items: [
                {
                    title: 'Objective',
                    description: 'Lorem ipsum dolor sit amet  elit. Aenean bibendum. Lorem ipsu',
                    bgColor: '#fff',
                    attachable: true,
                    boxStyle: {
                        height: '190px',
                        width: '891px',
                        borderRadius: '20px'
                    },
                    textareaStyle: {
                        height: '121px',
                        width: '835px',
                    }
                },
                {
                    title: 'Objective',
                    description: 'Lorem ipsum dolor sit amet  elit. Aenean bibendum. Lorem ipsu',
                    bgColor: '#fff',
                    attachable: false,
                    boxStyle: {
                        height: '190px',
                        width: '459px',
                        borderRadius: '20px'
                    },
                    textareaStyle: {
                        height: '121px',
                        width: '398px'
                    }
                },
            ]
        }]
    }

    const _makeItems = () => {
        let items = []
        for(let i =0; i<2; i++){
            items.push({
                objective: {
                    letter: 'O',
                    text: 'Objective',
                    bgColor: '#2B80AD',
                    details: 'Add text'
                },
                result: {
                    title: 'Objective',
                    bgColor: '#fff',
                    list: []
                }
            })
        }
        return items
    }

    if(isRef(activeTemplate)) {
        watchEffect(setInitialData)
    }

    return {
        updateActiveTemplate,
        getDataLayerItem,
        prepareConfiguration, 
        prepareRequest, 
        getPersistance,
        getTemplateBorderColor,
        content,
        getRootValue,
        getParamForAlevelAPI
    }
}