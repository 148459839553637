<template>
    <div class="relative font-poppins h-full" :class="{ 'w-12': !isExpanded }">
      <div class="">
        <div class="sticky top-0">
          <div class="i-w-200 pl-2 flex items-center justify-between h-9 pt-2 w-full">
              <InputSelectWithVmodel
                  v-model="column.associated_module_id"
                  label="Select Module"
                  :selected="module.id"
                  @change="updateWidgetSettings"
                  :items="modules"
                  :label-classes="'text-secondary-two fs-14 fw-500'"
                  :classes="'-mt-3 px-1 rounded-sm fs-12 fw-400 text-primary-one bg-transparent inst-dropdown param-dropdown w-32'"
              />
              <div @click="handleBarClick" class="fs-20 fw-800 cursor-pointer opacity-40 -mt-4">+</div>
          </div>
          <div class="bg-primary-four rounded-sm flex items-center justify-center mt-1" style="height: 145px">
            <span class="fs-16 fw-500 text-white ">{{ column.title }}</span>
          </div>
        </div>
      
        <div
          class="space-y-1 mt-1"
        >
          <div
            v-for="item in association.getFilterCollectionWithAssociatedModules(filteredCollections)"
            :key="item.id"
            class="flex gap-x-1"
            :class="getHeightClass(getExpansionStatesByLevel(item.filterCollection.id))"
          >
            <template v-if="item.associatedModules.length">
              <template v-for="(column, cIndex) in item.associatedModules">
                <div v-if="cIndex <= renderLimit" :key="column.id" class="w-72 h-full flex px-2 py-2 rounded-sm border border-primary-one fw-400 fs-16" >
                  <span class="truncate ls-5 font-poppins fs-16 fw-400" >
                      {{ column.title }}
                    </span>
                </div>
              </template>
            </template>
            <div
              :class="getHeightClass(getExpansionStatesByLevel(item.filterCollection.id))"
              class="w-72 flex justify-center py-2 rounded-sm border border-primary-one fw-400 fs-12 text-secondary-one"
              v-else
            ></div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script setup>
    import usePermission from '@/composable/usePermission.js'
    import useVerticalExpansion from '@/components/communicator/v2/templates/coordinate/useVerticalExpansion'
    const { canCreate, canEdit, canDelete, canRead } = usePermission()
    const {getHeightClass, getExpansionStatesByLevel} = useVerticalExpansion()
  </script>
  
  <script>
  import Association from "./association.js";
  import InputSelect from "@/components/roadmap/canvas/widgets/AssociatedContent/InputSelect.vue";
  import InputSelectWithVmodel from "@/components/roadmap/canvas/widgets/AssociatedContent/InputSelectWithVmodel.vue";
  import { mapGetters, mapActions } from "vuex";
  import { deleteWidget } from '@/store/modules/roadmap/services'
  import WidgetTitle from "@/components/roadmap/WidgetTitle.vue";
  import CollapsedBar from '@/components/roadmap/canvas/widgets/CollapsedBar'
  import CollapseHandle from "@/components/roadmap/canvas/widgets/CollapseHandle";
  import { getTheGap } from "@/store/modules/roadmap/services";
  import { getItemVisibility } from "@/store/modules/roadmap/services.js";
  import Parameter from "@/components/roadmap/canvas/widgets/people/component/Parameter.vue";

  export default {
    name: "AssociatedContent",

    components: {
      WidgetTitle,
      CollapsedBar,
      CollapseHandle,
      InputSelect,
      InputSelectWithVmodel,
      Parameter,
    },

    data() {
      return {
        propertyRange: {
          min: 0,
          max: 0,
        },
        renderLimit: 0,
        incrementing: true,
        selectedParameterId: null,
        showSetting: false,
        showDropdown: false,
        isExpanded: true,
        association: new Association(this),
      };
    },

    computed: {
      ...mapGetters({
        activeRoadmapData: "roadmap/programmatic",
        colorCodes: "global/colorCodes",
        activeData: "programmatic/activeScenarioCollection",
        project: "project/project",
      }),

      selectedModuleId() {
        return this.column.associated_module_id;
      },

      filterCollectionIds() {
        const collectionIdArr = [];
        this.filteredCollections.forEach((item) => {
          collectionIdArr.push(item.id);
        });
        return collectionIdArr;
      },
      
      getHeaderWidth() {
        if(this.isExpanded) {
          if(this.showSetting) {
            return 'm-w-260';
          }
          return 'm-w-200';
        }
        return 'w-12';
      }
    },

    props: [
      "column",
      "scenarioId",
      "module",
      "modules",
      "layout",
      "widget",
      "flatData",
      "filteredCollections",
      "isolatedModules",
    ],

    methods: {
      ...mapActions({
        fetchAssociationByCollection: "roadmap/fetchAssociationByCollection",
      }),
      removeWidget() {
        this.$Progress.start();
        this.$store.dispatch("roadmap/deleteWidget", this.widget).then(response => {
          this.$Progress.finish();
          deleteWidget(this.widget.id, this.layout);
        });
      },
      handleCollapse() {
        this.isExpanded = !this.isExpanded;
      },
      isLastAndHaveSpace(item) {
        return item.column.length < 3;
      },
      getGap: getTheGap,
      getItemVisibility,
      toggleWidgetSettings(e) {
        e.preventDefault();
        if (this.selectedModuleId) {
          this.showSetting = !this.showSetting;
        } else {
          alert("Please Select a Module.");
        }
      },
      updateWidgetSettings(e) {
        this.association.moduleId = e;
        this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
      },
  
      removeAssociation(item, association) {
        this.$Progress.start();
        this.association
          .addRemoveAssociation(
            {
              associated_id: association.id,
              scenario_collection_id: item.id,
              project_id: this.$route.params.id,
              scenario_id: this.activeRoadmapData.scenario_id,
            },
            this.filterCollectionIds,
            "delete"
          )
          .then((response) => {
            this.$Progress.finish();
          });
      },
  
      handleBarClick() {
        const max = this.association.maxItemLength;
        // if(!this.incrementing && this.renderLimit == 0) {
        //   this.showDropdown = false;
        // }
        if(this.incrementing && this.renderLimit == 0) {
          this.showDropdown = true;
        }
  
  
        if (this.renderLimit == 0) {
          this.incrementing = true;
        }
        if (this.incrementing) {
          this.renderLimit++;
        }
  
        if (!this.incrementing) {
          this.renderLimit--;
        }
        if (this.renderLimit >= max - 1) {
          this.incrementing = false;
        }
      },
  
      onSelectAssociation($event, item) {
        this.$Progress.start();
        this.association
          .addRemoveAssociation(
            {
              associated_id: $event,
              scenario_collection_id: item.id,
              project_id: this.$route.params.id,
              scenario_id: this.activeRoadmapData.scenario_id,
            },
            this.filterCollectionIds
          )
          .then((response) => {
            this.$Progress.finish();
          });
      },
  
      getData(items) {
        let moduleInIsolation = this.isolatedModules.find(
          (item) => item && item.module_id === this.module.id
        );
        if (moduleInIsolation && moduleInIsolation.isolate) {
          return items.filter(
            (data) =>
              moduleInIsolation.isolatedIds.includes(data.id) && !data.root
          );
        }
        return items;
      },
  
      UpdateValues(e) {
        let range = this.propertyRange;
        range.min = e.minValue;
        range.max = e.maxValue;
      },
  
      getDotsBackgroundColor(collection, module) {
        let collectionSettings = module.collection_settings;
        if (collection && collection.background_color) {
          return collection.background_color;
        }
  
        collectionSettings = JSON.parse(collectionSettings);
  
        if (collectionSettings.collections[collection.level].custom_bg) {
          return collectionSettings.collections[collection.level].custom_bg;
        }
  
        if (collectionSettings && collectionSettings.collections.length > 0) {
          let indexLevel = collection.level - 1;
          let colorCodes = this.colorCodes.slice(
            indexLevel * 9,
            indexLevel * 9 + 9
          );
          return colorCodes[
            collectionSettings.collections[collection.level].color
          ];
        }
        return null;
      },
  
      setActiveScenarioCollection(module, item) {
        this.$store.commit("programmatic/setActiveBrainstormScenarioCollection", item);
        this.$store.commit("build/setModulePropertySet", module.properties);
        this.$emit("toggleToPropertyMode");
      }
    },
  
    mounted() {
      this.association.getAssociations(this.filterCollectionIds);
      this.association.getModuleLevel(this.scenarioId, this.column.associated_module_id);
      this.association.moduleId = this.selectedModuleId;
    },
  };
  </script>
  