<template>
    <img
        @click="$emit('onClickImage')"
        :style="{
            height: height+'px',
            width: width+'px'
        }"
        class="rounded-full object-cover"
        :class="[isBlock ? 'block' : '', classes]"
        :src="path" 
        alt=""
    >
</template>

<script>
export default {
    name: 'RoundImage',
    props: {
        path: String,
        height:[String, Number],
        width:[String, Number],
        classes: String,
        isBlock: {
            type: Boolean,
            default: false
        },
    }
}
</script>
