<template>
    <div
        class="w-2 h-2 rounded-sm"
        :class="customMargin"
        :style="{background}"
    ></div>
</template>

<script>
    export default {
        props: {
            background: {
                type: String,
                default: '#C9C9C9'
            },
            customMargin: {
                default: 'i-my-2'
            }
        },
    }
</script>
