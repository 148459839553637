<template>
    <div>
        <div class="header">
            <h1 class="fs-20 fw-800 mb-3 mt-6">Diagram Canvas: {{ moduleTitle }}</h1>
        </div>
        <div class="content-wrapper">
            <div class="graph-box">
                <LeftPanel v-if="Array.isArray(conceptData.definitions)" :definitions="conceptData.definitions[1]" />
                <div class="content relative">
                    <GraphLineSvg
                        class="absolute top-0 left-0 right-0 bottom-0 svg-graph pointer-events-none"
                    />
                    <FourZonesInput :hintsText="conceptData.hints_text" />
                    
                    <div class="grid h-full">
                        <div class="h-full flex height-380px">
                            <div class="h-full w-full grid content-center" :style="{backgroundColor: !isEmpty(conceptData.colors) ? conceptData.colors[0] : ''}">
                                <span class="text-center fw-800 relative z-10" style="font-size: 187px;">
                                    {{ getHindText(conceptData.hints_text, 0) }}
                                </span>
                            </div>
                            <div class="h-full w-full grid content-center" :style="{backgroundColor: !isEmpty(conceptData.colors) ? conceptData.colors[1] : ''}">
                                <span class="text-center fw-800 relative z-10" style="font-size: 187px;">
                                    {{ getHindText(conceptData.hints_text, 1) }}
                                </span>
                            </div>
                        </div>
                        <div class="h-full flex height-380px">
                            <div class="h-full w-full grid content-center" :style="{backgroundColor: !isEmpty(conceptData.colors) ? conceptData.colors[2] : ''}">
                                <span class="text-center fw-800 relative z-10" style="font-size: 187px;">
                                    {{ getHindText(conceptData.hints_text, 2) }}
                                </span>
                            </div>
                            <div class="h-full w-full grid content-center" :style="{backgroundColor: !isEmpty(conceptData.colors) ? conceptData.colors[3] : ''}">
                                <span class="text-center fw-800 relative z-10" style="font-size: 187px;">
                                    {{ getHindText(conceptData.hints_text, 3) }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="z-20 relative">
                        <div
                            v-for="(l1, l1Index) in getData(collections)"
                            :key="l1Index"
                        >
                            <template
                                v-if="(getXValue(l1)>=horizontal_filter.minValue)
                                    && (getXValue(l1)<=horizontal_filter.maxValue)
                                    && (getYValue(l1)>=vertical_filter.minValue)
                                    && (getYValue(l1)<=vertical_filter.maxValue)
                            ">

                                <!-- v-if="filterLevel[0].visibility" -->
                                <div>

                                    <CircleTitle
                                        :showTitle="showTitle"
                                        :title="`${getIndexId(1)} ${getRootIndexNumber(1, l1Index)} ${l1.title}`"
                                        :circleText="getRootIndexNumber(1, l1Index)"
                                        :levelData="l1"
                                        :style="{
                                            position: 'absolute',
                                            left: getPosition(l1, 0, 40) + 'px',
                                            bottom: getPosition(l1, 1, 40) + 'px',
                                            color: activeTemplate.settings.color,
                                            background: getBg(1, l1)
                                        }"
                                    />
                                </div>

                                <div
                                    v-for="(l2, l2Index) in getData(l1.child)"
                                    :key="l2Index"
                                    class="relative"
                                >
                                    <template
                                        v-if="(getXValue(l2)>=horizontal_filter.minValue)
                                                && (getXValue(l2)<=horizontal_filter.maxValue)
                                                && (getYValue(l2)>=vertical_filter.minValue)
                                                && (getYValue(l2)<=vertical_filter.maxValue)
                                            "
                                    >
                                        <div>
                                            <CircleTitle
                                                :showTitle="showTitle"
                                                :title="`
                                                    ${ getIndexId(2) }
                                                    ${ getRootIndexNumber(1, l1Index) }.
                                                    ${ l2Index + 1 }
                                                    ${ l2.title }
                                                `"
                                                :circleText="`${ l1Index + 1 }.${ l2Index + 1 }`"
                                                :levelData="l2"
                                                :style="{
                                                    position: 'absolute',
                                                    left: getPosition(l2, 0, 35) + 'px',
                                                    bottom: getPosition(l2, 1, 35) + 'px',
                                                    color: activeTemplate.settings.color,
                                                    background: getBg(1, l2)
                                                }"
                                            />

                                            <div
                                                v-for="(l3, l3Index) in getData(l2.child)"
                                                :key="l3Index"
                                            >
                                                <template
                                                    v-if="(getXValue(l3)>=horizontal_filter.minValue)
                                                        && (getXValue(l3)<=horizontal_filter.maxValue)
                                                        && (getYValue(l3)>=vertical_filter.minValue)
                                                        && (getYValue(l3)<=vertical_filter.maxValue)
                                                ">
                                                    <div>
                                                        <CircleTitle
                                                            :showTitle="showTitle"
                                                            :title="`
                                                                ${ getIndexId(3) }
                                                                ${ getRootIndexNumber(1, l1Index) }.
                                                                ${ l2Index + 1 }
                                                                ${ l3Index + 1 }
                                                                ${ l3.title }
                                                            `"
                                                            :circleText="`${ l1Index + 1 }.${ l2Index + 1 }.${ l3Index + 1 }`"
                                                            :levelData="l3"
                                                            :style="{
                                                                position: 'absolute',
                                                                left: getPosition(l3, 0, 30) + 'px',
                                                                bottom: getPosition(l3, 1, 30) + 'px',
                                                                color: activeTemplate.settings.color,
                                                                background: getBg(1, l3)
                                                            }"
                                                        />

                                                        <div
                                                            v-for="(l4, l4Index) in getData(l3.child)"
                                                            :key="l4Index"
                                                        >
                                                            <template
                                                                v-if="(getXValue(l4)>=horizontal_filter.minValue)
                                                                    && (getXValue(l4)<=horizontal_filter.maxValue)
                                                                    && (getYValue(l4)<=vertical_filter.minValue)
                                                                    && (getYValue(l4)<=vertical_filter.maxValue)
                                                            ">
                                                                <div>
                                                                    <CircleTitle
                                                                        :showTitle="showTitle"
                                                                        :title="`
                                                                            ${ getIndexId(3) }
                                                                            ${ getRootIndexNumber(1, l1Index) }.
                                                                            ${ l2Index + 1 }
                                                                            ${ l3Index + 1 }
                                                                            ${ l4Index + 1 }
                                                                            ${ l4.title }
                                                                        `"
                                                                        :circleText="`${ l1Index + 1 }.${ l2Index + 1 }.${ l3Index + 1 }.${ l4Index + 1 }`"
                                                                        :levelData="l4"
                                                                        :style="{
                                                                            position: 'absolute',
                                                                            left: getPosition(l4, 0, 25) + 'px',
                                                                            bottom: getPosition(l4, 1, 25) + 'px',
                                                                            color: activeTemplate.settings.color,
                                                                            background: getBg(1, l4)
                                                                        }"
                                                                    />
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>

                                        </div>
                                    </template>
                                </div>

                            </template>
                        </div>
                    </div>
                </div>
                <BottomPanel v-if="Array.isArray(conceptData.definitions)" :definitions="conceptData.definitions[0]" />
            </div>
        </div>
    </div>
  </template>
  
  <script setup>
    import {cloneDeep, isEmpty} from "lodash";
    import { onMounted, ref } from 'vue'
    import LeftPanel from '@/components/communicator/v2/templates/concept/components/LeftPanel.vue'
    import BottomPanel from '@/components/communicator/v2/templates/concept/components/BottomPanel.vue'
    import FourZonesInput from './components/FourZonesInput.vue'
    import store from '@/store'
    import GraphLineSvg from "./GraphLineSvg.vue";
    import CircleTitle from "./components/CircleTitle.vue"

    const props = defineProps({
        activeTemplate:{
            type: Object
        },
        conceptData:{
            type: Object
        },
        collections:{
            type: Array
        },
        showTitle: {
            default: 1,
            type: [Boolean, Number]
        }
    })

    const horizontal_filter = { minValue: 0, maxValue: 10 }
    const vertical_filter = { minValue: 0, maxValue: 10 }
    const defaultColors = ["#2B80AD", "#3D6A83", "#6A7881", "#818487"]
    const colorCodes = ref({})
    const activeCommunicator = () => store.getters['communicator/communicator']
    const currentItem = ref()
    const moduleTitle = ref('')
    const viewDetails = ''
    const filterLevel = []
    const layoutSize = {
        width: 1590,
        height: 760
    }
    const getHindText = (hintsTextArray, index) => {
        if(hintsTextArray[index]){
            return hintsTextArray[index].charAt(0)
        }
    }
    const getXValue = (item) => {
      let xObj = item.properties.find(
        (hr) =>
            parseInt(hr.property_collection_id) ===
            parseInt(props.conceptData.hr_property_collection_id)
        )
        return xObj ? xObj.sample_value : 0;
    }

    const getYValue = (item) => {
        let yObj = item.properties.find((vr) =>
            parseInt(vr.property_collection_id) ===
            parseInt(props.conceptData.vr_property_collection_id)
        );
        return yObj ? yObj.sample_value : 0;
    }

    const getPosition = (item, pos, selfMargin) => {
        let canvasWidth = layoutSize.width;
        let canvasHeight = layoutSize.height;
        let level = item.level;
        let id = item.id;

        let tempX = 0;
        let tempY = 0;

        tempX = item.properties.find(
        (hr) =>
            parseInt(hr.property_collection_id) ===
            parseInt(props.conceptData.hr_property_collection_id)
        );
        tempY = item.properties.find(
        (hr) =>
            parseInt(hr.property_collection_id) ===
            parseInt(props.conceptData.vr_property_collection_id)
        );

      let x = tempX ? tempX.sample_value : 0;
      let y = tempY ? tempY.sample_value : 0;
      let currentLayout = [canvasWidth, canvasHeight];
      let xy = [
        (currentLayout[0] / 10) * x - selfMargin,
        (currentLayout[1] / 10) * y - selfMargin,
      ];

      return xy[pos];
    }

    const getLevelWiseColorCode = (level) => {
        if(!Array.isArray(colorCodes.value)) return ''
        let indexLevel = level - 1;
        return colorCodes.value.slice(indexLevel * 9, indexLevel * 9 + 9);
    }

    const moduleSettings = () => {
        let mySettings = props.conceptData.scenario_settings
        if(mySettings && mySettings.module_id){
            let targetModule = activeCommunicator().modules.find(
                (item) => parseInt(item.id) === parseInt(mySettings.module_id)
            );
            if (targetModule) {
                let module = cloneDeep(targetModule)
                let localSettings = JSON.parse(module.collection_settings);
                if (localSettings) {
                    return localSettings;
                }
                moduleTitle.value = targetModule.title
            }
        }
        
        return {}
    }

    const getBg = (level, item) => {
      if (item && item.background_color) {
        return item.background_color;
      } else {
        let localSettings = moduleSettings()
        if (localSettings && localSettings?.collections) {
            let collection = localSettings.collections[level];
            if (collection) {
              if (collection.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
        }
      }
      return defaultColors[level - 1];
    }

    const getRootIndexNumber = (level, index) => {
        let settings = moduleSettings();
        if (settings && settings?.collections) {
          let indexing = "";
          let collection = settings.collections[level];
          if (settings.index_number) {
            indexing = parseFloat(collection.index_number) + index;
          }
          return indexing;
        }
    }

    const getIndexId = (level) => {
        let localSettings = moduleSettings();
        if (localSettings && localSettings?.collections) {
            let collection = localSettings.collections[level];
            if (collection) {
                return collection.index_id;
            }
        }
        return "";
    }

    onMounted(() => {
        colorCodes.value = store.getters['global/colorCodes']
    })

    const getData = (item) => {
        return item
    }
  </script>
  
  <style scoped>
    .header{
        margin-left: 68px;
    }
    .height-380px{
        height: 380px
    }
    .graph-box{
        display: grid;
        gap: 14px;
    }
    .graph-box .content{
        grid-column: 2 / span 2;
        grid-row: 1;
    }
    .graph-box .bottom-panel{
        grid-column: 2 / span 2;
        grid-row: 2;
    }
    .graph-box .content{
        width: 1590px;
        height: 760px;
        margin-left: -32px;
        position: relative;
    }
    .svg-graph{
        transform: scaleY(0.995) scaleX(0.964);
        transform-origin: top left;
        color: var(--borderColor);
        z-index: 1;
    }
  </style>
  