<template>
    <div>
        <BaseChart
            type="line"
            :card="card"
            :chartOptions="chartOptions"
            :series="localSeries"
        />
    </div>
</template>

<script setup>
    import {ref, watchEffect, computed } from 'vue'
    import { isEmpty } from 'lodash'
    import BaseChart from './BaseChart.vue';
    import { useProjectData } from '@/components/dashboard/sidebar/useProjectData.js';
    import { useCustomData } from '@/components/dashboard/sidebar/useCustomData.js';

    const { handleLevelData } = useProjectData();
    const { customDataReceiver } = useCustomData();

    const props = defineProps({
        card: Object,
        series: {
            type: Array
        },
        categories: {
            type: Array
        },
        stroke: {
            type: [Number, String],
            default: 4
        },
    })

    const localSeries = ref([]);
    const localCategories = ref([]);
    const localColors = ref(['transparent'])
    const localStrokeColors = ref(['transparent'])
    const chartOptions = computed(() => {
        return {
            chart: {},
            colors: localColors.value,
            markers: {
                size: [4]
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    borderRadius: 4
                }
            },
            stroke: {
                width: props.stroke,
                colors: localStrokeColors.value
            },
            labels: props.categories || localCategories.value,

            yaxis: [
                {
                    // max: _getMaxValue(localSeries.value),
                    forceNiceScale: true,
                    opposite: false,
                    title: {
                        text: props.card.configuration.yAxisLeftLabel ? props.card.configuration.yAxisLeftLabel : '',
                    },
                },
                {
                    // max: _getMaxValue(localSeries.value),
                    forceNiceScale: true,
                    opposite: true,
                    title: {
                        text: props.card.configuration.yAxisRightLabel ? props.card.configuration.yAxisRightLabel : ''
                    }
                }
            ]
        }
    });

    const _getMaxValue = (series) => {
        let seriesData = [0]
        series.map(item => {
            if(isEmpty(item?.data)) return
            seriesData = [...seriesData, ...item.data]
        })
        let maxValue = Math.max(...seriesData)
        maxValue = isNaN(maxValue) ? 0 : maxValue
        return maxValue < 10 ? 9 : maxValue
    }

    const setColors = (item, index) =>
    {
        let color = item.color ? item.color : '#000'
        localColors.value.splice(index, 1, color);
        if(item.type == 'column'){
            localStrokeColors.value.splice(index, 1, 'transparent');
        }else{
            localStrokeColors.value.splice(index, 1, color);
        }
    }

    watchEffect(async () => {
        if(props.series) {
            localSeries.value = props.series.map((item, index) => {
                setColors(item, index)
                return {
                    name: item.name,
                    type: item.type ? item.type : 'line',
                    data: item.data.map(i => i.y)
                }
            });
            return;
        }


        
        let config = props.card.configuration;
        let response = await handleLevelData(config);
        localSeries.value = response.series.map((item, index) => {
            setColors(item, index)
            return {
                name: item.name,
                type: item.type ? item.type : 'line',
                data: item.data.map(i => i.y)
            }
        })
        localCategories.value = response.categories


        // project custom data with project data
        if(config.forms && config.forms.length > 0) 
        {
            let CustomData = customDataReceiver(config);
            if(!isEmpty(CustomData.series))
            {
                // create empty series
                let emptySeries = []
                localSeries.value.forEach((item) => {
                    item.data.forEach(data => {
                        if(data){
                            emptySeries.push({x: '', y: null})
                        }
                    })
                })


                let data = CustomData.series.map((item, index) => {
                    setColors(item, index)
                    return {
                        name: item.name,
                        type: item.type ? item.type : 'line',
                        data: [...emptySeries, ...item.data.map(i => i.y)]
                    }
                });

                localSeries.value = [...localSeries.value, ...data]
                localCategories.value = [...localCategories.value, ...CustomData.categories]
            }
        }
    })
</script>
