<template>
    <div 
                        
        class="border relative"
        style="border-radius: 20px;"
    >
        <slot></slot>

        <ColorIcon
            :class="{'invisible': !showIcon}"
            @click.native="clickThColorPicker"
            class="w-4 h-4 absolute cursor-pointer bottom-2 right-2"
        />
        <input
            @input="$emit('onUpdateTemplate')"
            ref="colorPickerElem" 
            type="color" 
            v-model="content.bgColor"
            class="absolute invisible bottom-2 right-2"
        />

        <div v-if="showIcon" class="flex absolute -bottom-10 right-4 gap-x-2 fw-700 fs-30 opacity-40">
            <p @click="createItem(itemIndex+1)" class="cursor-pointer">+</p>
            <p
                v-if="showRemoveIcon " 
                @click="removeItem(itemIndex)"
                class="cursor-pointer"
            >-</p>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
    content: Object,
    showIcon: Boolean,
    showRemoveIcon: Boolean,
    itemIndex: Number,
    templateMethods: Object
})
const colorPickerElem = ref()
const clickThColorPicker = () => colorPickerElem.value.click()
const { createItem, removeItem} = props.templateMethods
</script>