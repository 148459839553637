<template>
    <div>
        <div @click="changeClearance()"
            class="flex flex-col gap-1 mt-2 cursor-pointer"
        >
            <BarSolidIcon 
                
                v-for="n in 4" 
                :key="n"
                :class="[levelClearance >= n ? 'opacity-100' : 'opacity-20']" 
            />
        </div>
        <div class="flex flex-col gap-4 mt-4">
            <div
                v-for="(item, moduleIndex) in modules" 
                :key="moduleIndex"
                class="w-4 h-4 rounded-sm cursor-pointer" 
                :style="{backgroundColor: getBackground(item) }"
                :class="[currentModuleIndex == moduleIndex ? 'border border-current' : '']"
                @click="selectModule(item, moduleIndex)"
            ></div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import useModule from '@/composable/useModule.js'
    const props = defineProps({
        modules: Array,
    });
    const { getBackground } = useModule();
    const emit = defineEmits(['onChangeLevelClearance', 'onClickModule']);

    const currentIndex = ref(0);
    const currentModuleIndex = ref(0);
    const arr = [4, 3, 2, 1, 0, 1, 2, 3]

    const levelClearance = computed(() =>  arr[currentIndex.value])
    const changeClearance = () => {
        currentIndex.value = ++currentIndex.value % arr.length;
        emit('onChangeLevelClearance', levelClearance.value);
    }

    const selectModule = (item, moduleIndex) => {
        currentModuleIndex.value = moduleIndex;
        emit('onClickModule', item);
    }

</script>