import {set, ref} from 'vue'
import { prevIndex, nextIndex  } from '@/plugins/lodash'

const expansionStatesByLevel = ref({})

export default function useVerticalExpansion() {
    const expansionStates = ['normal', 'semiexpanded', 'expanded']
    const heightClass = {
        normal: 'h-10',
        semiexpanded:'h-20',
        expanded: 'h-40'
    }

    const getExpansionStatesByLevel = (levelId) => {
        if(!expansionStatesByLevel.value[levelId]) {
            set(expansionStatesByLevel.value, `${levelId}`, 'normal')
        }
        return expansionStatesByLevel.value[levelId]
    }

    const decreaseHeight = (levelId) => {
        const currentExpansionState = getExpansionStatesByLevel(levelId)
        const expansionIndex = _getExpansionStateIndex(currentExpansionState)
        const nextExpansionIndex = prevIndex(expansionIndex, 3)
        expansionStatesByLevel.value[levelId] = expansionStates[nextExpansionIndex]
    }
    const increaseHeight = (levelId) => {
        const currentExpansionState = getExpansionStatesByLevel(levelId)
        const expansionIndex = _getExpansionStateIndex(currentExpansionState)
        const nextExpansionIndex = nextIndex(expansionIndex, 3)
        expansionStatesByLevel.value[levelId] = expansionStates[nextExpansionIndex]
    }

    const getHeightClass = (state) =>  heightClass[state]

    const _getExpansionStateIndex = (name) => expansionStates.indexOf(name)

    return {
        expansionStatesByLevel,
        getExpansionStatesByLevel,
        decreaseHeight,
        increaseHeight,
        getHeightClass
    }
}