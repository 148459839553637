<template>
    <div class="absolute top-0 left-0 full w-1/2 h-full select-none" :style="{ width: `${getWidth(value)}%`, backgroundColor: bg, borderRadius: '1px' }">
        <!-- top tooltip start -->
        <span 
            v-if="tooltipPosition == 'top' && hasTooltip"
            class="absolute left-full bottom-full text-primary-one transform -translate-x-1/2 fs-10 fw-500 px-1 block whitespace-nowrap z-10 bg-white"
            style="border-radius: 2px; margin-bottom: 10px;" 
            :style="{visibility: changAble && visibility}"
            :title="text"
        >
            {{ text }}
            <svg class="absolute top-full left-1/2 transform -translate-x-1/2" width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.4 3.46667C3.2 3.73333 2.8 3.73333 2.6 3.46667L0 0L6 5.38385e-07L3.4 3.46667Z" :fill="bg"/>
            </svg>                
        </span>
        <!-- top tooltip end -->


        <!-- bottom tooltip icon start -->
        <svg v-if="hasTooltip && tooltipPosition == 'bottom'" class="absolute left-full transform -translate-x-1/2 mt-2" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.584.624a.5.5 0 0 1 .832 0L8 6H0L3.584.624Z" :fill="bg"/>
        </svg>
        <!-- bottom tooltip icon end -->

        <!-- draggable circle start -->
        <span 
            v-if="changAble"
            @mouseenter="visibility='visible'"
            @mouseout="visibility='hidden'"
            @mousemove="mouseMoveHandler"
            @mousedown="mouseDownHandler"
            class="block rounded-full _circle cursor-pointer">
        </span>
        <!-- draggable circle end -->
    </div>
</template>

<script setup>
    import { onMounted, onUnmounted, onUpdated, ref } from 'vue'

    const props = defineProps({
        value: {
            type: [String, Number],
            default: 0
        },
        goal: [Number, String],
        baseLine: [Number, String],
        bg: {
            type: String,
            default: 'orange'
        },
        text: {
            type: String,
            default: 'n/a'
        },
        tooltipPosition: {
            type: String,
            default: 'top'
        },
        changAble: {
            type: Boolean,
            default: false
        },
        hasTooltip: {
            type: Boolean,
            default: true
        }
    })

    let isMouseDown = false
    let selectedCircle = null
    let mousePosition = {x:null, y:null}
    let progressBar = null
    let progressBarWidth = 0
    const actualValue = ref(0)
    const visibility = ref('hidden')
    const getWidth = (w) => {
        return w > 100 ? 100 : w < 0 ? 0 : w
    }
    const emit = defineEmits()

    const mouseDownHandler = (e) => {
        isMouseDown = true
        selectedCircle = e.target
        mousePosition.x  = e.x
        mousePosition.y  = e.y
        progressBar      = selectedCircle.parentElement
        progressBarWidth = progressBar.offsetWidth
    }

    const mouseMoveHandler = (e) => {
        if(isMouseDown)
        {
            let direction = (e.x - mousePosition.x)
            let distance  = Math.sqrt((e.x - mousePosition.x) ** 2 + (e.y - mousePosition.y) ** 2)
            const getDistance = () => {
                return (direction>=0) ? (+ distance) : (-distance)
            }

            let newWidth = progressBarWidth + getDistance()
            if(newWidth >= 0 && newWidth < progressBar.parentElement.offsetWidth){
                let newValueInPercentage = (((progressBarWidth + getDistance()) * 100) / progressBar.parentElement.offsetWidth)
                actualValue.value = ((props.goal - props.baseLine) * newValueInPercentage) / 100
                emit('change', actualValue.value)
                emit('input', newValueInPercentage)
            }
        }
    }

    const mouseUpHandler = (e) => {
        if(isMouseDown){
            isMouseDown = false
            window.removeEventListener('mousemove', mouseMoveHandler)
            emit('onDragEnd', actualValue.value)
        }
    }

    onMounted(() => {
        window.removeEventListener('mousemove', mouseMoveHandler)
        window.removeEventListener('mouseup', mouseUpHandler)
        window.addEventListener('mousemove', mouseMoveHandler)
        window.addEventListener('mouseup', mouseUpHandler)
    })

    onUpdated(() => {
        window.removeEventListener('mousemove', mouseMoveHandler)
        window.removeEventListener('mouseup', mouseUpHandler)
        window.addEventListener('mousemove', mouseMoveHandler)
        window.addEventListener('mouseup', mouseUpHandler)
    })

    onUnmounted(() => {
        window.removeEventListener('mousemove', mouseMoveHandler)
        window.removeEventListener('mouseup', mouseUpHandler)
    })
</script>

<style scoped>
    ._circle{
        width: 14px;
        height: 14px;
        background: v-bind('props.bg');
        position: absolute;
        top: 1px;
        right: 0;
        transform: translate(50%, -50%);
        border: 2px solid #fff;
        box-shadow: 0px 2px 6px rgb(42 44 60 / 30%);
    }
</style>