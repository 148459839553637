import { render, staticRenderFns } from "./CoverPageTemplate.vue?vue&type=template&id=7cb63af8&scoped=true&"
import script from "./CoverPageTemplate.vue?vue&type=script&setup=true&lang=js&"
export * from "./CoverPageTemplate.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./CoverPageTemplate.vue?vue&type=style&index=0&id=7cb63af8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cb63af8",
  null
  
)

export default component.exports