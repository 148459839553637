<template>
    <div class="i-mt-30 font-poppins" v-if="template">
        <input type="text" @keyup="updateTemplate" v-model="template.title" class="i-border-1 i-h-30 border-secondary-five px-2 text-primary-one w-full rounded-sm fs-14 fw-500" />
        <InputSelect
            :items="[...[{first_name: 'Sample text', id: 'sampleText'}], ...responsible]"
            label="Select People"
            text-key="first_name"
            v-model="responsibleId"
            @change="onChangeResponsible"
        />
    </div>
</template>

<script setup>
import { ref,onMounted, computed } from 'vue'
import { useSidebar } from '@/views/screens/communicator/useSidebar.js'
import store from '@/store'
import router from "@/routes/routes.js";

import InputSelect from '@/components/communicator/v2/sidebar/components/InputSelect'

const props = defineProps({
    template: Object,
})

const route = router.currentRoute;
const { saveTemplateConfiguration } = useSidebar();
const responsible = ref([])
const responsibleId = ref(null)
const currentData = computed(() => props.template.data_layer[props.template.settings.currentDataIndex])

const fetchResponsible = async (projectId) => {
    const {data:{data}} = await store.dispatch('communicator/getResponsible', projectId)
    responsible.value = data
}

const onChangeResponsible = async (id) => {
    if(id == 'null') return
    const item = props.template.data_layer[props.template.settings.currentDataIndex]
    if(id == 'sampleText') {
        item.user_id = null
        item.biography="Biography"
        item.description=null
        item.image=null
        item.name="Name"
        item.occupation="Designation"
        item.user_id=null
    } else {
        item.user_id = Number(id)
    }
    let data = {
        synchronize: true,
        configuration: props.template.data_layer
    }
    let { configuration } =  await saveTemplateConfiguration(props.template.id, data);
    props.template.data_layer = configuration;
}

const updateTemplate = (data) => {
    store.dispatch("communicator/updateTemplate", data);
}

onMounted(() => {
    fetchResponsible(route.params.id)
})

</script>