<template>
    <div v-if="Object.keys(data).length" class="element pl-4 relative" :class="!title(activeTemplate, data.id)&&'no-data'">
        <div class="absolute grid items-center left_icon">
            <component
                @click.native="getIcon(activeTemplate, index, progressSentiment)"
                :is="iconTemplates[data.progressIconName]"
                :color="data.progressColor"
                style="transform: scale(1.2);"
                class="mt-5 ml-2 cursor-pointer select-none"

            />
        </div>
        <div>
            <div class="mt-3 top">
                <span v-if="getSampleTitle(activeTemplate, data.id)" class="absolute -top-075 current-state">
                    {{ getSampleTitle(activeTemplate, data.id) }}
                </span>
                <div class="buttons_wrapper">
                    <template v-if="getSampleValue(activeTemplate, data.id) === 0">
                        <span
                            v-for="(item, serial) in 10"
                            :key="serial"
                            :style="{
                                backgroundColor: activeTemplate.settings.borderColor
                            }"
                        ></span>
                    </template>
                    <template v-if="getSampleValue(activeTemplate, data.id)">
                        <span
                            v-for="(item, serial) in 10"
                            :key="serial"
                            :style="{
                                backgroundColor:
                                    (getParameterBg(getSampleValue(activeTemplate, data.id), activeTemplate.definition_style, serial) !== '#C9C9C9') ?
                                    getParameterBg(getSampleValue(activeTemplate, data.id), activeTemplate.definition_style, serial)
                                    :
                                    activeTemplate.settings.borderColor
                            }"
                        ></span>
                    </template>
                </div>
            </div>
            <h1 v-if="title(activeTemplate, data.id)" class="fw-700 fs-22 border-b borderColor truncate">
                {{ title(activeTemplate, data.id) }}
            </h1>
            <h1
                v-else
                class="fw-700 fs-22 border-b borderColor truncate"
                contenteditable="true"
                @input="(e)=>editTitle(e.target.innerText, data, activeTemplate)"
                v-once
                placeholder="Title"
            >
                {{ data.title }}
            </h1>
            <h2 v-if="description(activeTemplate, data.id)" class="fw-400 fs-14 overflow-auto" style="max-height: 85px">
                {{ description(activeTemplate, data.id) }}
            </h2>
            <h2
                v-else
                class="fw-400 fs-14 overflow-auto"
                style="max-height: 85px"
                contenteditable="true"
                @input="(e)=>editDescription(e.target.innerText, data, activeTemplate)"
                v-once
                placeholder="Description"
            >
                {{ data.description }}
            </h2>
        </div>

        <div :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']" class="action-btn-wrapper absolute">
            <button @click="handleLinkClick(['title', 'description'], data.id)" class="cursor-pointer">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.9995 7.5L7.18934 15.4398C6.90804 15.7211 6.75 16.1026 6.75 16.5005C6.75 16.8983 6.90804 17.2798 7.18934 17.5611C7.47064 17.8424 7.85218 18.0005 8.25 18.0005C8.64783 18.0005 9.02936 17.8424 9.31066 17.5611L18.6208 8.12132C18.8993 7.84274 19.1203 7.51203 19.2711 7.14805C19.4219 6.78407 19.4995 6.39397 19.4995 6C19.4995 5.60603 19.4219 5.21593 19.2711 4.85195C19.1203 4.48797 18.8993 4.15726 18.6208 3.87868C18.3422 3.6001 18.0115 3.37913 17.6475 3.22836C17.2835 3.0776 16.8934 3 16.4995 3C16.1055 3 15.7154 3.0776 15.3514 3.22836C14.9874 3.37913 14.6567 3.6001 14.3781 3.87868L5.06802 13.3185C4.22411 14.1624 3.75 15.307 3.75 16.5005C3.75 17.6939 4.22411 18.8385 5.06802 19.6824C5.91193 20.5264 7.05653 21.0005 8.25 21.0005C9.44348 21.0005 10.5881 20.5264 11.432 19.6824L19.1245 12" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
            </button>
            <button @click="handleElement(activeTemplate, index, 'add', limit)" class="cursor-pointer">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.5 12H19.5" stroke="currentColor" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                    <path d="M12 4.5V19.5" stroke="currentColor" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
            </button>
            <button @click="handleElement(activeTemplate, index, 'remove', limit)" class="cursor-pointer">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.75 12H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
        <span class="circle" :style="`--bg: ${ data.progressColor }`"> {{ index + 1 }} </span>
    </div>
</template>

<script setup>
    import { useTemplate } from '@/views/screens/communicator/useTemplate.js'
    import useRoadMap from "../useRoadMap"
    import { getParameterBg } from '@/store/services/traits/parameter.js'

    const { editTitle, editDescription, iconTemplates, handleProgress, handleElement, getSampleValue, getSampleTitle } = useRoadMap()
    const props = defineProps({
        activeTemplate: Object,
        progressSentiment: Object,
        switchSidebarComponent: Function,
        isUnderLine: Boolean,
        data: Object,
        index: Number,
        id: String,
        currentMode: String,
        config_visibility: Boolean,
        limit: [Number, String]
    });

    const { prepareConfiguration, prepareRequest, title, description, getPersistance } = useTemplate();
    const handleLinkClick = (options, itemId) => {
        props.switchSidebarComponent('TextSource', {
            persistance: getPersistance(props.activeTemplate, itemId),
            confId: itemId,
            transform: (config) => {
                let inputs = prepareConfiguration(options, config);
                if(config.parameter_id) {
                    inputs['parameter'] = setParameterId(config);
                }
                return prepareRequest(props.activeTemplate, itemId, config, inputs, true);
            }
        });
    }
    const setParameterId = (config) => {
        return {
            id: config.parameter_id,
            scenario_id: config.scenario_id,
            sample_value: null
        }
    }

    const getIcon = (activeTemplate, index, progressSentiment) => {
        handleProgress(activeTemplate, index, progressSentiment)
    }
</script>

<style scoped>
    .element{
        border-left: 2px solid var(--borderColor);
        position: absolute;
        left: var(--left);
        bottom: var(--bottom);
        height: 195px;
        width: 275px;
    }
    .buttons_wrapper{
        display: flex;
        gap: 14px;
    }
    .buttons_wrapper>span{
        width: 15px;
        height: 13px;
        display: block;
        background: #E2E2E2;
        cursor: pointer;
    }
    .icon{
        left: -91px;
        top: -1px;
    }
    .action-btn-wrapper{
        bottom: -53px;
        left: 46px;
        display: flex;
        gap: 9px;
    }

    .element.style-two{
        border-left-color: transparent;
    }
    .element.style-two .icon{
        top: -20px;
    }
    .element.style-two .action-btn-wrapper{
        bottom: unset;
        top: -44px;
    }
    .circle{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: block;
        background-color: var(--bg);
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(-50%, 50%);
        border: 6px solid;
        display: grid;
        place-content: center;
        font-weight: 800;
        font-size: 30px;
        z-index: 90;
    }
    .style-two .circle{
        bottom: 252px;
    }

    .left_icon{
        left: -90px;
        top: 0px;
        width: 70px;
        height: 70px;
    }
    .style-two .left_icon{
        left: -90px;
        top: -20px;
    }
    .borderColor{
        border-color: var(--borderColor);
    }
    .-top-075 {
      top: -0.75rem;
    }
</style>
