import { cloneDeep, get } from 'lodash'
import router from '@/routes/routes.js';
import { ref, onMounted, watch, computed, watchEffect } from 'vue'
import axios from 'axios'
import { isEmpty } from 'lodash'
import { useProjectData } from '@/components/dashboard/sidebar/useProjectData.js';
import { useCustomData } from '@/components/dashboard/sidebar/useCustomData.js';


const selectedCard = ref({});
let updateChartData, configurationHandlers;


const defaultData = {
    title: 'Chart Title',
    selectedComponentName: 'ProjectData',
    selectedDataType: 'ScenarioData',
    filterTreeData: [],
    showConfigComponent: true,
    chartTypes: null,
    axis: 'primary',
    legendTitle: '',
    project_id: null,
    scenario_id: null,
    data_source: null,
    target_source: null,
    module_id: null,
    assessment_id: null,
    l1Data: null,
    l2Data: null,
    l3Data: null,
    with_child: false,
    properties: [
        {
            property_id: null,
            parameter_id: null,
            parameters: [
                {
                    parameter_id: null,
                    color: ''
                }
            ]
        }
    ],
    seriesData: [
        { x: '', y: ''}
    ]
}

let defaultDataForSecondary = cloneDeep(defaultData)
    defaultDataForSecondary.axis = 'secondary'

const defaultConfig = {
    title: 'Chart Title',
    yAxisLeftLabel: '',
    yAxisRightLabel: '',
    horizontalCategoryType: null,
    chartType: null,
    presentation_type: null,
    forms: [
        cloneDeep(defaultData),
        cloneDeep(defaultDataForSecondary),
    ]
}
const config = ref(defaultConfig);

const resetConfiguration = () => {
    config.value = cloneDeep(defaultConfig)
}

const presentationType = computed(() => config.value.presentation_type);
const templateSwitchData = ref([
    {
        key: 'ProjectData',
        label: 'Project Data',
        disabled: false
    },
    {
        key: 'CustomData',
        label: 'Custom Data',
        disabled: false
    }
])

const assessmentRadioData = [
    {
        key: 'AssessmentData',
        label: 'Assessment Data',
        disabled: false
    },
    {
        key: 'ScenarioData',
        label: 'Scenario Data',
        disabled: false
    }
]

const selectedCardId = ref()

const chartTypes = [
    {id: 'ComboChart', title: 'Content & Parameters'},
    {id: 'AssessmentChart', title: 'Assessment Timeline'},
    {id: 'CustomDataChart', title: 'Combo Chart Custom Data'},
];

const presentations = [
    {id: 'parameter', title: 'Parameter'},
    {id: 'level', title: 'Level data'},
];

const horizontalCategoryType = [
    {id: 'date', title: 'Date'},
    {id: 'month', title: 'Month'}
]

let watchTimeoutId = null
watch(config, (value) => {
    clearTimeout(watchTimeoutId)
    watchTimeoutId = setTimeout(() => {
        configurationHandlers(value);
    }, 1500)
}, {deep: true});

watchEffect(() => {
   if(config.value.chartType) {
        templateSwitchData.value.forEach(item => {
            if(item.key !== 'CustomData') {
                item.disabled = config.value.chartType == 'CustomDataChart'
            }
        });
        config.value.forms.forEach((form) => {
            if(config.value.chartType == 'CustomDataChart') {
                form.selectedComponentName = 'CustomData'
            }else {
                form.selectedComponentName = 'ProjectData'
            }
           
        })
   }
});


const series = ref([
    {
        name: 'Static one',
        type: undefined,
        data: []
    }
])
const categories = ref([])

export const useConfiguration = (configuration = null) => {

    let { handleLevelData, handleParameterData } = useProjectData();
    let { customDataReceiver } = useCustomData();
  
    const route = router.currentRoute;
   
    configurationHandlers = async (config) => {
        updateChartData({configuration: config});
        let response = await handleLevelData(config);
        series.value = response.series;
        categories.value = response.categories;
        
        let seriesResponse = customDataReceiver(config)
        if(!isEmpty(seriesResponse.series))
        {
            // project data and custom data combine start
            let newSeries = seriesResponse.series
            newSeries.map((customSeries, index) => {
                let singleProjectData = series.value[index]
                if(singleProjectData?.type == customSeries?.type){
                    customSeries.data = [..._getEmptySeries(series.value), ...customSeries.data]
                }
                return customSeries
            })
            
            series.value = series.value.filter(item => item.data.length>0)
            series.value = [...series.value, ...newSeries]
            categories.value = [...categories.value, ...seriesResponse.categories]
        }
    }

    const _getEmptySeries = (series) => {
        let emptySeries = []
        series.forEach(item => {
            item.data.forEach(data => {
                if(data.y){
                    emptySeries.push({x:'', y: null})
                }
            })
        })

        return emptySeries
    }
   
    let timeoutId = null
    updateChartData = (payload, cardId = null) => {
        let localCardId = cardId || route.params.cardId;
        clearTimeout(timeoutId)
        timeoutId = setTimeout(async () => {
            return await axios.post(`workspaces/teams/dashboards/cards/update/${ localCardId }`, payload)
        }, 2000);
    }

    if(configuration && configuration.mountable) {
        onMounted(async () => {
            resetConfiguration()
            let card  = await axios.get(`workspaces/teams/dashboards/cards/${route.params.cardId}`).then(({data}) => data.data)
            selectedCard.value = card;
            if(!isEmpty(get(card, 'configuration.forms'))){
                config.value = card.configuration;
            }
        })
    }

    return {
        config,
        series,
        categories,
        chartTypes,
        presentations,
        defaultData,
        selectedCard,
        presentationType,
        selectedCardId,
        updateChartData,
        horizontalCategoryType,
        resetConfiguration,
        defaultConfig,
        templateSwitchData,
        assessmentRadioData
    }
}