<template>
    <div
        class="h-full relative"
        :style="{
            color: activeTemplate.settings.color,
            '--color': activeTemplate.settings.color,
            '--bg-color': activeTemplate.settings.background_color,
            '--borderColor': activeTemplate.settings.borderColor,
        }"
    >
        <div
            class="wrapper relative w-full h-full"
            :class="[currentMode != 'fullscreen' ? 'layout-scale-down' : '']"
        >
            <Header
                :data="activeTemplate.customized_data"
                :activeTemplate="activeTemplate"
                :layoutList="layoutList"
                :activeLayout="activeLayout"
                :activeConceptId="activeConceptId"
                :activeScenarioId="activeScenarioId"
                :setScenarioId="setScenarioId"
                :loadConcept="loadConcept"
                :config_visibility="config_visibility"
                :switchSidebarComponent="switchSidebarComponent"
                :synchronize="synchronize"
                :toggleShowTitle="toggleShowTitle"
                class="text-color"
                :class="[currentMode == 'fullscreen' && 'invisible', !config_visibility && 'invisible']"
            />
            
            <RightPanel class="absolute right-10 z-50" />
            <div class="relative z-10 top-16">
                <component
                    :is="activeLayout.component"
                    :activeTemplate="activeTemplate"
                    :collections="collections"
                    :currentMode="currentMode"
                    :config_visibility="config_visibility"
                    :switchSidebarComponent="switchSidebarComponent"
                    :showTitle="showTitle"
                    :synchronize="synchronize"
                    :from="from"
                />
            </div>
        </div>
    </div>
</template>

<script setup>

    import axios from 'axios'
    import store from '@/store'
    import router from '@/routes/routes.js'
    import { ref, watch, onMounted, computed } from 'vue'
    import Header from '@/components/communicator/v2/templates/roadmap/component/HighLevelComponent/Header.vue'
    import { useTemplate } from '@/views/screens/communicator/useTemplate'
    import useConcept from '@/components/communicator/v2/templates/concept/useConcept.js'
    import { useTeamwork } from '@/components/shared/stickyMenu/useTeamwork.js'
    import HighLevelContent from '@/components/communicator/v2/templates/roadmap/component/HighLevelComponent/HighLevelContent.vue'
    import { set } from 'vue'
    import RightPanel from './component/HighLevelComponent/RightPanel.vue'
    import useHighLevel from '@/components/communicator/v2/templates/roadmap/useHighLevel.js'
    import useRoadmapConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useRoadmapConcept.js'
    import useConceptCanvas from '@/components/roadmap/strategicRoadmap/roadmapConcept/useConceptCanvas.js'

    
    const { Callback } = useConceptCanvas()
    const { selectedConcept } = useRoadmapConcept(true)
    const { initDefaultSettings:solidObject } = useTeamwork()
    const { setInitialData } = useConcept()
    const { collections, getCollectionsData, activeScenarioId } = useHighLevel()
    const route = router.currentRoute

    const props = defineProps({
        activeTemplate: Object,
        currentMode: String,
        config_visibility: Boolean,
        synchronize: Boolean,
        switchSidebarComponent: Function,
        from: {
            type: String,
            default: "main"
        }
    })

    const { updateTemplate } = useTemplate()
    const showTitle = ref(false)
    const layoutList = [
        {name: 'HighLevelContent', key: "HighLevelContent", component: HighLevelContent}
    ]
    const activeLayout = ref(layoutList[0])
    const activeConceptId = ref(null)
    // const activeScenarioId   = ref(null)
    const moduleId     = ref(null)

    const loadConcept = async (e) => {
        const conceptId = e.target.value
        const customized_data = (props.activeTemplate.customized_data)

        // get active template data from data layer
        const activeTemplate  = customized_data.find(data => data.isActiveLayout)
        activeConceptId.value = conceptId
        activeTemplate.activeConceptId = conceptId

        const res = await axios.get(`roadmaps/concepts/show/${activeConceptId.value}`)
        selectedConcept.value = selectedConcept.value = res.data.data
        updateTemplate(props.activeTemplate)

        getCollectionsData(activeScenarioId.value, selectedConcept.value.module_id)
    }

    const setScenarioId = (id) => {
        activeScenarioId.value = id
        const customized_data = (props.activeTemplate.customized_data)
        const activeTemplate  = customized_data.find(data => data.isActiveLayout)
        set(activeTemplate, 'activeScenarioId', id)

        updateTemplate(props.activeTemplate)

        if(activeScenarioId.value && selectedConcept.value.module_id){
            getCollectionsData(activeScenarioId.value, selectedConcept.value.module_id)
        }
    }

    const toggleShowTitle = () => {
        showTitle.value = !showTitle.value
    }

    onMounted(async () =>
    {
        Callback.value = () => {
            getCollectionsData(activeScenarioId.value, selectedConcept.value.module_id)
        }

        // work with data Layer
        const customized_data = props.activeTemplate.customized_data
        if(!customized_data.length){
            const myCustomizedData = []
            layoutList.forEach((item, index) => {
                myCustomizedData.push({
                    id: 1,
                    title: 'High Level Roadmap',
                    description: 'Add Text',
                    colorOne: '#001A6E',
                    colorTwo: '#DFEDF9',
                    layoutName: item.key,
                    activeConceptId: null,
                    activeScenarioId: null,
                    isActiveLayout: index === 0 ? true : false,
                    texts: {
                        leftSideText: 'Add text',
                        bottomSideText: 'Add text',
                        headingOne: 'Owner',
                        headingTwo: 'Add text',
                        dataOne: 'Implementation date',
                        dataTwo: 'Add text',
                    }
                })
            })
            props.activeTemplate.customized_data = myCustomizedData
            updateTemplate(props.activeTemplate)
        }

        // get active template data on mounted start
            const activeTemplateData = customized_data.find(data => data.isActiveLayout === true)
            if(activeTemplateData){
                activeLayout.value = layoutList.find(item => {
                    return activeTemplateData.layoutName === item.key
                })
            }
            activeConceptId.value = activeTemplateData?.activeConceptId
            activeScenarioId.value = activeTemplateData?.activeScenarioId
        // get active template data on mounted end

        // load concept data according to active concept id
        if(activeConceptId.value){
            const res = await axios.get(`roadmaps/concepts/show/${activeConceptId.value}`)
            selectedConcept.value = selectedConcept.value = res.data.data

            // moduleId
            moduleId.value = selectedConcept.value?.scenario_settings?.module_id

            if(moduleId.value && activeScenarioId.value){
                getCollectionsData(activeScenarioId.value, moduleId.value)
            }


            // load definitions
            if(selectedConcept.value){
                store.dispatch("concept/loadParametersDefinition", {
                    parameters: [selectedConcept.value.hr_property_collection_id, selectedConcept.value.vr_property_collection_id],
                    vertical_parameter: selectedConcept.value.vr_property_collection_id
                })
            }
        }

        // get collections data
        if(moduleId.value){
            let {data} = await axios.get(`roadmaps/strategies/system-data/${activeScenarioId.value}?module_id=${moduleId.value}&synchronized=1&phases=1`).then(({data}) => data)
            if(data && data.children){
                collections.value = data.children
            }
        }
    })

    watch(props.activeTemplate, (newVal) => {
        setInitialData(props.activeTemplate)
    },{ deep: true })
</script>

<style scoped>
    .layout-scale-down{
        transform: scale(0.654);
        transform-origin: 0 0;
    }
    .wrapper{
        padding: 43px 90px 122px;
        width: 1920px;
        height: 1080px;
    }
    .text-color{
        color: var(--color)
    }
</style>