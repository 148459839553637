<template>
    <input
        class="panzoom-exclude"
        type="text"
        v-model="localValue"
    />
</template>
<script>
    export default {
        name: "InputText",
        props: {
            value: [String]
        },
        computed: {
            localValue: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        }
    }
</script>