<template>
<svg :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_15341_41889)">
        <circle cx="32" cy="29" r="25" fill="none"/>
        <circle cx="32" cy="29" r="24.0625" :stroke="color" stroke-width="1.875"/>
    </g>
    <path d="M32 31.5C33.3807 31.5 34.5 30.3807 34.5 29C34.5 27.6193 33.3807 26.5 32 26.5C30.6193 26.5 29.5 27.6193 29.5 29C29.5 30.3807 30.6193 31.5 32 31.5Z" :fill="color"/>
    <path d="M22 31.5C23.3807 31.5 24.5 30.3807 24.5 29C24.5 27.6193 23.3807 26.5 22 26.5C20.6193 26.5 19.5 27.6193 19.5 29C19.5 30.3807 20.6193 31.5 22 31.5Z" :fill="color"/>
    <path d="M42 31.5C43.3807 31.5 44.5 30.3807 44.5 29C44.5 27.6193 43.3807 26.5 42 26.5C40.6193 26.5 39.5 27.6193 39.5 29C39.5 30.3807 40.6193 31.5 42 31.5Z" :fill="color"/>
    <defs>
        <filter id="filter0_d_15341_41889" x="0.75" y="0.25" width="62.5" height="62.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2.5"/>
            <feGaussianBlur stdDeviation="3.125"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15341_41889"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15341_41889" result="shape"/>
        </filter>
    </defs>
</svg>

</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: '#2BAD7E'
            },
            width: {
              type: [Number, String],
              default: 64
            },
            height: {
              type: [Number, String],
              default: 64
            }
        }
    }
</script>
