<template>
    <div class="transition-all mb-2">
        <!-- Accordion Header  -->
        <div class="relative cursor-pointer" :style="{'background': bg}" @click="onVisible" :class="classes">
            <p class="flex-auto leading-5 font-poppins pr-5" :class="[alignClass, titleClasses]"> {{ title }} </p>
            <button class="absolute" :class="buttonClasses">
                <svg :class="[{'transform rotate-180': visibility}, iconClasses]" class="transition-all duration-200" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.99983 10.4754L12.0065 6.46872L11.0645 5.52539L7.99983 8.59206L4.93583 5.52539L3.99316 6.46806L7.99983 10.4754Z" fill="currentColor"/>
                </svg>
            </button>
        </div>

        <!-- Accordion Content  -->
        <div v-show="visibility === true" :class="[accordionClasses]">
            <slot v-bind="{ visibility }" />
        </div>
    </div>
</template>
<script>

export default {
    props: {
        title: {
            type: String,
            required: false
        },
        bg: {
          type: String,
          required: false,
          default: '#2B80AA'
        },
        active: {
            type: Boolean,
            default: false
        },
        trigger: {
            type: Function,
            required: false
        },
        align: {
            type: String,
            validator: (value) => ['left', 'center', 'right'].includes(value),
            default: 'center'
        },
        classes: {
          type: String,
          default: 'h-5 shadow-two rounded-sm'
        },
        iconClasses: {
          type: String,
          default: 'w-4 h-4 text-gray-400'
        },
        titleClasses: {
          type: String,
          default: 'fs-10 text-white pl-2'
        },
        buttonClasses: {
            type: String,
            default: 'i-top-2 i-right-2'
        },
        accordionClasses: {
          type: String,
          default: 'border-t border-gray-200'
        }
    },
    data() {
        return{
            visibility: this.active
        }
    },
    computed: {
        alignClass() {
            let classSet = {
                'left': 'text-left',
                'center': 'text-center',
                'right': 'text-right'
            };
            return classSet[this.align]
        }
    },
    methods: {
        onVisible() {
            this.visibility = !this.visibility;
            if(this.trigger) {
              this.trigger(this.visibility)
            }
        }
    }
}
</script>
