<template>
    <div class="px-10">
        <div @click="(showIntelligence = !showIntelligence)" class="select-none cursor-pointer font-poppins fs-16 fw-700 text-primary-one uppercase py-4 border-b border-secondary-four flex justify-between items-center">
            Goals & Analytics
            <svg :class="!showIntelligence && 'transform rotate-180'" class="cursor-pointer" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.25 7.5L10 13.75L3.75 7.5" stroke="#8D8D8D" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
        </div>
        <div v-if="showIntelligence">
            <router-link
                :to="{name: 'workspaceGoal', query: { projectId: projectId }}"
                class="font-poppins block fs-14 fw-500 text-color-616161 border-b border-secondary-four uppercase py-5 cursor-pointer"
            >
                Goal Management
            </router-link>
            <router-link
                v-for="(item, index) in items"
                :key="index"
                :to="{name: 'projectDashboard', params: { workspaceId: selectedWorkspaceId, projectId: projectId }}"
                class="font-poppins block fs-14 fw-500 text-color-616161 border-b border-secondary-four uppercase py-5 cursor-pointer"
            >
                {{ item.title }}
            </router-link>
        </div>                
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import router from '@/routes/routes.js';

    const showIntelligence = ref(false);
    const route = router.currentRoute;
    const items = [
        {id: 1, title: 'Project Sentiment'},
        {id: 2, title: 'Project Details'},
        {id: 3, title: 'Characteristic Intel'},
        {id: 4, title: 'Assessment Analytics'},
        {id: 5, title: 'Survey Analytics'}
    ];

    const props = defineProps ({
        selectedWorkspaceId: [Number, String],
        projectId: [Number, String]
    });
    onMounted(() => {
        showIntelligence.value = ['workspaceDashboard', 'projectDashboard'].includes(route.name);
    });
</script>