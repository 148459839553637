<template>
    <div 
      :class="[currentMode != 'fullscreen' ? 'scale-down' : '']"
      style="padding-left: 303px"
      :style="{color: activeTemplate.settings.color, overflow: 'hidden', paddingRight: activeTemplate.settings.configuration_no < 3 ? '385px' : '235px'}" 
      class="relative rounded flex flex-col justify-center i-gap-10 overflow-y-scroll communicator__template--wrapper"
    >
      <div v-if=" activeTemplate.settings.configuration_no < 3" class="-mt-10">
        <input 
          @input="updateTemplate(activeTemplate)" 
          :disabled="currentMode == 'fullscreen'" 
          :class="{
            'text-center': activeTemplate.settings.configuration_no == 2
          }"  
          class="fw-700 fs-74 w-full bg-transparent pr-5 mb-3" type="text" 
          v-model="activeTemplate.customized_data.title.value"
        >
        <EditableComponent  
          @input="updateTemplate(activeTemplate)" 
          :class="{
            'text-center': activeTemplate.settings.configuration_no == 2,
            'pl-10': activeTemplate.settings.configuration_no == 2,
            'pr-10': activeTemplate.settings.configuration_no == 1
          }" 
          :spacing="0"
          :isDisable="currentMode == 'fullscreen'" 
          v-model="activeTemplate.customized_data.description.value" 
          class=" fw-500 fs-36 w-full overflow-y-auto bg-transparent"
        />
      </div>
      <div v-else class="flex items-center gap-x-7 mb-24">
        <p class="fw-700 fs-140 mb-4" style="font-size: 140px; letter-spacing: .1em;">01</p>
        <div class="w-full">
          <input 
            @input="updateTemplate(activeTemplate)" 
            :disabled="currentMode == 'fullscreen'" 
            class="fw-700 fs-74 w-full bg-transparent -mt-4" type="text" 
            v-model="activeTemplate.customized_data.title.value"
          >
          <EditableComponent  
            @input="updateTemplate(activeTemplate)" 
            :isDisable="currentMode == 'fullscreen'"
            :spacing="0"
            v-model="activeTemplate.customized_data.description.value" 
            class=" fw-500 fs-35 w-full overflow-y-auto bg-transparent -mt-2"
          />
        </div>
      </div>
    </div>
</template>

<script setup>
import TemplateContainer from "../TemplateContainer.vue";
import EditableComponent from "@/elements/atom/EditableComponent.vue";
import { watch } from 'vue';
import useSectionCover from './useSectionCover'

const { updateTemplate } = useSectionCover()

const props = defineProps({
  activeTemplate: Object,
  switchSidebarComponent: Function,
  currentMode: String
})

const emit = defineEmits(['onClickLink'])

//have to refactor
const setInitialData = () => {
  let data = props.activeTemplate.customized_data;
  if (data && data.constructor == Array) {
    props.activeTemplate.customized_data = {
      title: {
        id: 'title',
        value: 'Title'
      },
      description: {
        id: 'description',
        value: 'This is an integral part of any presentation. This simple cover page template will help you to highlight key points in your presentation'
      }
    };
  }
}

watch(
  () => props.activeTemplate,
  (newVal) => {
      setInitialData(newVal)
  },
  { deep: true, immediate: true },
)

</script>

<style scoped>
.h-74 {
  height: 74px;
}
</style>
