<template>
    <div style="width: 280px">
        <div class="mb-4 space-y-5 overflow-y-auto" style="height: 265px;">
            <div v-for="(comment, index) in comments" :key="index">
                <Message 
                    :comment="comment" 
                    :item="item" 
                    :replay="true"
                    :deleteComment="deleteComment"
                    :handleComment="handleComment"
                />
            </div>
        </div>
        <div class="relative bg-primary-three border border-secondary-four rounded px-2.5 pb-3 py-2">
            <textarea 
                placeholder="Write your message here"
                class="bg-transparent w-full text-secondary-two placeholder-secondary-two fs-12"
                spellcheck="false"
                v-model="formForComment.text"
            >
            </textarea>
            <button @click="handleComment(formForComment)" class="absolute fs-10 fw-600 text-primary-four right-2.5 bottom-1.5">Send</button>
        </div>
    </div>
</template>


<script setup>
import { onMounted, watch } from 'vue'
import Message from './Message.vue'
import useComment from './useComment'

const props = defineProps({
    item: Object
})
const {
    getComments,
    handleComment,
    formForComment,
    comments,
    deleteComment
} = useComment(props.item)

onMounted(() => {
    getComments()
})
</script>