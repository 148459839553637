<template>
    <div class="p-10 text-primary-one modal-body" style="width: 680px">
        <div class="i-border-b-1 border-secondary-four mb-5">
            <p class="fs-20 text-primary-one mb-5">Share with Team</p>
        </div>

        <div class="mb-5 pb-5">
            <div class="flex justify-between mb-2">
                <p class="fs-20 text-primary-one fw-600">Share a one time link</p>
                <select disabled class="flex">
                    <option value="4" selected >Can View</option>
                </select>
            </div>
            <div class="flex justify-between gap-5">
                <select v-model="form.valid_for_hour"  class="i-border-1 border-secondary-four w-full pl-2 rounded">
                    <option value="null" disabled selected > Generate link</option>
                    <option value="0.5">30 minute </option>
                    <option value="1">01 hour </option>
                    <option value="2">02 hours </option>
                    <option value="4">04 hours </option>
                    <option value="8">08 hours </option>
                    <option value="24">24 hours </option>
                </select>
                <button @click="(e) => generateUrl(e, form)" class="h-10 w-32 flex items-center justify-center shadow-two rounded bg-primary-four text-primary-three fw-600">Copy link</button>
            </div>
        </div>
        <div class="mb-5 pb-5">
            <div class="flex justify-between mb-2">
                <p class="fs-20 text-primary-one fw-600">Invite people to collaborate</p>
                <select v-model="form.peoples[0].role" class="flex">
                    <option value="2" selected>Can Edit</option>
                    <option value="4">Can View</option>
                </select>
            </div>
            <div class="flex justify-between gap-5">
                <input v-model="form.peoples[0].email" type="email" class="i-border-1 border-secondary-four w-full pl-2 rounded" placeholder="Type email here">
                <button @click="sendPeopleInvitation(form)" class="h-10 w-32 flex items-center justify-center shadow-two rounded bg-primary-four text-primary-three fw-600">Invite</button>
            </div>
        </div>
        <div class="i-border-t-1 border-secondary-four pt-5">
            <ul>
                <li v-for="(invitation, index) in invitations.filter(item => item.email)" class="py-2 flex items-center gap-3" :key="index">
                    <img class="w-8 rounded-full" :src="getAvatar(invitation)" alt="">
                    <span class="fs-18 font-medium"> {{ getFullName(invitation) }}</span>
                    <svg @click="handleRemove(invitation.id, teamId)" width="10" height="10" class="ml-auto cursor-pointer" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.72732 0.333008L5.00065 4.05967L1.27398 0.333008L0.333984 1.27301L4.06065 4.99967L0.333984 8.72634L1.27398 9.66634L5.00065 5.93967L8.72732 9.66634L9.66732 8.72634L5.94065 4.99967L9.66732 1.27301L8.72732 0.333008Z" fill="#3D3D3D"/>
                    </svg>
                    <select disabled v-model="invitation.role" class="flex w-28 pl-2">
                        <option value="2" selected>Can Edit</option>
                        <option value="4">Can View</option>
                    </select>
                </li>
            </ul>
        </div>
    </div>
</template>
<script setup>
    import { ref, onMounted } from 'vue'
    import { useShare } from '@/views/screens/dashboard/useShare.js'
    
    const props = defineProps({
        teamId: [Number, String],
        callback: Function
    })
    const { form, invite, invitations, loadInvitations, getFullName, getAvatar, remove } = useShare()
    
    const generateUrl = async (e, form) => {
        invite(form, props.teamId)
        e.target.innerHTML = 'Copy link'
        let success = await copyToClipboard(window.location.href);
        if(success) {
            e.target.innerHTML = '&#10003; Copied'
        }
    }
    
    const sendPeopleInvitation = async (form) => {
        await invite(form, props.teamId)
        props.callback()
    }
    
    const copyToClipboard = async (text) => {
        let status = null
        try {
            await navigator.clipboard.writeText(text);
            status = true
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
        return status;
    }
    
    const handleRemove = async (invitationId, teamId) => {
        await remove(invitationId, teamId)
        props.callback()
    }

    onMounted(() => {
        loadInvitations(props.teamId)
    })
</script>

<style lang="scss" scoped>

</style>