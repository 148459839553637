<template>
    <div>
        <h3
            :style="{
                top: top+'px',
                bottom: bottom+'px',
                left: left+'px',
                width: width+'px',
            }"
            :class="{'-rotate-90' : isRotate}"
            title="Identifier" 
            class="transform absolute first-text font-poppins fs-14 text-secondary-one ellipse"
        > 
            {{text}}
        </h3>
    </div>
</template>

<script>
export default {
    name: 'VerticalText',
    props: {
        text: [String,Number],
        top: [String, Number],
        bottom: [String, Number],
        left: [String, Number],
        width: [String, Number],
        isRotate: {
            type: Boolean,
            default: true
        }
    }
}
</script>


<style scoped>
.first-text {
    transform-origin: 10% 94%;
    margin-bottom: 5px;
    text-align: left;
    padding-left: 10px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
