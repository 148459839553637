import LayoutNineImg from '@/components/dashboard/components/image/LayoutNineImg.vue'

let layout =  {
    name: 'layout9',
    image: LayoutNineImg,
    rows: [
        { id: 'row_1', class: 'grid gap-5 grid-cols-3 i-grid-rows-2' },
        { id: 'row_2', class: 'grid gap-5' }
    ],
    cards: [
        {
            rows_reference: 'row_1',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: 'i-row-span-2 col-span-2',
                height: 546,
                componentName: 'LargeChart'
            }
        },
        {
            rows_reference: 'row_1',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_1',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },

        
        {
            rows_reference: 'row_2',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 827,
                componentName: 'LargeChart'
            }
        }
    ],
    isSelected: false
}

export default layout