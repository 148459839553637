<template>
    <div
        :style="{ backgroundColor: bgColor }"
        class="flex-shrink-0 fs-12 px-0.1 fw-400 i-w-85 bg-white flex items-center justify-center border border-black rounded-sm "
    >
       <p class="w-full line-clamp clamp-3 text-center">{{ getPercentage(value) }}</p>
    </div>
</template>

<script setup>
const props = defineProps({
    bgColor: {
        default: '#fff'
    },
    value: String
})

const getPercentage = (value) => {
    // if(!value) return '00.0'
    // return value * 10
    return value
}
</script>