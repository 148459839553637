<template>
    <div>
        <div class="mt-8 brainstorm-navigation overflow-y-auto pb-10 space-y-8">
            <div class="flex flex-col pl-10 pr-10"  v-for="(module, moduleIndex) in isolatedModules" :key="module.id">
                <div class="relative flex flex-row shadow-one i-h-50 w-full rounded">
                    <div
                        class="flex items-center bld-level-w i-h-50 hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three cursor-pointer"
                        :style="{'background': getBackground(module)}"
                        @dblclick="setModule(moduleIndex)"
                    >
                        <div class="flex items-center pr-left-content w-full bld-level-w">
                            <span class="font-poppins fw-600 text-primary-three fs-14 px-4 ellipse prg-lft-title">{{ getIndexCode(module, 0, -1) }} {{ module.title }}</span>
                        </div>
                        <div
                            class="absolute pr-hover-div w-10 i-h-50 cursor-pointer items-center justify-center rounded-br rounded-tr" @click="toggleModule(module, moduleIndex)">
                            <img src="/images/icons/chevron_down_black.svg" alt="icon" :class="{'rotate-180': module.expanded}">
                        </div>
                    </div>
                </div>
                <div class="mt-2" v-if="module.expanded">
                    <button v-if="module.layouts.length == 0"  @click="createLayout(module)" class="fs-10 border border-secondary-one px-2 py-1 rounded">Create Layout</button>
                    <div class="my-6" v-for="(layout, layoutIndex) in module.layouts" :key="layout.id">
                        <div  class="flex gap-3 items-center mb-5">
                            <div class="flex gap-3 items-center cursor-pointer" @click="toggleLayout(layout, layoutIndex)">
                                <svg v-if="layout.expanded" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 6L0 -3.49691e-07L8 3.02842e-07L4 6Z" fill="#3D3D3D"/>
                                </svg>
                                <svg v-else width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.5 0.934259L5.09861 4L0.5 7.06574L0.5 0.934259Z" stroke="#8D8D8D"/>
                                </svg>
                                <p class=" font-medium fs-14 text-secondary-one">{{ layout.title }}</p>
                            </div>
                            <div class="flex space-x-2 ml-auto fw-700 fs-14">
                                <span v-if="module.layouts.length > 1" @click="deleteLayout(module, layout, handleAfterDeleteLayout)" class="cursor-pointer">-</span>
                                <span @click="createLayout(module)" class="cursor-pointer">+</span>
                            </div>
                        </div>
                        <!-- :disabled="!canEdit(project.cans)" -->
                        <div :ref="getPdfElement" v-if="layout.expanded">
                            <draggable
                                handle=".handler"
                                draggable=".item"
                                class=""
                                :group="{ name: 'area' }"
                                v-model="layout.module_templates"
                                @change="onRearrangeTemplate($event, layout)"
                            >
                                <div v-for="(template, templateIndex) in layout.module_templates" :key="template.id" class="item html2pdf__page-break">
                                    <div v-if="template && template.communicator_template_id" class="mb-5 relative">
                                        <div
                                            class="rounded minimum-shadow relative sidebar-template-height handler"
                                            :class="{'border border-primary-one' : activeTemplate.id == template.id }"
                                        >
                                            <div
                                                class="absolute h-full w-full inset-0 rounded"
                                                :style="{opacity: template.settings.background_color_opacity+'%', backgroundColor: template.settings.background_color}"
                                            >
                                            </div>
                                            <DownloadBarIcon @click.native="handleUploadTemplate($route.params, activeTemplate, activeModule)" class="w-4 h-4 transform rotate-180 opacity-50 hover:opacity-80 transition duration-500 absolute right-2 top-2 cursor-pointer z-30" />
                                            <img
                                                v-if="template.image && template.image.file_path"
                                                class="absolute h-full inset-0 object-cover w-full"
                                                :src="template.image.file_path"
                                                :style="{opacity: template.settings.background_image_opacity+'%'}"
                                                alt="inst-360"
                                            >
                                            <div @click="handleClickOnTemplate(template, templateIndex, layout, layoutIndex,module, moduleIndex)" class="h-full w-full inset-0 z-10 absolute rounded"></div>
                                            <div class="sidebar-template">
                                                <component
                                                    current-mode="normal"
                                                    :module="activeModule"
                                                    :modules="modules"
                                                    :activeTemplate="template"
                                                    from="sidebar"
                                                    :is="getTemplate(template.template.component)"
                                                />
                                            </div>
                                        </div>
                                        <p data-html2canvas-ignore class="fs-12 font-normal text-primary-one opacity-40 mt-3">#{{templateIndex + 1}} - {{ template.title }}</p>
                                    </div>
                                    <div v-else @click="handleClickOnTemplate(template, templateIndex, layout, layoutIndex,module, moduleIndex)">
                                        <div  class="h-full w-full bg-white sidebar-template-height rounded" :class="{'border border-primary-one' : template.active }"></div>
                                        <p class="fs-12 font-normal text-primary-one opacity-40 mt-3">#{{templateIndex + 1}} - {{ template.title }}</p>
                                    </div>
                                    
                                </div>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>

  <script setup>
    import { set } from 'vue';
    import router from '@/routes/routes.js';
    import useModule from '@/composable/useModule';
  
    import { useLayout } from '@/views/screens/communicator/useLayout';
    import { useTemplate } from '@/views/screens/communicator/useTemplate';

    const { createLayout, deleteLayout } = useLayout();
    const { onRearrangeTemplate, getTemplate, uploadTemplate } = useTemplate();

    const route = router.currentRoute;
    
    const props = defineProps({
        isolatedModules: Array,
        activeModule: Object,
        modules: Array,
        activeTemplate: Object,
        setActiveModule: Function,
        setActiveLayout: Function,
        setActiveTemplate: Function,
        getPdfElement: Function,
        loadInitialData: Function
    });

    let { getBackground, getIndexCode } = useModule();

    const setModule = (index) => {
      props.setActiveModule(index);
    }

    const toggleModule = (item, index) => {
        set(item, 'expanded', !item.expanded);
    }
    const toggleLayout = (item, index) => {
        props.setActiveLayout(index)
        props.activeModule.layouts.forEach(layout => {
            if(layout.id != item.id) {
                set(layout, 'expanded', false)
            }
        })

        //if expand the layout below code handle
        //first template selection of expanded layout
        if(!item.expanded && item.module_templates.length) {
            toggleTemplate(item.module_templates[0], 0, item.module_templates)
        }

        set(item, 'expanded', !item.expanded);
    }
    
    //template, templateIndex, layout, layoutIndex
    const handleClickOnTemplate = (template, templateIndex, layout, layoutIndex, module, moduleIndex) => {
        if(props.activeModule?.id != module.id) {
            setModule(moduleIndex)
        }
        props.setActiveLayout(layoutIndex)
        toggleTemplate(template, templateIndex, layout.module_templates)
        router.push({name: 'communicator', params: {id: route.params.id, communicator_id: route.params.communicator_id}, query:{module_id: module.id, layout_id: layout.id, template_id: template.id}})
        // console.log(template, templateIndex, layout, layoutIndex)
    }

    const toggleTemplate = (template, index, templates) => {
        props.setActiveTemplate(index);
        templates.forEach(item => set(item, 'active', false));
    }

    const handleUploadTemplate = (route,template, module) => {
        const data = {
            communicator_id: route.communicator_id,
            project_id: route.id,
            communicator_module_template_id: template.id,
            communicator_layout_id: template.communicator_layout_id,
            module_id: module.id
        }
        uploadTemplate(data)
    }

    const handleAfterDeleteLayout = (module, layout) => {
        let layouts = module.layouts
        const totalLayout = layouts.length
        const layoutIndex = layouts.findIndex(({id}) => id == layout.id)
        //if it's first layout
        if(layoutIndex == 0) {
            //push all templates to next layout
            module.layouts[1].module_templates = [module.layouts[1].module_templates, ...layout.module_templates]
        } else{
            //push all previous to next layout
            module.layouts[layoutIndex-1].module_templates = [...module.layouts[layoutIndex-1].module_templates, ...layout.module_templates]
        }

        if(layout.expanded) {
            router.push({
                name: 'communicator',
                params: route.params, 
            })
            props.loadInitialData()
        }
        //set first module as selected
    }
  </script>

<style>
.sidebar-template{
    height: 706px; 
    width: 1256px; 
    transform-origin: 0 0;
    transform: scale(.217);
}
.sidebar-template-height{
    height: 154px;
}
</style>