<template>
    <div>
        <ActivityHeader from="activityLog" :style="`padding-left: ${width}`" />
        <div class="overflow-y-auto" style="height: 350px">
            <div
                v-for="item in activities"
                :key="item.id"
            >
                <Row :item="item" from="activityLog" :style="`padding-left: ${width}`" >
                    <template v-slot:progress>
                        <ActivityLogTexts :activities="activitiesLogs ? activitiesLogs[item.id] : []" />
                    </template>
                </Row>
            </div>
        </div>
    </div>
</template>

<script setup>
    import Row from '@/components/dashboard/goal/components/Row.vue'
    import ActivityHeader from '@/components/dashboard/goal/components/ProgressHeader.vue'
    import { ref, computed, onMounted } from 'vue'
    import axios from 'axios'
    import ActivityLogTexts from '@/components/dashboard/goal/components/ActivityLogTexts.vue'

    const props = defineProps({
        width: String,
        parent: Object,
    })

    const activities = computed(() => {
        if(props.parent.i_am == 'key-activity' && props.parent.children) {
            return props.parent.children;
        }
        return [];
    })

    const activitiesLogs = ref([])
    onMounted(async () => {
        if(!props.parent?.id) return
        let result = await axios.get(`workspaces/goals/objectives/key-results/key-activities/${props.parent.id}/tracks`).then(({data}) => data)
        
        if(!result.status) return
        activitiesLogs.value = result.data
    })
</script>
