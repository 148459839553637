import { ref, unref, set, watchEffect, isRef } from 'vue'
import { get, set as l_set } from 'lodash'
import { useTemplate } from '@/views/screens/communicator/useTemplate'
import { getUnixTimeStamp } from '@/plugins/lodash'

export default function useConsultingFrontpage(activeTemplate) {

    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getIconVisibility, getTemplateBorderColor } = useTemplate();

    const onChangeConfig = (config) => {
        updateTemplate(activeTemplate.value)
    }

    const getContentImage = () => get(unref(activeTemplate), 'customized_data.styleConfig.background_image')

    const getContentImageStyle = () => {
        const opacity = get(unref(activeTemplate), 'customized_data.styleConfig.background_image_opacity')
        return {
            opacity: `${opacity}%`
        }
    }

    const getContentBgStyle = (source=null) => {
        const template = unref(activeTemplate)
        const {background_color, background_color_opacity} = source ?? template.customized_data.styleConfig
        return {
            backgroundColor: background_color,
            opacity: `${background_color_opacity}%`
        }
    }

    const getLogo = (key) => {
        const template = unref(activeTemplate)
        const logo =  get(template, `customized_data.${key}`, '/images/instrat_logo.svg')
        return logo ? logo : '/images/instrat_logo.svg'
    }

    const setInitialData = () => {
        const template = unref(activeTemplate)
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
        }
        // console.log(template)
        let checkTitle = "title" in template.customized_data;
        let subTitle = "subTitle" in template.customized_data;
        let checkDescription = "description" in template.customized_data;
        let checkConfig = "styleConfig" in template.customized_data;
        let checkFirstLogo = "firstLogo" in template.customized_data;
        let checkSecondLogo = "secondLogo" in template.customized_data;
        if (!checkTitle) {
            template.customized_data.title = {
                id: getUnixTimeStamp(),
                value: 'Create interactive data driven experiences'
            };
        }
        if (!subTitle) {
            template.customized_data.subTitle = {
                id: getUnixTimeStamp(),
                value: 'Data Driven Visual Communication & Reporting'
            };
        }
        if (!checkDescription) {
            template.customized_data.description = {
                id: getUnixTimeStamp(),
                value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum. Lorem ipsum dolor sit amet, consectetur adipiscing. Lorem ipsum dolor sit amet, consectetur dolor vitus dolor.'
            };
        }
        if(!checkFirstLogo) set(template.customized_data, 'firstLogo', null)
        if(!checkSecondLogo) set(template.customized_data, 'secondLogo', null)
        if(!checkConfig) {
            set(template.customized_data, 'styleConfig', {
                background_image: '',
                background_image_opacity: 100,
                background_color: '#30373B',
                background_color_opacity: 100
            })
        }
        
    }
    
    if(isRef(activeTemplate)) {
        watchEffect(setInitialData)
    }

    return {
        onChangeConfig,
        updateTemplate,
        getContentBgStyle,
        getContentImage,
        getContentImageStyle,
        getDataLayerItem,
        prepareConfiguration, 
        prepareRequest, 
        getPersistance,
        getLogo
    }
}