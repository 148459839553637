import { unref, set, watchEffect, isRef } from 'vue'
import { useTemplate } from '@/views/screens/communicator/useTemplate'

export default function useOkrFrontpage(activeTemplate) {
    const { updateTemplate, prepareConfiguration, prepareRequest, getPersistance, getDataLayerItem, getTemplateBorderColor } = useTemplate();
    const onChangeConfig = (config) => {
        updateTemplate(activeTemplate.value)
    }
    const setInitialData = () => {
        const template = unref(activeTemplate)
        if (template?.customized_data?.constructor == Array) {
            template.customized_data = {}
            template.customized_data.title = {id: 'title', value: 'Lorem dollar ipsum ammet'}
            template.customized_data.subtitle = {id: 'subtitle', value: 'Sub heading'}
        }
        let isItems = "items" in template.customized_data;
        let mainImage = "mainImage" in template.customized_data;
        if(!isItems){
            set(template.customized_data, 'items', _makeItems())
            // updateActiveTemplate()
        }
        if(!mainImage) set(template.customized_data, 'mainImage', null)
    
    }
    const getTitleStyle = () => {
        return {
            fontWeight: '700',
            fontSize: '80px',
            width: '797px',
            height: '256px',
            lineHeight: '128px',
        }
    }
    
    const getSubtitleStyle = () => {
        return {
            fontWeight: '500',
            fontSize: '42px',
            width: '642px',
            height: '125px',
            lineHeight: '140%',
        }
    }

    const updateActiveTemplate = () => updateTemplate(activeTemplate.value)

    const _makeItems = () => {
        return [
            {
                background_image: '',
                background_image_opacity: 100,
                background_color: '#2B80AD',
                background_color_opacity: 100,
                style: {
                    top: '119px',
                    right: '139px',
                    height: '597px',
                    width: '596px',
                },
                pickerPosition: {
                    top: '3px',
                    right: '0'
                }
            },
            {
                background_image: '',
                background_image_opacity: 100,
                background_color: '#2B80AD',
                background_color_opacity: 100,
                style: {
                    top: '356px',
                    right: '377px',
                    height: '597px',
                    width: '596px',
                },
                pickerPosition: {
                    bottom: '-45px',
                    right: '0'
                }
            },
            {
                background_image: '',
                background_image_opacity: 100,
                background_color: '#fff',
                background_color_opacity: 100,
                style: {
                    top: '355px',
                    right: '378px',
                    height: '363px',
                    width: '356px',
                },
                pickerPosition: {
                    bottom: '-45px',
                    right: '1px'
                }
            },

        ]
    }
    

    if(isRef(activeTemplate)) {
        watchEffect(setInitialData)
    }

    return {
        updateActiveTemplate,
        getDataLayerItem,
        prepareConfiguration, 
        prepareRequest, 
        getPersistance,
        getTemplateBorderColor,
        getTitleStyle,
        getSubtitleStyle,
        onChangeConfig
    }
}