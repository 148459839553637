<template>
  <div class="bg-white layout-dropdown rounded-lg">
        <h1 class="fs-18 text-primary-one pb-4 mb-6 border-b fw-600">Standard Layouts</h1>
        <div class="grid grid-cols-3 layout_wrapper items-start">
            <template v-for="layout in layoutSet" >
                <div
                    :key="get(layout, 'name')" 
                    class="cursor-pointer" 
                    v-if="layout.type !== 'Default'"
                    :class="get(layout, 'isSelected') && 'active'"
                    @click="()=>{
                        makeSelected(layout)
                        $emit('getSelectedLayout', layout)
                    }"
                >
                    <component :is="get(layout, 'image')" />
                </div>
            </template>
        </div>
        <div>
            <slot name="confirmation"></slot>
        </div>
    </div>
</template>

<script setup>
    import { layoutSet } from '@/mock/dashboard/request.js'
    import { get } from 'lodash'
    
    const makeSelected = (item) => {
        layoutSet.value.forEach((layout) => {
            layout.isSelected = (get(layout, 'name') == item.name)
        })
    }
</script>

<style>
  .teamwork-dashboard-wrapper{
    height: 1045px;
    overflow-y: auto;
    max-width: 1565px;
    width: 100%;
    margin-left: auto;
    padding: 0px 65px 40px 40px;
    margin-top: -45px;
  }
  .layout-dropdown {
    width: 660px;
    /* height: 658px; */
    box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.12);
    padding: 30px;
  }
  .layout-dropdown.position {
    top: calc(100% + 17px);
    left: 50%;
    transform: translateX(-50%);
  }
  .layout-dropdown::before{
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    border: 15px solid;
    border-color: transparent transparent #fff;
    filter: shadow(0px 15px 40px rgba(0, 0, 0, 0.12));
  }
  .layout-dropdown .layout_wrapper{
    gap: 18px;
  }
  .layout-dropdown .layout_wrapper>div{
    width: 188px;
    height: auto;
    border: 2px solid #ededed;
    padding: 4px;
    border-radius: 4px;
    position: relative;
  }
  .layout-dropdown .layout_wrapper>div:is(:hover, .active){
    border-color: #2B80AD;
  }
  .layout-dropdown .layout_wrapper>div svg.selected{
    display: none;
  }
  .layout-dropdown .layout_wrapper>div:is(:hover, .active) svg.selected{
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
  }
</style>