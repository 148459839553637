<template>
    <div>
        <ProgressHeader 
            from="progress" 
            :style="`padding-left: ${width}`" 
            :activities="activities" 
            :goalId="goalId"
        />
        <div class="overflow-y-auto" style="height: 308px">
            <div v-for="item in activities" :key="item.id" >
                <Row 
                    from="progress"
                    @onDelete="handleDelete(item, parent)"
                    :item="item"
                    :style="`padding-left: ${width}`" 
                    :handleUpdate="handleUpdate"
                    :goalId="goalId"
                    usedIn="progress"
                >
                    <template v-slot:progress>
                        <div class="grid w-full items-end mt-1">
                            <RangeProgress
                                class="-top-1"
                                width="100%" 
                                height="4px"
                            >
                                <RangeBar
                                    :class="item.actual_progress < item.target ? 'z-10' : 'z-0'" 
                                    bg="#2B80AD"
                                    @change="(value) => {
                                        item.actual_value = value
                                        transformGoal(goals, showActiveOnly)                
                                    }"
                                    :text="`Actual: ${Math.round(item.actual_progress)}%`"
                                    :changAble="item.type != 1"
                                    :goal="item.goal"
                                    :baseLine="item.baseline"
                                    :item="item"
                                    v-model="item.actual_progress"
                                    @onDragEnd='hasUnSaveItem = true'
                                />
                                <RangeBar
                                    bg="#ABA62B" 
                                    :text="`Target: ${Math.round(item.target)}%`"
                                    :changAble="true"
                                    :goal="item.goal"
                                    :baseLine="item.baseline"
                                    :item="item"
                                    v-model="item.target"
                                    @change="() => {
                                        transformGoal(goals, showActiveOnly)                
                                        
                                    }"
                                    @onDragEnd='hasUnSaveItem = true'
                                />
                            </RangeProgress>
                            <div class="w-full fs-10 fw-500 flex items-center justify-between text-center" style="margin-top: 2px; margin-bottom: 3px;">
                                <span @click="handlePicker(item, 'start_date')" class="flex items-center gap-1">
                                    Start Date: 
                                    {{ !!item.start_date && dateFormat(new Date(item.start_date), 'dd-MM-yyyy') }}
                                </span>
                                <span v-if="item.end_date" @click="handlePicker(item, 'end_date')" class="flex items-center ga-1">
                                    End Date:
                                    {{ dateFormat(new Date(item.end_date), 'dd-MM-yyyy') }}
                                </span>
                                <span v-else @click="handlePicker(item, 'end_date')" class="flex items-center ga-1">
                                    End Date:
                                    {{ dateFormat(new Date(item.end_Date), 'dd-MM-yyyy') }}
                                </span>
                            </div>
                            <RangeProgress
                                width="100%" 
                                height="2px"
                            >
                                <RangeBar 
                                    :class="getProgress(item) > item.actual_progress ? 'z-10' : ''" 
                                    :bg="(item.actual_progress > getProgress(item)) ? '#2BAD7E' : '#2B80AD'" 
                                    tooltipPosition="bottom"
                                    :hasTooltip="false"
                                    :value="item.actual_progress"
                                    :goal="item.goal"
                                    :baseLine="item.baseline"
                                    :item="item"
                                />
                                <RangeBar 
                                    :class="getProgress(item) < item.actual_progress ? 'z-10' : ''" 
                                    :bg="(item.actual_progress < getProgress(item)) ? '#AD2B81' : '#2B80AD'"
                                    tooltipPosition="bottom"
                                    :value="getProgress(item)" 
                                    :goal="item.goal"
                                    :baseLine="item.baseline"
                                    :item="item"
                                />
                            </RangeProgress>
                        </div>
                    </template>
                </Row>
            </div>
        </div>
        <div class="pb-4 pt-2 flex justify-end">
            <button @click="handleCreate({i_am: 'activity'}, parent)" class="ml-auto mr-5 fs-12 fw-600 text-primary-four">
                + Add Activity
            </button>
        </div>

        <Modal v-model="showDatePicker">
            <Datepicker
                @selected="(value) => setDate(selectedItem.item, selectedItem.key, value)"
                @input="showDatePicker = false"
                input-class="bg-transparent text-right w-62px"
                placeholder="dd-mm-yyyy"
                :format="'dd-MM-yyyy'"
                :value="selectedItem.item[selectedItem.key]"
                :inline="true"
            />
        </Modal>
    </div>
</template>

<script setup>
    import { computed, ref, watch } from 'vue'
    import Row from '@/components/dashboard/goal/components/Row.vue';
    import ProgressHeader from '@/components/dashboard/goal/components/ProgressHeader.vue'
    import RangeProgress from '@/components/dashboard/goal/components/RangeProgress/RangeProgress.vue'
    import RangeBar from '@/components/dashboard/goal/components/RangeProgress/RangeBar.vue'
    import { useGoal } from '@/views/screens/dashboard/useGoal.js';
    import Datepicker from 'vuejs-datepicker';
    import { format as dateFormat } from 'date-fns';
    import Modal from '../../../elements/atom/Modal.vue';
    import UseProgressCalculation from '@/components/dashboard/goal/components/UseProgressCalculation.js'

    const { transformGoal } = UseProgressCalculation()
    const selectedItem = ref({item: {}, key: null})
    const showDatePicker = ref(false)
    const handlePicker = (item, key) => {
        if(item.type == 1) return
        showDatePicker.value = true
        selectedItem.value = {
            item,
            key
        }
    }
    const { goals, getProgress, hasUnSaveItem, showActiveOnly } = useGoal()
    const props = defineProps({
        width: String,
        parent: Object,
        goalId: [String, Number],
        handleCreate: Function,
        handleDelete: Function,
        handleUpdate: Function
    })
    const activities = computed(() => {
        if(props.parent.i_am == 'key-activity' && props.parent.children) {
            return props.parent.children;
        }
        return [];
    });

    const setDate = (item, key, value) => {
        let date = dateFormat(new Date(value), 'yyyy-MM-dd')
        item[key] = date
    }

    watch(showDatePicker, () => {
        transformGoal(goals.value, showActiveOnly.value)                
    })
</script>