<template>
    <div  class="flex items-start gap-5">
        <div style="width: 526px" class="tools-panel border border-primary-four h-10 bg-primary-three shadow-one px-5 rounded flex items-center mb-7 justify-between">
            <img
                class="w-5 mr-4 cursor-pointer"
                src="/images/icons/brainstormV2/save_as.svg" alt=""
            >
            <input @input="$emit('updateCommunicator', communicator)" class="bg-transparent pr-5 fs-14 fw-600 text-primary-one i-border-r-1 intel-title-box communicator-title-box" v-model="communicator.title" type="text" placeholder="Communicator Name"/>

            <div class="flex ml-5 space-x-5 items-center">
                <ResetIcon @click.native="handleReset" class="w-4 h-4 text-primary-one cursor-pointer" />
                <SketchIcon class="w-5 h-5" />
                <div class="relative">
                    <img @click="clickColorPicker" src="/images/icons/brainstormV2/color_picker.svg" alt="picker" class="cursor-pointer" />
                    <input
                        v-if="activeTemplate"
                        ref="colorPickerElem"
                        type="color"
                        v-model="activeTemplate.settings.color"
                        @input="updateTemplate(activeTemplate)"
                        class="absolute top-0 bottom-0 invisible"
                    />
                </div>
                <ShareBoxed class="w-6 h-6" />
            </div>
        </div>
        <div 
            content="Coming soon"
            v-tippy="{ animateFill: false, theme : 'light bordered' }" 
            :class="iconWrapperClasses"
        >
            <Aa
                class="w-5 h-3"
                title="Quick Visualize"
                @click.native="$emit('onClick', 'Monitor')"
            />
        </div>
        <div 
            :class="iconWrapperClasses"
            content="Coming soon"
            v-tippy="{ animateFill: false, theme : 'light bordered' }" 
        >
            <Monument
                class="w-4 h-4"
                title="Quick Visualize"
                @click.native="$emit('onClick', 'Monitor')"
            />
        </div>
        <div 
            :class="iconWrapperClasses"
            content="Coming soon"
            v-tippy="{ animateFill: false, theme : 'light bordered' }" 
        >
            <Photograph
                class="w-5 h-4"
                title="Quick Visualize"
            />
        </div>
        <div 
            :class="iconWrapperClasses"
            content="Coming soon"
            v-tippy="{ animateFill: false, theme : 'light bordered' }" 
        >
            <SketchIcon
                class="w-5 h-5"
                title="Quick Visualize"
            />
        </div>

        <MonitorBorderedIcon
            class="w-10 h-10 minimum-shadow"
            title="Quick Visualize"
            @click.native="$emit('onClick', 'Monitor')"
        />

        <div :class="iconWrapperClasses">
            <PdfDownload
                @click.native="downloadPDF"
                class="w-5 h-5 cursor-pointer"
                :class="{'cursor-wait' : PDFLoader}"
                title="Download pdf"
            />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import usePanzoom from '@/elements/panzoom/usePanzoom.js'
const iconWrapperClasses = 'bg-primary-three minimum-shadow w-10 h-10 rounded flex items-center justify-center border border-primary-four'
const props = defineProps({
    activeTemplate: Object,
    communicator: Object,
    updateTemplate: Function,
    downloadPDF: Function,
    PDFLoader: Boolean
})
const emit = defineEmits(['onResetZoom'])

const { resetZoom } = usePanzoom()
const colorPickerElem = ref()

const clickColorPicker = () => {
    colorPickerElem.value.click()
}
const handleReset = () => {
    resetZoom()
    emit('onResetZoom')
}

</script>
<style>
.communicator-title-box {
  margin-left: -48px;
}
</style>
