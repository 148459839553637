import { set } from 'vue'
import axios from 'axios';
import { useTemplate } from '@/views/screens/communicator/useTemplate'

export default function useCanvas() 
{
    const { updateTemplate } = useTemplate()
    const setInitialData = (template) => 
    {
        if (template.settings.color === true || !template.settings.color) {
            set(template.settings, 'color', '#1C1C1D')
        }
        if (!('background_image_opacity' in template.settings)) {
            set(template.settings, 'background_image_opacity', 100);
        }
        if (!('background_color_opacity' in template.settings)) {
            set(template.settings, 'background_color_opacity', 100);
        }
        if (!('background_color' in template.settings)) {
            set(template.settings, 'background_color', '#fff');
        }
    }
    
    async function getLayout(brainstormId)
    {
        return await axios.get(`brainstorms/business-models/${brainstormId}`).then(({data}) => data.data);
    }

    const getEditableTitle = (template) => {
        return template.customized_data.title.value
    }

    let timeoutId = null
    const editTitle = (title, template) => {
        set(template.customized_data.title, 'value', title)

        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            updateTemplate(template)
        }, 1000)
    }

    return {
        setInitialData,
        getLayout,
        getEditableTitle,
        editTitle
    }
}