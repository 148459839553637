import { pick } from 'lodash';

import { getParameterValue, getDefinitionStyle, getParameterDefinition, getParameterDefinitionColor, getSystemProperties, getProperties } from '@/store/services/traits/parameter.js';
import Definition from '@/store/services/traits/definition.js'

export const useMetricSummary = () => {

    const getBackground = (collection, template, property, index, key = 'bg', total=10) => {
        let value       = getParameterValue(collection, property);
        let definition  = getDefinitionStyle(value, index, template.definition_style, total);
        if(definition) {
            return definition[key];
        }
    }

    const _getParameterDefinition = (collection, template, property) => {
        let value = getParameterValue(collection, property);
        return getParameterDefinition(value, template.definition_style);
    }

    const _getParameterDefinitionColor = (collection, template, property) => {
        let value = getParameterValue(collection, property);
        return getParameterDefinitionColor(value, template.definition_style);
    }

    const getDefinitionDescription = (collection, template, property) => {
        const definition = new Definition(template);
        let value       = getParameterValue(collection, property);
        return definition.getDefinition(property, value);
    }

    const _getProperties = (parameters, module) => {
        let systemProperties = getSystemProperties(parameters, module?.system_properties);
        return getProperties(parameters, systemProperties).map(item =>  {
            return pick(item, ['title', 'id', 'definition_id']);
        });
    }

    return {
        getBackground,
        _getParameterDefinition,
        _getParameterDefinitionColor,
        getDefinitionDescription,
        _getProperties,
        getParameterValue
    }
}