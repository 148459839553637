  <template>
    <div class="w-full flex flex-col two-by-two-layout">
        <div 
          class="grid w-full h-full border-4 borderColor rounded overflow-x-auto" 
          style="grid-template-columns: 1fr 1fr;"
        >
          <template v-for="(cat, index) in gridLayout.layout_grid_category" >
              <div 
                v-if="selectedCategories.includes(cat.category)" 
                :key="cat.id"
                style="height: 355px;"
              >
                  <LayoutComponent 
                    class="layoutWrapper relative i-p-15 h-full w-full flex-shrink-0 flex-grow-0 border borderColor"
                    :style="{'--backgroundImage': `url(${backgroundImages[index]?.bg})`, '--opacityForImg': backgroundImages[index]?.opacity}"
                    :category="cat" />
              </div>
            </template>
        </div>
    </div>
  </template>
  
  <script setup>
    import LayoutComponent from './components/LayoutComponent.vue';
    import imageOne from '@/assets/images/visualize/2x2-1.webp'
    const selectedCategories = ['key-partner', 'customer-relationship', 'cost-structure', 'revenue-stream'];
    const props = defineProps(['gridLayout'])
    const backgroundImages = [
        {
            bg: imageOne,
            opacity: 1/100*5
        },
        {}, // this empty object to match with index of for loop
        {}, // this empty object to match with index of for loop
        {}, // this empty object to match with index of for loop
        {
            bg: imageOne,
            opacity: 1/100*5
        },
        {}, // this empty object to match with index of for loop
        {}, // this empty object to match with index of for loop
        {
            bg: imageOne,
            opacity: 1/100*5
        },
        {
            bg: imageOne,
            opacity: 1/100*5
        },
    ]

  </script>
  
  <style scoped>
    .two-by-two-layout{
      height: 717px;
      width: 1598px;
      margin-left: 71px;
    }
    .layoutWrapper::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: var(--backgroundImage);
        opacity: var(--opacityForImg);
        pointer-events: none;
    }
    .inner_column{
      grid-template-rows: repeat(2, 100px);
      grid-auto-flow:column;
      min-width: 284px;
      grid-auto-columns: minmax(min-content, max-content);
    }
    .h-324px{
      height: 324px;
    }
    .h-655px{
      height: 655px;
    }
    .list{
        list-style: disc;
        display: grid;
        gap: 15px;
        margin-left: 20px;
        font-size: 16px;
        font-weight: 300px;
        color: #1C1C1D;
    }
    .borderColor{
      border-color: var(--borderColor)
    }
  </style>