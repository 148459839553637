<template>
  <!-- left panel start -->
  <div class="left-panel relative">
    <div class="number fs-14 fw-400 text-right">
      <span>10</span>
      <span>8</span>
      <span>6</span>
      <span>4</span>
      <span>2</span>
      <span>0</span>
    </div>
    <div class="level absolute top-0 h-full left-0">
            <span v-for="(definition, index) in getRevDef" :key="index">
                {{ definition }}
            </span>
    </div>
  </div>
  <!-- left panel end -->
</template>

<script setup>
import {computed} from 'vue'

const props = defineProps({
  definitions: {
    type: Array
  }
})

const getRevDef = computed(() => props.definitions.reverse())
</script>

<style scoped>
.left-panel {
  width: 60px;
}

.left-panel .number {
  display: flex;
  flex-direction: column;
  height: 98%;
  margin-top: 22px;
  justify-content: space-between;
}

.left-panel .level {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  transform: rotate(180deg);
}

.left-panel .level span {
  height: 135px;
  writing-mode: vertical-rl;
  text-align: center;
}

.left-panel .definition {
  position: absolute;
  top: 0;
  left: 35px;
  right: 35px;
  height: 100%;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
}

.left-panel .definition::before {
  content: '';
  border-left: 1px solid var(--color);
  position: absolute;
  left: 0;
  top: -14px;
  height: calc(100% + 28px);
}

.left-panel .definition > div {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 127px;
  top: 11px;
}

.left-panel .definition > div::before {
  content: '';
  border-top: 1px solid var(--color);
  position: absolute;
  left: -50px;
  top: 0;
  width: calc(100% + 60px);
}

.left-panel .definition > div:last-child ::after {
  content: '';
  border-top: 1px solid var(--color);
  position: absolute;
  left: -50px;
  bottom: 0;
  width: calc(100% + 60px);
}
</style>
