<template> 
    <div 
        :style="{
            backgroundColor: item.bgColor,
            height: height
        }"
        style="width: 200px; border: 1px solid #DBDBDB; border-radius: 20px;"
        class="relative flex items-center flex-col justify-center"
    >
        <input 
            style="font-size: 90px; height: 90px;" 
            type="text" 
            v-model="item.letter"
            @input="$emit('onChangeContent')"
            :maxlength="letterLength"
            class="bg-transparent fw-800 uppercase w-full text-center"
            :disabled="isDisabled"
        >
        <input 
            type="text" 
            v-model="item.text"
            :disabled="isDisabled"
            @input="$emit('onChangeContent')"
            class="bg-transparent fs-24 fw-300  text-center w-full"
        >
        <ColorIcon
            @click.native="clickThColorPicker"
            class="w-4 h-4 absolute right-4 bottom-4 cursor-pointer"
            :class="{'invisible': !showIcon}"
        />
        <input
            @input="$emit('onChangeContent')"
            ref="colorPickerElem" 
            type="color" 
            v-model="item.bgColor"
            class="absolute right-4 bottom-4 invisible"
        />
    </div>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
    item: Object,
    height: {
        default: '190px'
    },
    letterLength: {
        default: 1
    },
    showIcon: Boolean,
    isDisabled: Boolean,
})
const colorPickerElem = ref()
const clickThColorPicker = () => colorPickerElem.value.click()
</script>