import axios from "axios";
import { findIndex } from "lodash";
const source = {
    'main': [
        { label: 'Characteristics', active: true },
        { label: 'Chart', active: false },
        { label: 'Timeline', active: false },
        { label: 'Feedback', active: false },
    ],
    'groupOrAtomic': [
        { label: 'Groups', active: true },
        { label: 'Atomic', active: false }
    ],
    'rangeOrField': [
        { label: 'Ranges', active: true },
        { label: 'Fields', active: false },
    ]
}
const componentMapper = {
    Ranges: 'Range',
    Fields: 'Field',
    Chart: 'Chart',
    Characteristics: 'Range',
    Timeline: 'LineChart',
    Feedback: 'Range'
}
export default class ProgressViewController {
    constructor() {
        this.module = Object(null);
        this.selectedType = Object(null);
        this.moduleIndex = 0;
        this.selectedScenario = Object(null);
        Object.keys(source).forEach(key => {
            this[key] = source[key];
        })
        this.source = source;
        this.isChild = Object(null);
        this.template = 'Range'
        this.showDefStyle = false
    }
    switchComponent(type, sourceKye) {
        let { label } = type;
        this[sourceKye].forEach(item => item.active = false);
        this[sourceKye].find(item => item.label == label).active = true;
        if(sourceKye != 'groupOrAtomic') {
            this.template = componentMapper[label];
        }
        this.isChild = type;
    }
    setScenario(scenario) {
        this.selectedScenario = scenario;
    }

    async setModule(scenario, module, modules) {
        this.moduleIndex = findIndex(modules, {id: module.id});
        this.module = await this.getCollectionsByScenarioModule(scenario.id, module);
    }

    async getCollectionsByScenarioModule(scenarioId, module) {
       return await axios.get(`roadmaps/scenarios/${scenarioId}/modules/${module.id}`).then(({data}) => data.data);
    }

    setSelectedType(type) {
        this.types.map(item => item.active = false);
        type.active = true;
        this.selectedType = type;
    }

    toggleDefStyle() {
        this.showDefStyle = !this.showDefStyle
    }

}
