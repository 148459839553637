import LayoutFourImg from '@/components/dashboard/components/image/LayoutFourImg.vue'

let layout = {
    name: 'layout4',
    image: LayoutFourImg,
    rows: [
        {id: 'row_7', class: 'grid gap-5 grid-cols-2' },
        { id: 'row_8', class: 'grid gap-5 grid-cols-3' },
        { id: 'row_9', class: 'grid gap-5 grid-cols-3 i-grid-rows-2' }
    ],
    cards: [
        {
            rows_reference: 'row_7',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        },
        {
            rows_reference: 'row_7',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 396,
                componentName: 'LargeChart'
            }
        },

        {
            rows_reference: 'row_8',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_8',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_8',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },

        {
            rows_reference: 'row_9',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: 'i-row-span-2 col-span-2',
                height: 546,
                componentName: 'LargeChart'
            }
        },
        {
            rows_reference: 'row_9',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        },
        {
            rows_reference: 'row_9',
            configuration: {
                title: "Chart Title"
            },
            settings: {
                class: '',
                height: 258,
                componentName: 'SmallChart'
            }
        }
    ],
    isSelected: false
}

export default layout