import { onMounted, ref } from "vue"
import axios  from "axios"
import router from '@/routes/routes.js';
import LargeChart from '@/components/dashboard/panels/LargeChart.vue'
import SmallChart from '@/components/dashboard/panels/SmallChart.vue'
import CreateChart from '@/components/dashboard/panels/CreateChart.vue'

import ComboChart from '@/components/dashboard/charts/ComboChart.vue'
import AssessmentChart from '@/components/dashboard/charts/AssessmentChart.vue'
import CustomDataChart from '@/components/dashboard/charts/CustomDataChart.vue'

const dashBoardData = ref({
    id: null
});

export const useTeamDashboard = (configuration) => {    
    const route = router.currentRoute;

    const createDashboard = async (payload) => {
        let dashboard = await axios.post('workspaces/teams/dashboards', payload).then(({data}) => data.data);
        setDashboard(dashboard.id, payload.workspace_team_id);
    }

    const setDashboard = async (dashboardId, teamId) => {
        let { data } = await axios.get(`workspaces/teams/dashboards/${dashboardId}`)
        dashBoardData.value = data.data;

        let workspaceId = route.params.workspaceId;
        let projectId = route.params.projectId || route.query.projectId;
        router.push({name: 'teamDashboard', params: {workspaceId, teamId}, query:{dashboardId, projectId}})
    }

    const getDashboard = async (id) => {
        let { data } = await axios.get(`workspaces/teams/dashboards/${id}`)
        return data.data
    }

    if(configuration && configuration.mountable) {
        let { dashboardId } = route.query;
        let { teamId } = route.params;
        onMounted(() => {
            if(dashboardId) {
                setDashboard(dashboardId, teamId);
            }
        });
    }
    
    const getCards = (cards, row_id) => {
        if(!Array.isArray(cards)) {
            throw new Error('Cards is not an array.');
        }
        return cards.filter(item => item.rows_reference == row_id);
    }
    
    const clearChartData = async (cardId) => {
        let payload = {
            configuration: null
        }
        return await axios.post(`workspaces/teams/dashboards/cards/update/${ cardId }`, payload)
    }

    const deleteCard = async (cardId) => {
        return await axios.delete(`workspaces/teams/dashboards/cards/${cardId}`)
    }

    const deleteDashboard = async (dashboardId) => {
        return await axios.delete(`workspaces/teams/dashboards/${dashboardId}`)
    }

    return {
        createDashboard,
        getDashboard,
        setDashboard,
        dashBoardData,
        getCards,
        deleteCard,
        clearChartData,
        deleteDashboard,
        panels: [
            LargeChart,
            SmallChart,
            CreateChart
        ],
        charts: [
            ComboChart,
            AssessmentChart,
            CustomDataChart
        ]
    }
}