<template>
    <div class="relative z-10">
        <Header 
            :data="activeTemplate?.customized_data"
            :switchSidebarComponent="switchSidebarComponent"
            :activeTemplate="activeTemplate"
            style="--description-width: 462px"
            :hasDescription="false"
            :config_visibility="config_visibility"
            :currentMode="currentMode"
            class="relative z-10"
            :synchronize="synchronize"
        />

        <div class="absolute top-0 left-0 right-0 bottom-0">
            <svg class="dash-line pointer-events-none" width="1766" height="664" viewBox="0 0 1766 664" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26 238H1685C1712.61 238 1735 260.386 1735 288V361.451C1735 389.065 1712.61 411.451 1685 411.451H70.3669C45.8646 411.451 26.0015 431.314 26.0015 455.817V455.817V605C26.0015 632.614 48.3873 655 76.0016 655H1426" style="stroke:var(--borderColor)" stroke-width="3" stroke-dasharray="10 10"/>
                <path d="M1676.83 236.249C1678.21 237.01 1678.21 238.99 1676.83 239.751L1665.72 245.888C1664.38 246.624 1662.75 245.66 1662.75 244.137L1662.75 231.863C1662.75 230.34 1664.38 229.376 1665.72 230.112L1676.83 236.249Z" style="fill:var(--borderColor)"/>
                <path d="M1231.83 236.249C1233.21 237.01 1233.21 238.99 1231.83 239.751L1220.72 245.888C1219.38 246.624 1217.75 245.66 1217.75 244.137L1217.75 231.863C1217.75 230.34 1219.38 229.376 1220.72 230.112L1231.83 236.249Z" style="fill:var(--borderColor)"/>
                <path d="M786.83 236.249C788.207 237.01 788.207 238.99 786.83 239.751L775.717 245.888C774.384 246.624 772.75 245.66 772.75 244.137L772.75 231.863C772.75 230.34 774.384 229.376 775.717 230.112L786.83 236.249Z" style="fill:var(--borderColor)"/>
                <path d="M1659.17 412.751C1657.79 411.99 1657.79 410.01 1659.17 409.249L1670.28 403.112C1671.62 402.376 1673.25 403.34 1673.25 404.863L1673.25 417.137C1673.25 418.66 1671.62 419.624 1670.28 418.888L1659.17 412.751Z" style="fill:var(--borderColor)"/>
                <path d="M1220.17 412.751C1218.79 411.99 1218.79 410.01 1220.17 409.249L1231.28 403.112C1232.62 402.376 1234.25 403.34 1234.25 404.863L1234.25 417.137C1234.25 418.66 1232.62 419.624 1231.28 418.888L1220.17 412.751Z" style="fill:var(--borderColor)"/>
                <path d="M772.17 412.751C770.793 411.99 770.793 410.01 772.17 409.249L783.283 403.112C784.616 402.376 786.25 403.34 786.25 404.863L786.25 417.137C786.25 418.66 784.616 419.624 783.283 418.888L772.17 412.751Z" style="fill:var(--borderColor)"/>
                <path d="M357.17 413.751C355.793 412.99 355.793 411.01 357.17 410.249L368.283 404.112C369.616 403.376 371.25 404.34 371.25 405.863L371.25 418.137C371.25 419.66 369.616 420.624 368.283 419.888L357.17 413.751Z" style="fill:var(--borderColor)"/>
                <path d="M1425.83 653.249C1427.21 654.01 1427.21 655.99 1425.83 656.751L1414.72 662.888C1413.38 663.624 1411.75 662.66 1411.75 661.137L1411.75 648.863C1411.75 647.34 1413.38 646.376 1414.72 647.112L1425.83 653.249Z" style="fill:var(--borderColor)"/>
                <path d="M1236.83 653.249C1238.21 654.01 1238.21 655.99 1236.83 656.751L1225.72 662.888C1224.38 663.624 1222.75 662.66 1222.75 661.137L1222.75 648.863C1222.75 647.34 1224.38 646.376 1225.72 647.112L1236.83 653.249Z" style="fill:var(--borderColor)"/>
                <path d="M788.83 653.249C790.207 654.01 790.207 655.99 788.83 656.751L777.717 662.888C776.384 663.624 774.75 662.66 774.75 661.137L774.75 648.863C774.75 647.34 776.384 646.376 777.717 647.112L788.83 653.249Z" style="fill:var(--borderColor)"/>
                <path d="M370.83 653.249C372.207 654.01 372.207 655.99 370.83 656.751L359.717 662.888C358.384 663.624 356.75 662.66 356.75 661.137L356.75 648.863C356.75 647.34 358.384 646.376 359.717 647.112L370.83 653.249Z" style="fill:var(--borderColor)"/>
                <path d="M27.7508 542.83C26.99 544.207 25.01 544.207 24.2492 542.83L18.1122 531.717C17.376 530.384 18.3402 528.75 19.8629 528.75L32.1371 528.75C33.6598 528.75 34.624 530.384 33.8878 531.717L27.7508 542.83Z" style="fill:var(--borderColor)"/>
            </svg>
            <template v-if="progressSentiment">
                <ElementForRoadmap 
                    v-for="(singlePosition, index) in position" 
                    :key="index"
                    :style="{'--left': singlePosition.left, '--bottom': singlePosition.bottom}"
                    :data="getElement(activeTemplate, index)"
                    :index="index"
                    :class="singlePosition.style!=null&&'style-two'"
                    :isUnderLine="singlePosition.isUnderLine"
                    :switchSidebarComponent="switchSidebarComponent"
                    :activeTemplate="activeTemplate"
                    limit="8"
                    :progressSentiment="progressSentiment"
                    :config_visibility="config_visibility"
                    :currentMode="currentMode"
                />
            </template>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import ElementForRoadmap from './component/ElementForRoadmap.vue'
    import Header from './component/Header.vue'
    import useRoadMap from "./useRoadMap"

    const { getElement } = useRoadMap()
    const props = defineProps({
        activeTemplate: Object,
        progressSentiment: Object,
        switchSidebarComponent: Function,
        config_visibility: Boolean,
        synchronize: Boolean,
        currentMode: {
            default: 'normal'
        },
    })
    const elements = ref([])
    elements.value = props.activeTemplate?.customized_data?.element
    const position = [
        {left: '102px', bottom: '-220px', style: null},
        {left: '518px', bottom: '-220px', style: null},
        {left: '963px', bottom: '-220px', style: null},
        {left: '1408px', bottom: '-220px', style: null},

        {left: '1408px', bottom: '-646px', style: "two"},
        {left: '963px', bottom: '-646px', style: "two"},
        {left: '518px', bottom: '-646px', style: "two"},
        {left: '102px', bottom: '-646px', style: "two"},
    ]
</script>

<style scoped>
    .fs-42{
        font-size: 42px;
    }
    .dash-line{
        position: absolute;
        bottom: -645px;
        left: -65px;
        z-index: 10;
    }
    .circle{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: block;
        background-color: var(--bg);
        position: absolute;
        left: var(--left);
        bottom: var(--bottom);
        border: 6px solid;
        display: grid;
        place-content: center;
        font-weight: 800;
        font-size: 30px; 
        z-index: 90;
    }
</style>