<template>
    <div>
        <div class="flex space-x-5">
            <ModuleAccordion
                :layout="activeLayout"
                :module="activeModule"
                :layoutIndex="0"
                :moduleIndex="moduleIndex"
            >
                <slot :module="activeLayout"></slot>
                <template #footer>
                  <div class="flex justify-end pr-20 -mt-5 mb-3">
                    <Switcher @next="setNextTemplate" @prev="setPrevTemplate"/>
                  </div>
                </template>
            </ModuleAccordion>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import ModuleAccordion from "./ModuleAccordion";
    import Switcher from "@/elements/atom/Switcher";

    const props = defineProps({
        modules: Array,
        activeModule: Object,
        activeLayout: Object,
        setNextTemplate: Function,
        setPrevTemplate: Function,
        moduleIndex: Number
    });

    const getModuleBg = (module, level = 1) => {
        // return getModuleBg(module, level);
        return '#2b80ad'
    }

</script>

<style scoped>
.canvas-top-gap {
  padding-top: 160px;
  padding-left: 40px;
}
.canvas-container {
  height: 1080px;
  overflow: scroll;
}
.bs-content-div {
  height: 920px;
}
.bs-content-full-div {
  height: 1080px;
}

.add-widget {
  height: 50px;
  width: 50px;
}

.add-group-setting {
  height: 40px;
}
</style>
